<template>
  <div class="sortable" :class="{sortable_selected: selectable}">
      <div class="sortable_item"
           v-for="(item, index) in list"
           :key="`sortable_${index}`">
        <check-item
          :default_styles="as_check"
          @remove="removeItem(item, index)"
          @click="clickedSort(item, index)"
          :selected="selected_index === index"
          :handler="handler"
          :removable="removable">
          <slot name="index" :index="index"></slot>
          <slot name="item" v-bind="item"></slot>
        </check-item>
      </div>
  </div>
</template>

<script>
import Sortable from 'sortablejs'
import CheckItem from './CheckItem'

export default {
  name: 'Sortable',
  components: { CheckItem },
  props: {
    list: {
      type: Array,
      default: () => []
    },
    selected_index: {
      type: [Number, Boolean],
      default: false
    },
    removable: {
      type: Boolean,
      default: false
    },
    as_check: {
      type: Boolean,
      default: false
    },
    handler: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    selectable () {
      return typeof this.selected_index == 'number'
    },
  },
  mounted () {
    let props = {
      onEnd: (evt) => {
        this.$emit('shuffle', {
          from: evt.oldIndex,
          to: evt.newIndex,
        })
        let unlinked_list = JSON.parse(JSON.stringify(this.list)),
            old_p = unlinked_list[evt.newIndex]
        unlinked_list[evt.newIndex] = unlinked_list[evt.oldIndex]
        unlinked_list[evt.oldIndex] = old_p
        this.$emit('shuffled_items', unlinked_list)
      },
    }
    if (this.handler) {
      props['handle'] = ".check-item_handle"
    }
    new Sortable(this.$el, props)
  },
  methods: {
    clickedSort (el, index) {
      this.$emit('selected_index', index)
      this.$emit('selected_el', el)
    },
    removeItem (el, index) {
      this.$emit('removeItem', el)
      this.$emit('removeIndex', index)
    }
  }
}
</script>
