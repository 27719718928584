<template>
  <div
    v-if="pagination_data"
    class="laravel_pagination">
    <div class="laravel_pagination-show">
      <span class="laravel_pagination-show-texts">Show:</span>
      <select @change="changeShow">
        <option v-for="show in show_range"
                :selected="selected_per_page === show"
                :value="show"
        >{{ show }}
        </option>
      </select>
    </div>
    <div class="laravel_pagination-paginate">
      <div class="laravel_pagination-paginate-block">
        <a
          :href="first_page_href"
          @click.prevent="changePage(1)"
          :class="{disabled: is_first_page}">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M8.354 1.646a.5.5 0 0 1 0 .708L2.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
            <path fill-rule="evenodd" d="M12.354 1.646a.5.5 0 0 1 0 .708L6.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
          </svg>
        </a>
        <a
          :href="prev_page_href"
          :class="{disabled: is_first_page}"
          @click.prevent="changePage(pagination_data.current_page - 1)">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
          </svg>
        </a>
        <input :value="pagination_data.current_page"
               ref="input"
               @change="change"
               type="number">
        <a
          :href="next_page_href"
          :class="{disabled: is_last_page}"
          @click.prevent="changePage(pagination_data.current_page + 1)">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
          </svg>
        </a>
        <a
          :href="last_page_href"
          :class="{disabled: is_last_page}"
          @click.prevent="changePage(pagination_data.last_page)">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708z"/>
            <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708z"/>
          </svg>
        </a>
      </div>
      <span class="laravel_pagination-show-texts">Showing {{pagination_data.from}} of {{pagination_data.total}} results</span>
    </div>
  </div>
</template>

<script>
import { DEFAULT_RANGE, QUERY_PAGE_KEY, queryBuilder, SHOW_RANGE } from '../default_table/mixins/FetchMixin'

export default {
  name: 'LaravelPagination',
  props: {
    pagination_data: {
      type: Object,
      default: null
    },
    pagination_obj: {
      type: Object,
      default: null
    },
    selected_per_page: {
      type: Number,
      default: 0
    },
  },
  computed: {
    show_range () {
      return SHOW_RANGE
    },
    is_first_page() {
      return this.pagination_data.current_page <= 1
    },
    is_last_page() {
      return this.pagination_data.current_page >= this.pagination_data.last_page
    },
    first_page_href() {
      let data = JSON.parse(JSON.stringify(this.pagination_obj))
      data[QUERY_PAGE_KEY] = 1
      return `${location.pathname}${queryBuilder(data)}${location.hash}`
    },
    prev_page_href() {
      let data = JSON.parse(JSON.stringify(this.pagination_obj))
      let page = this.pagination_data.current_page - 1
      data[QUERY_PAGE_KEY] = page <= 1 ? 1 : page
      return `${location.pathname}${queryBuilder(data)}${location.hash}`
    },
    next_page_href() {
      let data = JSON.parse(JSON.stringify(this.pagination_obj))
      let page = this.pagination_data.current_page + 1
      data[QUERY_PAGE_KEY] = page > this.pagination_data.last_page ? this.pagination_data.last_page : page
      return `${location.pathname}${queryBuilder(data)}${location.hash}`
    },
    last_page_href() {
      let data = JSON.parse(JSON.stringify(this.pagination_obj))
      data[QUERY_PAGE_KEY] =  this.pagination_data.last_page
      return `${location.pathname}${queryBuilder(data)}${location.hash}`
    }
  },

  methods: {
    changeShow(e) {
      this.$emit('per_page', this.show_range[e.target.selectedIndex] || DEFAULT_RANGE)
    },
    changePage(page) {
      if (page > this.pagination_data.last_page) {
        page = this.pagination_data.last_page
      } else if (page < 1) {
        page = 1
      }
      this.$refs.input.value = page
      this.$emit('change_page', page)
    },
    change(e) {
      this.changePage(parseInt(e.target.value.replace(/[^0-9]/g,'')) || this.pagination_data.from)
    },
    unlinkData() {
      return
    }
  }
}
</script>
