<template>
  <popup-block
    :closeModal="closeModal"
    :shown="opened"
    is_wide
    @close="$emit('close')">

  </popup-block>
</template>

<script>
import PopupBlock from '__module_list/popup/PopupBlock'
import EditQuiz from '../../../Test/EditQuiz'
import DefaultInput from "../../../small-components/DefaultInput";
import Mixin from '../ModalMixins/ModalIndexMixin';
import ButtonActions from "../Parts/ButtonActions";
import DefaultTable from "../../../../../module_list/default_table/DefaultTable";
import ToggleButton from "../Parts/ToggleButton";
import SelectComponent from "../../../../../module_list/dropdown/SelectComponent";
import TrainButtons from "../../../../../module_list/TrainButtons";
import CircleIconOk from 'vue-ionicons/dist/ios-checkmark-circle'
import CircleIconNotOk from 'vue-ionicons/dist/ios-close-circle'
import {Container, Draggable} from "vue-smooth-dnd";
import range from "../../../../assets/manager/range";
import {applyDrag} from "../utils/helpers";
import DraggableUser from "./DraggableUser";

export default {
  name: "EditCreateUserGroupsModal",
  components: {
    DraggableUser,
    Container, Draggable,
    TrainButtons,
    SelectComponent,
    ToggleButton,
    DefaultTable,
    ButtonActions,
    DefaultInput,
    CircleIconOk,
    CircleIconNotOk,
    EditQuiz,
    PopupBlock
  },
  mixins: [Mixin],
  data() {
    return {

    }
  },
  methods: {

    closeModal() {
      this.text_success = ''
      this.text_error = ''
      this.opened = false
    },

    log(val, index) {
      console.log(val, index)
    },
    submitted() {
      this.text_success = ''
      this.text_error = ''
    },
    onDrop(dropResult) {
      this.items = applyDrag(this.items, dropResult);
    },
    relocateUsers(){
      let count = 0;
      const maxNumberOfGroups = 7
      this.items.forEach(group => {count += group.length })

      const estimatedNumberOfGroups = Math.min(count * 4, maxNumberOfGroups)
      if(this.items.length < estimatedNumberOfGroups){
        const flatUsers = [];
        this.items.forEach(group => {
          group.forEach(entity => {flatUsers.push(entity)})
        })
        const array = range(0, estimatedNumberOfGroups).map(() => []);
        let index = 0;
        while(flatUsers.length > 0){
          array[index] = flatUsers.pop();
          index = index === estimatedNumberOfGroups -1 ? 0 : index + 1
        }
        this.$set(this, 'items', array);
      }
    },
    pushUser(user, group){
      this.items[group].push(user);
    },
  }
}
</script>

