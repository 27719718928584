var render = function render(){var _vm=this,_c=_vm._self._c;return _c('table',{staticClass:"user-entity",staticStyle:{"vertical-align":"center"},attrs:{"width":"100%"}},[_vm._m(0),_c('tbody',_vm._l((_vm.getUsers),function(user){return _c('tr',[_c('td',[_c('span',{staticClass:"centred-cell"},[_vm._v(_vm._s(user.email))])]),_c('td',[_c('span',{staticClass:"centred-cell"},[_vm._v(_vm._s(_vm.camelize(user.firstName) + ' ' + _vm.camelize(user.lastName)))])]),_c('td',[_c('div',{staticClass:"field has-addons"},[_c('div',{staticClass:"control"},[(user.session !== -1)?_c('button',{staticClass:"button is-static is-small"},[_vm._v(" "+_vm._s(decodeURI(user.session.location))+" ")]):_c('div',{staticClass:"dropdown",class:{'is-active': _vm.dropdown === user.id}},[_c('div',{staticClass:"dropdown-trigger"},[_c('button',{staticClass:"button is-info is-small",attrs:{"aria-haspopup":"true","aria-controls":"dropdown-menu"},on:{"click":function($event){_vm.dropdown = _vm.dropdown === user.id ? 0 : user.id}}},[_vm._v("Select location ")])]),_c('div',{staticClass:"dropdown-menu",attrs:{"id":"dropdown-menu-2","role":"menu"}},[_c('div',{staticClass:"dropdown-content"},[_vm._l((_vm.sessions),function(a){return [_c('button',{staticClass:"button dropdown-item",on:{"click":function($event){return _vm.select(a.session_id)}}},[_vm._v(" "+_vm._s(a.session_location)+" ")])]})],2)])])]),_c('div',{staticClass:"control"},[(user.session !== -1)?_c('div',{staticClass:"dropdown",class:{'is-active': _vm.dropdown === user.id}},[_c('div',{staticClass:"dropdown-trigger"},[_c('button',{staticClass:"bi-align-middle button is-small my-auto",class:{
                     'is-disabled': user.session.activity,
                     'is-success': user.session.status === 1,
                     'is-warning': user.session.status === 0,
                     'is-danger': user.session.status > 1,
                  },attrs:{"aria-haspopup":"true","aria-controls":"dropdown-menu"},on:{"click":function($event){_vm.dropdown = _vm.dropdown === user.id ? 0 : user.id}}},[_vm._v(" action ")])]),_c('div',{staticClass:"dropdown-menu",attrs:{"id":"dropdown-menu-1","role":"menu"}},[_c('div',{staticClass:"dropdown-content"},[_vm._l((_vm.actions),function(a){return [_c('button',{staticClass:"button dropdown-item",class:a.color,on:{"click":function($event){return _vm.action(a.text, user.session.id)}}},[_vm._v(" "+_vm._s(a.text)+" ")])]})],2)])]):_c('button',{staticClass:"bi-align-middle button is-small my-auto is-static"},[_vm._v(" action ")])])])]),_c('td',[_c('a',{attrs:{"target":"_blank","href":'/api/v1/user/resource?user_id='+user.id}},[_c('button',{staticClass:"button is-info is-small"},[_vm._v("check cv")])])])])}),0)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',{staticClass:"header"},[_vm._v("Username")]),_c('th',{staticClass:"header"},[_vm._v("Name")]),_c('th',{staticClass:"header"},[_vm._v("Active session")]),_c('th',{staticClass:"header"},[_vm._v("Cv")])])])
}]

export { render, staticRenderFns }