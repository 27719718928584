<template>
  <default-table
    :collapse_line="collapse"
    :items="sessions_computed">
    <template slot="Location" slot-scope="props">
      {{ props.session_location }}
    </template>
    <template slot="Job" width="50%" slot-scope="props">
      {{ props.session_jobName }}
    </template>
    <template slot="Starts" slot-scope="props">
      {{ props.session_jrStartDate }}
    </template>
    <template slot="Members" slot-scope="props">
      {{ props.users ? props.users.length : 0 }}
    </template>
    <template slot="Actions" slot-scope="props">
      <tooltip text="Edit Session" is_inline tooltip_bg="#48c78e">
        <button class="button is-success" @click="$emit('modal', {target: 'init', props})">
          <edit-svg/>
        </button>
      </tooltip>
      <tooltip
        is_inline
        tooltip_bg="#48c78e"
        :text="collapse === props.__col_index ? 'Hide rooms' : 'Show rooms'">
        <button class="button is-success ml-10" @click="e => click_(e)">
          <down-svg/>
        </button>
      </tooltip>
      <tooltip
        is_inline
        class="ml-2"
        tooltip_bg="#48c78e"
        text="Add room">
        <button class="button is-success" @click="$emit('modal', {target: 'new', props})">
          <plus-svg style="font-size: 1.45rem;"/>
        </button>
      </tooltip>
    </template>
    <template v-slot:collapse_line="props">
      <div v-if="!props.room.length"
           class="px-15 py-10">
        <b>No active rooms available</b>
      </div>
      <template v-else>
        <default-table
          class="pr-15 pl-30 py-10"
          :items="getSessionRooms(props.room)">
          <template slot="Title" slot-scope="props" :title="props.title">
            {{ props.title }}
          </template>
          <template slot="Time" slot-scope="props">
            <template v-if="props.meta">
              {{ getTime(props) }}
            </template>
          </template>
          <template slot="Actions" slot-scope="props">
            <div class="d-flex gap-5">
              <tooltip
                text="Join meeting"
                tooltip_bg="#48c78e">
                <default-button @click="joinLink(props)" class="is-primary">
                  <ios-exit/>
                </default-button>
              </tooltip>
              <tooltip
                text="Copy link"
                tooltip_bg="#48c78e">
                <default-button
                  @click="copyLink(props)"
                  class="is-primary">
                  <ios-copy/>
                </default-button>
              </tooltip>
              <tooltip
                text="Start meeting"
                tooltip_bg="#48c78e">
                <default-button
                  class="is-primary"
                  @click="startLink(props)">
                  <ios-play/>
                </default-button>
              </tooltip>
              <tooltip
                is_inline
                text="Settings"
                tooltip_bg="#48c78e">
                <button class="button is-success"
                        @click="$emit('modal', {target: 'edit', room: props })">
                  <ios-settings/>
                </button>
              </tooltip>
            </div>

          </template>
        </default-table>
      </template>
    </template>
  </default-table>
</template>

<script>
import { mapGetters } from 'vuex'
import DefaultTable from '../../../module_list/default_table/DefaultTable'
import IosPerson from 'vue-ionicons/dist/ios-person'
import IosBody from 'vue-ionicons/dist/ios-body'
import IosPlay from 'vue-ionicons/dist/ios-play'
import DownSvg from 'vue-ionicons/dist/ios-arrow-down'
import PlusSvg from 'vue-ionicons/dist/ios-add'
import IosSettings from 'vue-ionicons/dist/ios-settings'
import IosExit from 'vue-ionicons/dist/ios-exit'
import EditSvg from 'vue-ionicons/dist/ios-create'
import Peoples from 'vue-ionicons/dist/ios-people'
import IosStar from 'vue-ionicons/dist/ios-star'
import DefaultButton from '../small-components/DefaultButton'
import TrainButtons from '../../../module_list/TrainButtons'
import bus from '../../bus'
import { Notification } from '../../helpers/httpCatcher'
import moment from 'moment'
import Tooltip from '../../../module_list/tooltip/Tooltip'
import IosCopy from 'vue-ionicons/dist/ios-copy'
import { getDateFormatStrToShow, getDateWithSomeTimeZone } from '../../mixins/dateMixin'

export default {
  name: 'TableSessionComponent',
  components: {
    Tooltip,
    TrainButtons,
    IosPlay,
    IosExit,
    IosSettings,
    IosCopy,
    DefaultButton,
    DefaultTable,
    IosBody,
    IosPerson,
    IosStar,
    EditSvg,
    DownSvg,
    PlusSvg,
    Peoples
  },
  props: {
    upcoming: {
      type: Boolean
    },
    dropdown_update: {
      type: Number,
      default: 0
    }
  },
  computed: {
    ...mapGetters([
      'getUsers',
      'getPermission',
      'sessions'
    ]),
    sessions_computed () {
      return this.sessions !== 0 ? this.sessions.filter(session => {
        const curdata = (new Date()).toISOString()
          .substring(0, 10)
        return this.$props.upcoming || (!this.$props.upcoming && ((session.session_jrEndDate >= curdata && session.session_jrStartDate <= curdata)
          || (session.session_trEndDate >= curdata && session.session_trStartDate <= curdata)))
      }) : []
    },
  },
  data: () => ({
    collapse: -1
  }),
  methods: {
    getSessionRooms (rooms) {
      if (!rooms) return []
      return rooms
        .map(room => ({
          title: room.room_name,
          meta: room.meta_chat,
          sid: room.session_id,
          id: room.id,
          alturl: room.meta.url
        }))
        .sort((a, b) => a.meta && b.meta ? (this.getTime(a) > this.getTime(b) ? 1 : -1) : (a.meta ? 1 : b.meta ? -1 : 0))
    },
    click_ (val) {
      const index = parseInt(val.target.closest('tr').dataset.index)
      if (index === this.collapse) {
        this.collapse = -1
      } else {
        this.collapse = index
      }
    },
    joinLink (room) {
      var strWindowFeatures = 'location=yes,height=600,width=720,scrollbars=yes,status=yes'
      let text = ''
      if (room.meta === null) {
        text = room.alturl
      } else {
        text = room.meta.join_url
      }
      window.open(text, '_blank', strWindowFeatures)
    },
    startLink (room) {
      var strWindowFeatures = 'location=yes,height=600,width=720,scrollbars=yes,status=yes'
      let text = ''

      if (room.meta !== null && null != room.meta.start_url) {
        text = room.meta.start_url
      } else {
        text = room.alturl
      }
      window.open(text, '_blank', strWindowFeatures)
    },
    copyLink (room) {
      let text = ''
      if (room.meta === null) {
        text = room.alturl
      } else {
        text = room.meta.join_url
      }

      this.copyTextToClipboard(text)
      bus.$emit('notification', new Notification('Copied'))
    },
    fallbackCopyTextToClipboard (text) {
      var textArea = document.createElement('textarea')
      textArea.value = text
      // Avoid scrolling to bottom
      textArea.style.top = '0'
      textArea.style.left = '0'
      textArea.style.position = 'fixed'

      document.body.appendChild(textArea)
      textArea.focus()
      textArea.select()

      try {
        var successful = document.execCommand('copy')
        var msg = successful ? 'successful' : 'unsuccessful'
        console.log('Fallback: Copying text command was ' + msg)
      } catch (err) {
        console.error('Fallback: Oops, unable to copy', err)
      }

      document.body.removeChild(textArea)
    },
    copyTextToClipboard (text) {
      if (!navigator.clipboard) {
        this.fallbackCopyTextToClipboard(text)
        return
      }
      navigator.clipboard.writeText(text)
        .then(function () {
          console.log('Async: Copying to clipboard was successful!')
        }, function (err) {
          console.error('Async: Could not copy text: ', err)
        })
    },
    getTime(room) {
      return getDateFormatStrToShow(
        getDateWithSomeTimeZone(room.meta.start_time, room.meta?.timezone),
        '-',
        true
      )
    }
  },
  watch: {
    'dropdown_update'() {
      if (this.collapse < 0) return
      let old_index = this.collapse
      this.collapse = -1
      setTimeout(() => {
        this.collapse = old_index
      })
    }
  }
}
</script>

<style>
.col span {
  transform: translateY(50%);
  display: inline-block;
}

.p10 {
  padding-left: 8px;
  width: 10vw;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  min-width: 150px;
}

.p15 {
  width: 15vw;
  min-width: 200px;
}

.row-collapse {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: .25rem;
  /*padding: .25rem 2.75rem .25rem .75rem;*/
  align-items: center;
}

.row-collapse:hover {
  background-color: #f9fafb;
}

.row-collapse .content {
  margin-bottom: 0;
}
</style>
