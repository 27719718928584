<template>
  <thead>
    <tr class="table_head">
      <td v-if="column_before"
          class="table_head-column_before"/>
      <td
        v-for="(val, index) in columns"
        :key="`table_head-${index}`"
      >
        <div class="table_head-content" :key="vkey">
          {{ getColumnName(val) }}
          <sort-arrows
            @click="changeSort(val)"
            v-if="sortable_field.includes(val)"
            :sort_up="sort_by.field === val && sort_by.asc"
            :sort_down="sort_by.field === val && sort_by.desc"/>
          <template v-if="isFieldHasFilter(val)">
            <default-filter :filterOptions="fieldFilterOptions(val)" @input:filterOptions="e => updHeader(e, val)"/>
          </template>
        </div>
      </td>
      <td v-if="column_after"
        class="table_head-column_after"/>
    </tr>
  </thead>
</template>

<script>
import SortArrows from './SortArrows'
import DefaultFilter from "./DefaultFilter";
export default {
  name: 'TableHead',
  components: {DefaultFilter, SortArrows },
  data: () => ({
    vkey: 0
  }),
  watch: {
    custom_fields(v) {
      this.vkey = this.vkey === 0 ? 1 : 0
    }
  },
  props: {
  custom_fields: {
    type: Object,
    default: () => {}
  },
    t_head_values: {
      type: Array,
      default: () => []
    },
    sortable_field: {
      type: Array,
      default: () => []
    },
    sort_by: {
      type: Object,
      default: () => ({
        asc:null,
        desc:true,
        field:"id"
      })
    },
    column_before: {
      type: Boolean,
      default: false
    },
    column_after: {
      type: Boolean,
      default: false
    },
    fields_name: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    columns () {
      return this.t_head_values
    },
    has_fields_name() {
      return this.fields_name && !!Object.keys(this.fields_name).length
    },

  },
  methods: {
    updHeader(b, field){
      const a={};
      const $a = field.toString().trim().toLowerCase()
      if(!!this.$props.custom_fields[field]){
        a[field] = b;
        this.$emit('input:custom_fields', a);
      }
      a[$a] = b;
      this.$emit('input:custom_fields', a);
    },
    isFieldHasFilter(field){
      return this.$props.custom_fields
        && (( !!this.$props.custom_fields[field] )
          || (!!this.$props.custom_fields[field.toString().trim().toLowerCase()]))
    },
    fieldFilterOptions(field){
      const $a = field.toString().trim().toLowerCase()
      if(!this.$props.custom_fields) return [];
      if(!!this.$props.custom_fields[field]){
        return this.$props.custom_fields[field];
      }
      return this.$props.custom_fields[$a];
    },
    changeSort(val) {
      let data = {
        field: val,
        asc: null,
        desc: null
      }
      if (this.sort_by.asc && this.sort_by.field === val) {
        data.desc = true
      } else {
        data.asc = true
      }
      this.$emit('sort', data)
    },
    getColumnName(column){
      if (!this.has_fields_name) {
        return column
      }
      return this.fields_name[column] || column
    }
  },
}
</script>
