<template>
  <div>
    <template v-if="is_main_test">
      <div v-if="is_arr" style="font-size: .8em; white-space: nowrap">
        <div v-for="(rate, index) in pass_rate"
             :key="index"
             :style="{color: rate.hex_color}">
          <strong>{{ rate.code }}</strong> - {{ rate.min }}%
        </div>
      </div>
      <div v-else>Configure language test</div>
    </template>
    <template v-else>
      {{ pass_rate }}%
    </template>
  </div>
</template>

<script>
export default {
  name: 'QuizPassRate',
  props: {
    pass_rate: {
      type: [Number, Array],
      default: 0
    },
    is_main_test: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    is_arr() {
      return Array.isArray(this.pass_rate)
    }
  }
}
</script>
