(function () {
  document.title = 'Journée Recruitement';

  //console.error('Going ')
  let favicon = document.querySelector('link[rel=icon]');
  if (!favicon) {
    favicon = document.createElement('link');
    favicon.rel = 'icon'
    favicon.href = '/images/logo.ico.png'
    favicon.type = 'image/png'
    document.head.appendChild(favicon)
  }
})()
