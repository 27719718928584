import {
  Notification,
  STATUS_ERROR,
  STATUS_MESSAGE,
  STATUS_SUCCESS,
  STATUS_WARNING
} from '../../application/helpers/httpCatcher';

const NOTIFY_DURATION = 300000
export const NOTIFY_ANIMATION_DURATION = 500

export default {
  actions: {
    actionPushNotification (ctx, { message = '', status = STATUS_MESSAGE }) {
      if (!message) return
      if (!ctx.getters.getNotificationStatuses.includes(status)) {
        status = STATUS_MESSAGE
      }
      const NOTIFICATION = new Notification(message, status, true, ctx.state.notification_id++)
      ctx.commit('mutationPushNotifications', NOTIFICATION)
      setTimeout(() => {
        ctx.dispatch('actionRemoveNotification', NOTIFICATION.id)
      }, NOTIFY_DURATION)
    },
    actionClearAllNotification (ctx) {
      ctx.commit('mutationSetNotifications', [])
    },
    actionRemoveNotification (ctx, id) {
      const NOTIFICATION = ctx.getters.getNotificationById(id)
      if (!NOTIFICATION) return
      NOTIFICATION.visible = false
    }
  },
  mutations: {
    mutationPushNotifications: (state, obj = {}) => {
      state.notifications.push(obj)
    },
    mutationSetNotifications: (state, list = []) => {
      state.notifications = list
    }
  },
  state: {
    notifications: [],
    notification_id: 0
  },
  getters: {
    getNotifications: state => state.notifications,
    getNotificationById: state => (id) => state.notifications.find(el => el.id === id),
    getNotificationStatuses: () => [STATUS_ERROR, STATUS_MESSAGE, STATUS_WARNING, STATUS_SUCCESS]
  }
}
