<template>
    <div v-if="perms !== [] && perms.includes('admin')">
        <button @click="toggle">
            create room
        </button>
        <div v-if="dialog" class="floating-wrapper">
            <div class="floating-dialog">
                <div class="floating-title">
                    <h3>Create room</h3>
                </div>
                <div class="floating-body">
                    <h5>Room name</h5>
                    <input type="text" v-model="name">
                    <h5>Start date</h5>
                    <input type="date" v-model="date">

                    <h5>Start time</h5>
                    <custom-time-picker v-model="time" />
                </div>
                <div class="">
                    <button @click="create">Ok</button>
                    <button @click="toggle">Close</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CustomTimePicker from './CustomTimePicker.vue'

export default {
  name: 'CreateRoom',
  components: { CustomTimePicker },
  data: () => ({
    perms: [],
    dialog: false,
    name: '',
    time: '0:0',
    date: new Date()
  }),
  computed: { ...mapGetters(['permission']) },
  watch: {
    permission (val) {
      this.perms = val !== undefined ? val.permission : []
    }
  },
  mounted () {
    this.$store.dispatch('permission')
  },
  methods: {
    create () {
      const data = {
        type: 2,
        duration: 120,
        topic: this.name,
        date: `${this.date} ${this.time}`
      }

      this.user = this.account !== undefined ? this.account : { firstName: 'fname' }
      const chain = this.$post('/api/v1/zoom/rooms/new', data, { 'x-auth': this.user.x_auth })
      chain.then((e) => {
        this.$emit('success', { message: 'created', data: e.data })
      }).catch((e) => {
        this.$emit('error', { message: 'Cannot create, errors', data: (e.data !== undefined ? e.data : e) })
      })
    },
    toggle () {
      this.dialog = !this.dialog
    }
  }
}
</script>

<style scoped>
.floating-body{
    display: flex;
    flex-flow: column;
}
</style>
