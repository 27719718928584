<template>
  <default-form
    :error_text="error_text"
    :success_text="success_text"
    @submit.prevent="sendForm">
    <p class="mb-10 bold">{{ form_text }}</p>
    <slim-select
      opened_on_select
      :values="getLanguages"
      searchable
      :multiple="multiple"
      :selected_multiple="selected"
      obj_value_name="name"
      @selected="changeSelected"
      @remove="removeSelected"
    />
    <template v-slot:buttons_block>
      <div class="button-wrapper mt-20">
        <button
          :class="{'is-loading': loading}"
          :disabled="!selected.length"
          class="button is-info ml-auto">Save
        </button>
      </div>
    </template>
  </default-form>
</template>

<script>
import DefaultForm from '../small-components/DefaultForm'
import SlimSelect from '../../../module_list/slim_select/SlimSelect'
import {mapActions, mapGetters} from 'vuex'
import axiosService, { DEFAULT_ERROR_REPS_TEXT } from '../../plugins/axiosService'

export default {
  name: 'UserLanguages',
  components: { SlimSelect, DefaultForm },
  props: {
    multiple: {
      type: Boolean,
      default: true
    },
    form_text: {
      type: String,
      default: 'Choose languages your know'
    }
  },
  computed: {
    ...mapGetters([
      'getLanguages',
      'getAccount'
    ])
  },
  data() {
    return {
      selected: [],
      loading: false,
      success_text: '',
      error_text: ''
    }
  },
  created() {
    this.fetchLanguages()
  },
  mounted() {
    this.selected = (this.getAccount.languages || []).map(el => el.id)
  },
  methods: {
    ...mapActions([
      'fetchLanguages'
    ]),
    changeSelected(item) {
      if (this.selected.includes(item.id)) return
      this.selected.push(item.id)
    },
    removeSelected(item) {
      let index_of = this.selected.indexOf(item.id)
      if (index_of >= 0) {
        this.selected.splice(index_of, 1);
      }
    },
    sendForm() {
      if (!this.selected.length) return
      this.success_text = ''
      this.error_text = ''
      axiosService.post('/api/v1/user/languages/change', {
          ids: this.selected
        })
        .then(r => {
          if (r.data.lang && this.getAccount) {
            this.success_text = 'Updated'
            this.getAccount.languages = r.data.lang || []
          }
          window.location.reload();
        })
        .catch(() => {
          this.error_text = DEFAULT_ERROR_REPS_TEXT
        })
    }
  }
}
</script>
