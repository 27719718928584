export default {
  actions: {
    actionRouteChange: (context) => {
      context.dispatch('actionAdditionalRouteName')
      context.dispatch('actionMutationRouteSuffix')
    },
    actionAdditionalRouteName: (context, str = '') => context.commit('mutationAdditionalRouteName', str),
    actionMutationRouteSuffix: (context, str = '') => context.commit('mutationRouteSuffix', str)
  },
  mutations: {
    mutationAdditionalRouteName: (state, str) => state.route_name = str,
    mutationRouteSuffix: (state, str) => state.route_suffix = str,
  },
  state: {
    route_name: '',
    route_suffix: ''
  },
  getters: {
    getAdditionalRouteName: state => state.route_name,
    getRouteSuffix: state => state.route_suffix
  }
}
