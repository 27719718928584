import { routesList } from '../router/routes'
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters([
      'getAccount'
    ]),
    get_routes_list () {
      const dashboardRoute = routesList.find(route => route.name === 'dashboard')
      return dashboardRoute && dashboardRoute.children ? dashboardRoute.children : []
    },
    get_routes_list_permission () {
      if (this.getAccount?.permission?.permission) {
        return this.get_routes_list.filter(route => {
          if (route.meta?.permission) {
            return this.getAccount.permission.permission.some(permission => route.meta.permission === permission && !route.meta.hidden)
          }
        })
      }
      return []
    }
  },
}
