<template>
  <div class="screen centered-content">
    <div class="card-500 card mt-mobile pt-15">
      <step-enter-code/>
    </div>
  </div>
</template>

<script>
import StepEnterCode from '../Login/StepEnterCode'
export default {
  name: 'StepEnterCodeLayout',
  components: { StepEnterCode }
}
</script>
