<template>
  <div class="dropdown" :class="active? 'is-active' : ''">
    <div class="dropdown-trigger">
      <slot default v-if="Object.keys($scopedSlots)['default'] !== undefined"></slot>
      <template v-else>
        <button class="button" :class="classes" aria-haspopup="true" aria-controls="dropdown-menu" @click="open">
          <span>

            <template v-if="multiple">
              <template v-for="(key, index) in keys_selected">
                <template v-if="select">{{items[key][select]}} </template>
                <template v-else>
                  {{items[key]}}
                </template>
                <template v-if="index !== keys_selected.length -1 ">, </template>
              </template>
            </template>
            <template v-else>
              <template v-if="key_selected !== -1">
                <template v-if="select">
                   {{ value[select] }}
                </template>
                <template v-else>
                   {{ value }}
                </template>
              </template>
            </template>

            <template v-if="(multiple && keys_selected.length === 0) || (!multiple && key_selected === -1)">
               {{label}}
            </template>
          </span>
          <span class="icon is-small">
            <ios-down class="is-dark"/>
          </span>
        </button>
        <button class="button" v-if="clearable && (!multiple &&  key_selected !== -1) || (multiple && keys_selected.length > 0)" @click="clearSelection">
          <ios-trash class="is-dark" />
        </button>
     </template>
    </div>
    <div class="dropdown-menu" id="dropdown-menu" role="menu">
      <div class="dropdown-content">
        <div class="field dropdown-item" v-if="searchEnabled">
          <div class="control has-icons-left">
            <input type="text" placeholder="Your trip's name" class="input is-transparent" v-model="searchField">
            <span class="icon is-left">
            <i class="fa fa-search"></i>
          </span>
          </div>
        </div>
        <hr class="dropdown-divider" v-if="searchEnabled">
        <template v-for="(item, key) in items">
          <a @click="event => selectedItem(item, key, event)" class="dropdown-item" :class="isKeyActive(key) ? 'is-active' : ''">
            <template v-if="select">
              {{ item[select] }}
            </template>
            <template v-else>
              {{ item }}
            </template>
          </a>
          <ios-trash @click="clearSelection" v-if="enableTrash"/>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import iosTrash from 'vue-ionicons/dist/ios-trash.vue';
import iosDown from 'vue-ionicons/dist/ios-arrow-down.vue';

export default {
  components: {iosTrash, iosDown},
  name: "SelectComponent",
  props: {
    value: {
      default: ''
    },
    items: {
      type: Array,
      default: () => ([])
    },
    select: {
      type: String,
      required: false,
      default: ''
    },
    enableTrash: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    searchEnabled: {
      default: false,
      type: Boolean,
      required: false
    },
    closeOnSelect: {
      default: true,
      type: Boolean,
      required: false
    },
    class_: {
      type: String
    },
    label: {
      default: 'Select',
      type: String,
      required: false,
    },
    multiple: {
      default: false,
      type: Boolean,
      required: false
    },
    error: {
      default: false,
      type: Boolean,
      required: false
    }
  },
  computed: {
    classes(){
      const _classes = {
        'is-danger':this.error, 'is-outlined': this.error
      }
      if(this.$props.class_){
        const classes_ =  this.$props.class_.split(' ')
        classes_.forEach(cls => {
          _classes[cls] = true
        })
        console.log(_classes)
      }
      return _classes
    }
  },
  data: () => ({
    searchField: '',
    active: false,
    key_selected: -1,
    keys_selected: []
  }),
  mounted(){
    if(this.value){
      let key = this.items.findIndex(item => {
        if(this.selected){
          return item['selected'] === this.value
        } else {
          return item === this.value
        }
      })
      if(key !== -1){
        this.key_selected = key
      }
    }
  },
  methods: {
    isKeyActive(key){
      if(this.$props.multiple){
        return -1 !== this.getKeyIndex(key)
      } else {
        return this.key_selected === key
      }
    },
    getKeyIndex(key){
      return this.keys_selected.findIndex(i => i === key)
    },
    open(e){
      this.active = !this.active
      this.$emit('open', e)
    },
    clearSelection(){
      if(!this.$props.multiple){
        this.key_selected = -1;
        this.$emit('input', null)
        this.$emit('selected', {index: this.key_selected, value: null})
      } else {
        this.keys_selected = [];
        this.$emit('input', [])
        this.$emit('selected', {index: -1, value: []})
      }

    },
    selectedItem(item, key, event){
      if(!this.$props.multiple) {
        this.key_selected = key === this.key_selected ? -1 : key
        const value = this.key_selected === -1 ? item : null

        this.$emit('input',   item)
        this.$emit('selected', {index: key,  value, event: event})
        if(this.$props.closeOnSelect){
          this.active = false
        }
        return;
      }

      const index = this.getKeyIndex(key)
      if(-1 !== index) {
        this.keys_selected.splice(index, 1);
      } else {
        this.keys_selected.push(key);
      }

      const selected = this.$props.items.filter((_e, i) => this.isKeyActive(i))

      this.$emit('input', selected)
      this.$emit('selected', {index: key, value: selected, event: event})

      if(this.$props.closeOnSelect){
        this.active = false
      }
    }
  }
}
</script>

