<template>
  <aside id="aside_dashboard" class="aside_dashboard">
    <div class="aside_dashboard-logo">
      <default-button
        class="aside_dashboard-logo-item"
        :router_obj="{name: 'dashboard.home'}">
        JR
      </default-button>
    </div>
    <div class="aside_dashboard-links"
         ref="links">
      <default-button
        v-for="(route, index) in get_routes_list_permission"
        :router_obj="{name: route.name}"
        class="aside_dashboard-links-item"
        :key="`route_${index}`">
        <img v-if="route.meta.name"
             :src="routeImage(route.meta.name)"
             alt="aside images">
        <span>
          {{ route.meta.name || '-' }}
        </span>
      </default-button>
    </div>

    <div class="aside_dashboard-notifications">
      <div class="aside_dashboard-notifications-wrapper"
           :class="{has_notifications: !!get_notifications}"
           @click="showNotifications">
        <notifications-icon/>
        <div v-show="get_notifications" class="aside_dashboard-notifications-count">{{ get_notifications }}</div>
      </div>
    </div>
    <div class="aside_dashboard-external_links">
      <a :href="dare_link" @click.prevent="goDare">
        <img
          class="aside_dashboard-external_links_logo"
          src="https://dare-tradeacademy.com/wp-content/uploads/2022/04/logo-dare-white-3.png" alt="dare-trade-academy">
      </a>
    </div>
  </aside>
</template>

<script>
import DefaultButton from '../small-components/DefaultButton'
import NotificationsIcon from 'vue-ionicons/dist/md-notifications'
import { Browser } from '@capacitor/browser'
import { mapActions, mapGetters } from 'vuex'
import RouteMixin from '../../mixins/RouteMixin'

export default {
  name: 'AsideDashboard',
  mixins: [RouteMixin],
  components: {
    DefaultButton,
    NotificationsIcon
  },
  computed: {
    ...mapGetters([
      'getNotifications'
    ]),
    dare_link () {
      return this.$constants.DARE_TRADE_URL
    },

    get_notifications () {
      let notifications = this.getNotifications.length
      notifications = parseInt(notifications)
      if (!notifications || notifications < 0) {
        return ''
      } else if (notifications > 99) {
        return 99
      }
      return notifications
    }
  },
  methods: {
    ...mapActions([
      'actionPushNotification'
    ]),
    goDare () {
      Browser.open({ url: this.dare_link })
    },
    showNotifications () {
      this.getNotifications.forEach(el => el.visible = true)
    },
    routeImage (route_name) {
      if (!route_name) {
        return `/images/no-image.svg`
      }
      return `/images/${route_name.toLowerCase()
        .replaceAll(' ', '-')}.svg`
    }
  },
  mounted () {
    //add time to render router links
    setTimeout(() => {
      this.$refs.links.querySelector('.router-link-active')
        ?.scrollIntoView()
    }, 200)
  },
}
</script>
