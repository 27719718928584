<template>
  <div>
    <popup-block
      :closeModal="closeModal"
      is_wider
      :shown="opened"
      @close="$emit('close')">
      <template v-slot:popup_title>Edit session {{ editing.id }}</template>
      <template>
        <tabs>
          <tab
            name="Main Info"
            tab_img_href="/images/quiz-list.svg">
            <default-input type='name' label="Location" required name="Location"
                           v-model="editing.location"></default-input>
            <default-input type='name' label="Job name" required name="Job position"
                           v-model="editing.job"></default-input>
            <default-input type='name'
                           input_textarea
                           input_textarea_rows="2"
                           label="Description" required name="Session description"
                           v-model="editing.desc"></default-input>
            <div class="clearfix tile is-0">
              <div class="column is-6">
                <default-input label="Recruitment session start" required name="Session description"
                               v-model="editing.start1" type="date" ref='calendarTrigger'
                               :max="availableDates(1, true)"
                ></default-input>

                <default-input label="Training session start" required name="Session description"
                               v-model="editing.start2" type="date" ref='calendarTrigger'
                               :min="availableDates(1)"
                               :max="availableDates(3, true)"
                ></default-input>
              </div>
              <div class="column is-6">
                <default-input label="Recruitment session end" required name="Session description"
                               v-model="editing.end1" type="date" ref='calendarTrigger'
                               :min="availableDates(0)"
                               :max="availableDates(2, true)"
                ></default-input>

                <default-input label="Training session end" required name="Session description"
                               v-model="editing.end2" type="date" ref='calendarTrigger'
                               :min="availableDates(2)"
                ></default-input>
              </div>
            </div>
          </tab>


          <tab
            name="Plan meetings"
            tab_img_href="/images/quiz-list.svg">
            <h2>Plan meetings</h2>
            <default-table :style="watch_params.showJr?'':'display: none'"
                           v-if="editing.meetings_jr && editing.meetings_jr.length > 0"
                           :items="editing.meetings_jr">
              <template slot="title" slot-scope="props">
                <default-input v-model="editing.meetings_jr[props.__col_index].title"
                               v-if="is_editing === props.__col_index"
                               type="text" class="mb-0"/>
                <template v-else>{{editing.meetings_jr[props.__col_index].title}}</template>
              </template>
              <template slot="date" slot-scope="props">
                {{ editing.meetings_jr[props.__col_index].date }}
              </template>
              <template slot="start" slot-scope="props">
                <default-input v-model="editing.meetings_jr[props.__col_index].start"
                               v-if="is_editing === props.__col_index"
                               type="time" step="60" class="mb-0"/>
                <template v-else>{{editing.meetings_jr[props.__col_index].start}}</template>
              </template>
              <template slot="end" slot-scope="props">
                <default-input v-model="editing.meetings_jr[props.__col_index].end"
                               v-if="is_editing === props.__col_index"
                               type="time" step="60" class="mb-0"/>
                <template v-else>{{editing.meetings_jr[props.__col_index].end}}</template>
              </template>
              <template slot="update" slot-scope="props">
                <toggle-button v-model="editing.meetings_jr[props.__col_index].enabled"/>
                <button
                  class="button is-small p-0 is-dark is-outlined"
                  :class="{'is-success': props.__col_index !== is_editing, 'is-primary': props.__col_index === is_editing}"
                  @click="edit(props.__col_index)" style="font-size: 1.45rem;height: inherit;">
                  <div class="ion" >
                    <edit-icon height="1em" width="1em" />
                  </div>
                </button>
                  <button v-if="editing.meetings_jr[props.__col_index].duration > 60*24"
                          style="font-size: 1.45rem;height: inherit;"
                          class="button is-small is-danger p-0 is-dark is-outlined" tooltip="Very long duration">
                  <circle-icon-not-ok height="1em" width="1em"/>
                  </button>
                <button v-else-if="!(
                  (editing.start1 <= props.date && editing.end1 >= props.date)
                  || (editing.start2 <= props.date && editing.end2 >= props.date))"
                          style="font-size: 1.45rem;height: inherit;" @click="deleteRoom(props.id)"
                          class="button is-small is-primary p-0 is-dark is-outlined" tooltip="Wrong date selected">
                  <circle-icon-not-ok height="1em" width="1em"/>
                  </button>
              </template>
            </default-table>

            <div class="pt-2">
              <h5 class="pb-4 ">
                Add room
              </h5>
              <train-buttons>
                <default-input type="date" placeholder="Select Date"
                               style="min-width: 240px"
                               v-model="selectedDateForMeeting" :min="editing.start1" :max="editing.end2"
                               :tooltip='"Date should be after " + editing.start1 +" and before " + editing.end1 + " or after "+editing.start2 + " and before " + editing.end2'
                >
                </default-input>
                <default-button slot="2" class="button" @click="createRoomAction"
                                :class="{'is-static': !correctDate, 'is-success': correctDate}" >Create room</default-button>
              </train-buttons>

            </div>
            <div v-if="states">
              <div class="text-block py-2" v-if="states.dates">
                Correct meetings: {{ states.dates.length }}
              </div>
              <div class="text-block py-2" v-if="states.skipped">
                Number of dates without meetings: {{ states.skipped.length }}
              </div>
              <div class="text-block py-2" v-if="states.wrongs">
                Wrong dates meetings: {{ states.wrongs.length }}
              </div>

            </div>
          </tab>


          <tab
            name="Plan participants"
            tab_img_href="/images/quiz-list.svg">
            <participants-plan v-model="editing" @input:meets="e => {meets_plan = e}"/>
          </tab>
        </tabs>
      </template>

      <template v-slot:popup_footer>
        <button class="button is-link is-outlined" @click="submitFormClose">Save</button>
      </template>
    </popup-block>
  </div>
</template>

<script>
import PopupBlock from '__module_list/popup/PopupBlock'
import EditQuiz from '../../../Test/EditQuiz'
import DefaultInput from "../../../small-components/DefaultInput";
import Mixin from '../ModalMixins/ModalIndexMixin';
import ButtonActions from "../Parts/ButtonActions";
import axiosService from "../../../../plugins/axiosService";
import bus from "../../../../bus";
import DefaultTable from "../../../../../module_list/default_table/DefaultTable";
import ToggleButton from "../Parts/ToggleButton";
import SelectComponent from "../../../../../module_list/dropdown/SelectComponent";
import TrainButtons from "../../../../../module_list/TrainButtons";
import CircleIconOk from 'vue-ionicons/dist/ios-checkmark-circle'
import CircleIconNotOk from 'vue-ionicons/dist/ios-close-circle'
import UserAllocateComponents from "../Parts/UserAllocateComponents";
import Tabs from "../../../../../module_list/tabs/Tabs";
import Tab from "../../../../../module_list/tabs/Tab";
import {mapActions} from "vuex";
import EditIcon from "../../../small-components/icons/EditIcon";
import DefaultButton from "../../../small-components/DefaultButton";
import moment from "moment";
import UserListEntity from "./UserListEntity";
import ParticipantsPlan from "./ParticipantsPlan";

export default {
  name: "EditSessionComponent",
  computed: {
    correctDate(){
      return this.selectedDateForMeeting !== '' && this.editing.meetings_jr.findIndex(i => i.date === this.selectedDateForMeeting) === -1
      && ((this.editing.start1 <= this.selectedDateForMeeting && this.editing.end1 >= this.selectedDateForMeeting) ||
          (this.editing.start2 <= this.selectedDateForMeeting && this.editing.end2 >= this.selectedDateForMeeting)
        )
    },

    states(){
      const dates = [];
      const skipped = [];
      const wrongs = [];
      if(!this.editing || !this.editing.meetings_jr)return{}
      this.editing.meetings_jr.forEach(item => {
        if(((this.editing.start1 <= item.date && this.editing.end1 >= item.date) ||
          (this.editing.start2 <= item.date && this.editing.end2 >= item.date))){
          dates.push(item.date);
        } else {
          wrongs.push(item.date)
        }
      })

      let iterate = moment(this.editing.start1);
      while(iterate <= moment(this.editing.end1)){
        if(dates.findIndex(i => iterate.toISOString().substring(0,10) === i) === -1){
          skipped.push(iterate.toISOString().substring(0,10))
        }
        iterate.add(1, 'days')
      }
       iterate = moment(this.editing.start2);
      while(iterate <= moment(this.editing.end2)){
        if(dates.findIndex(i => iterate.toISOString().substring(0,10) === i) === -1){
          skipped.push(iterate.toISOString().substring(0,10))
        }
        iterate.add(1, 'days')
      }
      return {
        dates,
        skipped,
        wrongs
      }
    }
  },
  components: {
    ParticipantsPlan,
    UserListEntity,
    DefaultButton,
    EditIcon,
    Tab,
    Tabs,
    UserAllocateComponents,
    TrainButtons,
    SelectComponent,
    ToggleButton,
    DefaultTable,
    ButtonActions,
    DefaultInput,
    CircleIconOk,
    CircleIconNotOk,
    EditQuiz,
    PopupBlock
  },
  mixins: [Mixin],
  data() {
    return {
      meets_plan: [],
      items: [[]],
      text_success: '',
      selectedDateForMeeting: '',
      groups: [],
      meetings: [[]],
      is_editing: -1,
      text_error: '',
      and_close: false, watch_params: {showJr: true}
    }
  },

  methods: {
    ...mapActions(['updSession', 'createRoom', 'updRoom']),
    deleteRoom(id){
      axiosService.post('/api/v1/zoom/rooms/delete', {id}).then(e => {
        bus.$emit('notification', new Notification('Room successfully deleted.'))
      })

    },
    save() {
      const hash = this.$route.hash;
      switch (hash) {
        case '#plan-meetings':
          if (!this.watch_params.showJr) this.editing.meetings_jr = null
          if (!this.watch_params.showTr) this.editing.meetings_tr = null
          console.log(this.editing);

          const currentList = this.editing.meetings_jr
          currentList.forEach((meeting) => {
            const m = {
              title: meeting.title,
              meeting: meeting.id,
              start1: meeting.date,
              end1: meeting.date,
              start2: meeting.start,
              end2: meeting.end,
              sid: meeting.sid
            }
            console.log('sending', meeting)
            this.updRoom(m)
          })

          // this.updSession(this.editing)
          break;
        case '#participants':
          axiosService.post('/api/v1/admin/users/groups/push', {
            groups: this.items.map(e => e.map(i => i.id)),
            global: this.isGlobal,
            public: this.isPublic,
            session_id: this.editing.id
          })
          break;
        case '#plan-participants':
          axiosService.post('/api/v1/sessions/part/allocateRoom', {
            session: this.editing.id,
            rooms: this.meets_plan.map(e => Object.assign({}, e, {list: e.list.map(d => d.id)}))
          }).then(e => {
            console.log(e.data);
          })
            break;
        default:
          this.updSession(this.editing)
          break;

      }
    },
    GPUSH(v) {
      if (v.value) this.groups.splice(v.index, v.length, v.value)
      else this.groups.splice(v.index, v.length)
    },
    fetch(params) {
      axiosService.post('/api/v1/admin/users/filtered', params).then(e => {
        this.groups = Object.values(e.data)
      })
    },
    load() {
      this.groups = [];
      axiosService.post('/api/v1/admin/users/groups/get', {session_id: this.editing.id}).then(e => {
        this.editing.groups = e.data.map(a => a.group_id)

        const users = e.data.map(entity => {
          entity.user['group'] = entity.group_id
          return entity.user
        })

        const sliced = [];
        users.forEach(user => {
          if (sliced[user.group] === undefined) {
            sliced[user.group] = [user]
          } else {
            sliced[user.group].push(user)
          }
        })

        const slice = [];
        sliced.forEach(item => {
          slice.push(item)
        });
        this.items = slice;
      })
    },

    availableDates(date_number = 0, reverse = false) {
      const dates = [
        this.editing.start1,
        this.editing.end1,
        this.editing.start2,
        this.editing.end2,
      ];
      let last = null;
      if (!reverse) {
        for (let i = 0; i <= date_number && i < dates.length; i++) {
          if (dates[i] != null && dates[i] !== '')
            last = dates[i]
        }
        return last
      } else {
        for (let i = dates.length - 1; i >= date_number; i--) {
          if (dates[i] != null && dates[i] !== '')
            last = dates[i]
        }
        return last
      }
    },
    createRoomAction(){
      if(this.correctDate) {
        let date = this.selectedDateForMeeting;
        const session = {
          start1: this.selectedDateForMeeting,
          end1: this.selectedDateForMeeting,
          start2: '09:00',
          end2: '11:00',
          title: 'Room ' + this.selectedDateForMeeting,
          session_id: this.editing.id,
          users: []
        }
        this.createRoom(session)
        // createRoom(context, session){
        //   const data = {}
        //   data.title = session.title
        //   data.session_id = session.session_id
        //   data.start = {
        //     date: session.start1,
        //     time: session.start2
        //   }
        //   data.end = {
        //     date: session.end1,
        //     time: session.end2
        //   }
        //   data.users = session.users_selected ?? session.users
        //   axiosService.post('/api/v1/zoom/room/create', data).then(e => {
        //     context.commit('UPDROOM', e.data)
        //     bus.$emit('notification', new Notification('Room successfully created.'))
        //   }).catch(e => {
        //
        //     bus.$emit('notification', new Notification('Fields filled incorrectly.', STATUS_ERROR))
        //   })
        // },
      }
    },
    closed() {
    },
    getAvailableDates(e) {
      const day = 24 * 60 * 60 * 1000;
      const _case = e === 'tr' ? 2 : 1
      if (!this.editing['meetings_' + e]) return;
      const [start, end] = [(new Date(this.editing['start' + _case])).getTime(), (new Date(this.editing['end' + _case])).getTime()]
      const days = [];

      for (let i = start; i <= end; i += day) {
        const date = (new Date(i)).toISOString().substring(0, 10)
        if (this.editing['meetings_' + e].findIndex(item => item.date === date) === -1) {
          days.push(date);
        }
      }
      return days;
    },
    edit(id){
      this.is_editing = this.is_editing === -1 ? id: -1
    },
    closeModal() {
      this.text_success = ''
      this.text_error = ''
      this.opened = false
    },
    geturl(meta) {
      try {
        const val = JSON.parse(meta)
        console.log(val)
        return val.url
      } catch (e) {
        return '#'
      }
    },
    submitted() {
      this.text_success = ''
      this.text_error = ''
    },

  },
}
</script>

<style lang="scss">

  .plan-parts-wrapper{
    display: flex;
    flex-flow: row;
    column-gap: 1rem;
    .circle-icon{
      fill: none;
      width:24px;
      height: 24px;
      &.active{
        stroke-width: 4px;
        stroke:#c2ffc2;
      }
    }
    .tabulate{
      padding-top: .5rem;
      padding-left: .75rem;
      border-bottom: 2px gray solid;
      padding-bottom: .25rem;
      background-color: #f3f3f3;
    }
    background-color: #f784323f;
    .col-6{
      background-color: white;
      max-width: 50%;
      width: 50%;

    }
    .user{
      display: flex;
      justify-content: left;
      .name{
        padding-left:.5rem;
        padding-right: 5rem;
      }
      .last-meeting{
        flex-grow: 1;
        text-align: right;
        text-overflow: ellipsis;
        overflow: hidden;
        padding-right: .5rem;
      }
    }
    .meeting{
      border-bottom: gray solid .5px;
      background-color: #f8f8f8;
      padding: .2rem .25rem;
    }

  }
</style>
