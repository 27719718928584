import bus from "../bus";


export const STATUS_ERROR = 'error'
export const STATUS_MESSAGE = 'message'
export const STATUS_WARNING = 'warning'
export const STATUS_SUCCESS = 'success'

class CatcherParams{
  constructor(notifications = true) {
    this.notifications = notifications
  }
}

class Notification{
  constructor(message = '', type = STATUS_MESSAGE, visible = true, id = -1) {
    this.visible = visible
    this.message = message
    this.type = type
    this.id = id
    this.emit.bind(this)
  }
  emit(){
    bus.$emit('notification', this)
    console.log('notify sent')
  }
}
const SERVICE_UNAVAILABLE = new Notification('Request failed.', STATUS_WARNING)
const CODE_ERROR = new Notification('Something went wrong, try to reload page if something not work or contact administrator.', STATUS_ERROR)
const UNPROCESSABLE_ERROR = new Notification('Requested data not found', STATUS_WARNING);
const FORBIDDEN_ERROR = new Notification('Access forbidden', STATUS_ERROR);
const UNAUTHORIZED_ERROR = new Notification('Authorization failed', STATUS_WARNING);

export function statuserror(status){
  switch (status) {
    case 422: return UNPROCESSABLE_ERROR;
    case 403: return FORBIDDEN_ERROR;
    case 401: return UNAUTHORIZED_ERROR;
  }
  return null
}


class HttpCatcher {
  constructor(params = new CatcherParams(true)) {

    this.params = params
    return this
  }

  process(error) {
    return new Promise((a, r) => {
      if (error.response) {
        console.log('Hello in catcher');
        /* Response is available */
        const {data, status, headers} = error.response
        switch(status){
          default:
            const se = statuserror(status)
            if(this.params.notifications && se !== null ){
              bus.$emit('notification', se)
            } else {
              console.log(se, this.params, data.error);
            }
            a({status, data, headers})
        }
      } else if (error.request) {
        // The request was made but no response was received
        if(this.params.notifications){
          bus.$emit('notification', SERVICE_UNAVAILABLE)
          a({data: error, status: undefined})
        }
        console.log(error.request);
      } else {
        bus.$emit('notification', CODE_ERROR)
        a({data: error.request, status: null})
      }

    })
  }



}



export {Notification, HttpCatcher, CatcherParams}
