<script>
import ModalIndexMixin from './ModalIndexMixin'
const TR_PREFIX = 'tr'
const JR_PREFIX = 'jr'
export default {
  name: "SessionMixin",
  mixins: [ModalIndexMixin],
  data: () => ({
    watch_params: {
      s1: false,
      s2: false,
      e1: false,
      e2: false,
      showTr: false,
      showJr: false
    }
  }),
  watch: {
    'editing.start1'() {
      this.watch_params.s1 = true
      this.tryUpdate(JR_PREFIX)
    },
    'editing.start2'() {
      this.watch_params.s2 = true
      this.tryUpdate(TR_PREFIX)
    },
    'editing.end1'(val) {
      this.watch_params.e1 = true
      this.tryUpdate(JR_PREFIX)
    },
    'editing.end2'() {
      this.watch_params.e2 = true
      this.tryUpdate(TR_PREFIX)
    },
  },
  methods:{
    tryUpdate(e) {
      const _case = e === TR_PREFIX ? 2 : 1
      if( /* If set, update on any parameter change, if unset, only when two updated*/
        (this.editing['meetings_' + e] &&
          (this.watch_params['s' + _case] || this.watch_params['e' + _case])) ||
        (!this.editing['meetings_' + e] && (this.watch_params['s' + _case] && this.watch_params['e' + _case]))
      ){
        const [a, b] = [(new Date(this.editing['start' + _case])), (new Date(this.editing['end' + _case]))]
        const gen = this.generate(a, b, e)
        this.$set(this.editing, `meetings_${e}`, gen)
        this.watch_params['s' + _case] = false;
        this.watch_params['e' + _case] = false;
      }
    },
    generate(date1, date2, old){
      const max_meetings = 5;
      let tile = 'tr' === old ? 'Training session' : 'Recruitment  session'

      let day = 24 * 60 * 60 * 1000;
      let start = date1.getTime()
      let end = date2.getTime()
      let array = [];

      let old_data = this.editing[`meetings_${old}`]
      let arr_index = 0;

      if (start > end) return []

      for (let i = start; i <= end && max_meetings > array.length; i += day) {
        let date = (new Date(i)).toISOString().substring(0, 10)
        if (old_data && old_data[arr_index] && old_data[arr_index].date === date) {
          array[arr_index] = old_data[arr_index]
        } else {
          array.push({
            date,
            start: '10:00',
            end: '16:00',
            enabled: true,
            title: `${tile}. Day ${arr_index+1}. Veo Worldwide | No Waste Just Value`,
          })
        }
        arr_index++
      }
      return array
    },
  }

}
</script>
