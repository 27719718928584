var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card user-list-card"},[_c('h2',[_vm._v("User list")]),_c('div',{staticClass:"users pb-12"},[_c('table',{staticClass:"user-entity"},[_vm._m(0),_c('tbody',_vm._l((_vm.getUsers),function(user){return _c('tr',[_c('td',[_c('span',{staticClass:"centred-cell"},[_vm._v(" "+_vm._s(user.email)+" ")])]),_c('td',[_c('span',{staticClass:"centred-cell"},[_vm._v(" "+_vm._s(_vm.camelize(user.firstName) + ' ' + _vm.camelize(user.lastName)))])]),_c('td',[(user.session === -1)?_c('span',{staticClass:"centred-cell"},[_vm._v(" not connected ")]):[_c('div',{staticClass:"field has-addons"},[_c('div',{staticClass:"control"},[_c('button',{staticClass:"button is-static is-small"},[_vm._v(_vm._s(decodeURI(user.session.location)))])]),_c('div',{staticClass:"control"},[_c('button',{staticClass:"bi-align-middle button is-small my-auto",class:{
                     'is-success': user.session.status === 1,
                     'is-warning': user.session.status === 0,
                     'is-danger': user.session.status > 1,
                     'is-disabled': user.session.status > 1
                  },on:{"click":function($event){return _vm.approveUser(user.session.id, user.id)}}},[_vm._v(" "+_vm._s(user.session.status === 1 ? 'approve' : 'decline')+" ")])])])]],2),_c('td',[_c('a',{attrs:{"target":"_blank","href":'/api/v1/user/resource?user_id='+user.id}},[_c('button',{staticClass:"button is-info is-small"},[_vm._v("check cv")])])]),_c('td',[_c('span',{staticClass:"role-name centred-cell"},[_vm._v(_vm._s(user.role))]),_c('div',{staticClass:"dropdown",class:{'is-active': _vm.dropdown === user.id}},[_c('div',{staticClass:"dropdown-trigger"},[_c('button',{staticClass:"button is-info is-small",attrs:{"aria-haspopup":"true","aria-controls":"dropdown-menu"},on:{"click":function($event){_vm.dropdown = _vm.dropdown === user.id ? 0 : user.id}}},[_vm._v("grant")])]),_c('div',{staticClass:"dropdown-menu",attrs:{"id":"dropdown-menu","role":"menu"}},[_c('div',{staticClass:"dropdown-content"},[_vm._l((_vm.approveRoles),function(a){return [_c('button',{staticClass:"button dropdown-item",on:{"click":function($event){$event.preventDefault();return _vm.recrute(user.id, a)}}},[_vm._v(" grant a "+_vm._s(a)+" role ")])]})],2)])])])])}),0)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',{staticClass:"header"},[_vm._v("Username")]),_c('th',{staticClass:"header"},[_vm._v("Name")]),_c('th',{staticClass:"header"},[_vm._v("Active session")]),_c('th',{staticClass:"header"},[_vm._v("Cv")]),_c('th',{staticClass:"header"},[_vm._v("Role ")])])])
}]

export { render, staticRenderFns }