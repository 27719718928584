<template>
  <label class="file_input">
    <span class="file_input-img-wrapper">
       <img alt="cv"
            ref="img"
            @error="replaceByDefault"
            :src="file_source">
    <svg
      v-if="cv_input && !empty_cv_value"
      class="file_input-check"
      xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"  viewBox="0 0 16 16">
      <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>
    </svg>
    </span>

    <input
      @change="updateValue"
      type="file"
      :accept="accept_files"
      hidden/>
    <span class="file_input-file-name">{{get_file_name}}</span>
  </label>
</template>

<script>

import axiosService from '../../application/plugins/axiosService'
import UserMixin from '../../application/mixins/UserMixin'

export default {
  name: 'FileInput',
  mixins: [UserMixin],
  props: {
    file_src: {
      type: String,
      default: ''
    },
    cv_input: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    default_src() {
      return this.cv_input
        ? this.empty_user_cv
        : this.empty_user_avatar
    },
    file_source() {
      if (!this.file_src) {
        return this.default_src
      }
      return this.file_src
    },
    accept_files() {
      return this.cv_input
        ? 'application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword'
        : 'image/png, image/gif, image/jpeg'
    },
    get_file_type() {
      return this.cv_input
        ? 'CV'
        : 'avatar'
    },
    get_file_name() {
      return this.empty_cv_value && this.cv_input
        ? `Upload your ${this.get_file_type}`
        : `Your ${this.get_file_type}`
    }
  },
  data() {
    return {
      empty_cv_value: false
    }
  },
  methods: {
    replaceByDefault() {
      if(this.$refs.img!= null)this.$refs.img.src = this.default_src
      if (this.cv_input) {
        axiosService.get(this.file_src, {
          headers: {
            Accept: 'image/png',
            'resource-type': 'avatar'
          }
        })
        .catch(() => {
          this.empty_cv_value = true
        })
      }
    },
    updateValue(e) {
      if (e.target?.files?.length) {
        let file = e.target.files[0]
        this.$emit('file_uploaded', file)
        this.empty_cv_value = false
        if (!this.cv_input) {
          this.$refs.img.src = URL.createObjectURL(file)
        }
      }
    },
  },
}
</script>
