<script>
export default {
  name: "AllocateMixin",
  data: () => ({
    selector: ['Users without session', 'Users without approved session', 'Users from all sessions', 'Current Session Users'],
    point: '',
  }),
  methods: {
    fetchUsers() {
      const params = {}
      switch (this.point) {
        case "Users without session":
          params.session_id = null;
          break;
        case 'Users without approved session':
          params.approved = false;
          break;
        case 'Users from all sessions':
          params.session_id = "*"
          break;
        case 'Current Session Users':
          params.session_id = this.$props.session_id
      }
      this.$emit('fetch', params)
    },
    itemRemove(index, hub = null){
      const v = this.$props.value
      if(hub === null) this.$emit('splice', {index, length: 1})
      else v[hub].splice(index, 1)

      if(hub !== null)console.log('Removing item from array', index, v, hub);
      else console.log('Removing item from array', index, this.$props.items, hub);
      if(hub !== null)this.$emit('input', v)
    },
    hubAvailability(id){
      return -1 === this.$props.items.findIndex(entity => entity.id === id)
    },
    pushToHub(index, value){
      console.log('Pushing to hub', this.$props.value)
      this.$emit('push', {index, length:  0, value})
    },
    inputAvailability(row, id){
      const v = this.$props.value
      return -1 === v[row].findIndex(entity => entity.id === id)
    },
    pushValue(index, row, value){
      console.log('Pushing to group', this.$props.items);
      this.$emit('pushV', ({
        index,
        row,
        value
      }));
    }
  },

}
</script>
