var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.finalOptions.transition)?_c('div',{directives:[{name:"touch",rawName:"v-touch:swipe.left",value:(_vm.leftSwipe),expression:"leftSwipe",arg:"swipe",modifiers:{"left":true}},{name:"touch",rawName:"v-touch:swipe.right",value:(_vm.rightSwipe),expression:"rightSwipe",arg:"swipe",modifiers:{"right":true}},{name:"touch-options",rawName:"v-touch-options",value:({ swipeTolerance: _vm.finalOptions.swipeTolerance }),expression:"{ swipeTolerance: finalOptions.swipeTolerance }"}],staticClass:"slither-slider"},[_c('slider-controller',{ref:"sliderController",attrs:{"options":_vm.finalOptions,"container-width":_vm.containerWidth},on:{"newNumberOfPages":value => {
      this.numberOfPages = value;
    },"newNumberOfSlides":value => {
      this.numberOfSlides = value;
    },"newActiveIndex":value => {
      this.activeIndex = value;
      this.$emit('changed', value);
    }}},[_vm._t("default")],2),_c('slider-controls',{attrs:{"options":_vm.finalOptions,"show-controls":_vm.finalOptions.controls && this.numberOfSlides > 1,"previous":_vm.$slots.previous,"next":_vm.$slots.next},on:{"next":_vm.next,"prev":_vm.prev}}),_c('slider-dots',{attrs:{"options":_vm.finalOptions,"show-dots":_vm.finalOptions.dots,"number-of-slides":this.numberOfSlides,"number-of-pages":this.numberOfPages,"active-index":this.activeIndex},on:{"goToIndex":_vm.goToIndex}})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }