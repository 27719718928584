<template>
  <div>
    <tabs @tab_change="selectedTab">
      <alert-block
        v-if="error || success"
        :text="error || success"
        :is_success="!!success"
        class="mb-20"
        has_close
      />
      <tab
        name="Languages"
        tab_img_href="/images/languages.svg">
        <default-table
          change_location
          :fetch_url="lang_url"
          response_key_list="list"
          :update_outer="update_obj"
          :active_table="tab_selected === 'Languages'">
          <template slot="id" slot-scope="props">
            {{ props.id }}
          </template>
          <template slot="active" slot-scope="props">
            <toggled
              @input="changeActive(props)"
              :value="props.active || 0"
            />
          </template>
          <template slot="default" slot-scope="props">
            <toggled
              @input="changeDefault(props)"
              :value="props.default || 0"
            />
          </template>
          <template slot="code" slot-scope="props">
            {{ props.code }}
          </template>
          <template slot="name" slot-scope="props">
            {{ props.name }}
          </template>
        </default-table>
      </tab>
      <tab name="Accounts"
           style_fix
           tab_img_href="/images/manage-page.svg">
        <manage/>
      </tab>
      <tab name="Email Templates" style_fix tab_img_href="/images/email-com.svg">
        <div class="row">
          <div class="col-3">
            <div class="block " style="background-color: #1a202c;color: white;min-height: 200px;">
              <template v-for="cat in categories">
                <div class="select is-open cat-list py-2 px-4" @click="toggleCategory(cat)">
                <span class="text">
                  {{ catLang[cat] }}
                </span>
                </div>
                <div class="categories" v-if="openedCategory === cat">
                  <div class="cat-list py-2 px-5 grid-cat" v-for="a in templateCategories[cat]"
                       @click="selectTemplate(a.id)">
 <span class="is_default-template ml-auto" v-if="a['is_default'] === 1">
                   <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" class="mr-2"
                        viewBox="0 0 512 512" height="16px" width="16px" stroke="white"
                        xml:space="preserve">
                      <g>
                        <path d="M474.045,173.813c-4.201,1.371-6.494,5.888-5.123,10.088c7.571,23.199,11.411,47.457,11.411,72.1
                          c0,62.014-24.149,120.315-68,164.166s-102.153,68-164.167,68s-120.316-24.149-164.167-68S16,318.014,16,256
                          S40.149,135.684,84,91.833s102.153-68,164.167-68c32.889,0,64.668,6.734,94.455,20.017c28.781,12.834,54.287,31.108,75.81,54.315
                          c3.004,3.239,8.066,3.431,11.306,0.425c3.24-3.004,3.43-8.065,0.426-11.306c-23-24.799-50.26-44.328-81.024-58.047
                          C317.287,15.035,283.316,7.833,248.167,7.833c-66.288,0-128.608,25.813-175.48,72.687C25.814,127.392,0,189.712,0,256
                          c0,66.287,25.814,128.607,72.687,175.479c46.872,46.873,109.192,72.687,175.48,72.687s128.608-25.813,175.48-72.687
                          c46.873-46.872,72.687-109.192,72.687-175.479c0-26.332-4.105-52.26-12.201-77.064
                          C482.762,174.736,478.245,172.445,474.045,173.813z" fill="white" stroke="16px"/>
                        <path fill="white" stroke="16px" d="M504.969,83.262c-4.532-4.538-10.563-7.037-16.98-7.037s-12.448,2.499-16.978,7.034l-7.161,7.161
                          c-3.124,3.124-3.124,8.189,0,11.313c3.124,3.123,8.19,3.124,11.314-0.001l7.164-7.164c1.51-1.512,3.52-2.344,5.66-2.344
                          s4.15,0.832,5.664,2.348c1.514,1.514,2.348,3.524,2.348,5.663s-0.834,4.149-2.348,5.663L217.802,381.75
                          c-1.51,1.512-3.52,2.344-5.66,2.344s-4.15-0.832-5.664-2.348L98.747,274.015c-1.514-1.514-2.348-3.524-2.348-5.663
                          c0-2.138,0.834-4.149,2.351-5.667c1.51-1.512,3.52-2.344,5.66-2.344s4.15,0.832,5.664,2.348l96.411,96.411
                          c1.5,1.5,3.535,2.343,5.657,2.343s4.157-0.843,5.657-2.343l234.849-234.849c3.125-3.125,3.125-8.189,0-11.314
                          c-3.124-3.123-8.189-3.123-11.313,0L212.142,342.129l-90.75-90.751c-4.533-4.538-10.563-7.037-16.98-7.037
                          s-12.448,2.499-16.978,7.034c-4.536,4.536-7.034,10.565-7.034,16.977c0,6.412,2.498,12.441,7.034,16.978l107.728,107.728
                          c4.532,4.538,10.563,7.037,16.98,7.037c6.417,0,12.448-2.499,16.977-7.033l275.847-275.848c4.536-4.536,7.034-10.565,7.034-16.978
                          S509.502,87.794,504.969,83.262z"/>
                      </g>
                    </svg>
                  </span>
                    <span class="grid-cat-title">{{ a.title }}</span>


                  </div>
                </div>
              </template>

              <div class="is-open cat-list py-2 px-4">
                <button class="button is-fullwidth">Create new</button>
              </div>
              <div class="is-open cat-list py-2 px-4">
                <select-component class_="is-small" :items="Object.values(catLang)" type="array" v-model="testCategory"/>
                <button class="button ml-auto is-small" @click="sendTestCategory">Send test</button>
              </div>
            </div>

          </div>
          <div class="col-9">

            <div class="block " style="background-color: #cec298;min-height: 200px;padding: 1.5rem 1.5rem;">
              <default-input v-model="template.header"
                             placeholder="Subject"
                             style="max-width: 600px;"
                             required>
              </default-input>
              <default-input v-model="template.title"
                             placeholder="Title"
                             style="max-width: 600px;"
                             required>
              </default-input>
              <select-component :items="Object.values(catLang)" type="array" v-model="template.category"/>
              <VueEditor style="background-color: white" v-model="template.body" />
              <custom-checkbox style="max-width: 600px;display: block"
                               name="available"
                               v-model="template['is_default']"
                               :checked="template['is_default']"
                               @change="template['is_default'] = !template['is_default']">
                <strong>Is default template</strong>
              </custom-checkbox>

              <default-button class="button is-primary"
                              @click="updateTemplate">Save
              </default-button>

            </div>
          </div>
        </div>
      </tab>
    </tabs>
  </div>
</template>

<script>
import TabMixin from '../../mixins/TabMixin'
import axiosService, {DEFAULT_ERROR_REPS_TEXT} from '../../plugins/axiosService'
import Tab from '__module_list/tabs/Tab'
import Toggled from '__module_list/toggled/Toggled'
import DefaultTable from '__module_list/default_table/DefaultTable'
import Tabs from '__module_list/tabs/Tabs'
import AlertBlock from '__module_list/alert_block/AlertBlock'
import Manage from "../Manage/Manage";
import DefaultInput from "../small-components/DefaultInput";

import SelectComponent from "__module_list/dropdown/SelectComponent";
import CustomCheckbox from '__module_list/custom-checkbox/CustomCheckbox';

import DefaultButton from '../small-components/DefaultButton'
import bus from "../../bus";
import { VueEditor } from "vue2-editor";
import {Notification, STATUS_SUCCESS, STATUS_ERROR} from "../../helpers/httpCatcher";

export default {
  name: 'SiteConfig',
  mixins: [TabMixin],
  components: {
    DefaultButton,
    CustomCheckbox,
    SelectComponent,
    DefaultInput,
    Manage,
    VueEditor,
    AlertBlock,
    Toggled,
    DefaultTable,
    Tab,
    Tabs
  },
  data() {

    return {
      update_obj: null,
      success: '',
      error: '',
      testCategory: 'No action',
      transformed: '',
      categories: [],
      openedCategory: '',
      template: {
        'title': '',
        'header': '',
        'body': '',
        'category': 'No action',
        'is_default': false
      },
      templateCategories: {},
      catLang: {
        "no-action": 'No action',
        "invitation": "Invite",
        "confirm-email": "Confirm Email",
        "session-approved": 'Approved Session',
        "candidate-approved": "Approved Candidate",
        'invited-to-meeting': 'Meeting invitation',
        'welcome': 'Welcome message',
        'failed-hr': 'Interview rejected',
      },
      tags: [
        {action: "bold", start: '[b]', end: '[/b]', tooltip: 'Bold', symbol: 'B', style: 'font-weight: bold;'},
        {action: "italic", start: '[i]', end: '[/i]', tooltip: 'Italic', symbol: 'I', style: 'font-style: italic;'},
        {
          action: "underline",
          start: '[u]',
          end: '[/u]',
          tooltip: 'Underline',
          symbol: 'U',
          style: 'text-decoration: underline;'
        },
        {
          action: "strike",
          start: '[s]',
          end: '[/s]',
          tooltip: 'Strict',
          symbol: 'S',
          style: 'text-decoration: line-through;text-decoration-color: black;'
        },
        {action: "list", start: '[list]', end: '[/list]', tooltip: 'List', symbol: 'list'},
        {action: "link", start: '[link $url]', end: '[/link]', tooltip: 'Url', symbol: 'url'},
        {action: 'copy', tooltip: 'copy', symbol: 'Copy'},
        {action: 'paste', tooltip: 'paste', symbol: 'Paste'},
        {action: 'toggle', tooltip: 'toggle', symbol: '⌨'},
        {action: 'erase', tooltip: 'erase', symbol: '⌫'},
      ]

    }
  },
  computed: {
    lang_url() {
      return '/api/v1/admin/languages'
    }
  },
  mounted() {
    this.initListeners();
    this.getCategoryList();
  },
  methods: {
    onUpdate ({ getJSON, getHTML }) {
      this.json = getJSON()
      this.html = getHTML()
      console.log('html', this.html)
    },
    /**/
    initListeners(){
      const editor = this.$refs.html_editor;
      console.log('editor', editor);
    },
    testFunction(e) {
      const html = e.originalTarget;
      window.html = html;
      console.log(this.getInputSelection(html));
    },
    moveCursor(pos){
      window.moveCursorTo = pos;
      this.$nextTick((self = this) => {
        let range = document.createRange()
        range.setStart(this.$refs.html_editor, Math.min(this.$refs.html_editor.outerHTML.length, window.moveCursorTo))
        range.select();
        range.collapse(true)
      })
    },

    extractText(drop){
      return new Promise((e,r)=> {
        if(!drop)r('not found');
        for (let i = 0; i < drop.length; i++) {
          if (drop[i].kind === 'string' && drop[i].type ==='text/plain') {
            drop[i].getAsString((s) => {e(s)})
            console.log('found )))')
          }
        }
      })
    },

    getInputSelection(el) {
      var start = 0, end = 0, text, content, range,
        textInputRange, len, endRange;

      if (typeof el.selectionStart == "number" && typeof el.selectionEnd == "number") {
        start = el.selectionStart;
        end = el.selectionEnd;
      } else {
        range = window.getSelection();
        if(range) {
          start = range.anchorOffset;
          end = range.focusOffset;
          if(start > end) {
            const temp = end;
            end = start;
            start = temp;
          }
          content = range.focusNode.textContent
          text = content.substring(start, end)
        }
      }

      return {start, end, text, range, content};
    },
    /**/
    getCategoryList() {
      axiosService.post('/api/v1/templates/categories').then(e => {
        this.categories = e.data.templates;
      })
    },
    getTemplateList() {
      axiosService.post('/api/v1/templates/list').then()
    },
    sendTestCategory(){
      console.log(this.testCategory)
      for (const [key, value] of Object.entries(this.catLang)) {
        if (this.testCategory === value) {
          this.testCategory = key;
          break;
        }
      }

      console.log(this.testCategory)

      axiosService.post('/api/v1/templates/test', {category: this.testCategory}).then(e => {
        bus.$emit('notification', new Notification('Result: ' + e.data.result,
          e.data.result === 'success' ? STATUS_SUCCESS : STATUS_ERROR))

      });
    },
    updateTemplate() {

      console.log(this.template.category)
      for (const [key, value] of Object.entries(this.catLang)) {
        if (this.template.category === value) {
          this.template.category = key;
          break;
        }
      }

      console.log(this.template.category)
      axiosService.post('/api/v1/templates/update', this.template).then(e => {
        this.template = {
          'title': '',
          'header': '',
          'body': '',
          'category': 'No action',
          'is_default': false
        };
        bus.$emit('notification', new Notification('Successfully created template', STATUS_SUCCESS))
      }).catch(e => {
        console.log(e.response.status);
        if(e.response.status === 422){
          if(e.response.data.errors.body){
            bus.$emit('notification', new Notification('Too long template size. ', STATUS_ERROR))
          }
          else {
            bus.$emit('notification', new Notification('Fields filled incorrectly ', STATUS_ERROR))
          }
        }
      })
    },
    createTemplate() {
      this.template = {
        'title': '',
        'header': '',
        'body': '',
        'category': 'No action',
        'is_default': false
      };
    },
    toggleCategory(category) {
      if (!this.templateCategories[category]) {
        axiosService.post('api/v1/templates/list', {category}).then(o => {
          this.templateCategories[category] = o.data;
          this.openedCategory = category;
        })
      } else {
        this.openedCategory = this.openedCategory === category ? 0 : category;
      }
    },
    selectTemplate(id) {
      axiosService.post('api/v1/templates/get', {template_id: id}).then(e => {
        const catid  = e.data.meta_type
        const cat = Object.entries(this.catLang)[catid];
        console.log(cat);
        this.template = Object.assign({}, {
          template_id: id,
          'is_default': e.data['is_default'] === 1 || e.data['is_default'] === true,
          category: cat[1],
          'title': e.data.title,
          'header': e.data.header,
          'body': e.data.body,
        });
        // this.template.category = 0;

      })
    },
    sendData(url, id) {
      axiosService.post(url, {id})
        .then(r => {
          this.error = ''
          this.success = 'Updated'
          if (r.data.items) {
            this.update_obj = r.data.items
          }
        })
        .catch(e => {
          this.success = ''
          this.error = e.response.data.error || DEFAULT_ERROR_REPS_TEXT
        })
    },
    changeActive(item) {
      this.sendData(`${this.lang_url}/active`, item.id)
    },
    changeDefault(item) {
      this.sendData(`${this.lang_url}/default`, item.id)
    }
  }
}
</script>
<style lang="scss">
.select.cat-list {
  display: block;
}

.select.is-open {
  content: "x";
  transform: rotateX(0deg);
}

.increased-height textarea.input {
  height: 200px;
}

.template-control {
  .marker {
    min-width: 48px;
    text-align: center;
  }

  margin-top: 1rem;
  max-width: 600px;
  background-color: white;
  border: 1px solid #dbdbdb;
  border-bottom-width: 0;
}
</style>
