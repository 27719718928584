<template>
  <div>
    <tabs
      @tab_change="selectedTab">
      <tab
        :name="user_list_tab"
        tab_img_href="/images/users.svg">
          <user-tables
            change_location
            user_button
            :active_table="tab_selected === user_list_tab"
            :update_outer="table_user"
            @modal_user="editModalUser"
          />
      </tab>
      <tab
        :name="user_results_tab"
        tab_img_href="/images/score.svg">
        <default-table
          change_location
          fetch_url="/api/v1/quiz/statistics"
          response_key_list="statistics"
          :active_table="tab_selected === user_results_tab"
          :filter_fields_names="user_results_filter_fields_names"
          :fields_name="user_results_field_names">
          <template slot="id" slot-scope="props">
            {{ props.id }}
          </template>
          <template slot="title" slot-scope="props">
            {{ props.title || '' }}
          </template>
          <template slot="lastName" slot-scope="props">
            <template v-if="props.user">
              {{ props.user.firstName || '' }} {{ props.user.lastName || '' }}
            </template>
          </template>
          <template slot="pass_rate" slot-scope="props">
            <template v-if="isNumeric(props.pass_rate)">
              {{ props.pass_rate || 0 }}%
            </template>
            <template v-else>
              <tooltip
                v-for="(a, index) in props.pass_rate"
                :text="a.lvl"
                tooltip_bg="#48c78e"
                style="display:inline-block;"
                :key="'test_result' + index">
                <badge
                  :is_main_test="!!(props.quiz_meta && props.quiz_meta.main)"
                  :result="a.min"
                  :pass_rate="props.pass_rate"/>
              </tooltip>
            </template>
          </template>
          <template slot="-" slot-scope="props">
            <badge
              :is_main_test="!!(props.quiz_meta && props.quiz_meta.main)"
              :result="props.score"
              :pass_rate="props.pass_rate"/>
          </template>
          <template slot="row_after" slot-scope="props">
            <div class="flex flex-wrap justify-content-between">
              <tooltip
                is_inline
                text="Show statistics"
                tooltip_bg="#ffe08a">
                <button class="button is-warning " @click="statisticQuiz(props)">
                  <statistic-icon color="#946c00"/>
                </button>
              </tooltip>
              <tooltip
                is_inline
                text="Show User"
                tooltip_bg="#ffe08a">
                <button class="button is-warning" @click="emitOpenUsers(props)">
                  <user-icon color="#946c00"/>
                </button>
              </tooltip>
            </div>
          </template>
        </default-table>
      </tab>
      <tab
        v-if="getAccountPermissionAdmin"
        tab_img_href="/images/add-new.svg"
        name="New user">
        <edit-user-form
          :users_roles="users_roles"
        />
      </tab>
    </tabs>
    <user-modal
      :obj="modal_user"
      :roles="users_roles"
      @updated_sessions="updateUsersIndex"
      @updated="updateUsersIndex"
      @closed="editModalUser()"
    />
    <user-answers-quiz-modal
      :obj="modal_user_quiz"
      @closed="statisticQuiz()"
    />
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import Tabs from '__module_list/tabs/Tabs.vue'
import Tab from '__module_list/tabs/Tab.vue'
import DefaultTable from '../../module_list/default_table/DefaultTable'
import DefaultButton from './small-components/DefaultButton'
import Configure from './small-components/icons/Configure'
import EditQuizModal from './modals/EditQuizModal'
import UserModal from './modals/UserModal'
import axiosService from '../plugins/axiosService'
import Badge from './small-components/Badge'
import StatisticIcon from './small-components/icons/StatisticIcon'
import UserAnswersQuizModal from './modals/UserAnswersQuizModal'
import UserMixin, {transformUserSessions} from '../mixins/UserMixin'
import UserIcon from 'vue-ionicons/dist/ios-person'
import Tooltip from '../../module_list/tooltip/Tooltip'
import TabMixin from '../mixins/TabMixin'
import UserTables from './tables/UserTable'
import DefaultForm from './small-components/DefaultForm'
import EditUserForm from './Users/EditUserForm'

export default {
  name: 'Admin',
  mixins: [UserMixin, TabMixin],
  components: {
    EditUserForm,
    DefaultForm,
    UserTables,
    Tooltip,
    UserAnswersQuizModal,
    StatisticIcon,
    Badge,
    UserIcon,
    UserModal,
    EditQuizModal,
    Configure,
    DefaultButton,
    DefaultTable,
    Tabs,
    Tab,

  },
  data() {
    return {
      users: [],
      users_roles: [],
      quiz_results: [],
      modal_user_quiz: {},
      table_user: null,
      modal_user: {}
    }
  },
  computed: {
    ...mapGetters(['getAccountPermissionAdmin']),
    user_results_tab() {
      return 'User Results'
    },
    user_list_tab() {
      return 'User List'
    },
    user_results_field_names() {
      return {
        title: 'Title',
        lastName: 'Name',
        pass_rate: 'Rate'
      }
    },
    user_results_filter_fields_names() {
      return {
        language_id: 'Language',
        main: 'Quiz type',
      }
    }
  },
  created() {
    this.getUserRoles()
  },
  methods: {
    isNumeric(e){
      return typeof e === "number"
    },
    setUsers(list) {
      this.users = list.users.map(user => {
        return transformUserSessions(user)
      }) || []
    },
    updateUsersIndex(obj) {
      this.table_user = obj
    },
    editModalUser(user = {}) {
      this.modal_user = user
    },
    statisticQuiz(obj = {}) {
      this.modal_user_quiz = obj
    },
    emitOpenUsers({user}) {
      axiosService.get(`/api/v1/admin/users/${user.id}`)
        .then(r => {
          this.modal_user = r.data.user || {}
        })
    },
    getUserRoles() {
      axiosService.post('/api/v1/user/roles')
        .then(r => {
          this.users_roles = r.data || []
        })
      return []
    },
  }
}
</script>

<style>
  .flex{
    display: flex
  }
  .flex-wrap{
    flex-wrap: wrap;
  }

  .justify-content-between{
    justify-content: space-between;
  }
</style>
