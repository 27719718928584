<template>
  <div class="field has-addons">
    <div
      v-for="st in buttons_range"
      v-show="!show_status || (show_status && st === approved)"
      class="control">
      <button class="button is-small"
              @click="$emit('click', st)"
              :class="{'is-info': st === approved }">
        <template v-if="st === -1">Awaiting</template>
        <template v-else-if="st">Approved</template>
        <template v-else>Rejected</template>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SessionsButtonsStatus',
  props: {
    approved: {
      type: Number,
      default: -1
    },
    buttons_range: {
      type: Array,
      default: () => [-1, 0, 1]
    },
    show_status: {
      type: Boolean,
      default: false
    }
  },
}
</script>
