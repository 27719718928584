<template>
  <div class="slim_select" tabindex="0">
    <div class="slim_select-placeholder" v-show="placeholder">{{placeholder}}</div>
    <div class="slim_select-label"
         :class="{label_error: error_text}"
         @click="changeDropdown(!opened)">
      <span class="slim_select-selected">
        <template v-if="multiple && selected_multiple_items.length">
          <span
            v-for="item in selected_multiple_items"
            class="slim_select-selected-multiple">
            {{item[obj_value_name]}}
            <span
              class="slim_select-selected-multiple-clear"
              @click.stop="clearSelected(item)">✖</span>
          </span>
        </template>
        <template v-else>
          {{selected_text}}
        </template>
      </span>
      <span class="slim_select-clearable" v-if="selected_el" @click.stop="clearSelected()">✖</span>
      <div class="slim_select-arrow" :class="{opened: opened}">
        <span class="slim_select-arrow-line"></span>
      </div>
    </div>
    <div
      v-if="error_text"
      class="slim_select_error_text">{{error_text}}</div>
    <div
      :class="{opened: opened}"
      class="slim_select-dropdown">
      <div v-if="searchable"
           class="slim_select-dropdown-searchable">
          <input
            v-model="search_val"
            type="text">
        <span v-if="search_val"
              @click.stop="search_val = ''">✖</span>
      </div>
      <div v-for="item in filtered_values"
        @click="selectedValue(item)"
        :class="{selected: item[obj_key_name] === selected || selected_multiple.includes(item[obj_key_name])}"
        class="slim_select-dropdown-option">
        {{item[obj_value_name] || item || ''}}
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'SlimSelect',
  props: {
    values: {
      type: Array,
      default: () => []
    },
    parent_key: {
      type: [String, Number],
      default: ''
    },
    searchable: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ""
    },
    selected: {
      type: [Boolean, String, Number],
      default: false
    },
    selected_index: {
      type: Number,
      default: -1
    },
    obj_key_name: {
      type: String,
      default: 'id'
    },
    obj_value_name: {
      type: String,
      default: 'value'
    },
    selected_multiple: {
      type: Array,
      default: () => []
    },
    multiple: {
      type: Boolean,
      default: false
    },
    opened_on_select: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    error_text: {
      type: String,
      default: ''
    },
    empty_option_text: {
      type: String,
      default: 'Select'
    }
  },
  data() {
    return {
      opened: false,
      search_val: ''
    }
  },
  computed: {
    selected_text() {
      if (!this.selected_el) return this.empty_option_text
      let value_name = this.selected_el[this.obj_value_name]
      return value_name !== '' ? value_name : this.empty_option_text
    },
    selected_multiple_items() {
      if (!this.multiple) return []
      return this.values.filter(el => this.selected_multiple.includes(el[this.obj_key_name]))
    },
    is_index_select () {
      return this.selected_index >= 0
    },
    selected_el() {
      if (this.is_index_select) {
        let temp_obj = {}
        temp_obj[this.obj_value_name] = this.filtered_values[this.selected_index]
        return temp_obj
      }
      return this.filtered_values.find(el => el[this.obj_key_name] === this.selected)
    },
    filtered_values() {
      if (this.search_val === '') return this.values
      return this.values.filter(el => el[this.obj_value_name].toLowerCase().includes(this.search_val_loser))
    },
    search_val_loser() {
      return this.search_val.toLowerCase()
    }
  },
  methods: {
    blurEvt(e) {
      if (e.target.closest('.slim_select') !== this.$el) {
        this.closeDropdown()
      }
    },
    changeDropdown(bool = false) {
      if (bool) {
        this.openDropdown()
      } else {
        this.closeDropdown()
      }
    },
    openDropdown() {
      this.opened = true
      document.addEventListener('click', this.blurEvt)
    },
    closeDropdown() {
      this.opened = false
      this.search_val = ''
      document.removeEventListener('click', this.blurEvt)
    },
    selectedValue(val) {
      if (this.parent_key) {
        val = {...{parent: this.parent_key}, ...val}
      }
      this.$emit('selected', val)
      if (!this.opened_on_select) {
        this.closeDropdown()
      }
    },
    clearSelected(item) {
      let data
      if (this.is_index_select) {
        data = ''
      } else {
        data = {}
        data[this.obj_key_name] = ''
        if (this.parent_key) {
          data['parent'] = this.parent_key
        }
      }
      if (this.multiple && item && this.selected_multiple.includes(item[this.obj_key_name])) {
        this.$emit('remove', {...{parent: this.parent_key}, ...item})
      } else {
        this.$emit('selected', data)
      }
      this.closeDropdown()
    }
  },
  beforeDestroy() {
    document.removeEventListener('click', this.blurEvt)
  }
}
</script>
