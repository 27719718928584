<template>
  <div class="tab-component">
    <ul class="tab-component_list tabs_list-component">
      <li
        v-for="tab in tabs"
        v-show="!tab.hidden"
        :class="{ is_active: tab.isActive }">
        <a :href="tab.href" @click.prevent="selectTab(tab)">
          <img v-if="tab.tab_img_href" :src="tab.tab_img_href" alt="tab-image">
          <span>{{ tab.name }}</span>
          <span class="notify-qty" v-if="tab.notification">{{ tab.notification }}</span>
        </a>
      </li>
    </ul>
    <div class="tab-component_details">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Tabs',
  props: {
    change_hash: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      tabs: [],
      hash_from_ev: false
    }
  },
  created () {
    this.tabs = this.$children
  },
  mounted () {
    let tab = this.tabs.find(tab => tab.href === location.hash) || this.tabs.find(tab => !tab.hidden)
    if (tab) {
      this.changeTabByName(tab.name)
    }
    if (this.change_hash) {
      window.addEventListener('hashchange', this.hashChanged, false)
    }
  },
  methods: {
    selectTab (selectedTab) {
      if (!selectedTab) {
        return
      }
      this.hash_from_ev = true
      if (this.change_hash) {
        location.hash = selectedTab.href
      }
      this.changeTabByName(selectedTab.name)
      setTimeout(() => { // add time to event end for hash change
        this.hash_from_ev = false
      }, 0)
    },
    changeTabByName (tabName) {
      if (!tabName) return
      this.tabs.forEach(tab => {
        let isActive = tab.name === tabName
        tab.isActive = isActive
        if (isActive) {
          this.$emit('tab_change', tab.name)
        }
      })
    },
    hashChanged (e) {
      if (this.hash_from_ev) return
      let hash = new URL(e.newURL).hash,
        tab = this.tabs.find(tab => tab.href === hash)
      if (!tab) return
      this.changeTabByName(tab.name)
    }
  },
  destroyed () {
    window.removeEventListener('hashchange', this.hashChanged, false)
  }
}
</script>
