<template>
  <div class="sort_arrows" @click="$emit('click')">
    <svg xmlns="http://www.w3.org/2000/svg"
         :class="{checked: sort_up}"
         width="16" height="16"
         fill="currentColor"
         viewBox="0 0 16 16">
      <path
        d="M3.204 5h9.592L8 10.481 3.204 5zm-.753.659 4.796 5.48a1 1 0 0 0 1.506 0l4.796-5.48c.566-.647.106-1.659-.753-1.659H3.204a1 1 0 0 0-.753 1.659z"/>
    </svg>
    <svg xmlns="http://www.w3.org/2000/svg"
         :class="{checked: sort_down}"
         width="16"
         height="16"
         fill="currentColor"
         viewBox="0 0 16 16">
      <path
        d="M3.204 5h9.592L8 10.481 3.204 5zm-.753.659 4.796 5.48a1 1 0 0 0 1.506 0l4.796-5.48c.566-.647.106-1.659-.753-1.659H3.204a1 1 0 0 0-.753 1.659z"/>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'SortArrows',
  props: {
    sort_up: {
      type: Boolean,
      default: false,
    },
    sort_down: {
      type: Boolean,
      default: false,
    }
  }
}
</script>
