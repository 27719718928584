<template>
  <div class="user-select-wrap">
    <h4>Whitch users will be allowed to this meetings</h4>
    <div>
      <label style="display: block;">
        <span>Allow all users </span>
        <input type="checkbox" name="allowUsers" v-model="allowAllUsers" @input="value => toggle(value)"/>
      </label>
      <template v-if="!allowAllUsers">
        <select-component :clearable="true" :multiple="true" :close-on-select="false" v-model="selected" @input="e => $emit('input:select', e.value)" :items="value" select="email" />
      </template>
    </div>
  </div>
</template>

<script>
import SelectComponent from "../../../../../../module_list/dropdown/SelectComponent";

export default {
  name: "SelectSessionUserComponent",
  components: {SelectComponent},
  props: {
    value: {
      type: Array
    }
  },
  data: () => ({
    allowAllUsers: true,
    selected: []
  }),
  methods:{
    toggle(val){
      console.log(val, this.selected)
      this.allowAllUsers = !this.allowAllUsers;
      if(!this.allowAllUsers){
        this.selected =  []
      }
      this.$emit('input:select', this.selected)
    }
  }
}
</script>

