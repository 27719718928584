<template>
  <div class="field has-addons">
    <div class="control train-buttons">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "TrainButtons",
}
</script>

<style scoped>

</style>
