<template>
  <div class="language-levels">
    <hr>
    <div class="language-levels_title">Configure language rates</div>
    <template v-if="is_arr">
      <div v-for="(rate, index) in rate_list" :key="index">
        <div class="language-levels_row">
          <default-input
            required
            name="pass_rate"
            type="number"
            :min="index ? rate_list[index - 1].min : 0"
            placeholder="Rate percentage"
            @change="(e) => changeMinVal(e, index)"
            :validate_f="validatePercentage"
            :value="rate.min"/>
          <default-input
            required
            name="rate_text"
            type="text"
            placeholder="Rate name"
            v-model="rate.lvl"/>
          <default-input
            required
            name="rate_text"
            type="text"
            placeholder="Rate code"
            :validate_f="validateCodes"
            v-model="rate.code"/>
          <default-input
            required
            name="rate_color"
            type="color"
            placeholder="Color"
            v-model="rate.hex_color"/>
          <div>
            <tooltip
              text="Remove"
              tooltip_color="#cc0f35"
              tooltip_bg="#feecf0">
              <default-button
                class="language-levels_remove button is-danger is-light">
                <close outer_class="" @click.stop="removeIndex(index)"/>
              </default-button>
            </tooltip>
          </div>
        </div>
      </div>
      <alert-block
        v-if="error_text"
        class="mb-10"
        :text="error_text"
      />
      <div
        class="button is-link is-outlined"
        :class="{disabled: maximum_rate_selected >= 100}"
        @click="addNewStep">
        <plus-icon/>
        Add new step
      </div>
    </template>
    <hr>
  </div>
</template>

<script>
import { LANGUAGE_LEVELS_LIST } from '../../mixins/TestMixin'
import DefaultInput from './DefaultInput'
import Close from './icons/Close'
import PlusIcon from './icons/PlusIcon'
import DefaultButton from './DefaultButton'
import Tooltip from '../../../module_list/tooltip/Tooltip'
import input_validations from '../../helpers/input_validations'
import AlertBlock from '../../../module_list/alert_block/AlertBlock'

export default {
  name: 'LanguageLevels',
  mixins: [input_validations],
  props: {
    rate_list: {
      type: [Array, Number],
      default: 0
    },
    error_text: {
      type: String,
      default: ''
    }
  },
  components: {
    AlertBlock,
    Tooltip,
    DefaultButton,
    PlusIcon,
    Close,
    DefaultInput
  },
  computed: {
    is_arr() {
      return Array.isArray(this.rate_list);
    },
    maximum_rate_selected()  {
      if (!this.rate_list.length) return 0
      return this.rate_list.reduce((x, y) => x.min < y.min ? y : x).min || 0
    },
  },
  methods: {
    changeMinVal(e, i) {
      if (!this.is_arr) return
      let val = e.target.value || 0
      val = parseInt(val)
      // pre validate
      if (val < 0) {
        val = 0
      } else if (val > 100) {
        val = 100
      }
      if(i > 0 && this.rate_list[i - 1].min > val) {
        val = this.rate_list[i - 1].min + 1
      }
      e.target.value = val
      this.rate_list[i].min = val
    },
    validatePercentage(val) {
      let duplicates = this.rate_list.filter(el => el.min === val),
          valid = duplicates.length === 1
      return {
        valid: valid,
        error_text: valid ? '' : 'Duplicated values'
      }
    },
    validateCodes(val) {
      let duplicates = this.rate_list.filter(el => el.code === val),
          valid = duplicates.length === 1
      return {
        valid: false,
        error_text: valid ? '' : 'Duplicated values'
      }
    },
    removeIndex(i) {
      if (!this.is_arr) return
      this.rate_list.splice(i, 1);
    },
    addNewStep() {
      if (!this.is_arr) return
      let rate = JSON.parse(JSON.stringify(LANGUAGE_LEVELS_LIST[0]))
      rate.min = this.maximum_rate_selected + 1
      if (rate.min >= 100) {
        rate.min = 100
      }
      this.rate_list.push(rate)
    }
  }
}
</script>
