var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"generateUsers"},[_c('div',{staticClass:"wrapper pt-4"},[_c('default-input',{staticClass:"d-inline-block",staticStyle:{"width":"200px","max-width":"15rem"},attrs:{"label":"Number of users"},on:{"input":e => {
        _vm.number = e;
        if(e < _vm.names.length){
          this.names.splice(
            Math.max(e, 0),
            Math.max(_vm.names.length - e, 0)
          )
        } else if(_vm.numberSelected){
          _vm.generateUsers();
      }}},model:{value:(_vm.number),callback:function ($$v) {_vm.number=$$v},expression:"number"}}),_c('select-component',{staticClass:"d-inline-block",attrs:{"items":_vm.availableRoles,"error":_vm.showRoleError},on:{"selected":() => {_vm.showRoleError = false}},model:{value:(_vm.role),callback:function ($$v) {_vm.role=$$v},expression:"role"}}),_c('button',{staticClass:"button is-danger",on:{"click":_vm.generateUsers}},[_vm._v(" Show usernames ")]),_c('div',{staticClass:"d-block"},[_c('default-input',{staticClass:"d-inline-block",staticStyle:{"width":"400px","max-width":"15rem"},attrs:{"label":"Last Name"},model:{value:(_vm.lastName),callback:function ($$v) {_vm.lastName=$$v},expression:"lastName"}}),_c('default-input',{staticClass:"d-inline-block password",staticStyle:{"width":"400px","max-width":"15rem"},attrs:{"type":"password","label":"Password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1)],1),_c('div',{staticClass:"wrapper"},[_c('button',{staticClass:"button is-link",on:{"click":_vm.sendRequest}},[_vm._v("Create users")]),(_vm.numberSelected)?_c('button',{staticClass:"button is-link",on:{"click":function($event){_vm.hidden = !_vm.hidden}}},[_vm._v("Hide table")]):_vm._e()]),(_vm.numberSelected && !_vm.hidden)?[_c('div',{staticClass:"wrapper"},[_c('default-table',{attrs:{"items":_vm.names},scopedSlots:_vm._u([{key:"id",fn:function(props){return [_vm._v(" "+_vm._s(props.__col_index)+" ")]}},{key:"First Name",fn:function(props){return [_vm._v(" "+_vm._s(props.name)+" ")]}}],null,false,2475083647)},[_c('template',{slot:"Last Name"},[_vm._v(" "+_vm._s(_vm.lastName)+" ")]),_c('template',{slot:"Role"},[_vm._v(" "+_vm._s(_vm.role)+" ")])],2)],1)]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }