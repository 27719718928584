import constants from "../helpers/constants";

class Websocket{
  instance;


  /*
  * 0 - new instance
  * 1 - opened
  *
  * 2 - failed
  * 3 - closed
  *
  * 4 - active
  * */
  state;
  data;

  ws;
  connectsuccess;
  
  constructor(path = '') {
    if(!(constants.chatEnabled ?? false)){
      console.debug('Sockets disabled by default');
      return;
    }
    this.instance = new WebSocket(constants.socketPath + path);
    this.state = 0;
    this.open();
    this.message();
    this.error();
    this.close();
  }

  open(){
    this.instance.onopen = (e) => {
      const cookie = window.localStorage.getItem('x-auth');
      if(cookie != null) {
        this.state = 1;
        console.log(this.state, cookie)
        this.instance.send('connect_' + cookie)
      }
    }
  }

  message(){
    this.instance.onmessage = (message) => {
      if(this.state === 1 && message.data.includes('ok_')){
        this.state = 4;
        if(typeof this.connectsuccess === 'function'){
          try {
            const msg = JSON.parse(message.data.replace('ok_', ''))
            this.connectsuccess(msg);
          }catch (e) {
            this.connectsuccess(message.data.replace('ok_', ''));
          }
        }
      } else if(this.state === 1){
        this.state = 2;
      }

      if(this.state === 4){
        if(typeof this.ws === 'function'){
          this.ws(message.data);
        }
      }
    }
  }

  close(){
    this.instance.onclose = (e) => {
      this.state = 3;
    }
  }

  error(){
    this.instance.onerror = (e) => {
      console.log('Websocket error: ', e.data);
    }
  }

  status() {
    switch (this.state) {
      case 0: return 'new';
      case 1: return 'opened';
      case 3: return 'closed';
      case 4: return 'active';
      default: return 'failed';
    }
  }

  send(message){
    this.instance.send(message)
  }

}

export default Websocket;
