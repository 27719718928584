<template>
  <div>
    <popup-block
      :closeModal="closeModal"
      :shown="opened"
      is_wide
      @close="$emit('close')">
      <template v-slot:popup_title>Create session</template>
      <template>
        <div>
          <default-input
            type='name'
            label="Location"
            required
            name="Location"
            :valid="isValid('location')"
            @focusout="fout('location')"
            v-model="editing.location"/>

          <default-input
            type='name'
            label="Job name"
            required
            name="Job position"
            :valid="isValid('job')"
            @focusout="fout('job')"
            v-model="editing.job"/>

          <default-input
            type='name'
            label="Description"
            required
            name="Session description"
            :valid="isValid('desc')"
            @focusout="fout('desc')"
            v-model="editing.desc"/>
          <div class="clearfix tile is-0">
            <div class="column is-6">
              <default-input
                label="Recruitment session start"
                required
                name="Session description"
                v-model="editing.start1"
                type="date"
                ref='calendarTrigger'
                :valid="isValid('start1')"
                @focusout="fout('start1')"
                :max="availableDates(1, true)"
              ></default-input>

              <default-input
                label="Training session start"
                required
                name="Session description"
                v-model="editing.start2"
                type="date"
                ref='calendarTrigger'
                :min="availableDates(1)"
                :valid="isValid('start2')"
                @focusout="fout('start2')"
                :max="availableDates(3, true)"
              />
            </div>
            <div class="column is-6">
              <default-input
                label="Recruitment session end"
                required name="Session description"
                v-model="editing.end1"
                type="date"
                ref='calendarTrigger'
                :min="availableDates(0)"
                :max="availableDates(2, true)"
                :valid="isValid('end1')"
                @focusout="fout('end1')"
              />

              <default-input
                label="Training session end"
                required
                name="Session description"
                v-model="editing.end2"
                type="date"
                ref='calendarTrigger'
                :valid="isValid('end2')"
                @focusout="fout('end2')"
                :min="availableDates(2)"
              />
            </div>
          </div>


          <h2>Plan meetings</h2>

          <h4 class="py-4">Recruitment meetings
            <button class="button is-small is-outlined is-primary p-0" style="font-size: 1.45rem; height: inherit;"
                    @click="watch_params.showJr = !watch_params.showJr">
              <circle-icon-ok v-if="watch_params.showJr"/>
              <circle-icon-not-ok v-else/>
            </button>
          </h4>
          <default-table :style="watch_params.showJr?'':'display: none'"
                         :items="editing_meetings_jr">
            <template slot="title" slot-scope="props">
              <default-input v-model="editing_meetings_jr[props.__col_index].title" type="text" class="mb-0"/>
            </template>
            <template slot="date" slot-scope="props">
              {{ editing_meetings_jr[props.__col_index].date }}
            </template>
            <template slot="start" slot-scope="props">
              <default-input v-model="editing_meetings_jr[props.__col_index].start" type="time" step="60" class="mb-0"/>
            </template>
            <template slot="end" slot-scope="props">
              <default-input v-model="editing_meetings_jr[props.__col_index].end" type="time" step="60" class="mb-0"/>
            </template>
            <template slot="update" slot-scope="props">
              <toggle-button v-model="editing_meetings_jr[props.__col_index].enabled"/>
            </template>
          </default-table>

          <h4 class="py-4">Training meetings
            <toggle-button v-model="watch_params.showTr" @click="watch_params.showTr = !watch_params.showTr"/>
          </h4>
          <default-table
            :style="watch_params.showTr?'':'display: none'"
            :items="editing_meetings_tr">
            <template slot="title" slot-scope="props">
              <default-input v-model="editing_meetings_tr[props.__col_index].title" type="text" class="mb-0"/>
            </template>
            <template slot="date" slot-scope="props">
              {{ editing_meetings_tr[props.__col_index].date }}
            </template>
            <template slot="start" slot-scope="props">
              <default-input v-model="editing_meetings_tr[props.__col_index].start" type="time" step="60" class="mb-0"/>
            </template>
            <template slot="end" slot-scope="props">
              <default-input v-model="editing_meetings_tr[props.__col_index].end" type="time" step="60" class="mb-0"/>
            </template>
            <template slot="update" slot-scope="props">
              <toggle-button v-model="editing_meetings_tr[props.__col_index].enabled"/>
            </template>
          </default-table>
        </div>
      </template>

      <template v-slot:popup_footer>
        <button class="button is-link is-outlined"
                :class="{'is-static': !completed, 'is-primary': completed }"
                @click="submitFormClose">Save
        </button>
      </template>
    </popup-block>
  </div>
</template>

<script>
import PopupBlock from '__module_list/popup/PopupBlock'
import EditQuiz from '../../../Test/EditQuiz'
import DefaultInput from '../../../small-components/DefaultInput'
import ButtonActions from '../Parts/ButtonActions'
import DefaultTable from '../../../../../module_list/default_table/DefaultTable'
import CircleIconOk from 'vue-ionicons/dist/ios-checkmark-circle'
import CircleIconNotOk from 'vue-ionicons/dist/ios-close-circle'
import ToggleButton from '../Parts/ToggleButton'
import SessionMixin from '../ModalMixins/SessionMixin'
import { mapActions } from 'vuex'

export default {
  name: 'CreateSessionComponent',
  components: {
    ToggleButton,
    DefaultTable,
    ButtonActions,
    CircleIconOk,
    CircleIconNotOk,
    DefaultInput,
    EditQuiz,
    PopupBlock
  },
  mixins: [SessionMixin],
  data () {
    return {
      text_success: '',
      text_error: '',
      checked: {},
      and_close: false
    }
  },
  methods: {
    ...mapActions(['newSession']),
    save () {
      const form = this.editing
      if (!this.watch_params.showJr) this.editing.meetings_jr = null
      if (!this.watch_params.showTr) this.editing.meetings_tr = null

      this.newSession(form)
    },
    availableDates (date_number = 0, reverse = false) {
      const dates = [
        this.editing.start1,
        this.editing.end1,
        this.editing.start2,
        this.editing.end2,
      ]
      let last = null
      if (!reverse) {
        for (let i = 0; i <= date_number && i < dates.length; i++) {
          if (dates[i] != null && dates[i] !== '') {
            last = dates[i]
          }
        }
        return last
      } else {
        for (let i = dates.length - 1; i >= date_number; i--) {
          if (dates[i] != null && dates[i] !== '') {
            last = dates[i]
          }
        }
        return last
      }
    },
    closed () {
      this.opened = false
    },
    closeModal () {
      this.text_success = ''
      this.text_error = ''
      this.opened = false
    },
    geturl (meta) {
      try {
        const val = JSON.parse(meta)
        return val.url
      } catch (e) {
        return '#'
      }
    },
    fout (name = '') {
      this.$set(this.checked, name, true)
    },
    isValid (name) {
      const result = this.checked[name] && (this.editing[name] == null || this.editing[name].trim() === '')
      return !result
    },
  },
  computed: {
    editing_meetings_jr () {
      return this.editing.meetings_jr?.filter(Boolean) || []
    },
    editing_meetings_tr () {
      return this.editing.meetings_tr?.filter(Boolean) || []
    },
    completed () {
      const dates = [
        this.editing.start1,
        this.editing.end1,
        this.editing.start2,
        this.editing.end2,
      ]
      let result = this.editing.desc != null && this.editing.desc.trim() !== ''
      result &= this.editing.job != null && this.editing.job.trim() !== ''
      result &= this.editing.location != null && this.editing.location.trim() !== ''
      for (let i = 0; i < 4 && i < dates.length; i++) {
        result &= dates[i] != null && dates[i] !== ''
      }
      return result
    }
  }
}
</script>
