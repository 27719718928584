import axiosService from '../../plugins/axiosService'

export default {
  actions: {
    fetchLanguages(context) {
      if (context.getters.getLanguagesLoading || context.getters.getLanguageLoaded) return
      context.dispatch('actionLanguageLoading', true)
      axiosService.post('/api/v1/user/languages')
        .then(r => {
          context.dispatch('actionLanguage', r.data.lang || [])
        })
        .catch(e => {
          console.log(e)
        })
        .finally(() => {
          context.dispatch('actionLanguageLoading', false)
          context.dispatch('actionLanguageLoaded', true)
        })
    },
    fetchDefaultLanguage(context){
      axiosService.get('/api/v1/admin/languages/default').then(e =>{
        context.commit('mutationDefaultLanguage', e.data)
      })
    },
    actionLanguage:(context, list = []) => context.commit('mutationLanguage', list ),
    actionLanguageLoading:(context, bool = false) => context.commit('mutationLanguageLoading', bool),
    actionLanguageLoaded:(context, bool = false) => context.commit('mutationLanguageLoaded', bool),
  },
  mutations: {
    mutationDefaultLanguage:(state, list = []) => state.defaultLang = list,
    mutationLanguage:(state, list = []) => state.languages = list,
    mutationLanguageLoading:(state, bool = false) => state.languages_loading = bool,
    mutationLanguageLoaded:(state, bool = false) => state.languages_loaded = bool,
  },
  state: {
    defaultLang: '',
    languages: [],
    languages_loading: false,
    languages_loaded: false,
  },
  getters: {
    getLanguages: state => state.languages,
    defaultLanguage: state => state.defaultLang,
    getLanguagesLoading: state => state.languages_loading,
    getLanguageLoaded: state => state.languages_loaded,
    getLanguageById: state => (id) => state.languages.find(el => el.id === id)?.name || '',
  }
}
