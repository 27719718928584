<template>
  <div class="pre_loader">
    <svg width="44" height="44" viewBox="0 0 44 44" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fill-rule="evenodd" stroke-width="2">
        <circle :stroke="loader_color"
          cx="22" cy="22" r="1">
          <animate attributeName="r"
                   begin="0s" dur="1.8s"
                   values="1; 20"
                   calcMode="spline"
                   keyTimes="0; 1"
                   keySplines="0.165, 0.84, 0.44, 1"
                   repeatCount="indefinite" />
          <animate attributeName="stroke-opacity"
                   begin="0s" dur="1.8s"
                   values="1; 0"
                   calcMode="spline"
                   keyTimes="0; 1"
                   keySplines="0.3, 0.61, 0.355, 1"
                   repeatCount="indefinite" />
        </circle>
        <circle  :stroke="loader_color"
          cx="22" cy="22" r="1">
          <animate attributeName="r"
                   begin="-0.9s" dur="1.8s"
                   values="1; 20"
                   calcMode="spline"
                   keyTimes="0; 1"
                   keySplines="0.165, 0.84, 0.44, 1"
                   repeatCount="indefinite" />
          <animate attributeName="stroke-opacity"
                   begin="-0.9s" dur="1.8s"
                   values="1; 0"
                   calcMode="spline"
                   keyTimes="0; 1"
                   keySplines="0.3, 0.61, 0.355, 1"
                   repeatCount="indefinite" />
        </circle>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'Loader',
  props: {
    loader_color: {
      type: String,
      default: '#ED8B00'
    }
  }
}
</script>
