<template>
  <popup-block
    :closeModal="closeModal"
    :loader="!obj ||!obj.id"
    :shown="opened">
    <template v-slot:popup_title>Quiz: {{ obj.quiz_title || '' }}.</template>
    <template
      v-if="min_correct_answers"
      v-slot:popup_sub_title>
      Minimum correct answers: <b>{{ min_correct_answers }}</b>
    </template>
    <div v-if="local_obj" class="answers-quiz_modal">
      <div v-for="(el, i) in local_obj.asks.questions">
        <p class="bold mb-10">#{{i+1}} {{el.question || ''}}</p>
        <ul class="answers-quiz_modal_answers">
          <li
            v-for="(answer, answer_i) in el.answers"
            :class="{selected: answer_i === el.selected, correct: answer_i === el.correct}"
            class="mb-10">{{answer}}
          </li>
        </ul>
      </div>
    </div>
    <template v-slot:popup_footer>
      <button class="button is-success is-outlined" @click="closeModal">Close</button>
    </template>
  </popup-block>
</template>

<script>
import PopupBlock from '__module_list/popup/PopupBlock'
import ModalMixin from '../../mixins/ModalMixin'
import Loader from '../../../module_list/loader/Loader'
import Toggled from '../../../module_list/toggled/Toggled'
import Tabs from '../../../module_list/tabs/Tabs'
import Tab from '../../../module_list/tabs/Tab'
import SelectComponent from '../../../module_list/dropdown/SelectComponent'

export default {
  name: 'UserAnswersQuizModal',
  mixins: [ModalMixin],
  components: {
    SelectComponent,
    Toggled,
    Loader,
    PopupBlock,
    Tabs,
    Tab,
  },
  props: {
    obj: {
      type: Object,
      default: () => ({})
    },
  },
  computed: {
    min_correct_answers() {
      if (!this.local_obj) return ''
      let val = Math.round((this.local_obj?.asks?.questions?.length || 0) * (this.local_obj.pass_rate || 0) / 100);
      return !isNaN(val) ? val : ''
    }
  },
  data () {
    return {
      local_obj: null,
    }
  },
  methods: {
    openModal () {
      this.opened = true
    },
    closeModal () {
      this.local_obj = null
      this.opened = false
      this.$emit('closed', true)
    }
  },
  watch: {
    'obj.id' (val) {
      if (!val) return
      this.openModal()
      this.local_obj = this.obj
    },
  }
}
</script>
