<template>
  <div class="actions pt-4">
    <button class="button is-success" @click="confirm" >Confirm</button>
    <button class="button is-danger" @click="cancel">Cancel</button>
  </div>
</template>

<script>
export default {
name: "ButtonActions",
  methods: {
    confirm(){
      this.$emit('confirm')
    },
    cancel(){
      this.$emit('cancel')
    }
  }
}
</script>
