<template>
  <div class="dashboard">
    <aside-dashboard/>
    <div class="dashboard_main">
      <header-block/>
      <div v-if="permissions_fetched" class="dashboard_content">
        <router-view/>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderBlock from './blocks/HeaderBlock'
import { mapActions, mapGetters } from 'vuex'
import AsideDashboard from './blocks/AsideDashboard'

export default {
  name: 'DashboardRoot',
  components: {
    AsideDashboard,
    HeaderBlock
  },
  methods: {
    ...mapActions([
      'actionPermission',
      'actionRouteChange',
      'fetchLanguages',
    ]),
    checkRoutePermission() { // prevent pass to protected route
      if (!this.getPermission ||
        !this.getPermission.permission ||
        !this.$route.matched.some(route => this.getPermission.permission.includes(route.meta.permission))
      ) {
        this.$router.push({ name: 'dashboard.home' })
      }
    }
  },
  computed: {
    ...mapGetters([
      'getPermission'
    ])
  },
  data() {
    return {
      permissions_fetched: false
    }
  },
  created () {
    this.actionPermission()
      .then(() => {
        this.checkRoutePermission()
        this.fetchLanguages()
      })
      .finally(() => {
        this.permissions_fetched = true
      })
  },
  watch: {
    '$route'() {
      this.checkRoutePermission()
      this.actionRouteChange()
    },
  }
}
</script>
