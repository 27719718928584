<template>
  <div class="users">
    <div class="cards_item">
      <div class="cards_item-header">
        <span>Users connected</span>
        <default-button @click="sendUpdateRequest()"
                        class="is-warning cards_item-header_link">
          <svg color="rgba(0,0,0,.7)" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path
              d="M13.5 2c-5.288 0-9.649 3.914-10.377 9h-3.123l4 5.917 4-5.917h-2.847c.711-3.972 4.174-7 8.347-7 4.687 0 8.5 3.813 8.5 8.5s-3.813 8.5-8.5 8.5c-3.015 0-5.662-1.583-7.171-3.957l-1.2 1.775c1.916 2.536 4.948 4.182 8.371 4.182 5.797 0 10.5-4.702 10.5-10.5s-4.703-10.5-10.5-10.5z"/>
          </svg>
        </default-button>
      </div>
      <default-table
        :fields_name="{created_at: 'created', test: 'test title' }"
        :custom_fields="table_filters"
        @input:custom_fields="customFiltersUpdated"
        :items="usersFiltered">
        <template slot="id" slot-scope="props">
          {{ props.id }}
        </template>
        <template slot="Name" slot-scope="props">
          <span @click="openHistory(props)" :style="loading? 'cursor:progress;': 'cursor: pointer;'">
            {{ props.user_name }}
          </span>
        </template>
        <template slot="Role" slot-scope="props">
          <a class="button is-link is-small">{{ props.role }}</a>
        </template>

        <template slot="Room" slot-scope="props">
          <div :tooltip='props.status === "connected" ? "Connected to room" : props.room? "Waiting" : "Not allowed"'
               class="button is-small is-outlined"
               :class='props.status === "connected" ? "is-success" : props.room? "is-static" : "is-warning"'>
            {{ props.room ? props.room : 'connected to session' }}
          </div>
        </template>
        <template slot="Zoom nickname" slot-scope="props">
          <span v-if="props.connections.length > 0">
            {{ props.connections[0].user_name }}
          </span>
        </template>
      </default-table>
    </div>
    <div class="modal show-user" :class="activeModal? 'is-active' : ''" @keypress.esc="activeModal = false">
      <div class="modal-background" @click="activeModal = false"></div>
      <div class="modal-content card d-cap-min">
        <div class="user-name pb-4" autofocus><h4>{{ userModalInfo.user_name }}</h4></div>
        <div class="list-holder">
          <table width="100%">
            <thead>
            <tr>
              <th>Name</th>
              <th>Room</th>
              <th>Time</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="a in userModalData">
              <td>
                {{ a.user.firstName + ' ' + a.user.lastName }}
              </td>
              <td>
                {{ a.room.room_name }}
              </td>
              <td>
                {{ a.created_at }}
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axiosService from "../../plugins/axiosService";
import DefaultTable from "../../../module_list/default_table/DefaultTable";
import DefaultButton from "../small-components/DefaultButton";
import moment from "moment";

export default {
  name: "UserTable",
  components: {DefaultButton, DefaultTable},
  data: () => ({
    table_filters: {room: {filter: [], available: []}},
    debug: '',
    filterMeeting: {
      enabled: false,
      selected: null,
    },
    userModalData: [],
    userModalInfo: {},
    loading: false,
    activeModal: false
  }),
  props: {
    users: {
      default: []
    }
  },
  mounted() {
    this.table_filters.room.available = this.fillRoomsBy(this.$props.users)
  },
  watch: {
    users(val) {
      this.table_filters.room.available = this.fillRoomsBy(val)
    }
  },
  methods: {
    customFiltersUpdated(e) {
      this.table_filters = Object.assign({}, this.table_filters, e)
    },
    fillRoomsBy(val) {
      if (val && val.length === 0 && this.$props.users.length > 0) {
        val = this.$props.users
      }
      if (val) {
        const z = [];
        const a = val.map(u => u.room).filter(e => {
          if (!z[e]) {
            z[e] = true;
            return true;
          } else return false;
        });
        // console.log(13, a, this.$props.users)
        this.$forceUpdate()
        return a;
      }
      this.$forceUpdate()
      return []
    },
    openHistory(user) {
      this.loading = true
      this.userModalInfo = user
      axiosService.post('/api/v1/sessions/part/history', {user_id: user.id}).then(e => {
        this.userModalData = e.data.map(item => {
          item.created_at = moment(item.created_at).format('MMMM Do YYYY, h:mm:ss a');
          return item
        })
        this.activeModal = true
        this.loading = false
      })
    },
    sendUpdateRequest() {
      // axiosService.post('/api/v1/admin/zoom/get').then(e => {
      //   this.debug = e.data
      // })
      this.$emit('reload')
    },
  },
  computed: {
    usersFiltered() {
      if (this.table_filters.room.filter.length > 0) {
        return this.users.filter(e => this.table_filters.room.filter.findIndex(a => a === e.room) !== -1)
      }
      return this.users;
    }
  }
}
</script>

<style scoped>
.d-cap-min {
  min-height: 260px;

}
</style>
