<template>
  <div
    :style="style_list"
    :is="tag">
      <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'SlideUpDown',
  props: {
    active: {
      type: Boolean,
      default: false
    },
    tag: {
      type: String,
      default: 'div'
    },
    transition_duration_seconds: {
      type: String,
      default: '.3'
    }
  },
  data() {
    return {
      height: 0
    }
  },
  computed: {
    style_list() {
      return {
        transition: `height ${this.transition_duration_seconds}s ease`,
        height: `${this.height}px`,
        overflow: 'hidden',
      }
    }
  },
  watch: {
    active(val) {
      this.height = !val ? 0 : this.$el.scrollHeight
    }
  }
}
</script>
