import { email_regex, password_regex } from './regex'
import ComponentHelper from '../mixins/ComponentHelper'

export const input_min_length = 2
/**
 * @param val
 * @param field_name
 * @returns {{valid: boolean, error_text: (string|string)}}
 */
export const validateLogin = (val, field_name) => {
  const valid = !!(val && val.length >= input_min_length)
  return {
    valid: valid,
    error_text: !valid ? `Min length of ${field_name} should be at least ${input_min_length}` : ''
  }
}
/**
 * @param val
 * @returns {{valid: boolean, error_text: (string|string)}}
 */
export const validateNumber0100 = (val) => {
  const valid = !isNaN(val) && val >= 0 && val <= 100
  return {
    valid: valid,
    error_text: !valid ? `Min value is 0 max 100` : ''
  }
}
/**
 * todo Write Phone mask & validation
 * @param val
 * @param field_name
 * @returns {{valid: boolean, error_text: (string|string)}}
 */
export const validatePhone = (val, field_name) => {
  const valid = !!(val && val.length === 8)
  return {
    valid: valid,
    error_text: !valid ? `Min length of ${field_name} should be at least ${input_min_length}` : ''
  }
}
/**
 * @param val
 * @param field_name
 * @returns {{valid: boolean, error_text: string}}
 */
export const validatePassword = (val, field_name) => {
  let valid_length = false
  let valid_match = false
  let valid_text = ''
  if (val && val.length) {
    valid_length = val.length >= input_min_length
    valid_match = !!val.match(password_regex)
  }
  if (!valid_length) {
    valid_text = `Min length of ${field_name} should be at least ${input_min_length}`
  } else if (!valid_match) {
    valid_text = 'Password should contains minimum one of: lower case letter, upper case letter & numeric character, special characters are forbidden'
  }
  return {
    valid: valid_length && valid_match,
    error_text: valid_text
  }
}
/**
 * @param val
 * @param field_name
 * @param password
 * @returns {{valid: boolean, error_text: string}}
 */
export const confirmPassword = (val, field_name, password) => {
  let valid_length = false
  let valid_match = false
  let valid_text = ''
  if (val) {
    valid_length = val.length >= input_min_length
    valid_match = password === val
  }
  if (!valid_length) {
    valid_text = `Min length of ${field_name} should be at least ${input_min_length}`
  } else if (!valid_match) {
    valid_text = 'Password confirmation didnt match'
  }
  return {
    valid: valid_length && valid_match,
    error_text: valid_text
  }
}
/**
 * @param val
 * @returns {boolean}
 */
export const validateEmail = (val) => {
  let valid_length = false
  let valid_match = false
  let valid_text = ''
  if (val) {
    valid_length = val.length >= input_min_length
    valid_match = !!val.match(email_regex)
  }
  if (!valid_length) {
    valid_text = `Min length of email should be at least ${input_min_length}`
  } else if (!valid_match) {
    valid_text = 'Your email seams like didnt match to email address'
  }
  return {
    valid: valid_length && valid_match,
    error_text: valid_text
  }
}

export const validateForm = (el = false) => {
  return ComponentHelper.methods.getAllVueComponentsByClass('.default_input.validate_inputs', el)
    .map(component => {
      component.updateValid(component.value)
      return component
    })
}

export default {
  methods: {
    validateLogin (val, field_name) {
      return validateLogin(val, field_name)
    },
    validateNumber0100 (val) {
      return validateNumber0100(val)
    },
    validatePassword (val, field_name) {
      return validatePassword(val, field_name)
    },
    validatePasswordOld (val, field_name, password) {
      let validate = validatePassword(val, field_name)
      if (val === password) {
        validate.valid = false
        validate.error_text = "New password can't match with old"
      }
      return validate
    },
    confirmPassword (val, field_name, password) {
      return confirmPassword(val, field_name, password)
    },
    validateEmail (val) {
      return validateEmail(val)
    }
  }
}
