import Vue from 'vue'
import Vuex from 'vuex'

import account from "./modules/Account";
import counter from "./modules/Counter";
import chats from "./modules/Chats";
import admin from "./modules/Admin";
import pageRouter from './modules/pageRouter'
import languages from './modules/languages'
import notifier from '__module_list/notifier/notifier'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    account,
    counter,
    admin,
    chats,
    pageRouter,
    languages,
    notifier,
  },
  // strict: process.env.NODE_ENV !== 'production'
})
