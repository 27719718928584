<script>
import bus from "../bus";
import {Notification} from "../helpers/httpCatcher";
import Websocket from "../plugins/websocket";

const USERCONNECTED = 'userconnected_'
const USERDISCONNECTED = 'userdisconnected_'

export default {
  name: "WSController",
  data: () => ({
    connected: false,
    connection_started: false,
  }),
  created() {
    this.$ws = new Websocket()
    this.$ws.onopen = (msg) => {
      console.log(msg, 1493)
    }
    this.$ws.ws = (msg) => {
      console.log(msg, 1231);
      let obj = null;
      if(msg &&   msg.includes("ok_{")) {
        msg = msg.substring(3,)
        obj = JSON.parse(msg)
        bus.$emit('notification', new Notification("Notifications enabled"))
      } else if(msg.includes("notification{")){
        msg = msg.substring(12,)
        obj = JSON.parse(msg)
        bus.$emit('notification', new Notification("Message " + msg))
      }
    }

    this.$ws.connectsuccess = (msg) => {
      console.log('connected ', msg)
    }
  },
  methods: {
    ready() {
      console.log('ready')
    },
    chatMessage(msg) {
      console.log(msg);
    }
  }

}
</script>
