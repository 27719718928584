<template>
  <div class="past-meetings">
    <div class="meeting-wrapper">
      <default-table class="meeting-entity" :items="value">
        <template slot="Title" slot-scope="props">
          <div class="title">
            {{ props.topic }}
          </div>
        </template>
        <template slot="Date" slot-scope="props">
          <span class="room-date">{{getDate(props)}}</span>
        </template>
        <template slot="Start" slot-scope="props">
          <span class="room-start">starts at {{getStartTime(props)}}</span>
        </template>
        <template slot="Duration" slot-scope="props">
          <span class="duration"> for {{ getDuration(props) }}</span>
        </template>
      </default-table>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import DefaultTable from "../../../../module_list/default_table/DefaultTable";

export default {
name: "MeetingList",
  components: {DefaultTable},
  props: {
    value: {}
  },
  methods:{
    getDate(item){
      const time = moment(item.start_time).toISOString(true);
      return time.substring(0,10)
    },
    getStartTime(item){
      const time = moment(item.start_time).toISOString(true);
      return time.substring(11,16)
    },
    getDuration(item){
      return moment.duration(item.duration * 60 * 1000).humanize()
    }
  }

}
</script>

<style scoped lang="scss">
  .past-meetings{
    background-color: #00d1b205;
    display: block;
    min-width: 40%;
    max-width: 800px;
    .meeting-entity{
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .side-wrapper{
        display: flex;
        flex-flow: row wrap;
        font-size: 1rem;
        max-width: 60%;
        column-gap: 1rem;
        .room-start{
          color: #1a202cac;
        }
        min-width: 200px;
      }
      .title{
        margin-bottom: 0;
        padding: .2rem;
        font-size: 1.25rem;
        color: #2d3748;
        font-weight: 600;
        max-width: 40%;
        min-width: 200px;
      }
    }
  }
</style>
