<template>
  <div>
    <popup-block
      :closeModal="closeModal"
      :shown="opened"
      @close="$emit('close')">
      <template v-slot:popup_title>Create room</template>
      <template v-if="editing">
        <default-form @submit.prevent="submitFormClose" :id="uuid">

          <div class="row">
            <default-input
              class="col-12"
              type='name'
              label="Room name"
              required
              name="Location"
              :valid="isValid('title')"
              @focusout="fout('title')"
              v-model="editing.title"/>

            <default-input
              class="col-6"
              label="Meeting start date"
              type="date"
              :value="editing.start1"
              @input="changeStart1"
              :min="editing.session_jrStartDate"
              :max="editing.session_trEndDate"
              :valid="isValid('start1')"
              @focusout="fout('start1')"
            />

            <default-input
              class="col-6"
              label="Meeting start time"
              type="time"
              step="60"
              :valid="isValid('start2')"
              @focusout="fout('start2')"
              v-model="editing.start2"/>

            <default-input
              class="col-6"
              label="Meeting end date"
              type="date"
              v-model="editing.end1"
              :min="editing.start1 != null && editing.start1 !== '' ? editing.start1 : editing.session_jrStartDate"
              :max="editing.session_trEndDate"
              :valid="isValid('end1')"
            />
            <default-input
              class="col-6"
              label="Meeting end time"
              type="time"
              step="60"
              v-model="editing.end2"
              :valid="isValid('end2')"
              @change="fout('end2')"
              :min="editing.start1 === editing.end1 && editing.start2 != null && editing.start2 !== '' ? editing.start2 : ''"
            />
          </div>
          <show-session-component
            v-model="editing"/>
          <select-session-user-component
            class="mt-20"
            v-model="editing.users"
            @input:select="e => editing.users_selected = e"/>
        </default-form>
      </template>

      <template v-slot:popup_footer>
        <button class="button is-link is-outlined"
                :form="uuid"
                type="submit">Save
        </button>
      </template>
    </popup-block>
  </div>
</template>

<script>
import PopupBlock from '__module_list/popup/PopupBlock'
import EditQuiz from '../../../Test/EditQuiz'
import DefaultInput from '../../../small-components/DefaultInput'
import Mixin from '../ModalMixins/ModalIndexMixin'
import ButtonActions from '../Parts/ButtonActions'
import SelectSessionUserComponent from './blocks/SelectSessionUserComponent'
import TrainButtons from '../../../../../module_list/TrainButtons'
import ShowSessionComponent from '../Parts/ShowSessionComponent'
import { mapActions } from 'vuex'
import moment from 'moment'
import DefaultForm from '../../../small-components/DefaultForm'

export default {
  name: 'CreateRoomComponent',

  components: {
    DefaultForm,
    ShowSessionComponent,
    TrainButtons,
    SelectSessionUserComponent,
    ButtonActions,
    DefaultInput,
    EditQuiz,
    PopupBlock
  },
  mixins: [Mixin],
  data () {
    return {
      text_success: '',
      checked: {},
      text_error: '',
      and_close: false,
    }
  },
  methods: {
    ...mapActions(['createRoom']),
    save () {
      this.createRoom(this.editing).then(() => {
        this.$emit('created')
      })
    },
    closed () {
    },
    fout (name = '') {
      this.$set(this.checked, name, true)
    },
    isValid (name) {
      const result = this.checked[name] && (this.editing[name] == null || this.editing[name].trim() === '')
      return !result
    },
    changeStart1(val) {
      this.editing.start1 = val
      if (this.editing.start1 < this.editing.end1) return
      this.editing.end1 = this.editing.start1
      if (this.editing.start2 < this.editing.end2) return
      this.editing.end2 = this.editing.start2
    }
  },
  computed: {
    uuid() {
      return 'create-room' + this._uid
    },
    completed () {
      const dates = [
        this.editing.start1,
        this.editing.end1,
        this.editing.start2,
        this.editing.end2,
      ]

      let result = this.editing.title != null && this.editing.title.trim() !== ''
      for (let i = 0; i < 4 && i < dates.length; i++) {
        result &= dates[i] != null && dates[i] !== ''
      }

      return result
    }
  },
  watch: {
    editing (newVal) {
      if (newVal.start2 == null) {
        newVal = JSON.parse(JSON.stringify(newVal))
        newVal['start1'] = newVal.session_jrStartDate
          ? newVal.session_jrStartDate
          : moment().toISOString().substring(0, 10)

        newVal['end1'] = newVal.session_jrStartDate
          ? newVal.session_jrStartDate
          : moment().add(1, 'hours').toISOString().substring(0, 10)

        newVal['start2'] = moment().toISOString().substring(11, 16)
        newVal['end2'] = moment().add(1, 'hours').toISOString().substring(11, 16)

        this.$set(this, 'editing', newVal)
      }
    },
  },
}
</script>

