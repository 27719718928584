<template>
  <div class="card">
    <h3 class="d-flex flex-row justify-content-between">
      <span>Banned or inactive users</span>
      <button class="button is-outlined"
              :class="{
                 'is-danger': countSelected > 0,
                 'is-static': countSelected === 0
              }"
              :readonly="countSelected === 0"
              @click="deleteUsers"
      >delete ({{countSelected}})</button></h3>

    <div class="wrapper">
      <default-table :items="users">
        <template slot="id" slot-scope="props">
          {{props.id}}
        </template>
        <template slot="First Name" slot-scope="props">
          {{props.firstName}}
        </template>
        <template slot="Last Name" slot-scope="props">
          {{props.lastName}}
        </template>
        <template slot="Role" slot-scope="props">
          <button class="button is-outlined is-static" :readonly="true">{{props.role_active.role.role}}</button>
        </template>
        <template slot="Actions" slot-scope="props">
          <button class="button is-link is-outlined" @click="deleteUser(props.id)">delete</button>
        </template>
        <template slot="Select" slot-scope="props">
          <toggle-button :small="false" v-model="selected[props.__col_index]"></toggle-button>
        </template>
      </default-table>
    </div>
  </div>
</template>

<script>
import axiosService from "../../../plugins/axiosService";
import DefaultTable from "../../../../module_list/default_table/DefaultTable";
import bus from "../../../bus";
import {Notification, STATUS_WARNING, STATUS_SUCCESS} from "../../../helpers/httpCatcher";
import ToggleButton from "../../Session/Modal/Parts/ToggleButton";

export default {
  name: "UserListInactive",
  components: {ToggleButton, DefaultTable},
  data:() =>  ({
    users: [],
    selected: [],
  }),
  created() {
    bus.$on('users_generated', e => {
      this.users.push(...Object.values(e))
    })
    axiosService.post('/api/v1/admin/users/inactive').then(e => {
      this.users = Object.values(e.data);
      console.log(e.data)
    });
  },
  computed: {
    countSelected() {
      return this.selected.reduce((reducer, item) => reducer + (item ? 1 : 0), 0)
    }
  },
  methods: {
    toggle(index){
      this.selected[index] = !this.selected[index]
    },
    deleteUsers(){
      axiosService.post('/api/v1/admin/users/delete', {user_id: this.selected.map((_e, key) => this.users[key].id)}).then(e => {
        const response = e.data.filter(a => a.status === 'deleted');

        response.forEach(item => {
          const id = this.users.findIndex(user => user.id === item.user_id)
          if(id === -1) return
          this.users.splice(id, 1)
        })

        bus.$emit('notification', new Notification('Deleted users with ids: '+ response.map(user => user.user_id).join(", ") , STATUS_SUCCESS))
      }).catch(e => {
        bus.$emit('notification', new Notification('Cant delete users '+ e.response.data.ids.join(", "), STATUS_WARNING))
      }).finally(() => {
        this.selected = []
      })
    },
    deleteUser(id){
      axiosService.post('/api/v1/admin/users/delete', {user_id: id}).then(e => {
        const response = e.data[0]
        if(response.status === 'deleted'){
          const identifier = this.users.findIndex(item => item.id === response.user_id)
          if(identifier === -1)return
          const user = this.users.splice(identifier, 1)
          bus.$emit('notification', new Notification('Deleted '+ user[0].firstName , STATUS_SUCCESS))
        }
      }).catch(e => {
        bus.$emit('notification', new Notification('Cant delete users '+ e.response.data.ids.join(", "), STATUS_WARNING))
      })
    }
  }
}
</script>
