<template>
  <div class="dropdown dropdown_component"
       :class="get_dropdown_class_list">
    <div class="dropdown_content"
         @click="clickDropdown">
      <slot name="dropdown_content"/>
    </div>
    <div class="dropdown_body"
         :class="get_body_class_list">
      <div class="dropdown_body-content">
        <slot name="dropdown_body"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Dropdown',
  props: {
    attached_left: {
      type: Boolean,
      default: false
    },
    attached_right: {
      type: Boolean,
      default: false
    },
    open_on_hover: {
      type: Boolean,
      default: false
    },
    opened_on_select: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      opened: false,
    }
  },
  computed: {
    get_dropdown_class_list () {
      return {
        has_dropdown_body: !!this.$slots.dropdown_body,
        open_on_hover: this.open_on_hover,
        opened: this.opened
      }
    },
    get_body_class_list () {
      return {
        attached_right: this.attached_right,
        opened: this.opened
      }
    }
  },
  methods: {
    clickDropdown() {
      if (this.open_on_hover) return
      if (this.opened) {
        this.closeDropdown()
      } else {
        this.openDropdown()
      }
    },
    openDropdown() {
      this.checkSiblingsComponent()
      document.addEventListener('click', this.clickOutside)
      setTimeout(() => {
        this.opened = true
      }, 0)
    },
    clickOutside(e) {
      if (!e.target.closest('.dropdown_component')
        || !this.opened_on_select && e.target.closest('.dropdown_body-content')
      ) {
        this.closeDropdown()
      }
    },
    checkSiblingsComponent() {
      Array.from(document.querySelectorAll('.dropdown_component.opened'))
        .forEach(el => {
          if (el?.__vue__?._uid && this._uid !== el.__vue__._uid) {
            el.__vue__.closeDropdown()
          }
        })
    },
    closeDropdown() {
      this.opened = false
      document.removeEventListener('click', this.clickOutside)
    }
  }
}
</script>
