<template>
  <form class="default_form" @submit.prevent="submit">
    <slot></slot>
    <div class="small-success-code" v-if="success_text">
      <ios-checkmark-circle class="pb-1 my-auto mr-2"/>
      {{success_text}}
    </div>
    <alert-block
      v-if="!!error_text"
      :text="error_text"
    />
    <slot name="buttons_block"></slot>
  </form>
</template>

<script>

import IosCheckmarkCircle from 'vue-ionicons/dist/md-checkmark-circle-outline.vue'
import ComponentHelper from '../../mixins/ComponentHelper'
import { validateForm } from '../../helpers/input_validations'
import AlertBlock from '__module_list/alert_block/AlertBlock'

export default {
  name: "DefaultForm",
  components: {
    AlertBlock,
    IosCheckmarkCircle
  },
  mixins: [ComponentHelper],
  props: {
    error_text: {
      type: String,
      default: ''
    },
    success_text: {
      type: String,
      default: ''
    },
    submit_f: {
      type: [Function, Boolean],
      default: false
    }
  },
  methods: {
    submit(e) {
      if (typeof this.submit_f == 'function') {
        return this.submit_f(e)
      }
      return this.defaultSubmitF(e)
    },
    defaultSubmitF(e) {
      let components = validateForm(this.$el)
      setTimeout(() => { // add interval to mutate components
        if (components.every(el => el.is_valid)){
          this.$emit('submit', e)
        }
      }, 0)
    }
  }
}
</script>
