<template>
  <div class="pass-test">
    <div class="row">
      <div class="col-12 col-sm-3 mt-10">
        <div class="card overflow-hidden px-0 py-0">
          <div
            v-for="(question, index) in question_list"
            class="pass-test_question"
            :class="{active: selected === index, disabled: !passed_answers.includes(question.id)}"
            @click="changeQuestion(index)">
            Question #{{ index + 1 }}
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-9 mt-10">
        <div
          v-for="(question, index) in question_list"
          class="pass-test_answers">
          <div class="card"
               v-if="index === selected"
               :key="question.id">
            <h5>{{ question.question }}</h5>

            <custom-checkbox
              v-for="(answer, i) in question.answers"
              class="pass-test_answers-checkbox"
              is_radio
              :checked="checkbox_answers.includes(`${question.id}_${i}`)"
              @change="updateAnswers(question, i)"
              :name="`question_${index}`"
              :key="`question_${index}_answer_${i}`"
            >
              {{ answer }}
            </custom-checkbox>

            <div class="field is-grouped">
              <div v-if="index"
                   class="control">
                <button class="button"
                        :class='{"is-info": index > 0}'
                        @click="changeQuestion(selected - 1 )">
                  Previous
                </button>
              </div>
              <div class="control">
                <button
                  v-if="notlastitem"
                  class="button is-success"
                  :disabled="!passed_answers.includes(question.id)"
                  @click="changeQuestion(selected + 1 )">Next
                </button>
                <button
                  v-else
                  class="button is-danger"
                  :disabled="!passed_answers.includes(question.id)"
                  @click="send">Finish
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import axiosService from '../../plugins/axiosService'
import bus from '../../bus'
import CustomCheckbox from '../../../module_list/custom-checkbox/CustomCheckbox'
import {Notification, STATUS_WARNING} from "../../helpers/httpCatcher";

export default {
  name: 'PassTest',
  components: { CustomCheckbox },
  data: () => ({
    quiz: null,
    answers: [],
    successed: 0,
    sending: false,
    current: null,
    selected: 0
  }),
  computed: {
    ...mapGetters([
      'passedQuizIds'
    ]),
    notlastitem () {
      return this.selected < this.quiz.question.length - 1
    },
    question_list () {
      return this.quiz?.question || []
    },
    passed_answers () {
      return this.answers.map(e => e.id)
    },
    checkbox_answers () {
      return this.answers.map(e => `${e.id}_${e.answer}`)
    }
  },
  mounted () {
    axiosService.post(`/api/v1/quiz/${this.$route.params.id}`)
      .then(e => {
        let quiz = e.data
        this.checkQuiz(quiz)
        this.quiz = quiz
      })
  },
  methods: {
    ...mapActions([
      'checkAuth'
    ]),
    changeQuestion (i) {
      this.selected = i
    },
    updateAnswers (q, answer_index) {
      let answer_obj = this.answers.find(answer => answer.id === q.id)
      if (answer_obj) {
        answer_obj.answer = answer_index
      } else {
        this.answers.push({
          id: q.id,
          answer: answer_index
        })
      }
    },
    checkQuiz (quiz) {
      if (!quiz.available
        || new Date(quiz.openFrom) > new Date()
        || !quiz.question.length
        || this.passedQuizIds.includes(quiz.id)
      ) {
        this.$router.push({ name: 'tests' })
      }
    },
    send () {
      if(this.sending){
        bus.$emit('notification', new Notification('Sending results', STATUS_WARNING))
        return;
      }
      this.sending = true
      axiosService.post('/api/v1/quiz/score', {
        a: this.answers.map(el => el.answer),
        q: this.$route.params.id
      })
        .then(r => {
          let result = r.data.score || 0
          bus.$emit('notification', { text: 'Your score is ' + result })
          this.$router.push({ name: 'tests' })
        }).catch(e => {
        this.sending = false
      })
    }
  }
}
</script>
