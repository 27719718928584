<template>
  <div class="header_block" v-show="getAccountXAuth">
    <div class="header_block-page">{{ router_name }}</div>

    <div class="header_block-user">
      <user-avatar
        :src="default_user_avatar"
      />
      <dropdown-button
        class="header_block-user-btn"
        :btn_text="user_name + ' (' +  (getPermission.name || '' ) + ')' "
      >
        <router-link :to="{name: 'profile'}">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person" viewBox="0 0 16 16">
            <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z"/>
          </svg>
          Profile
        </router-link>
        <div class="" @click="logoutAction">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-box-arrow-left" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M6 12.5a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-8a.5.5 0 0 0-.5.5v2a.5.5 0 0 1-1 0v-2A1.5 1.5 0 0 1 6.5 2h8A1.5 1.5 0 0 1 16 3.5v9a1.5 1.5 0 0 1-1.5 1.5h-8A1.5 1.5 0 0 1 5 12.5v-2a.5.5 0 0 1 1 0v2z"/>
            <path fill-rule="evenodd" d="M.146 8.354a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L1.707 7.5H10.5a.5.5 0 0 1 0 1H1.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3z"/>
          </svg>
          Logout
        </div>
      </dropdown-button>
    </div>

  </div>
</template>

<script>

import { mapGetters, mapActions } from 'vuex'
import DropdownButton from '__module_list/dropdown_button/DropdownButton'
import DefaultButton from '../small-components/DefaultButton'
import UserMixin from '../../mixins/UserMixin'
import UserAvatar from './UserAvatar'

export default {
  name: 'HeaderBlock',
  components: { UserAvatar, DefaultButton, DropdownButton },
  mixins: [UserMixin],
  computed: {
    ...mapGetters([
      'getAccountXAuth',
      'getAccount',
      'getPermission'
    ]),
    router_name () {
      return this.$route.meta.name
    },
    user_name() {
      if (!this.getAccount) return  ''
      return `${this.getAccount.first} ${this.getAccount.last}`;
    },
    replaceByDefault() {
      if(this.$refs.img!= null) this.$refs.img.src = this.default_src
    }
  },
  methods: {
    ...mapActions([
      'logoutAction'
    ])
  }
}
</script>
