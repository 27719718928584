<template>
  <div class="">
    <div>
      <h3 class="pb-2">Allocate users for room {{ session_id }} </h3>
    </div>
    <div class="controlPane">
      <train-buttons class="wrap-2 d-block">
<!--        <select-component :items="selector" v-model="point" @selected="e => fetchUsers()"/>-->
        <button class="button is-primary" @click="refetch">Fetch</button>
      </train-buttons>
      <container class="wrap-available-users" @drop="e => dropAcceptor(e, 'hub')"
                 :should-accept-drop="() => true"
                 :get-child-payload="(index) => datapayload(index)">

        <draggable-user v-for="(val, index) in items" :value="val" :key="index"/>
      </container>


      <div class="user-control-panel" :key="renderKey">
        <h4 style="display: flex;flex-flow: wrap row; justify-content: flex-start;">
          <div style="text-align: center;display: flex;flex-direction: column;justify-content: center;">
            Users
          </div>
        </h4>
        <div class="user-group-grid">
          <div class="columns-user-groups" v-for="(group, index) in value">
            <h3>Room - {{ index + 1 }}</h3>

            <container class="wrap-available-users" @drop="e => dropAcceptor(e, index)"
                       v-if="group.length > 0"
                       group-name="1" :should-accept-drop="() => true"
                       drop-class="test-class"
                       :drop-placeholder="dropPlaceholderOptions"
                       :key="index"
                       :get-child-payload="(i) => (datapayload(i, index))">

              <template v-for="(val, i) in group">
                <draggable-user :value="val" v-if="val.role_active" :key="getKeyFor(index, i)"/>
              </template>
<!--              <draggable-user v-for="(val) in group" :value="val" v-if="val.role_active"/>-->
            </container>

            <container class="grid-clear" drop-class="test-class" group-name="1"
                       @drop="e => dropAcceptor(e, index)" :should-accept-drop="() => true"
                       v-else>
              <div class="block-remove-user-group" >
                <span class="wrap-remove" tooltip="Remove group" @click="remGroup">
                  <circle-trash class="remove-button"/>
                </span>
                <circle-drop class="square"/>
              </div>
            </container>

          </div>
          <container class="grid-clear" group-name="1" :should-accept-drop="() => true"
                     @drop="e => {createGroupWithElement(e)}"
               :class="{wrap:value && value.length % 3 !== 0, hidden: value && value.length >= 7}">
            <tooltip
              text="Add new group"
              tooltip_color="#015498"
              tooltip_bg="#01549888">
              <div class="block-add-user-group"
                   :class="{pressed: addingGroup}"
                   @click="addGroup">
                <circle-add style="font-size: 2rem;"/>
              </div>
            </tooltip>
          </container>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import DraggableUser from "../Groups/DraggableUser";
import TrainButtons from "../../../../../module_list/TrainButtons";
import SelectComponent from "../../../../../module_list/dropdown/SelectComponent";
import {Container, Draggable} from "vue-smooth-dnd";
import ToggleButton from "./ToggleButton";
import axiosService from "../../../../plugins/axiosService";
import AllocateMixin from '../ModalMixins/AllocateMixin'
import CircleAdd from 'vue-ionicons/dist/ios-add-circle-outline'
import CircleTrash from 'vue-ionicons/dist/ios-backspace'
import CircleDrop from 'vue-ionicons/dist/ios-square-outline'
import Tooltip from '__module_list/tooltip/Tooltip'

export default {
  name: "UserAllocateComponents",
  mixins: [AllocateMixin],
  components: {
    Tooltip,
    ToggleButton,
    SelectComponent,
    TrainButtons,
    DraggableUser,
    Container,
    Draggable,
    CircleAdd,
    CircleDrop,
    CircleTrash
  },
  data: () => ({
    dropPlaceholderOptions: {
      className: 'drop-preview',
      animationDuration: '150',
      showOnTop: true
    },
    renderKey: 0,
    addingGroup: false,
    isPublic: false,
    isGlobal: false,
  }),
  props: {
    value: {},
    items: {},
    session_id: {}
  },
  mounted() {
    this.point = this.selector[Math.max(this.selector.length - 1, 0)]
    this.fetchUsers();
  },
  methods: {
    refetch(){
      /* @TODO: */
      this.$emit('refetch');
    },
    /*  ISHUB: FALSE, 1, 2, 3*/
    datapayload(item, hub = null) {
      console.log('payload created', item, hub,"\n", this.$props.value.map(gr => gr.map(i=>i.firstName + ' ' + i.lastName).join(', ')).join("\n"));
      if (null !== hub) {
        return {sender: item, index: hub, entity: this.$props.value[hub][item]}
      } else {
        return {sender: 'hub', index: item, entity: this.$props.items[item]}
      }
    },
    getKeyFor(index, i){
      return index + "_" + i;
    },
    remGroup() {
      const irem = this.$props.value.findIndex(e => e.length === 0)
      if (this.$props.value.length > 1 && -1 !== irem) {
        this.$props.value.splice(irem, 1);
      }
      console.log( this.$props.value.map(gr => gr.map(i=>i.firstName + ' ' + i.lastName).join(', ')).join("\n"));
    },
    createGroupWithElement(e){
      const insert = e.addedIndex
      const remove = e.removedIndex
        if (this.addingGroup === true || insert == null) return;
        this.addingGroup = true;
        setTimeout(() => {
          this.addingGroup = false;
        }, 500)
        if (this.$props.value.length < 7) {
          this.$props.value.push([
            e.payload.entity
          ]);
        }
        console.log( this.$props.value.map(gr => gr.map(i=>i.firstName + ' ' + i.lastName).join(', ')).join("\n"));
    },
    addGroup() {
      if (this.addingGroup === true) return;
      this.addingGroup = true;
      setTimeout(() => {
        this.addingGroup = false;
      }, 500)
      if (this.$props.value.length < 7) {
        this.$props.value.push([]);
      }
      console.log( this.$props.value.map(gr => gr.map(i=>i.firstName + ' ' + i.lastName).join(', ')).join("\n"));
    },
    canInsertTo(payload, target, index = null){
      const v = this.$props.value
      const id =  payload.id
      if(target) {
        return -1 === this.$props.items.findIndex(entity => entity.id === id)
      } else {
        /*findIndex(array => -1  !==   .... )  */
        return -1 === v[index].findIndex(entity => entity.id === id)
      }
    },

    dropAcceptor(e, self) {
      const value = this.$props.value
      const hub = (self === 'hub')

      const insert = e.addedIndex
      const remove = e.removedIndex

      const insertEntityId = e.payload.entity.id

      if (remove !== null) {
        this.itemRemove(remove, hub ? null : self);
        this.renderKey++;
      }

      if (insert !== null && this.canInsertTo(e.payload.entity, hub, self)) {
        if (hub) {
          this.renderKey++;
          this.pushToHub(insert, e.payload.entity)
        } else {
            this.pushValue(insert, self, e.payload.entity)
            let count = 0;
            const maxNumberOfGroups = 7
            value.forEach(group => {
              count += group.length
            })
            if (count / 4 > value.length && value.length < maxNumberOfGroups) {
              value.push([])
              this.$emit('input', value)
            }

        }
      }
    },
  }
}
</script>

<style scoped lang="scss">
.block-add-user-group, .block-remove-user-group {
  border-radius: 4px;

  min-height: 78px;
  padding-top: 23px;
  text-align: center;
  width: 100%;
  border: 1px solid #0909cd33;
}

.columns-user-groups .grid-clear {
  padding-top: 1rem;
}

.grid-clear {
  transition: padding ease-in-out .5s;
}

.user-group-grid .grid-clear.wrap {
  border-bottom: none;
  padding-top: 46px
}

.block-add-user-group:hover {
  color: #2c3e50;
  border: 1px solid #0909cd83;
  cursor: pointer;
}

.pressed {
  color: red;
}

.wrap-available-users .draggable-user-item {
  cursor: crosshair;
  user-select: unset;
}

.user-control-panel {
  padding: 0rem 1rem 2rem;
  display: block;
  border: 1px solid white;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background: white;
  margin-top: 2rem;
  min-height: 65px;
}

.user-control-panel h4 {
  padding: 0.75rem 1rem;
  color: orange;

}

.user-group-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  @media screen and (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.controlPane {
  background-color: white;
  padding: .5rem;
}

.controlPane .wrap {
  border-bottom: 1px solid #2d3748;
}

.controlPane .wrap-available-users {
  max-height: unset;
  background-color: inherit;
}

.wrap-available-users {
  background-color: #f3f4f6;
  padding: 1rem .5rem;
  min-height: 65px;
  grid-template-columns: repeat(3, 1fr);
  display: grid;
  grid-auto-flow: row dense;
  column-gap: 1rem;
  row-gap: 1rem;
  @media screen and (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.columns-user-groups .wrap-available-users {
  grid-template-columns: 1fr;
}

.test1 {
  background-color: #f3f4f6;
  padding: 1rem .5rem;
  display: flex;
  flex-flow: wrap row;
  justify-content: space-between;
}

.draggable-item {
  background-color: #42b98333;
  color: black;
  font-size: 1.25rem;
}

.wrap-2 {
  padding: .5rem;
}

.block-remove-user-group:hover .remove-button{
  color: #8a4a4a;
}
.block-remove-user-group .remove-button:hover{
  color: black;
  font-weight: 600;

}
.block-remove-user-group:hover .square{
  color: #cdcdf5;
}
.padded-row{

}
.hidden{
  display: none;
}


.wrap-remove{
  color: #cdcdf5;
  font-size: 1.25rem;
  margin-right: 2rem;
  height: 32px;
  display: inline-block;
}
.remove-button, .square{
  font-size: 2rem;
}
.ion.remove-button:hover::after{
  background-color: #8a4a4a;
  pointer-events: none;
}

.ion.remove-button::after {
  display: block;
  content: " ";
  position: absolute;
  left: 48px;
  top: -16px;
  height: 4rem;
  background: #cdcdf5;
  width: 2px;
}

</style>
