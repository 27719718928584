<template>
  <div v-if="!roleIsGuest">
      <div class="mt-4">
        <dashboard-managers v-if="roleIsManager"/>
        <dashboard-candidate v-else/>
<!--        <zoom-modal/>-->
      </div>
  </div>
  <div v-else>
    <step-mandatory/>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import CreateRoom from './LandingPage/CreateRoom.vue'
import _av from '../assets/no-user-image-icon-23.png'
import adm from '../assets/admin.png'
import SessionComponents from './Admin/SessionComponents'

import {Browser} from '@capacitor/browser'
import axiosService from '../plugins/axiosService'
import UserListComponent from './Admin/UserListComponent'
import StepMandatory from './Login/StepMandatory'

import IosBrush from 'vue-ionicons/dist/ios-brush'
import DashboardManagers from './blocks/DashboardManagers'
import DashboardCandidate from './blocks/DashboardCandidate'
import UserModal from "./modals/UserModal";
import PopupBlock from "../../module_list/popup/PopupBlock";
import constants from "../helpers/constants";
const appClient = 'HnctbGm_QYmv3ytz4UZ_Cw'

const authUrl = constants.BaseURL + '/api/v1/zoom/oauth/provide'

export default {
  name: 'Dashboard',
  components: {
    PopupBlock,
    UserModal,
    DashboardCandidate,
    DashboardManagers,
    StepMandatory,
    UserListComponent, CreateRoom, SessionComponents, IosBrush},

  computed: {
    ...mapGetters(['getAccount', 'getPermission', 'getQuizList']),
    isManager() {
      console.log(this.getPermission.permission)
      return this.getPermission != null && this.getPermission.permission != null
        && (this.getPermission.permission.findIndex(i => i === 'rooms') !== -1)
    },
    roleIsGuest() {
      return (!this.getPermission || this.getPermission.name === 'guest')
    },
    roleIsManager() {
      return (!this.getPermission || this.getPermission.permission.find(el => el === 'promote' || el === 'list'))
    },
  },
  data: () => ({
    user: {dn: ''}, role: '', perms: [], rooms: [], loadingStarted: false,
    av: _av,
    email: '-%#!#!!#$!',
    active: 0,
    pages: [
      'Active Session',
      'Available Session',
      'Users',
    ],
    adm: adm,
    quizes: []
  }),
  watch: {
    getPermission(val) {
      if (val !== undefined) {
        if (this.user !== undefined) {
          this.user.permissions = val
        }
        console.log(this.user)
      }
    },
    async permission(val) {
      this.role = val !== undefined ? val.name : val
      this.perms = val !== undefined ? val.permission : []
      const w = async () => {
        if (val !== undefined && val.permission !== undefined && this.rooms.length < 1) {
          this.getListRooms()
        }
      }
      await w()
    }
  },
  created() {
    if( this.updateEntityData() ){
      this.fetchAdminData()
    }
  },
  methods: {
    ...mapActions([
      'actionPermission',
      'checkAuth',
      'fetchUsers',
      'fetchSessions',
    ]),
    camelize(str) {
      return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
        return index !== 0 ? word.toLowerCase() : word.toUpperCase();
      }).replace(/\s+/g, '');
    },
    updateEntityData(){
      this.quizes = this.getQuizList;
      const user = this.getAccount
      if (user !== undefined && user.first !== undefined) {
        this.$set(this.user, "dn", this.camelize(user.first) + ' ' + this.camelize(user.last));
        return true;
      }
      return false;
    },
    fetchAdminData(){
      this.fetchSessions({
        date: (new Date()).toISOString().substring(0, 10)
      })

      this.fetchUsers().then(users => {
        this.userList = users
      })
    },
    oauth() {
      this.openExt(`https://zoom.us/oauth/authorize?response_type=code&client_id=${appClient}&redirect_uri=${authUrl}`)
    },
    // eslint-disable-next-line no-unused-vars
    _delete(id) {
      const chain = axiosService.post('/api/v1/zoom/rooms/delete', {id})
      chain.then(() => {
        this.rooms.splice(this.rooms.findIndex(e => e.id === id), 1)
      })
    },
    fetchTable() {
      console.log(this.perms)
      if (this.perms.includes('list') || this.perms.includes('promote')) {
        axiosService.post('/api/v1/user/list')
          .then((response) => {
            this.table1 = response.data
          }).catch()
      }
    },
    getListRooms() {
      if (!this.loadingStarted) {
        this.fetchTable()
        const chain = axiosService.post('/api/v1/zoom/rooms/list')
        chain.then((e) => {
          e.data.items.forEach((i) => {
            this.rooms.push(i)
          })
        })
      }
      this.loadingStarted = true
    },
    generateRooms() {
      const names = [
        'Raginhard Phelim',
        'Ayaz Haruna',
        'James Njord',
        'Arnifrid Deodato',
        'Turnus Othman',
        'Chikondi Fabia',
        'Aamadu Zahide',
        'Andżelika Hugleikr',
        'Heng Spas',
        'Wazo Mildred'
      ]
      for (let i = 0; i < 3; i += 1) {
        this.rooms.push({name: names[i], smth: 'test'})
      }
    },
    test(id) {
      this.$router.push({name: 'quiz', params: {id}})
    },
    goChat() {
      this.$router.push({name: 'pusher'})
    },
    goDare() {
      openExt(this.$constants.DARE_TRADE_URL)
    },
    async openExt(url) {
      await Browser.open({url: url})
    },
    logout() {
      this.user = this.account !== undefined ? this.account : {firstName: 'fname'}
      const chain = axiosService.post('/logout')
      chain.then(() => {
        this.$store.dispatch('logout')
        this.$router.push({name: 'home'})
      })
    },

  }
}
</script>

<style scoped>
.title {
  font-family: Roboto, sans-serif;
  font-size: 24px;
  text-align: center;
  padding-top: 24px;
  padding-bottom: 12px;
}

.wrap {
  width: 100%;
}

@media (min-width: 400px) {
  div.grid-dashboard {
    grid-template-columns: 1fr .5fr;
  }

  div.profile {
    grid-template-columns: 1fr 2fr;
  }
}

.grid-dashboard {
  display: grid;
  grid-column-gap: 1rem;
  grid-template-columns: 1fr;

}

.profile {
  display: grid;
  grid-column-gap: 1rem;
  grid-template-columns: 1fr;
}

</style>
