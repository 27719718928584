<template>
  <loader v-if="data_loading"/>
  <div v-else-if="!getAccountLanguages.length">
    <div class="mt-20 card">
      <user-languages
        form_text="To continue languages your know"/>
    </div>
  </div>
  <div v-else>
    <tabs>
      <tab
        name="Available tests"
        tab_img_href="/images/add.svg"
        :notification="getAccountAvailableTests.length">
        <default-table
          :fields_name="available_tests_fields"
          :items="getAccountAvailableTests"
          empty_list_text="No available tests">
          <template slot="id" slot-scope="props">
            {{ props.id }}
          </template>
          <template slot="title" slot-scope="props">
            {{ props.title }}
          </template>
          <template slot="description" slot-scope="props">
            {{ props.description }}
          </template>
          <template slot="language_id" slot-scope="props">
            <template v-if="props.meta_value">
              {{userLanguages([props.meta_value.language_id], false)}}
              <template v-if="props.meta_value.main">(language test)</template>
            </template>
            <template v-else>-</template>
          </template>
          <template slot="row_after" slot-scope="props">
            <default-button :router_obj="{name: 'testId', params: {id: props.id}}">Run test</default-button>
          </template>
        </default-table>
      </tab>
      <tab
        name="Finished tests"
        tab_img_href="/images/check.svg"
        :notification="getAccountPassedTests.length">
        <default-table
          :fields_name="{score: 'Your score', test_id: 'id', quiz_title: 'Title', quiz_description: 'Description' }"
          :items="getAccountPassedTests">
          <template slot="test_id" slot-scope="props">
            {{ props.test_id }}
          </template>
          <template slot="quiz_title" slot-scope="props">
            {{ props.quiz_title }}
          </template>
          <template slot="quiz_description" slot-scope="props">
            {{ props.quiz_description }}
          </template>
          <template slot="score" slot-scope="props">
            {{ props.score > 0 ? level(props) : '0%' }}
          </template>
          <template slot="status" slot-scope="props">
            <badge
              :is_main_test="!!(props.quiz_meta && props.quiz_meta.main)"
              :result="props.score"
              :pass_rate="props.quiz.pass_rate"/>
          </template>
          <template slot="row_after" slot-scope="props">
            <button class="button is-warning ml-10" @click="setModalQuiz(props)">
              <statistic-icon color="#946c00"/>
            </button>
          </template>
        </default-table>
      </tab>
    </tabs>
    <user-answers-quiz-modal
      :obj="modal_quiz"
      @closed="setModalQuiz()"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Tabs from '__module_list/tabs/Tabs.vue'
import Tab from '__module_list/tabs/Tab.vue'
import DefaultTable from '__module_list/default_table/DefaultTable'
import DefaultButton from '../small-components/DefaultButton'
import Loader from '../../../module_list/loader/Loader'
import Badge from '../small-components/Badge'
import UserAnswersQuizModal from '../modals/UserAnswersQuizModal'
import StatisticIcon from '../small-components/icons/StatisticIcon'
import axiosService from '../../plugins/axiosService'
import UserLanguages from '../blocks/UserLanguages'
import userMixin from '../../mixins/UserMixin'
import { LANGUAGE_LEVELS } from '../../mixins/TestMixin'

export default {
  name: 'TestList',
  mixins: [userMixin],
  components: {
    UserLanguages,
    StatisticIcon,
    UserAnswersQuizModal,
    Badge,
    Loader,
    DefaultButton,
    Tabs,
    Tab,
    DefaultTable
  },
  computed: {
    ...mapGetters([
      'getAccountAvailableTests',
      'getAccountPassedTests',
      'getAccountLanguages',
    ]),
    available_tests_fields() {
      return {
        title: "Title",
        description: "Description",
        language_id: "Language",
      }
    }
  },
  data () {
    return {
      data_loaded: false,
      data_loading: false,
      modal_quiz: null,
    }
  },
  created () {
    if (this.data_loaded || this.data_loading) return
    this.data_loading = true
    this.fetchUserQuizList()
      .then(() => {
        this.data_loaded = true
        this.data_loading = false
      })
  },
  mounted() {
    if (this.$route.params?.id) {
      this.$nextTick(() => {
        this.fetchFinishedTest(this.$route.params?.id)
      })
    }
  },
  methods: {
    ...mapActions([
      'fetchUserQuizList'
    ]),
    level(val){
      if(val.quiz !== undefined && val.quiz.meta_value !== undefined){
        val = val.score
      } else {
        return val.score + '%'
      }

      let select = 'A1';
      for(let [key, value] of Object.entries(LANGUAGE_LEVELS)){
        if(val >= value.min){
          select = value.code
        }
      }
      return select
    },
    setModalQuiz(obj = {}) {
      this.modal_quiz = obj
    },
    fetchFinishedTest(id) {
      if (!id) return
      axiosService.get(`/api/v1/user/quiz-${id}`)
        .then(resp => {
          this.setModalQuiz(resp.data.test_result || null)
        })
        .catch((e) => {
          console.log([e])
        })
    }
  }
}
</script>
