<template>
    <div class="-question">
        <div class="body">
            {{ value['q'] }}
        </div>
        <div class="answer">
                <div v-for="(item,index) of value" class="answers-wrapper"    v-if="index !== 'q' && index !== 'r'"
                     @click="upd(index)">
        <input
            type="checkbox"
            :checked="selected[index]"
            :value="true"
            :unchecked-value="false"
            style="margin-right: .5rem;"
        />
            {{item}}

                </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'Question',
  data: () => ({
    selected: {}
  }),
  props: {
    value: {
      required: false
    }
  },
  watch: {
    selected: {
      deep: true,
      handler: () => {}
    }
  },
  methods: {
    upd (index) {
      this.selected[index] = !this.selected[index]
      const keys = Object.keys(this.selected).filter(e => index !== e)
      // eslint-disable-next-line no-restricted-syntax
      for (const k of keys) {
        this.selected[k] = false
      }
      this.$forceUpdate()

      this.$emit('selected', index)
    }
  }
}
</script>

<style scoped>
    .-question{
        max-width: 400px;
        display: flex;
        flex-direction: column;
        padding: .5rem .5rem 0;
    }

</style>
