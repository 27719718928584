<template>
  <div class="global-wrapper">
    <div class="time-picker wrap-time">
      <div v-if="showControls" class="control-wrapper pr-4">
        <div class="controlUp" @click="hours = (hours + 1) % 24"><v-icon class="mb-2 m-0" color="white">mdi-arrow-up</v-icon></div>
        <div class="controlDown" @click="hours = (hours - 1) < 0 ? 23 :(hours - 1)"><v-icon color="white">mdi-arrow-down</v-icon></div>
      </div>
      <div class="hours" @input="inputHour" contenteditable="true">{{compHours}}</div>
      <div class="divider">:</div>
      <div class="minutes" @input="inputMinute" contenteditable="true">{{compTime}}</div>
      <div v-if="showControls" class="control-wrapper pl-4">
        <div class="controlUp" @click="minutes = (minutes + 1) % 60"><v-icon class="p-0 m-0" color="white">mdi-plus</v-icon></div>
        <div class="controlDown" @click="minutes = (minutes - 1) < 0 ? 59 :(minutes - 1)"><v-icon color="white">mdi-minus</v-icon></div>
      </div>
      <div class="relative"></div>
    </div>
    <div class="d-flex flex-wrap justify-space-around flex-row pa-2">
      <slot>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CustomTimePicker',
  data: () => ({
    hours: 0,
    minutes: 0,
    am_enabled: false,
    am: false,
    showControls: true
  }),
  props: {
    value: {
      type: String,
      required: false
    }
  },
  created () {
    if (this.value !== undefined) {
      [this.hours, this.minutes] = this.value.split(':')
      this.$emit('input', `${0}:${0}`)
    }
  },
  updated () {
    this.$emit('input', `${this.compHours}:${this.compTime}`)
  },
  methods: {
    actionSelect (e) {
      console.debug(e)
    },
    inputMinute (e) {
      const { start } = this.getSelectionCharacterOffsetWithin(e.target)
      const text = e.data
      const oldMinutes = this.minutes
      if (e.inputType === 'insertText') {
        // eslint-disable-next-line no-restricted-globals
        if (!isNaN(text)) {
          if (text.length - start === 0) {
            if (text.length === 1) {
              this.minutes = (Number(text) * 10) + (this.minutes % 10)
              if (Number(text) >= 6) {
                this.minutes = 59
              }
            } else if (text.length === 2) {
              if (Number(text) > 59) {
                this.minutes = 59
              } else {
                this.minutes = Number(text)
              }
            }
          } else {
            const number = Number(text.charAt(0))
            this.minutes = (Math.floor(this.minutes / 10) * 10) + number
          }
        }
      } else if (start < 1) {
        this.minutes %= 10
      } else {
        this.minutes = Math.floor(this.minutes / 10) * 10
      }
      if (oldMinutes === this.minutes) {
        this.minutes = oldMinutes + 0.0001
        this.$nextTick(() => {
          this.minutes -= 0.0001
        })
      } else {
        this.$emit('input', `${this.compHours}:${this.compTime}`)
      }
      // eslint-disable-next-line no-console
      console.debug(e.cancelable, start)
    },
    inputHour (e) {
      const { start } = this.getSelectionCharacterOffsetWithin(e.target)
      const text = e.data
      const oldHours = this.hours
      if (e.inputType === 'insertText') {
        // eslint-disable-next-line no-restricted-globals
        if (!isNaN(text)) {
          if (start - text.length === 0) {
            if (text.length === 1) {
              this.hours = (Number(text) * 10) + (this.hours % 10)
              if (Number(text) > 2) {
                this.hours = 23
              }
            } else if (text.length === 2) {
              if (Number(text) > 23) {
                this.hours = 23
              } else {
                this.hours = Number(text)
              }
            }
          } else {
            const number = Number(text.charAt(0))
            if (this.hours >= 19.9) {
              const a = Math.floor(this.hours / 10)
              if ((a * 10) + number > 23.9) this.hours = 23
              else this.hours = (a * 10) + number
            } else {
              this.hours = (Math.floor(this.hours / 10) * 10) + number
            }
          }
        }
      } else if (start < 1) {
        this.hours %= 10
        e.target().focus()
        e.target().setSelectionRange(2, 2)
      } else {
        this.hours = Math.floor(this.hours / 10) * 10
      }

      if (oldHours === this.hours) {
        this.hours = oldHours + 0.0001
        this.$nextTick(() => {
          this.hours -= 0.0001
        })
      } else { this.$emit('input', `${this.compHours}:${this.compTime}`) }
    },
    getSelectionCharacterOffsetWithin (element) {
      let start = 0
      const end = 0
      const doc = element.ownerDocument || element.document
      const win = doc.defaultView || doc.parentWindow
      let sel
      if (typeof win.getSelection !== 'undefined') {
        sel = win.getSelection()
        if (sel.rangeCount > 0) {
          const range = win.getSelection().getRangeAt(0)
          const preCaretRange = range.cloneRange()
          preCaretRange.selectNodeContents(element)
          preCaretRange.setEnd(range.startContainer, range.startOffset)
          start = preCaretRange.toString().length
        }
        // eslint-disable-next-line no-cond-assign
      } else if ((sel = doc.selection) && sel.type !== 'Control') {
        const textRange = sel.createRange()
        const preCaretTextRange = doc.body.createTextRange()
        preCaretTextRange.moveToElementText(element)
        preCaretTextRange.setEndPoint('EndToStart', textRange)
        start = preCaretTextRange.text.length
      }
      return { start, end }
    }
  },
  computed: {
    compHours () {
      this.hours %= 24
      this.hours = this.hours < 0 ? this.hours * -1 : this.hours
      if (this.hours < 10) {
        return `0${this.hours}`
      }
      return this.hours
    },
    compTime () {
      this.minutes %= 60
      this.minutes = this.minutes < 0 ? this.minutes * -1 : this.minutes
      if (this.minutes < 10) {
        return `0${this.minutes}`
      }
      return this.minutes
    }
  }
}
</script>

<style lang="scss">
  .global-wrapper{
    border-radius: .5rem;
     background-color: white;
  }
  .control-wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    height: 52px;
    grid-row-gap: 4px;
    .controlUp, .controlDown {
      &:hover{
        background-color: #474747;
      }
      background-color: #333333;
      height: 24px;
      display: block;
      font-size: 0;
    }
  }
  .time-picker{
    color: rgba(0,0,0,.87);
    font-family: Roboto, sans-serif;
    background-image: linear-gradient(#ddd, #bbb, #ddd);
  }
  .divider{
    content: ':';
    display: contents;
  }
  .wrap-time{
    font-size: 2rem;
    padding: 1rem 2rem .5rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    width: max-content;
  }
</style>
