<template>
  <router-link
    v-if="is_router_link"
    :to="router_obj"
    @click="$emit('click', $event)"
    class="button">
    <slot></slot>
  </router-link>
  <component
    v-else
    class="button"
    :class="{disabled: disabled}"
    @click="$emit('click', $event)"
    :is="tag"
    :type="type"
    :href="get_href">
    <slot></slot>
  </component>
</template>

<script>
export default {
  name: 'DefaultButton',
  props: {
    router_obj: {
      type: Object,
      default: () => ({})
    },
    tag: {
      type: String,
      default: 'button'
    },
    href: {
      type: [String, Boolean],
      default: false
    },
    type: {
      type: String,
      default: 'button'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    is_router_link () {
      return this.router_obj != null && Object.keys(this.router_obj).length !== 0
    },
    get_href () {
      return (typeof this.href == 'string' && !!this.href) ? this.href : false
    }
  }
}
</script>
<style>
.button {
  transition: opacity .3s ease;
}
.button.disabled {
  pointer-events: none;
  opacity: .5;
}
</style>
