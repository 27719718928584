<template>
  <div class="popup-block"
       v-if="local_shown"
       :class="{opened: shown}">
    <template>
      <transition name="fade_popup">
        <div
          v-show="shown_transition"
          class="popup-block_shadow"
          @click="closeModal">
        </div>
      </transition>
      <transition name="slide_popup">
        <div
          v-show="shown_transition"
          class="popup-block_el"
          :class="class_list">
          <div class="popup-block_el-content">
            <div class="popup-block_el-content-header">
              <div class="popup-block_el-content-header-title">
                <slot name="popup_title"/>
              </div>
              <div
                @click="closeModal"
                class="popup-block_el-content-header-close">
                <svg xmlns="http://www.w3.org/2000/svg"
                     height="25px"
                     viewBox="0 0 512 512"
                     width="25px"
                     xml:space="preserve">
                <path
                    d="M443.6,387.1L312.4,255.4l131.5-130c5.4-5.4,5.4-14.2,0-19.6l-37.4-37.6c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4  L256,197.8L124.9,68.3c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4L68,105.9c-5.4,5.4-5.4,14.2,0,19.6l131.5,130L68.4,387.1  c-2.6,2.6-4.1,6.1-4.1,9.8c0,3.7,1.4,7.2,4.1,9.8l37.4,37.6c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1L256,313.1l130.7,131.1  c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1l37.4-37.6c2.6-2.6,4.1-6.1,4.1-9.8C447.7,393.2,446.2,389.7,443.6,387.1z"/>
                </svg>
              </div>
            </div>
            <div class="popup-block_el-content-header-sub_title">
              <slot name="popup_sub_title"/>
            </div>
            <alert-block
              v-if="head_status_text"
              class="popup-block_el-content-header-status"
              :text="head_status_text"
              :is_success="!header_status_error"
            />
            <div class="popup-block_el-content-body">
              <loader v-if="loader"/>
              <slot v-else/>
            </div>
            <div
              v-show="has_popup_footer"
              class="popup-block_el-content-footer">
              <slot name="popup_footer"/>
            </div>
          </div>
        </div>
      </transition>

    </template>
  </div>
</template>

<script>
import Loader from '../loader/Loader'
import AlertBlock from '../alert_block/AlertBlock'
export default {
  name: 'PopupBlock',
  components: { AlertBlock, Loader },
  props: {
    shown: {
      type: Boolean,
      default: false,
      required: true
    },
    closeModal: {
      type: Function,
      required: true
    },
    is_wide: {
      type: Boolean,
      default: false,
    },
    is_wider: {
      type: Boolean,
      default: false,
    },
    loader: {
      type: Boolean,
      default: false
    },
    head_status_text: {
      type: String,
      default: ''
    },
    header_status_error: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      local_shown: false,
      shown_transition: false,
      timeout_f: false
    }
  },
  computed: {
    has_popup_footer () {
      return !!this.$slots.popup_footer
    },
    class_list() {
      return {
        wide: this.is_wide,
        wider: this.is_wider
      }
    }
  },
  methods: {
    closeSiblingsModals () {
      let el = document.querySelector('.popup-block.opened')
      if (el?.__vue__?.shown) {
        el.__vue__.closeModal()
      }
    },
  },
  watch: {
    shown (newVal) {
      if (newVal) {
        clearTimeout(this.timeout_f)
        this.closeSiblingsModals()
        this.local_shown = true

      } else {
        this.timeout_f = setTimeout(() => {
          this.local_shown = false
        }, 300)
      }
      this.$nextTick(() => {
        this.shown_transition = newVal
      })
      document.body.style.overflow = newVal ? 'hidden' : 'unset'
    }
  }
}
</script>
