export default {
  setCookie(name, value){
    const exdays = 3;
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));

    const cookie = {}

    cookie[name] = value
    cookie.expires =  (new Date(Date.now() + 60 * 1000 * 60 * 24 * 3)).toUTCString(),
    cookie.path = '/'

    let request = ''

    for (let key in cookie) {
      if (request === '') request = key + '=' + cookie[key]
      else request += ';' + key + '=' + cookie[key]
    }

    document.cookie = request
    return request
  },
  setHead(name, value){
    let head = document.head;
    if(head) {
      const metas = document.querySelector("meta[http-equiv='"+name+"']")
      let el = metas === null ? document.createElement('meta') : metas;
      el.setAttribute('http-equiv', name);
      el.content = value
      if(metas === null) {
        head.appendChild(el)
      }
    }
  },
  clearHead(name){
    const metas = document.querySelector("meta[http-equiv='"+name+"']")
    if (metas) {
      metas.remove()
    }
  },
  clearCookie(name){
    document.cookie = name+'=; Max-Age=-99999999;';
  },
  getAuth(){
    return window.localStorage.getItem('x-auth')
  }
}
