<template>
  <div class="card">
    <div class="content">
      <div class="position-absolute floating back-icon">
        <button class="button" @click="back"><back-icon /> back</button>
      </div>
      <h2>Processing of personal data </h2>
      <p>
        <strong>SC DARE International SRL</strong>, with its registered office in Iași, Pacurari Street, no 116 B,1st floor,
        office B1.09, Iași County, registered with Business Registration Number J22/2024/2015, Unique Registration Code
        35216059, hereinafter reffered to as
        ”<strong>The Company</strong>”, processes – together with its partner companies – personal data in a way that complies
        with the legal regulations concerning personal data processing and its free movement.
      </p>
      <p>The present agreement informs about the means by which your personal data can be collected and used. Please
        read carefully and take notice of the following:</p>
      <p><strong>The Company can process, without being limited to the hereunder personal data categories:</strong></p>
      <ul>
        <li>contact details, for instance last name, first name, address, e-mail, phone number;</li>
        <li>information provided when registering on our website, with regard to education, age and hobbies;</li>
        <li>information posted as comments, messages or any other form of communication sent as note or inquiry about
          the site and its content.
        </li>
      </ul>
      <p>
        This type of data can be collected when filling in the form found at
        <a href="http://www.veoworldwide.com/ro/faq#,">http://www.veoworldwide.com/ro/faq</a>
        the ”<strong>One step closer to your awesome job</strong>” application or by uploading your CV.
      </p>
      <h4>Processing Your Personal Data</h4>
      <p><strong>The Company</strong> can process your personal data to serve the following purposes:</p>
      <ul>
        <li>the present or future collaboration (including, but not limited to employment, collaboration, professional
          training, various partnerships);
        </li>
        <li>communication and correspondence with regard to development opportunities within The Company, as well as to
          its contributors (for instance job opportunities within The Company or related information);
        </li>
        <li>notices or reports sent to authorities, certified state or governmental agencies and institutions;</li>
        <li> probity checks in compliance with data published online, including, but not limited to Facebook, LinkedIn,
          Twitter etc.
        </li>
      </ul>

      <p>Data collection and processing for the above mentioned purposes can be performed on the following basis:</p>
      <ul>
        <li>your consent;</li>
        <li>a legal obligation;</li>
        <li>various contractual obligations;</li>
        <li>The Company’s legitimate interest towards protecting its image within an optimal context of serving its
          economical target.
        </li>
      </ul>
      <p>
        Recipients to whom collected personal data can be disclosed, in some cases: We can disclose your personal data
        only when we have the right to or when we are compelled to (for instance, if it’s required by a certified public
        institution, by legal regulations, by a court order or another judicial or administrative procedure); if a new
        law or regulation implies it; if we believe, in good faith, that a disclosure is necessary in order to protect
        our – or others’ – rights or to help with the investigation or prevention of unlawful activities, public order
        or good customs.

      </p>
      <h4>Personal Data Retention</h4>
      <p>
        Personal data can be retained on The Company’s servers within the EU for maximum 2 years, during which the above
        mentioned purposes can be served, or for an amount of time stipulated in the current legislation.
      </p>
      <h4>Individual Rights Concerning Personal Data</h4>
      <ul>

        <li>the right to access personal data that affects you;</li>
        <li>the right to seek rectifications or updates when data is inaccurate or incomplete;</li>
        <li>the right to claim data deletion under certain circumstances and in compliance with the law (for instance,
          when personal data is no longer necessary in regard to the aforementioned purposes);
        </li>
        <li>the right to request limited processing in certain circumstances (e.g when refuting the accuracy of
          collected data – for the time required to verify the specified data);
        </li>
        <li>the right to file a complaint against The Company and/or another competent authority;</li>
        <li>the right to deny the processing of personal data, according to the law;</li>
        <li>the right to data portability, thus transferring your personal data to another operator of your choice,
          within the law;
        </li>
        <li>the right to be notified about any personal data breach;</li>
        <li>the right to withdraw your consent to personal data processing, at any given time;</li>
        <li>the right to be forgotten, thus filing a request for data erasure, within the law.</li>
      </ul>
      <p>
        We reserve the right to deny your data erasure request should processing your personal data be subject to a
        legal obligation, to protection of the rights or legitimate interests of third parties or to finding, exerting
        or defending certain rights before the court.
      </p>
      <h4>Confidentiality Policy Revisions</h4>
      <p>The present agreement can be periodically modified or updated according to the law and to The Company’s
        policies. Any revisioned version of the Confidentiality Policy will be published on The Company’s website to
        ensure users’ real time access.</p>

      <p><strong>Contact Details</strong></p>
      <p>
        For further information regarding the present agreement or your personal data processing please contact us
        through any of the means below, including your name, postal or e-mail address (depending on the means of
        communication selected), phone number and the purpose of your request.
      </p>
      <p>Please contact us:</p>
      <ul>
        <li>
          Please contact us: <a href="mailto:juridic@veoworldwide.ro">juridic@veoworldwide.ro</a>;
        </li>
        <li>
          in person or through a request sent by mail at The Company’s office (at the aforementioned address).
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import BackIcon from 'vue-ionicons/dist/md-arrow-round-back.vue'
export default {
  name: "Agreement",
  components: {BackIcon},
  methods: {
    back(){
      this.$router.back()
    }
  }
}
</script>

<style scoped>
.position-absolute.floating.back-icon{
  position: fixed;
  right: 2rem;
  top: 4rem;
  background-color: #fff8;
  padding: .5rem 1rem;
}
  .card{
    max-width: 1280px;
    font-size: 1rem;
    margin-left: auto;
    margin-right: auto;
    padding: 1rem 2rem;
  }
  .card p{
    font-size: 1rem;
  }
</style>
