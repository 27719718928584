import axiosService from '../../plugins/axiosService'
import {HttpCatcher, Notification, STATUS_ERROR} from "../../helpers/httpCatcher";
import bus from "../../bus";

const state = {
  sessions: 0,
  activeSession: 0,
  userSessions: [],
  users: []
}
const getters = {
  sessions: state => (state.sessions),
  getUsers: state => (state.users),
  getUserSessions: state => state.userSessions,
  getUserActiveSession: state => state.userSessions.find(session => session.activity),
  getUserApprovedSession: state => state.userSessions.find(session => session.approved === 1),
  getUserBlockedSession: state => state.userSessions.filter(session => !session.approved) || [],
  getUserBlockedSessionIds: (state, getters) => getters.getUserBlockedSession.map(session => session.session_id) ?? [],
  activeSession: state => state.activeSession,
}

const mutations = {
  FETCH_SESSIONS(context, payload) {
    context.sessions = payload
  },
  NEWSESSION(context, payload) {
    if (Array.isArray(payload) && payload.length) {
      payload = payload[0]
    }
    context.sessions.push(payload)
    bus.$emit('notification', new Notification("Session created"))
  },
  STORE_ACTIVE(context, payload) {
    context.activeSession = payload
  },
  STORE_USERS(context, payload) {
    context.users = payload
  },
  UPDSESSION(context, payload) {
    const entity = context.sessions.find(s => payload.session_id === s.session_id)
    if(entity){
      const props = Object.getOwnPropertyNames(entity);
      props.forEach(prop => {
        entity[prop] = (payload[prop] !== undefined) ? payload[prop] : entity[prop]
      })
    } else {
      context.sessions.push(payload)
    }
  },
  ADD_USER(context, payload) {
    if (payload.id === undefined) return null;
    if (context.users === null) {
      context.users = []
    }
    const uindex = context.users.findIndex(e => payload.id === e.id);
    if (uindex === -1) {
      context.users.push(payload)
    } else {

      if (payload.session !== undefined && payload.session !== -1) {
        payload.session = Object.assign({}, context.users[uindex].session, payload.session)
      }
      context.users.splice(uindex, 1, Object.assign({}, context.users[uindex], payload));
    }
  },
  UPDROOM(context,payload){
    const uid = context.sessions.findIndex(s => payload.session_id === s.session_id)
    if(uid !== -1){
      const entity = context.sessions[uid].room.find(fn => fn.room_id === payload.room_id);
      if(entity) {
        const props = Object.getOwnPropertyNames(entity);
        props.forEach(prop => {
          entity[prop] = (payload[prop] !== undefined) ? payload[prop] : entity[prop]
        })
      } else {
        context.sessions[uid].room.push(payload)
      }
    }
  },
  USER_IN_SESSION(context, payload) {
    context.user_session = payload
  },
  REMOVE_USER(context, payload) {
    if (context.users !== null) {
      const index = context.users.findIndex(e => e.id === payload.id)
      if (index !== -1) {
        context.users.splice(index, 1)
      }
    } else {
      context.users = []
    }
  },
  USER_SESSIONS: (context, list) => context.userSessions = list || []
}

const actions = {
  fetchSessions(context, data) {
    // return null
    const auth = context.getters.getAccountXAuth
    const role = context.getters.getPermission
    const sessionAvailable = role !== undefined && role.permission !== undefined && role.permission.findIndex(e => e === 'join') !== -1

    if (data instanceof Date) {
      data = {date: data}
    }

    if (!sessionAvailable) return
    return axiosService.post('/api/v1/sessions/list', data)
      .then((e) => {
        if (e.data.sessions !== undefined && e.data.sessions.length > 0) {
          e.data.sessions = e.data.sessions.map(session => {
            session.session_location = decodeURI(session.session_location)
            return session
          })

          context.commit('FETCH_SESSIONS', e.data.sessions)
        }
        if (e.data.session != null && e.data.session.session_id !== undefined) {
          context.commit('STORE_ACTIVE', e.data.session)
        }
        context.commit('USER_SESSIONS', e.data.user_sessions || [])
      })
      .catch(e => {
        (new HttpCatcher()).process(e)
      })
  },
  async joinSession(context, session_id) {
    // return null
    const auth = context.getters.getAccountXAuth
    const role = context.getters.getPermission
    const sessionAvailable = role !== undefined && role.permission !== undefined && role.permission.findIndex(e => e === 'join') !== -1

    if (!sessionAvailable) {
      return
    }
    await axiosService.post('/api/v1/sessions/join', {session: session_id})
      .then((e) => {
        context.commit('USER_IN_SESSION', e.data.entity)
        context.commit('STORE_ACTIVE', e.data.entity?.session || 0)
      })
      .catch(e => {
        if (e.response && e.response.status === 403) {
          bus.$emit('notification', new Notification('You can\'t join this session now.', STATUS_ERROR))
        }
        (new HttpCatcher()).process(e)
      })
  },
  updSession(context, session){
    axiosService.post('/api/v1/sessions/edit', session).then(e => {
      context.commit('UPDSESSION', e.data)

    });
  },
  async createRoom(context, session){
    const data = {}
    data.title = session.title
    data.session_id = session.session_id
    data.start = {
      date: session.start1,
      time: session.start2
    }
    data.end = {
      date: session.end1,
      time: session.end2
    }
    data.users = session.users_selected ?? session.users
    return await axiosService.post('/api/v1/zoom/room/create', data).then(e => {
      context.commit('UPDROOM', e.data)
      bus.$emit('notification', new Notification('Room successfully created.'))
    }).catch(e => {
      bus.$emit('notification', new Notification('Fields filled incorrectly.', STATUS_ERROR))
    })
  },
  updRoom(context, session){
    const data = {
      title: session.title,
      meeting: session.meeting,
      start: {
        date: session.start1,
        time: session.start2
      },
      end: {
        date: session.end1,
        time: session.end2
      },
      sid: session.sid
    }
    data.users = session.users_selected ?? session.users
    axiosService.post('/api/v1/zoom/room/edit', data).then(e => {
      context.commit('UPDROOM', e.data)
      bus.$emit('notification', new Notification('Room successfully updated.'))
    }).catch(e => {
      console.log(e);
      bus.$emit('notification', new Notification('Fields filled incorrectly.', STATUS_ERROR))
    })
  },
  newSession(context, session) {
    // return null
    const role = context.getters.getPermission
    const sessionAvailable = role !== undefined && role.permission !== undefined && role.permission.findIndex(e => e === 'rooms') !== -1
    if (!sessionAvailable) return

    const auth = context.getters.account !== 0 && context.getters.account !== undefined ? context.getters.account.x_auth : ''
    axiosService.post('/api/v1/sessions/new', session)
      .then((e) => {
        context.commit('NEWSESSION', e.data)
      })
      .catch(e => {
        (new HttpCatcher()).process(error)
      })
  },
  fetchUsers(context, nodata = null) {
    const role = context.getters.getPermission
    const sessionAvailable = role !== undefined && role.permission !== undefined && role.permission.findIndex(e => e === 'rooms') !== -1
    if (!sessionAvailable) return

    return axiosService.post('/api/v1/user/list')
      .then(response => {
        const users = response !== undefined && response.data !== undefined && response.data.users !== undefined
          ? response.data.users : []
        context.commit('STORE_USERS', users)
        return users
      })
      .catch((error) => {
        (new HttpCatcher()).process(error)
        context.commit('STORE_USERS', [])
        return []
      })
  },
  userEdit(context, nodata = null) {
    context.commit('ADD_USER', nodata)
  },
  clearSessions(context, nodata = null) {
    context.commit('STORE_ACTIVE', 0)
    context.commit('FETCH_SESSIONS', 0)
    context.commit('USER_SESSIONS', [])
  }
}

export default {
  state,
  mutations,
  actions,
  getters
}
