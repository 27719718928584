<template>
  <div class="chat py-4">
    <h4>Session chat</h4>
    <div class="chatBox">
      <div class="message-box">
        <div class="message" v-for="chm in top" :class="chm.left? 'left' : ''">
          <div class="user" v-if="!chm.message.includes(chm.user)">{{ chm.user }}</div>
          <div class="message-content">
            {{ chm.message }}
          </div>
        </div>
      </div>
      <train-buttons class="py-4">
        <input class="input text-chat" v-model="chatEntity.msgBox" @keypress.enter="trySend"/>
        <button class="button is-info" @click="trySend">Send</button>
      </train-buttons>
    </div>
  </div>
</template>

<script>
import TrainButtons from "../../../module_list/TrainButtons";
import bus from "../../bus";
import {mapGetters} from "vuex";

export default {
  name: "ChatComponent",
  components: {TrainButtons},
  data: () => ({
    chatEntity: {
      msgBox: '',
      messages: [],
      chat_id: ''
    },
  }),
  created() {
    bus.$on('chat_message', (msg) => this.parse(msg))
    console.log('Chat component activated')
    bus.$emit('chat-ready');
  },
  computed: {
    ...mapGetters(['getAccount']),
    top() {
      return this.chatEntity.messages.filter((_a, b, r) => b > r.length - 10)
    },
  },
  methods: {
    parse(message) {
      try {
        const msg = message.toString().replace('ok_', '')
        const obj = JSON.parse(msg)
        if (obj !== undefined && obj.length !== undefined) {
          const accid = this.getAccount.id
          console.log(accid);
          this.chatEntity.messages = obj.map(e =>
            Object.assign(e, {left: e.user_id === accid })
          )
        } else if (obj) {
          this.chatEntity.messages.push(obj)
        }
      } catch (e) {
        console.log('system', message, e)
      }
    },
    trySend() {
      if (this.$wschannels !== null && this.$wschannels.chat !== null) {
        this.$wschannels.chat.send('send_' + this.chatEntity.msgBox)
        this.chatEntity.messages.push({user: this.dn, message: this.chatEntity.msgBox, left: true})
      }
    },
  }
}
</script>

<style scoped>

@media screen and (max-width: 600px) {
  .text-chat {
    min-width: auto;
  }
}

@media screen and (min-width: 600px) {
  .text-chat {
    min-width: 350px;
  }
}
.chatBox{
  max-width: 600px;

}
.message.left{
  margin-left: auto;
}
.message{
  max-width: 50%;
  padding: 4px 16px;
  border-radius: 8px;
}

.message-box {
  min-height: 120px;
  margin-top: .5rem;
  border: 4px #2b74b1 groove;
  padding: .25rem .5rem;
  max-width: 600px;
  overflow-y: scroll;
  max-height: 400px;
  background-color: #efefff;
}
</style>
