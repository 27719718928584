<template>
  <div>
    <h5 class="pb-4">
      Enter your login information
    </h5>
    <default-form
      @submit="submitLogin"
      class="mx-4"
      :error_text="errormsg">
      <div>
        <default-input
          name="email"
          type="email"
          placeholder="Email"
          required
          v-model="email.val"
          :valid.sync="email.valid"
          @input="errormsg = ''"
          :validate_f="validateEmail"
        />
        <default-input
          v-if="step === 2"
          name="name"
          required
          type="password"
          placeholder="Password"
          v-model="password.val"
          :valid.sync="password.valid"
        />
      </div>
      <template v-slot:buttons_block>
        <div class="card-actions">
          <p class="pb-4">
            Service require login to use functionality
          </p>
          <div class="button-wrapper">
            <default-button
              :disabled="!email.val"
              type="submit"
              class="is-link ml-auto"
            >Next
            </default-button>
          </div>
        </div>
      </template>
    </default-form>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import DefaultInput from '../small-components/DefaultInput'
import DefaultButton from '../small-components/DefaultButton'
import input_validations from '../../helpers/input_validations'
import axiosService, { DEFAULT_ERROR_REPS_TEXT } from '../../plugins/axiosService'

import IosAlertIcon from 'vue-ionicons/dist/ios-information-circle.vue'
import DefaultForm from '../small-components/DefaultForm'
// import bus from '../../bus'

export default {
  name: 'StepEmail',
  mixins: [input_validations],
  data: () => ({
    step: 0,
    email: {
      val: '',
      valid: true,
    },
    password: {
      val: '',
      valid: true,
    },
    errormsg: '',
  }),
  components: {
    DefaultForm,
    DefaultButton,
    DefaultInput,
    IosAlertIcon
  },
  methods: {
    ...mapActions([
      'loginAction'
    ]),
    goToRegistration () {
      this.$router.push({
        name: 'register',
        params: { email: this.email.val }
      })
    },
    submitLogin () {
      this.errormsg = ''
      if (this.step !== 2) {
        axiosService.post('/account/exist', {
          email: this.email.val
        })
          .then(e => {
            if (e.data.exists) {
              this.step = 2
            } else {
              this.errormsg = 'Can\'t found user with this email'
            }
          })
          .catch(e => {
            console.log(e)
            if (e.response?.data?.message === 'blocked') {
              this.errormsg = 'Your account is blocked'
            }
          })
      } else {
        axiosService.post('/login', {
          email: this.email.val,
          password: this.password.val
        })
          .then(e => {
            if (e.data.user !== undefined) {
              this.loginAction({ ...e.data.user, ...{ x_auth: e.headers['x-auth'] } })
              this.$router.push({ name: 'dashboard.home' })
            } else {
              this.errormsg = 'Can\'t found user with this email'
            }
          })
          .catch(e => {
            if (e !== undefined && e.response !== undefined && e.response.status === 403) {
              this.errormsg = 'Wrong login or password'
            } else {
              this.errormsg = e.response?.data?.message || DEFAULT_ERROR_REPS_TEXT
            }
          })
      }
    }
  }
}
</script>
