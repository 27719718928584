<template>
    <div class="profile_page-security_form mt-20">
      <h2 class="mb-10">
        Finish your registration
      </h2>
      <default-form
        :error_text="error"
        @submit="submitForm"
      >
        <div class="row">
          <div class="col-sm-6 col-12">
            <default-input
              name="first_name"
              placeholder="First name"
              required
              v-model="firstname.val"
              :valid.sync="lastname.valid"
              :validate_f="validateLogin"
            />
          </div>
          <div class="col-sm-6 col-12">
            <default-input
              name="last_name"
              placeholder="Last name"
              required
              v-model="lastname.val"
              :valid.sync="lastname.valid"
              :validate_f="validateLogin"
            />
          </div>
          <div class="col-sm-6 col-12">
            <default-input
              name="phone"
              placeholder="Phone"
              type="tel"
              v-model="phone.val"
              :valid.sync="phone.valid"
            />
          </div>
        </div>
        <template v-slot:buttons_block>
          <div class="button-wrapper mt-10">
            <default-button
              type="submit"
              class="button is-success"
            >Submit
            </default-button>
          </div>
        </template>
      </default-form>
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import DefaultButton from "../small-components/DefaultButton";
import DefaultInput from "../small-components/DefaultInput";
import input_validations from "../../helpers/input_validations";
import axiosService from "../../plugins/axiosService";
import DefaultForm from '../small-components/DefaultForm'

export default {
  name: 'StepMandatory',
  mixins: [input_validations],
  data() {
    return {
      error: '',
      firstname: {
        val: '',
        valid: true
      },
      lastname: {
        val: '',
        valid: true
      },
      phone: {
        val: '',
        valid: true
      },
    }
  },
  components: {
    DefaultForm,
    DefaultInput,
    DefaultButton
  },
  computed: {
    ...mapGetters([
      'getAccount'
    ]),
  },
  methods: {
    ...mapActions([
      'actionPermission',
      'loginAction'
    ]),
    submitForm() {
      if (!this.firstname.valid
        || !this.lastname.valid
        || !this.phone.valid
      ) return;
      let success = false
      axiosService
        .post('/api/v1/user/update', {
          firstName: this.firstname.val,
          lastName: this.lastname.val,
          phone: this.phone.val
        })
        .then(r => {
          if (r.data.role_updated) {
            success = true
            this.actionPermission()
          }
          if (r.data.user) {
            this.loginAction({ ...r.data.user, ...{ x_auth: r.headers['x-auth'] } })
            this.actionPermission()
          }
        })
        .catch(e => {
          console.log([e])
        })
        .finally(() => {
          if (!success) {
            this.error = 'Cant update your profile'
          } else {
            window.location.reload();
          }
        })
    }
  }
}
</script>
