<template>
  <div
    class="table_filters">
    <div class="table_filters-list">
      <search-select
        v-if="search_fields.length"
        class="table_filters-list-search"
        :search_val="search_val"
        :search_by_list="search_fields"
        :selected_index="search_by_index"
        @search="$emit('search', true)"
        @selectedSearchBy="emitSearchBySelected"
        @searchVal="emitParentSearch"
      />
      <template v-if="filters && filters_is_looped">
        <div v-for="(values, key) in filters"
             class="table_filters-list-filter">
          <slim-select
            v-show="!filter_query_outer_arr.includes(key)"
            searchable
            :placeholder="filterPlaceholder(key)"
            :parent_key="key"
            :values="values"
            :selected="selected_filters[key]"
            :key="index_v"
            @selected="changeFilters"
            clearable
          />
        </div>
      </template>
    </div>
    <transition name="fade">
      <tooltip
        class="table_filters-clear"
        tooltip_bg="#ffe08a"
        tooltip_color="#000000b3"
        text="Clear filters"
        is_inline>
        <button
          v-show="has_selected_filers_or_search"
          @click="$emit('clear')"
          class="button px-10 is-warning">
          <clear-filter-icon color="#000000b3"/>
        </button>
      </tooltip>
    </transition>
  </div>
</template>

<script>

import SlimSelect from '../slim_select/SlimSelect'
import SearchSelect from '../search_select/SearchSelect'
import ClearFilterIcon from '../../application/components/small-components/icons/ClearFilterIcon'
import Tooltip from '../tooltip/Tooltip'

export default {
  name: 'TableFilters',
  components: {
    Tooltip,
    ClearFilterIcon,
    SearchSelect,
    SlimSelect
  },
  props: {
    filters: {
      type: [Object, Array],
      default: null
    },
    selected_filters: {
      type: Object,
      default: null
    },
    search_fields: {
      type: Array,
      default: () => []
    },
    search_val: {
      type: String,
      default: ''
    },
    search_by_index: {
      type: Number,
      default: -1
    },
    filter_fields_names: {
      type: Object,
      default: null
    },
    selected_order_by: {
      type: Boolean,
      default: false
    },
    filter_query_outer: {
      type: Object,
      default: null
    },
    has_selected_filers_or_search: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      index: 0,
    }
  },
  computed: {
    filter_query_outer_arr() {
      if (!this.filter_query_outer) return []
      return Object.keys(this.filter_query_outer)
    },
    index_v () {
      this.index++
      return this.index
    },
    filters_is_looped () {
      if (!this.filters) return
      return Array.isArray(this.filters) || Object.keys(this.filters).length
    },
  },
  methods: {
    changeFilters (data) {
      if (!data.parent || this.selected_filters[data.parent] === undefined) return
      this.selected_filters[data.parent] = data['id']
      this.$emit('filter_update', true)
    },
    filterPlaceholder (key) {
      if (!this.filter_fields_names) return key
      return this.filter_fields_names[key] || key
    },
    emitParentSearch (val) {
      this.$emit('searchVal', val)
    },
    emitSearchBySelected (val) {
      this.$emit('selectedSearchBy', val)
    }
  }
}
</script>
