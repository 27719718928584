<template>
  <div class="generateUsers">
    <div class="wrapper pt-4">
      <default-input v-model="number" class="d-inline-block " style="width:200px;max-width: 15rem;"
                     label="Number of users"
                     @input="e => {
          number = e;
          if(e < names.length){
            this.names.splice(
              Math.max(e, 0),
              Math.max(names.length - e, 0)
            )
          } else if(numberSelected){
            generateUsers();
        }}"
      ></default-input>
      <select-component :items="availableRoles" class="d-inline-block" :error="showRoleError" v-model="role"
                        @selected="() => {showRoleError = false}"
      ></select-component>
      <button class="button is-danger" @click="generateUsers">
        Show usernames
      </button>
      <div class="d-block">
        <default-input v-model="lastName" class="d-inline-block " style="width:400px;max-width: 15rem;"
                       label="Last Name"></default-input>
        <default-input v-model="password" class="d-inline-block password" type="password"
                       style="width:400px;max-width: 15rem;" label="Password"></default-input>
      </div>
    </div>
    <div class="wrapper">
      <button class="button is-link" @click="sendRequest">Create users</button>
      <button class="button is-link" v-if="numberSelected" @click="hidden = !hidden">Hide table</button>
    </div>
    <template v-if="numberSelected && !hidden">
      <div class="wrapper">
        <default-table :items="names">
          <template slot="id" slot-scope="props">
            {{ props.__col_index }}
          </template>
          <template slot="First Name" slot-scope="props">
            {{ props.name }}
          </template>
          <template slot="Last Name">
            {{ lastName }}
          </template>
          <template slot="Role">
            {{ role }}
          </template>
        </default-table>
      </div>
    </template>
  </div>
</template>

<script>
import DefaultInput from "../../small-components/DefaultInput";
import SelectComponent from "../../../../module_list/dropdown/SelectComponent";
import DefaultTable from "../../../../module_list/default_table/DefaultTable";
import namesAvailable from "../../../assets/manager/namesAvailable";
import range from "../../../assets/manager/range";
import axiosService from "../../../plugins/axiosService";
import bus from "../../../bus";
import {Notification, STATUS_WARNING} from "../../../helpers/httpCatcher";

export default {
  name: "GenerateUsers",
  components: {DefaultTable, SelectComponent, DefaultInput},
  data: () => ({
    numberSelected: false,
    hidden: false,
    number: 5,
    role: '',
    lastName: 'User Test',
    availableRoles: ['user', 'candidate', 'recruiter', 'manager'],
    setPassword: '',
    showRoleError: false,
    password: '',
    defaultPwd: 'LLLq##19',
    names: []
  }),
  mounted() {
    this.password = this.defaultPwd
  },
  methods: {
    generateUsers() {
      this.numberSelected = true
      console.log('started', range(0, this.number))
      const n = namesAvailable()
      while (this.names.length < this.number) {
        const open = Math.floor(Math.random() * n.length)
        this.names.push({name: n[open]})
      }
    },
    sendRequest() {
      if (!this.role) {
        bus.$emit('notifications', new Notification('Select role before send request', STATUS_WARNING))
        this.showRoleError = true
        return;
      }
      if (this.names.length < this.number) this.generateUsers(this.numberSelected)
      axiosService.post('/api/v1/admin/generate/users', {
        users: this.names.map(e => (
          {
            firstName: e.name,
            lastName: this.lastName,
            role: this.role,
            password: this.password
          }
        ))
      }).then(e => {
        bus.$emit('users_generated', e.data)
      })
    }
  }
}
</script>
