<template>
  <div>
    <popup-block
      :closeModal="closeModal"
      :shown="opened">
      <template v-slot:popup_title>Edit  </template>
      <template>
        <div class="card">
          User list:
          <ul v-for="user in modal_data">
            <li>{{user.firstName}} {{user.lastName}}
              <template v-if="user.zoom && user.zoom.room">
                <a class="button d-block is-small is-warning" :href="geturl(user.zoom.room.meta)">{{ decodeURI(user.zoom.room.room_name) }}</a>

              </template>
            </li>
          </ul>
        </div>
      </template>
      <template v-slot:popup_footer>
        <button class="button is-link is-outlined" @click="submitForm">Save</button>
        <button class="button is-success ml-10 is-outlined" @click="submitFormClose">Save & Close</button>
      </template>
    </popup-block>
  </div>
</template>

<script>
import PopupBlock from '__module_list/popup/PopupBlock'
import ModalMixin from '../../mixins/ModalMixin'
import EditQuiz from '../Test/EditQuiz'
import DefaultInput from "../small-components/DefaultInput";

export default {
  name: 'ShowUsersModal',
  mixins: [ModalMixin],
  components: {
    DefaultInput,
    EditQuiz,
    PopupBlock
  },
  props: {
    modal_data: {
      default: () => []
    }
  },
  data() {
    return {
      text_success: '',
      text_error: '',
      local_quiz: null,
      and_close: false
    }
  },
  methods: {
    openModal() {
      this.opened = true
    },
    closed() {
      this.local_quiz = null
    },
    closeModal() {
      this.text_success = ''
      this.text_error = ''
      this.opened = false
      this.$emit('closed', true)
    },
    submitFormClose() {
      this.and_close = true
      this.submitForm()
    },
    geturl(meta){
      try{
        const val = JSON.parse(meta)
        console.log(val)
        return val.url
      }catch (e) {
        return '#'
      }
    },
    submitted() {
      this.text_success = ''
      this.text_error = ''
    },

  },
  watch: {
    modal_data(val) {
      console.log(val)
      if (!val || val.length <= 0) return
      console.log(val);
      this.openModal()
      this.local_quiz = val
    },
  }
}
</script>
