<template>
  <div class="session-info is-0">
    <div class="header">
      <div class="title">
        {{ value.session_location }}
      </div>
    </div>
    <div class="dates">

      <div class="date-wrapper">
        <h4 class="mx-auto text-center">Recrutement dates</h4>
        <train-buttons class="jr-dates">
          <div class="start-date button is-primary is-small">{{ value.session_jrStartDate }}</div>
          <button class="button is-static is-small">~</button>
          <div class="end-date button is-primary is-small">{{ value.session_jrEndDate }}</div>
        </train-buttons>
      </div>

      <div class="date-wrapper">
        <h4 class="mx-auto text-center">Training dates</h4>
        <train-buttons class="tr-dates">
          <div class="start-date button is-primary is-small">{{ value.session_trStartDate }}</div>
          <button class="button is-static is-small">~</button>
          <div class="end-date button is-primary is-small">{{ value.session_trEndDate }}</div>
        </train-buttons>
      </div>

    </div>
  </div>
</template>

<script>
import TrainButtons from "../../../../../module_list/TrainButtons";

export default {
  name: "ShowSessionComponent",
  components: {TrainButtons},
  props: {
    value: {
      type: Object
    }
  }
}
</script>

<style lang="scss">
.session-info{
  position: relative;
  padding: 2rem .5rem 1.5rem;
  background-color: white;
  box-shadow: 0px 0px 18px 10px #eee;
  h4{
    padding-bottom: .5rem;
  }
  .header{
    display: block;
    position: absolute;
    left: 50%;
    top: 1rem;
    .title{
      color: #e6951d;
    text-decoration: underline 2px;
    }
    margin-left: auto;
    margin-right: auto;
    transform: translateX(-50%);
    padding: .25rem .5rem;
    border-radius: 8px;
    margin-bottom: 0;
  }
  .dates{
    display: flex;
    position: relative;
    flex-flow: row wrap;
    justify-content: space-around;
    align-items: center;
    margin-top: 2rem;
  }
  .tr-dates, .jr-dates{
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}
</style>
