<template>
  <div>
    <div class="quiz-edit-questions">
      <div class="row">
        <div class="col-12 card card-title mt-20">
          <div class="card-title_content">
            {{ quiz.title }}
          </div>
          <div class="field is-grouped">
            <default-button
              :router_obj="back_route" class="button is-danger mr-15">
              Close
            </default-button>
            <div class="field has-addons">
              <div class="control">
                <button class="button is-success is-outlined" @click="saveQuestion">Save</button>
              </div>
              <div class="control">
                <button class="button is-info is-outlined" @click="saveAndClose">Save & Close</button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 card mt-10 default_form" v-if="!!success || !!error">
          <div class="small-success-code" v-if="!!success">
            <ios-checkmark-circle class="pb-1 my-auto mr-2"/>
            {{success}}
          </div>
          <alert-block
            v-if="!!error"
            :text="error"
          />
        </div>
        <div class="col-12 col-sm-3 mt-10">
          <div class="quiz-edit-questions_group card">
            <sortable
              removable
              as_check
              @removeIndex="removeQuestion"
              @shuffle="shuffleQuestion"
              @selected_index="changeSelected"
              :list="questions"
              :selected_index="selected"
            >
              <template slot="item" slot-scope="item">
                Question "{{ item.question }}"
              </template>
            </sortable>
            <div
              @click="aqdNewQuestion"
              class="quiz-edit-questions_group_new-question button is-link is-outlined">
              <plus-icon/>
              Add question
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-9 mt-10">
          <div
            v-for="(question, index) in questions"
            v-show="index === selected"
            :data-index="index"
            :key="`question_${index}`"
            class="quiz-edit-questions_answers">
            <div class="card">
              <div class="mb-20">
                <default-input
                  input_textarea
                  placeholder="Question"
                  v-model="question.question"
                  required
                />
              </div>
              <sortable
                handler
                removable
                @shuffle="shuffleAnswer"
                @removeIndex="removeAnswer"
                :list="question.answers"
                :key="`question_${index}_correct_${question.correct}`"
                >
                <template slot="index" slot-scope="item">
                  <custom-checkbox
                    is_radio
                    @change="question.correct = item.index"
                    :checked="question.correct === item.index"
                    :name="`question_${index}`"/>
                  <default-input
                    input_textarea
                    placeholder="Answer"
                    v-model="question.answers[item.index]"
                    required
                  />
                </template>
              </sortable>
              <div
                @click="addNewAnswer(question)"
                class="quiz-edit-questions_group_new-question button is-link is-outlined">
                <plus-icon/>
                Add answer
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axiosService, {DEFAULT_ERROR_REPS_TEXT} from '../../plugins/axiosService'
import DefaultInput from '../small-components/DefaultInput'
import CustomCheckbox from '../../../module_list/custom-checkbox/CustomCheckbox'
import Sortable from '../blocks/Sortable'
import Close from '../small-components/icons/Close'
import DefaultButton from '../small-components/DefaultButton'
import {validateForm} from '../../helpers/input_validations'
import AlertBlock from '../../../module_list/alert_block/AlertBlock'
import IosCheckmarkCircle from 'vue-ionicons/dist/md-checkmark-circle-outline.vue'
import PlusIcon from '../small-components/icons/PlusIcon'


export default {
  name: 'QuizEditQuestions',
  components: {
    PlusIcon,
    AlertBlock,
    DefaultButton,
    Close,
    Sortable,
    CustomCheckbox,
    DefaultInput,
    IosCheckmarkCircle
  },
  data () {
    return {
      quiz: {},
      questions: [],
      selected: 0,
      error: '',
      success: '',
    }
  },
  computed: {
    back_route() {
      return {
        name: 'dashboard.listQuiz'
      }
    }
  },
  created () {
    let quiz_id = this.$route.params.mid
    if (!parseInt(quiz_id) || quiz_id <= 0) {
      this.closeQuiz()
      return
    }
    axiosService.post('/api/v1/quiz/' + quiz_id)
      .then(r => {
        this.quiz = r.data
        this.questions = r.data.question
      })
      .catch(e => {
        console.log([e])
        this.closeQuiz()
      })
  },
  methods: {
    changeSelected (i) {
      this.selected = i
    },
    removeQuestion (removeIndex) {
      this.questions.splice(removeIndex, 1)
    },
    shuffleQuestion ({
      from,
      to
    }) {
      let new_id = this.questions[to].id
      this.questions[to].id = this.questions[from].id
      this.questions[from].id = new_id
    },
    aqdNewQuestion () {
      this.questions.push({
        id: -1,
        question: '',
        answers: [],
        correct: 0,
        test_id: this.$route.params.mid
      })
      this.changeSelected(this.questions.length - 1)
    },
    addNewAnswer (question) {
      question.answers.push('')
    },
    removeAnswer (removeIndex) {
      this.questions[this.selected].answers.splice(removeIndex, 1)
    },
    shuffleAnswer ({from, to}) {
      let new_q = this.questions[this.selected].answers[to]
      this.questions[this.selected].answers[to] = this.questions[this.selected].answers[from]
      this.questions[this.selected].answers[from] = new_q
      if (this.questions[this.selected].correct === from) {
        this.questions[this.selected].correct = to
      } else if (this.questions[this.selected].correct === to) {
        this.questions[this.selected].correct = from
      }
    },

    validQuestions() {
      validateForm(this.$el)
      return new Promise((resolve) => {
        let valid = false
        setTimeout(() => {
          let invalid_field = this.$el.querySelector('.default_input.validate_inputs .input.is-danger')
          if (!invalid_field) {
            valid = true
          } else {
            // open error tab
            this.changeSelected(parseInt(invalid_field.closest('.quiz-edit-questions_answers')?.dataset?.index || 0))
          }
          resolve(valid);
        }, 0);
      });
    },
    saveAndClose() {
      this.saveQuestion()
        .then(() => {
          setTimeout(() => {
            this.closeQuiz()
          }, 2000)
        })
    },

    async saveQuestion () {
      let result = await this.validQuestions()
      if (!result) return
      this.quiz.question = this.questions
      this.error = ''
      this.success = ''
      return new Promise((resolve, reject) => {
        axiosService.post('/api/v1/quiz/question/edit', this.quiz)
          .then(r => {
            this.success = 'Updated'
            resolve(r.data)
          })
          .catch(() => {
            this.error = DEFAULT_ERROR_REPS_TEXT
            reject(false)
          })
      });
    },
    closeQuiz(){
      this.$router.push(this.back_route)
    }
  }
}
</script>
