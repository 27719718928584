<template>
  <div class="card">
    <div class="Header">
      <h4>Generate accounts</h4>
    </div>
    <div class="content">
      <generate-users />
    </div>
    <div class="content">
      <user-list-inactive />
    </div>
  </div>
</template>

<script>
import GenerateUsers from "./components/GenerateUsers";
import UserListInactive from "./components/UserListInactive";
export default {
name: "Manage",
  components: {UserListInactive, GenerateUsers}
}
</script>
