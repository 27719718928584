<template>
  <div class="screen centered-content">
    <div class="card-500 card mt-mobile pt-15">
      <h2>
        Register
      </h2>
      <h5 class="pb-4">
        Setting password
      </h5>
      <default-form
        @submit="submitForm"
        class="mx-4">
        <default-input
          name="email"
          placeholder="Email"
          type="email"
          :read_only="email_read_only"
          v-model="email.val"
          :valid.sync="email.valid"
          :validate_f="validateEmail"
        />
        <default-input
          name="name"
          required
          type="password"
          placeholder="Password"
          v-model="password.val"
          :valid.sync="password.valid"
          :validate_f="validatePassword"
        />
        <default-input
          name="name"
          required
          type="password"
          placeholder="Confirm Password"
          :compare="password.val"
          v-model="password_confirm.val"
          :valid.sync="password_confirm.valid"
          :validate_f="confirmPassword"
        />
        <label style="text-align: left;padding: 1rem 1rem;">
           <input type="checkbox" v-model="agree"/>
          <span style="padding: 1.25rem;font-size: 1rem;word-break: normal;text-align: left;">I agree with the <a href="/agreement" >processing of my personal data</a> for the purpose of current or future collaboration.</span>
        </label>


        <p class="pb-4" style="font-size: 1rem">
          Set your password for continue registration and have access to your account.
          You can open this page at any time to end register process.
        </p>
        <template v-slot:buttons_block>
          <div class="card-actions">
            <div class="button-wrapper">
              <default-button
                @click.prevent="cancel"
              >Cancel
              </default-button>
              <default-button
                type="submit"
                class="is-info"
                :class="{'is-static': !agree }"
              >Save
              </default-button>
            </div>
          </div>
        </template>
      </default-form>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import DefaultButton from '../small-components/DefaultButton'
import DefaultInput from '../small-components/DefaultInput'
import input_validations from '../../helpers/input_validations'
import axiosService from '../../plugins/axiosService'
import DefaultForm from '../small-components/DefaultForm'

export default {
  name: 'Register',
  mixins: [input_validations],
  props: {
    agree: true,
    email_: {
      default: '',
      required: false,
    },
  },
  data () {
    return {
      error: false,
      email_read_only: false,
      code: {
        val: this.$route.params.code,
        valid: true
      },
      password: {
        val: '',
        valid: true
      },
      password_confirm: {
        val: '',
        valid: true
      },
      email: {
        val: '',
        valid: true
      },
    }
  },
  mounted () {
    this.email.val = this.email_
    if (!this.code.val) {
      return
    }
    axiosService.post('/account/code', {
      code: this.code.val
    })
      .then(r => {
        if (r.data?.email) {
          this.email.val = r.data?.email || ''
          this.email_read_only = true
        }
      })
  },
  watch: {
    email_ (val) {
      this.email.val = val
    },
    code_ (val) {
      this.email.val = val
    },
  },
  components: {
    DefaultForm,
    DefaultInput,
    DefaultButton
  },
  methods: {
    ...mapActions([
      'loginAction'
    ]),
    cancel () {
      this.$router.push({
        name: 'home',
        hash: '#register',
        params: { email: this.email.val }
      })
    },
    submitForm () {
      if (!this.code.valid
        || !this.email.valid
        || !this.password_confirm.valid
      ) {
        return
      }

      axiosService.post('/account/setpwd', {
        ref: this.code.val,
        email: this.email.val,
        password: this.password.val,
        password_confirmation: this.password_confirm.val,
      })
        .then(resp => {
          if (!resp.data.user) {
            this.errors = true
            return
          }
          this.loginAction({ ...resp.data.user, ...{ x_auth: resp.headers['x-auth'] } })
          this.$router.push({ name: 'dashboard.home' })
        })
        .catch((e) => {
          if (e.response !== undefined) {
            if (e.response.status === 404) {
              this.$router.push({ 'name': 'home' })
            } else if (e.response.status === 403) {
              this.$router.push({
                name: 'register',
                params: { email: this.email.val }
              })
            }
          }
          this.errors = true
          console.log(e)
        })
    }
  }
}
</script>
