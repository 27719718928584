<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="5 5 40 40" width="20" height="20">
    <path :fill="color" d="M10.021,38.573v-21c0-0.276,0.241-0.5,0.538-0.5h5.92c0.297,0,0.538,0.224,0.538,0.5v21	c0,0.276-0.241,0.5-0.538,0.5h-5.92C10.262,39.073,10.021,38.849,10.021,38.573z"/>
    <path :fill="color" d="M20.021,38.573v-27c0-0.276,0.241-0.5,0.538-0.5h5.92c0.297,0,0.538,0.224,0.538,0.5v27	c0,0.276-0.241,0.5-0.538,0.5h-5.92C20.262,39.073,20.021,38.849,20.021,38.573z"/>
    <path :fill="color" d="M30.021,38.56V14.586c0-0.283,0.241-0.513,0.538-0.513h5.92c0.297,0,0.538,0.23,0.538,0.513V38.56	c0,0.283-0.241,0.513-0.538,0.513h-5.92C30.262,39.073,30.021,38.843,30.021,38.56z"/>
    <path :fill="color" d="M8.021,37.99V8h-2v31.99h36v-2H8.021z"/>
  </svg>
</template>

<script>
export default {
  name: 'StatisticIcon',
  props: {
    color: {
      type: String,
      default: '#fff'
    }
  }
}
</script>
