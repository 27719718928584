<template>
  <popup-block
    is_wide
    :head_status_text="invite_success || invite_error"
    :header_status_error="!!invite_error"
    :closeModal="closeModal"
    :shown="opened">
    <template v-slot:popup_title>Edit test: {{ local_quiz.title || '' }}</template>
    <template v-if="local_quiz">
      <tabs
        :change_hash="false"
        @tab_change="selectedTab">
        <tab name="Info">
          <edit-quiz
            :original_main="original_data.main"
            :original_pass_rate="original_data.pass_rate"
            :text_success="text_success"
            :text_error="text_error"
            @submitted="submitted"
            ref="edit_quiz"
            :new_quiz="local_quiz"
          />
        </tab>
        <tab
          :hidden="!invite_users"
          class="invite-users"
          name="Invite users">
          <template v-if="invite_users">
            <user-table
              :filter_query="filter_query"
              user_checkbox
              :active_table="tab_selected === 'Invite users'"
              :checked_ids="invited_ids"
              @checked_user="changeSelectedUsers"
            />
          </template>
        </tab>
      </tabs>
    </template>
    <template v-slot:popup_footer>
      <template v-if="tab_selected === 'Info'">
        <button class="button is-link is-outlined" @click="submitForm">Save info</button>
        <button class="button is-success ml-10 is-outlined" @click="submitFormClose">Save & Close</button>
      </template>
      <template v-else-if="tab_selected === 'Invite users'">
        <button class="button is-link is-outlined" @click="addUsers">Save changes</button>
      </template>
    </template>
  </popup-block>
</template>

<script>
import ModalMixin from '../../mixins/ModalMixin'
import EditQuiz from '../Test/EditQuiz'
import axiosService, { DEFAULT_ERROR_REPS_TEXT } from '../../plugins/axiosService'
import Tabs from '../../../module_list/tabs/Tabs'
import Tab from '../../../module_list/tabs/Tab'
import UserTable from '../tables/UserTable'
import DefaultTable from '../../../module_list/default_table/DefaultTable'
import userMixin from '../../mixins/UserMixin'
import Tooltip from '../../../module_list/tooltip/Tooltip'
import tabMixin from '../../mixins/TabMixin'
import PopupBlock from '../../../module_list/popup/PopupBlock'

export default {
  name: 'EditQuizModal',
  mixins: [ModalMixin, userMixin, tabMixin],
  components: {
    PopupBlock,
    Tooltip,
    DefaultTable,
    UserTable,
    Tab,
    Tabs,
    EditQuiz,
  },
  props: {
    quiz: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    invite_users () {
      return !this.local_quiz.public && this.local_quiz.language_id
    },
    filter_query () {
      if (!this.local_quiz || !this.local_quiz.language_id) return null
      let data = {language_id: this.local_quiz.language_id}
      if (this.invited_ids.length) {
        data['order_ids'] = this.invited_ids
      }
      return data
    }
  },
  data () {
    return {
      invite_success: '',
      invite_error: '',
      text_success: '',
      text_error: '',
      local_quiz: null,
      and_close: false,
      invited_ids: [],
      original_data: {
        pass_rate: 0,
        main: 0
      }
    }
  },
  methods: {
    openModal () {
      this.opened = true
    },
    closed () {
      this.local_quiz = null
    },
    closeModal () {
      this.text_success = ''
      this.text_error = ''
      this.invite_success = ''
      this.invite_error = ''
      this.opened = false
      this.invited_ids = []
      this.$emit('closed', true)
    },
    submitForm () {
      this.$refs.edit_quiz?.$children[0]?.submit()
    },
    submitFormClose () {
      this.and_close = true
      this.submitForm()
    },
    changeSelectedUsers (obj) {
      let index = this.invited_ids.indexOf(obj.id)
      if (index >= 0) {
        this.invited_ids.splice(index, 1)
      } else {
        this.invited_ids.push(obj.id)
      }
    },
    submitted (val) {
      this.text_success = ''
      this.text_error = ''
      axiosService.post('/api/v1/quiz/edit', val)
        .then(resp => {
          this.text_success = 'Updated'
          for (let [key, value] of Object.entries(resp.data)) {
            if (this.local_quiz[key] !== undefined) {
              this.local_quiz[key] = value
            }
          }
          if (this.and_close) {
            setTimeout(() => {
              this.closeModal()
            }, 2000)
          }
          this.$emit('updated', this.local_quiz)
        })
        .catch(() => {
          this.text_error = DEFAULT_ERROR_REPS_TEXT
        })
        .finally(() => {
          this.and_close = false
        })
    },
    addUsers() {
      this.invite_success = ''
      this.invite_error = ''
      axiosService.post('/api/v1/quiz/invite-users', {
        ids: this.invited_ids,
        test_id: this.local_quiz.id
      })
        .then(resp => {
          this.invite_success = 'Updated'
          this.local_quiz.meta_value = resp.data
          this.$emit('updated', this.local_quiz)
        })
        .catch(e => {
          console.log(e)
          this.invite_error = DEFAULT_ERROR_REPS_TEXT
        })
        .finally(() => {
          this.and_close = false
        })
    },
  },
  watch: {
    'quiz.id' (val) {
      if (!val) return
      this.local_quiz = this.quiz
      this.$set(this.local_quiz, 'language_id', this.quiz.meta_value?.language_id || 0)
      this.$set(this.local_quiz, 'main', this.quiz.meta_value?.main || 0)
      this.invited_ids = JSON.parse(JSON.stringify(this.quiz.meta_value?.user_ids || []))
      if (this.local_quiz.main && !Array.isArray(this.local_quiz.pass_rate)) {
        this.local_quiz.pass_rate = []
      }

      // store data before change
      this.original_data.pass_rate = JSON.parse(JSON.stringify(this.local_quiz.pass_rate || 0))
      this.original_data.main = JSON.parse(JSON.stringify(this.local_quiz.main || 0))
      this.openModal()
    },
  }
}
</script>
