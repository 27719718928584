<template>
  <div>
    <h5 class="pb-4">
      Enter your email information
    </h5>
    <default-form
      @submit="submitLogin"
      class="mx-4"
      :success_text="success_msg"
      :error_text="errormsg">
      <div>
        <default-input
          name="email"
          type="email"
          placeholder="Email"
          required
          v-model="email.val"
          :valid.sync="email.valid"
          @input="errormsg = ''"
          :validate_f="validateEmail"
        />
      </div>
      <template v-slot:buttons_block>
        <div class="card-actions">
          <div class="button-wrapper">
            <default-button
              :disabled="!email.val"
              type="submit"
              class="is-link ml-auto"
            >Next
            </default-button>
          </div>
        </div>
      </template>
    </default-form>
  </div>
</template>

<script>
import DefaultInput from '../small-components/DefaultInput'
import DefaultButton from '../small-components/DefaultButton'
import input_validations from '../../helpers/input_validations'
import axiosService from '../../plugins/axiosService'

import IosAlertIcon from 'vue-ionicons/dist/ios-information-circle.vue'
import DefaultForm from '../small-components/DefaultForm'

export default {
  name: 'ResetPassword',
  mixins: [input_validations],
  data() {
    return {
      email: {
        val: '',
        valid: true,
      },
      errormsg: '',
      success_msg: ''
    }
  },
  components: {
    DefaultForm,
    DefaultButton,
    DefaultInput,
    IosAlertIcon
  },
  methods: {
    submitLogin () {
      this.errormsg = ''
      this.success_msg = ''
      axiosService.post('/forgot-password', { email: this.email.val })
        .then(() => {
          this.success_msg = 'Check your email. You will receive a password recovery link at your email address in a few minute'
        })
        .catch(e => {
          this.errormsg = e.response?.data?.errors?.email[0] || 'The given data was invalid.'
        })
    }
  }
}
</script>
