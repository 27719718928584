<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="20" height="20">
    <path :fill="color" d="M 8.5 6 C 7.1364058 6 6 7.1364058 6 8.5 L 6 11.589844 C 6 13.886494 7.0554772 16.058837 8.859375 17.480469 L 19 25.470703 L 19 40.5 A 1.50015 1.50015 0 0 0 21.371094 41.720703 L 28.333984 36.748047 L 28.349609 36.736328 L 28.371094 36.720703 A 1.50015 1.50015 0 0 0 29 35.177734 L 29 33.949219 L 26 30.949219 L 26 34.728516 L 22 37.585938 L 22 26 L 27.806641 26 A 1.50015 1.50015 0 0 0 28.734375 25.677734 L 39.140625 17.480469 C 40.944523 16.058837 42 13.886494 42 11.589844 L 42 8.5 C 42 7.1364058 40.863594 6 39.5 6 L 8.5 6 z M 9 9 L 39 9 L 39 11.589844 C 39 12.969193 38.369258 14.268678 37.285156 15.123047 L 27.287109 23 L 20.746094 23 A 1.50015 1.50015 0 0 0 20.707031 22.994141 L 10.714844 15.123047 C 9.6307412 14.268678 9 12.969193 9 11.589844 L 9 9 z M 29.5 28 C 29.11625 28 28.732453 28.146453 28.439453 28.439453 C 27.853453 29.024453 27.853453 29.975547 28.439453 30.560547 L 34.878906 37 L 28.439453 43.439453 C 27.853453 44.024453 27.853453 44.975547 28.439453 45.560547 C 28.731453 45.854547 29.116 46 29.5 46 C 29.884 46 30.267547 45.853547 30.560547 45.560547 L 37 39.121094 L 43.439453 45.560547 C 43.732453 45.854547 44.116 46 44.5 46 C 44.884 46 45.267547 45.853547 45.560547 45.560547 C 46.146547 44.975547 46.146547 44.024453 45.560547 43.439453 L 39.121094 37 L 45.560547 30.560547 C 46.146547 29.975547 46.146547 29.024453 45.560547 28.439453 C 44.974547 27.853453 44.025453 27.853453 43.439453 28.439453 L 37 34.878906 L 30.560547 28.439453 C 30.267547 28.146453 29.88375 28 29.5 28 z"/>
  </svg>
</template>

<script>
export default {
  name: 'ClearFilterIcon',
  props: {
    color: {
      type: String,
      default: '#fff'
    }
  }
}
</script>
