<template>
  <div>
    <div class="plan-parts-wrapper">
      <div class="col-6">
        <h5 class="tabulate">
          Users
        </h5>
        <div class="white-list">
          <container class="wrap-available-users"
                     @drop="e => dropAcceptor(e, 'hub')"
                     v-if="users.length > 0"
                     group-name="322" :should-accept-drop="() => true"
                     drop-class="test-class"
                     :drop-placeholder="dropPlaceholderOptions"
                     :get-child-payload="(i) => (datapayload(i))">
            <template v-for="uu in users">
              <user-list-entity :value="uu"/>
            </template>
          </container>
          <div v-else class="py-2 px-4">
            All users allocated
          </div>
        </div>
      </div>
      <div class="col-6" tabindex="0" @keydown.ctrl="_onkeydown" @keyup="_onkeyup" @keypress.ctrl="_onkeypress">
        <h5 class="tabulate">
          Meetings
        </h5>
        <div class="meetings" v-if="meetings.length">
          <div class="meeting" v-for="(mt, key) in meetings">
            <div class="meeting-title">
              {{ mt.title }} | {{ mt.date }}
            </div>
            <div class="meeting-user-list" :key="key">
              <container class="wrap-available-users" @drop="e => dropAcceptor(e, key)"
                         v-if="meetings.length > 0"
                         group-name="322" :should-accept-drop="() => true"
                         drop-class="test-class"
                         :drop-placeholder="dropPlaceholderOptions"
                         :key="key"
                         :get-child-payload="(i) => (datapayload(i, key))">
                <template v-for="uu in mt.list">
                  <user-list-entity :value="uu"/>
                </template>
              </container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {Container, Draggable} from "vue-smooth-dnd";
import UserListEntity from "./UserListEntity";
import axiosService from "../../../../plugins/axiosService";
import DefaultButton from "../../../small-components/DefaultButton";

export default {
  name: "ParticipantsPlan",
  data: () => ({
    dropPlaceholderOptions: {
      className: 'drop-preview',
      animationDuration: '150',
      showOnTop: true
    },
    ctrl: false,
    actionStarted: false,
    ctrlLocked: false,
    rooms: [],
    users: [],
    meetings: [[]],
  }),
  props: {
    value: {},
    meets: [],
  },
  mounted() {
    this.meetings = this.$props.value.meetings_jr.map((entity) => ({
      list: [],
      id: entity.id,
      title: entity.title,
      date: entity.date,
    }))
    this.$emit('input:meets', this.meetings);
    this.fetch(this.$props.value.id);
  },
  components: {
    DefaultButton,
    UserListEntity,
    Container,
    Draggable
  },
  methods: {
    _onkeydown(){
      this.ctrl = true;
    },
    _onkeyup(key){
      if(key.key=== "Control"){
        this.ctrl = false;
      }
    },
    save() {
      axiosService.post('/api/v1/sessions/part/allocateRoom', {
        session: this.$props.value.id,
        rooms: this.meetings.map(e => Object.assign({}, e, {list: e.list.map(d => d.id)}))
      }).then(e => {
        console.log(e.data);
      })
    },
    test() { //sessions/part/test
      axiosService.post('/api/v1/admin/zoom/get').then(e => {
        console.log(e.data);
      })
    },
    inputAvailability(row, id) {
      return -1 === this.meetings[row].list.findIndex(entity => entity.id === id)
    },
    itemRemove(index, hub = null) {
      if (hub === null) this.users.splice(index, 1)
      else this.meetings[hub].list.splice(index, 1)
    },
    hubAvailability(id) {
      return -1 === this.users.findIndex(entity => entity.id === id)
    },
    pushToHub(_index, value) {
      this.users.push(value)
    },
    pushValue(index, row, value) {
      this.meetings[row].list.splice(index, 0, value)
    },
    /*  ISHUB: FALSE, 1, 2, 3*/
    datapayload(item, hub = null) {
      if (null !== hub) {
        return {sender: item, index: hub, entity: this.meetings[hub].list[item]}
      } else {
        return {sender: 'hub', index: item, entity: this.users[item]}
      }
    },
    fetch(session_id) {
      axiosService.post('/api/v1/admin/users/filtered', {session_id}).then(users => {
        this.users = Object.values(users.data).map(e => {
          e.status = 'new';
          return e;
        })
        this.reload(session_id)
      })

    },
    reload(session_id) {
      axiosService.post('/api/v1/sessions/part/rooms', {session_id}).then(e => {
        this.rooms = Object.values(e.data.users)
        this.rooms.forEach(room => {
          const index = this.users.findIndex(user => user.id === room.user_id)
          if (index === -1) return
          const copy = this.users[index];
          copy.status = 'connected'
          this.users.splice(index, 1, copy)
        })
        this.meetings.forEach((data, key) => {
          const id = e.data.meetings.findIndex(room => room.room_key === data.id)
          if(id !== -1){
            this.meetings[key]['list'] =  e.data.meetings[id].users
          }
          this.$emit('input:meets', this.meetings);
          this.users = this.users.filter(user => this.meetings[key]['list'].findIndex(b => b.id === user.id) === -1)
        })
      })
    },
    dropAcceptor(e, self) {
      if(this.ctrl && !this.actionStarted) {
        this.actionStarted = true;
        this.ctrlLocked = true;
      }

      const hub = (self === 'hub')
      const insert = e.addedIndex
      const remove = e.removedIndex
      const insertEntityId = e.payload.entity.id

      if (remove !== null && !this.ctrlLocked) {
        this.itemRemove(remove, hub ? null : self);
        this.actionStarted = false;
        this.ctrlLocked = false;
      }
      if(remove !== null){
        this.actionStarted = false;
      }

      if (insert === null) return
      if (hub) {
        if (this.hubAvailability(insertEntityId))
          this.pushToHub(insert, e.payload.entity)
      } else if (this.inputAvailability(self, insertEntityId)) {
        this.pushValue(insert, self, e.payload.entity)
      }
    }
  },
}
</script>
