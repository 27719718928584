<template>
  <default-form
    @submit="storeTestInfo"
    :success_text="text_success"
    :error_text="text_error"
    class="tab-form edit-quiz"
  >
    <div class="row">
      <div class="col-12 col-sm-6">
        <default-input
          required
          name="name"
          :validate_f="validateLogin"
          v-model="new_quiz.title"/>
      </div>
      <div class="col-12 col-sm-6">
        <default-input
          required
          name="name"
          placeholder="Description"
          :validate_f="validateLogin"
          v-model="new_quiz.description"/>
      </div>
      <div class="col-12 col-sm-6">
        <slim-select
          :error_text="lang_error"
          :values="getLanguages"
          obj_value_name="name"
          empty_option_text="Choose language"
          :selected="new_quiz.language_id"
          @selected="(data) => new_quiz.language_id = (data.id || 0)"
        />
      </div>
      <template v-if="!new_quiz.main">
        <div class="col-12 col-sm-6">
          <default-input
            v-if="!new_quiz_pass_rate_is_arr"
            required
            name="pass_rate"
            type="number"
            placeholder="Pass rate from 0% to 100%"
            :validate_f="validateNumber0100"
            :value="new_quiz.pass_rate"
            @input_event="passRate"/>
        </div>
        <div
          v-if="new_quiz.questions"
          class="col-12 mb-10">
          <p style="font-size: 1rem;">
            Minimum questions to pass the test is: <b>{{ min_questions }}</b>
          </p>
        </div>
      </template>
      <div
        v-else
        class="col-12">
        <language-levels
          :rate_list="new_quiz.pass_rate"
          :error_text="pass_rate_err"
        />
      </div>
      <div class="col-12">
        <div class="mb-10">
          <custom-checkbox
            name="available"
            :checked="!!new_quiz.main"
            @change="changeMainProp(!new_quiz.main)">
            <strong>Is main Language test</strong>
          </custom-checkbox>
        </div>
        <div class="mb-10">
          <custom-checkbox
            name="available"
            :checked="!!new_quiz.available"
            @change="new_quiz.available = !new_quiz.available">
            Available for search
          </custom-checkbox>
        </div>
        <div class="mb-10">
          <custom-checkbox
            name="available"
            :checked="!!new_quiz.public"
            @change="new_quiz.public = !new_quiz.public">
            Public visibility
          </custom-checkbox>
        </div>
      </div>
      <div class="col-12 col-sm-6 mt-10">
        <default-input
          placeholder="Open from"
          type="date"
          required
          name="date"
          :value="openFrom"
          @input="updateOpenFrom"
        />
      </div>
    </div>
    <template v-if="show_btn" v-slot:buttons_block>
      <div class="button-wrapper mt-20">
        <button class="button is-success" type="submit">Save</button>
      </div>
    </template>
  </default-form>
</template>

<script>
import DefaultInput from '../small-components/DefaultInput'
import DefaultForm from '../small-components/DefaultForm'
import input_validations from '../../helpers/input_validations'
import DefaultTable from '../../../module_list/default_table/DefaultTable'
import DefaultButton from '../small-components/DefaultButton'
import EditQuizModal from '../modals/EditQuizModal'
import SlimSelect from '../../../module_list/slim_select/SlimSelect'
import { mapGetters, mapActions } from 'vuex'
import UserTable from '../tables/UserTable'
import CustomCheckbox from '../../../module_list/custom-checkbox/CustomCheckbox'
import LanguageLevels from '../small-components/LanguageLevels'
import { LANGUAGE_LEVELS_LIST } from '../../mixins/TestMixin'


export default {
  name: 'EditQuiz',
  components: {
    LanguageLevels,
    CustomCheckbox,
    UserTable,
    SlimSelect,
    EditQuizModal,
    DefaultButton,
    DefaultTable,
    DefaultForm,
    DefaultInput,
  },
  props: {
    new_quiz: {
      type: Object,
      default: () => ({})
    },
    show_btn: {
      type: Boolean,
      default: false
    },
    text_success: {
      type: String,
      default: ''
    },
    text_error: {
      type: String,
      default: ''
    },
    original_main: {
      type: Number,
      default: 0
    },
    original_pass_rate: {
      type: [Number, Array],
      default: 0
    }
  },
  mixins: [input_validations],
  computed: {
    ...mapGetters([
      'getLanguages'
    ]),
    openFrom () {
      let date = this.new_quiz.openFrom.split(' ')[0]
      return date || ''
    },
    min_questions () {
      return Math.round(this.new_quiz.questions * this.new_quiz.pass_rate / 100)
    },
    new_quiz_pass_rate_is_arr() {
      return Array.isArray(this.new_quiz.pass_rate)
    }
  },
  data () {
    return {
      lang_error: '',
      pass_rate_err: '',
    }
  },
  mounted () {
    this.fetchLanguages()
  },
  methods: {
    ...mapActions([
      'fetchLanguages'
    ]),
    storeTestInfo () {
      this.pass_rate_err = ''
      if (this.new_quiz.main) {
        if (!Array.isArray(this.new_quiz.pass_rate)) {
          this.pass_rate_err = "Wrong format of pass rate"
          return
        } else if (this.new_quiz.pass_rate.length < 2 || this.new_quiz.pass_rate.length > 10) {
          this.pass_rate_err = "Should be minimum 2 and maximum 10 language rates"
          return
        }
      }
      if (!this.new_quiz.language_id) {
        this.lang_error = 'Choose language'
        return
      }
      this.lang_error = ''
      this.$emit('submitted', {
        id: this.new_quiz.id,
        title: this.new_quiz.title,
        description: this.new_quiz.description,
        public: this.new_quiz.public,
        available: this.new_quiz.available,
        pass_rate: this.new_quiz.pass_rate,
        openFrom: this.new_quiz.openFrom || '',
        language_id: this.new_quiz.language_id,
        main: this.new_quiz.main || 0
      })
    },
    updateOpenFrom (val) {
      this.new_quiz.openFrom = val || ''
    },
    passRate (e) {
      let val = parseFloat((e.target.value || '0').replace(!/[0-9\.]+/g, ''))
      if (val < 0) {
        val = 0
      } else if (val > 100) {
        val = 100
      }
      this.new_quiz.pass_rate = val || ''
      e.target.value = val
    },
    changeMainProp(val) {
      if (val) {
          this.new_quiz.pass_rate = JSON.parse(JSON.stringify(LANGUAGE_LEVELS_LIST))
      } else {
          this.new_quiz.pass_rate = 0
      }
      this.new_quiz.main = val ? 1 : 0
      if (this.original_main === this.new_quiz.main){
        this.new_quiz.pass_rate = this.original_pass_rate
      }
    }
  }
}
</script>
