<template>
  <loader v-if="loading"/>
  <div v-else
       class="cards">
    <div class="cards_item full_width">
      <div class="cards_item-header">
        <span>Last registered users</span>
        <default-button
          :router_obj="{name: 'admin.users'}"
          class="is-warning cards_item-header_link">
          <arrow-right color="rgba(0,0,0,.7)"/>
        </default-button>
      </div>
      <default-table
        :items="users"
        :fields_name="{active_session: 'session', session_status: 'status', created_at: 'created'}">
        <template slot="id" slot-scope="props">
          {{ props.id }}
        </template>
        <template slot="name" slot-scope="props">
          {{ props.lastName }} {{ props.firstName }}
        </template>
        <template slot="email" slot-scope="props">
          {{ props.email }}
        </template>
        <template slot="role" slot-scope="props">
          {{ props.role || '' }}
        </template>
        <template slot="active_session" slot-scope="props">
          {{props.active_session ? props.active_session.session_location || '-' : '-'}}
        </template>
        <template slot="session_status" slot-scope="props">
          {{props.active_session ? sessionStatusText(props.active_session.approved) : '-'}}
        </template>
        <template slot="created_at" slot-scope="props">
          {{ getDateFormatStrToShow(props.created_at) || '-' }}
        </template>
      </default-table>
    </div>
    <div class="cards_item">
      <div class="cards_item-header">
        <span>Last quiz pass</span>
        <default-button
          :router_obj="{name: 'admin.users', hash: '#user-results' }"
          class="is-warning cards_item-header_link">
          <arrow-right color="rgba(0,0,0,.7)"/>
        </default-button>
      </div>
      <default-table
        :fields_name="{created_at: 'created', test: 'test title' }"
        :items="quiz_results">
        <template slot="id" slot-scope="props">
          {{ props.id }}
        </template>
        <template slot="test" slot-scope="props">
          {{ props.quiz.title }}
        </template>
        <template slot="user" slot-scope="props">
          <template v-if="props.user">
            {{ props.user.lastName }} {{ props.user.firstName }}
          </template>
        </template>
        <template slot="score" slot-scope="props">
          <badge
            v-if="props.quiz && props.quiz_meta"
            :is_main_test="!!(props.quiz_meta.main)"
            :result="props.score"
            :pass_rate="props.quiz.pass_rate"/>
        </template>
        <template slot="created_at" slot-scope="props">
          {{ getDateFormatStrToShow(props.created_at) || '-' }}
        </template>
      </default-table>
    </div>
    <div class="cards_item">
      <div class="cards_item-header">
        <span>Awaiting to approve in session</span>
        <default-button
          :router_obj="{name: 'dashboard.listSessions', hash: '#approve-users-for-session'}"
          class="is-warning cards_item-header_link">
          <arrow-right color="rgba(0,0,0,.7)"/>
        </default-button>
      </div>
      <default-table
        :fields_name="{session_id: 'session id', user_id: 'user id', created_at: 'created'}"
        :items="connections">
        <template slot="session_id" slot-scope="props">
          {{ props.session_id }}
        </template>
        <template slot="session" slot-scope="props">
          {{ props.session ? props.session.session_location : '-' }}
        </template>
        <template slot="user_id" slot-scope="props">
          {{ props.user_id }}
        </template>
        <template slot="user" slot-scope="props">
          <template v-if="props.user">
            {{ props.user.lastName }} {{ props.user.firstName }}
          </template>
        </template>
        <template slot="created_at" slot-scope="props">
          {{ getDateFormatStrToShow(props.created_at) || '-' }}
        </template>
      </default-table>
    </div>
  </div>
</template>

<script>
import axiosService from '../../plugins/axiosService'
import Loader from '__module_list/loader/Loader'
import DefaultButton from '../small-components/DefaultButton'
import ArrowRight from '../small-components/icons/ArrowRight'
import UserMixin, { transformUserSessions } from '../../mixins/UserMixin'
import DefaultTable from '__module_list/default_table/DefaultTable'
import dateMixin from '../../mixins/dateMixin'
import Badge from '../small-components/Badge'

export default {
  name: 'DashboardManagers',
  mixins: [dateMixin, UserMixin],
  components: {
    Badge,
    DefaultTable,
    ArrowRight,
    DefaultButton,
    Loader
  },
  data () {
    return {
      loading: false,
      users: [],
      quiz_results: [],
      connections: []
    }
  },
  created () {
    this.getDashboardData()
  },
  methods: {
    getDashboardData () {
      this.loading = true
      axiosService.post('/api/v1/dashboard-data')
        .then(r => {
          this.users = r.data.users?.map(user => {
            return transformUserSessions(user)
          }).sort((a,b)=> a.id < b.id ? 1 : a.id === b.id ? 0 : -1 ) ?? []
          this.quiz_results = r.data.quiz_results.sort((a,b)=> a.id < b.id ? 1 : a.id === b.id ? 0 : -1 ) ?? []
          this.connections = r.data.connections.sort((a,b)=> a.id < b.id ? 1 : a.id === b.id ? 0 : -1 ) ?? []
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
