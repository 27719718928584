import Vue from 'vue'
import('./assets/app.scss')
import App from './App'
import router from './router/routes'
import store from './store/vuex-store'
import Vue2TouchEvents from "vue2-touch-events";
import axiosService from './plugins/axiosService'
import constants from './helpers/constants'
import authHelper from "./plugins/authHelper";
require('./plugins/title')
require('dotenv').config()
const ax = (headers) => axiosService.create({baseURL: constants.BaseURL, timeout: 10000, method: 'post', headers})
Vue.use(Vue2TouchEvents, {
    swipeTolerance: 80
});
function eraseCookie(name) {
  document.cookie = name+'=; Max-Age=-99999999;';
}
Vue.prototype.$constants = constants
window.config = constants

// eslint-disable-next-line no-multi-assign
Vue.http = Vue.prototype.$http = () => axiosService.create({ baseURL: constants.BaseURL, timeout: 10000, method: 'post' })
// eslint-disable-next-line no-multi-assign
Vue.post = Vue.prototype.$post = (url, data, headers = {}) => ax(headers).post(url, data)
// eslint-disable-next-line no-multi-assign
Vue.get = Vue.prototype.$get = (url, headers) => ax(headers).get(url)

Vue.config.productionTip = false

Vue.ws = Vue.prototype.$ws = null;

Vue.wschannels = Vue.prototype.$wschannels = {
  activity: null,
  chat: null,
  language: null,
  sessions: null,
}

/* eslint-disable no-new */
new Vue({
  components: {App},
  router,
  store,
  render: function(createElement) {
    return createElement('App');
  },
  // eslint-disable-next-line global-require
 //  Stylesheets: [require('./assets/app.scss')]
}).$mount('#app')

const auth = authHelper.getAuth()

authHelper.setHead('x-auth', auth)
authHelper.setCookie('x-auth', auth)


