<script>
export default {
  name: "ActionsMixin",
  methods: {
    _closed() {
      this.$emit('close', true)
      this.closed();
    },
    closeModal() {
      this._closed();
    },
    submitForm() {
      this.save()
      this._closed()
    },
    submitFormClose() {
      this.and_close = true
      this.submitForm()
    },
    save(){
      this._closed()
    },
    submitted() {
      this.text_success = ''
      this.text_error = ''
    },
  }
}
</script>

