import Vue from 'vue'
import vuex from '../store/vuex-store'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
  routes: [
    {
      path: '/',
      name: 'home',
      component: require('../components/Login/LoginPage.vue').default,
      meta: {
        name: 'Welcome'
      }
    },
    {
      path: '/forgot-password',
      name: 'forgot-password',
      component: require('../components/Login/ResetNewPassword.vue').default,
      meta: {
        name: 'Forgot your password'
      }
    },
    {
      path: '/register/password/:code',
      name: 'registerPassword',
      component: require('../components/Login/StepPassword.vue').default,
      props: true,
      meta: {
        name: 'Register password'
      },
      query: {
        email_: '',
        code_: ''
      },
    },
    {
      path: '/register/:code',
      name: 'registerByCode',
      component: require('../components/Layouts/StepEnterCodeLayout.vue').default,
      props: true,
      meta: {
        name: 'Registration code'
      }
    },
    {
      path: '/reset/',
      name: 'reset',
      component: require('../components/Layouts/StepEnterCodeLayout.vue').default,
      meta: {
        name: 'Reset'
      }
    },
    {
      path: '/reset/:code',
      name: 'reset.code',
      component: require('../components/Layouts/StepEnterCodeLayout.vue').default,
      props: true,
      meta: {
        name: 'Reset code'
      }
    },
    {
      path: '/info',
      name: 'readme',
      component: require('../components/LandingPage.vue').default
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: require('../components/DashboardRoot').default,
      meta: {
        auth: true
      },

      children: [
        {
          path: '',
          name: 'dashboard.home',
          component: require('../components/Dashboard').default,
          meta: {
            permission: 'welcome',
            name: 'Dashboard'
          }
        },
        {
          path: '/profile',
          name: 'profile',
          component: require('../components/Profile.vue').default,
          meta: {
            permission: 'profile',
            name: 'Profile'
          }
        },
        {
          path: '/contacts',
          name: 'dashboard.contacts',
          component: require('../components/about/Contacts.vue').default,
          meta: {

            permission: 'welcome',
            name: 'Contacts'
          }
        },
        {
          path: '/english-test',
          name: 'english-test',
          component: require('../components/EnglishTest.vue').default,
        },
        {
          path: '/pusher',
          name: 'pusher',
          component: require('../components/Pusher/PusherTestComponent.vue').default,
        },
        {
          path: '/tests',
          name: 'tests',
          component: require('../components/quiz/TestList.vue').default,
          meta: {
            permission: 'profile',
            name: 'Language tests',
          }
        },

        // {
        //   path: '/tests/:id',
        //   name: 'quiz',
        //   props: true,
        //   component: require('../components/Test/Quiz.vue').default,
        //   meta: {
        //     permission: 'profile',
        //     name: 'Quiz list',
        //     hidden: true
        //   }
        // },
        {
          path: '/test/:id',
          name: 'testId',
          props: true,
          component: require('../components/quiz/PassTest.vue').default,
          meta: {
            permission: 'profile',
            name: 'Language test',
            hidden: true
          }
        },
        {
          path: '/admin/quizes',
          name: 'dashboard.listQuiz',
          component: require('../components/Test/List.vue').default,
          meta: {
            permission: 'promote',
            name: 'Quiz list',
          }
        },
        {
          path: '/sessions',
          name: 'dashboard.userSession',
          component: require('../components/user-session/UserSession.vue').default,
          meta: {
            permission: 'join',
            name: 'My session',
          }
        },
        {
          path: '/admin/sessions',
          name: 'dashboard.listSessions',
          component: require('../components/Session/SessionList.vue').default,
          props: true,
          meta: {
            permission: 'promote',
            name: 'Session list',
          }
        },
        {
          path: '/admin/quizes/:mid',
          name: 'editQuiz',
          props: true,
          component: require('../components/Admin/QuizEditQuestions.vue').default,
          meta: {
            permission: 'promote',
            name: 'Quiz',
            hidden: true
          }
        },
        // {
        //   path: '/admin/chats',
        //   name: 'admin.chats',
        //   component: require('../components/messages/ChatsComponent.vue').default,
        //   meta: {
        //     permission: 'chat',
        //     name: 'Chats',
        //   }
        // },
        {
          path: '/admin/users',
          name: 'admin.users',
          component: require('../components/Admin').default,
          meta: {
            permission: 'rooms',
            name: 'Users',
          }
        },
        {
          path: '/admin/site-config',
          name: 'admin.site_config',
          component: require('../components/Admin/SiteConfig.vue').default,
          meta: {
            permission: 'admin',
            name: 'Site settings',
          }
        },
      ],
    },
    {
      path: '/agreement',
      name: 'Agreement',
      component: require('../components/about/Agreement.vue').default,
      meta: {
        all: true,
        name: 'Agreement',
      }
    },
    {
      path: '*',
      redirect: '/'
    }
  ],
  mode: 'history'
})
router.beforeEach(async (to, from, next) => {
  vuex.dispatch('actionClearAllNotification')
  if (!vuex.getters.getAccountLoaded) {
    await vuex.dispatch('checkAuth')
  }
  if (to.matched.some(r => r.meta.all)) {
    next()
    return
  }
  const auth_required = to.matched.some(r => r.meta.auth) || false
  if (auth_required && !vuex.getters.getAccount) {
    next({ name: 'home' })
    return
  } else if (!auth_required && vuex.getters.getAccount) {
    next({ name: 'dashboard.home' })
    return
  }

  document.title = to.meta.name + ' — Journée Recruitement'
  next()
})

export const routesList = router.options.routes || []

export default router
