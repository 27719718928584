<template>
  <default-table
    class="user-table"
    :class="user_table_classes"
    :change_location="change_location"
    response_key_list="users"
    :active_table="active_table"
    fetch_url="/api/v1/user/list"
    :update_outer="update_outer"
    :filter_fields_names="filter_fields_names"
    :filter_query_outer="filter_query"
    :fields_name="users_fields">
    <template
      slot="row_before" slot-scope="props">
      <custom-checkbox
        :checked="checked_ids.includes(props.id)"
        @change="$emit('checked_user', props)"
        bordered name="users"/>
    </template>
    <template slot="id" slot-scope="props">
      {{ props.id }}
    </template>
    <template slot="email" slot-scope="props">
      {{ props.email }}
    </template>
    <template slot="lastName" slot-scope="props">
      {{ props.firstName || '' }} {{ props.lastName || '' }}
    </template>
    <template slot="active_session" slot-scope="props">
      {{activeSession(props.sessions).session_location || '-'}}
    </template>
    <template slot="language_ids" slot-scope="props">
      <tooltip
        v-if="props.languages"
        tooltip_bg="#48c78e"
        :text="userLanguages(langIds(props.languages), false)">
        {{userLanguages(langIds(props.languages))}}
      </tooltip>
    </template>
    <template slot="session_status" slot-scope="props">
      {{sessionStatusText(activeSession(props.sessions).approved || '')}}
    </template>
    <template slot="role" slot-scope="props">
      {{ props.role || '' }}
    </template>
    <template slot="cv" slot-scope="props">
      <span v-html="checkCv(props.user_meta)"></span>
    </template>
    <template
      slot="row_after" slot-scope="props">
      <tooltip
        is_inline
        tooltip_bg="#48c78e"
        text="Show user info">
        <button class="button is-success" @click="$emit('modal_user', props)">
          <configure/>
        </button>
      </tooltip>
    </template>
  </default-table>
</template>

<script>
import DefaultTable from '../../../module_list/default_table/DefaultTable'
import Configure from '../small-components/icons/Configure'
import Tooltip from '../../../module_list/tooltip/Tooltip'
import userMixin from '../../mixins/UserMixin'
import CustomCheckbox from '../../../module_list/custom-checkbox/CustomCheckbox'

export default {
  name: 'UserTable',
  components: { CustomCheckbox, Tooltip, Configure, DefaultTable },
  mixins: [userMixin],
  props: {
    update_outer: {
      type: Object,
      default: null
    },
    active_table: {
      type: Boolean,
      default: false
    },
    change_location: {
      type: Boolean,
      default: false
    },
    user_button: {
      type: Boolean,
      default: false
    },
    user_checkbox: {
      type: Boolean,
      default: false
    },
    filter_query: {
      type: Object,
      default: null
    },
    checked_ids: {
      type: Array,
      default: () => []
    },
  },
  computed: {
    user_table_classes() {
      return {
        show_btn: this.user_button,
        show_checkbox: this.user_checkbox
      }
    },
    users_fields() {
      return {
        lastName: 'Name/Last name',
        active_session: 'Current session',
        session_status: 'Session status',
        language_ids: 'Languages'
      }
    },
    filter_fields_names() {
      return {
        role_id: 'User role',
        active: 'Status',
        approved: 'Session status',
        language_id: 'Language'
      }
    },
  }
}
</script>
