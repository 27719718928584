<template>
  <draggable class="draggable-user" :class="{'connected': value.is_connected}" :key="value.id">
    <div class="draggable-user-item">
      <div class="draggable-user-item_left-block">
        <user-avatar
          class="draggable-user-item_avatar"
          :src="img"
        />
        <div v-if="value.role_active"
             class="draggable-user-item_role">
          {{ value.role_active.role.role }}
        </div>
      </div>

      <div class="draggable-user-item_right-block">
        <div class="draggable-user-item_name">
          {{ value.firstName + ' ' + value.lastName }}
        </div>
<!--        <div>-->
<!--          <template v-if="value.sessions && value.sessions.length > 0">-->
<!--            {{ getSession(value.sessions) }}-->
<!--          </template>-->
<!--          <template v-else>-->
<!--            not active session-->
<!--          </template>-->
<!--        </div>-->
        <div class="draggable-user-item_lang"
          v-show="test_results && test_results.length">
          <div class="draggable-user-item_lang-sub-title">Languages</div>
          <tooltip
            v-for="(test_result, index) in test_results"
            :text="getTooltip(test_result)"
            tooltip_bg="#48c78e"
            :key="'test_result'+index">
            <badge
              is_main_test
              :result="test_result.score"
              :pass_rate="test_result.quiz.pass_rate"
            />
            <span style="margin-left: 5px">{{ getLanguageById(test_result.quiz_meta.language_id).code || '' }}</span>
          </tooltip>
        </div>
      </div>
      <div class="draggable-user-item_history" v-if="historyAvailable">
        <template v-for="(a, position) in reversedArray">
            <span class="room-number px-1" :class="{'not-last': position + 1 !== value.room_transfers.length}"  v-if="a !== -1">
              {{a + 1}}
            </span>
        </template>
      </div>

    </div>
  </draggable>
</template>

<script>
import {Draggable} from 'vue-smooth-dnd'
import {mapGetters} from 'vuex'

import CircleAdd from 'vue-ionicons/dist/ios-checkmark-circle'
import {LANGUAGE_LEVELS} from '../../../../mixins/TestMixin'
import Badge from '../../../small-components/Badge'
import Tooltip from '__module_list/tooltip/Tooltip'
import UserAvatar from '../../../blocks/UserAvatar'

export default {
  name: 'DraggableUser',
  components: {
    UserAvatar,
    Badge,
    Tooltip,
    Draggable,
    CircleAdd
  },
  props: {
    value: {
      type: Object | Array,
      required: true
    }
  },
  computed: {
    reversedArray(){
      const array = this.$props.value.room_transfers
      return array.reverse();
    },
    ...mapGetters(['sessions', 'defaultLanguage', 'getLanguages']),
    historyAvailable(){
      return this.$props.value.room_transfers &&  this.$props.value.room_transfers.length > 0
    },
    defaultLangResult () {
      const v = this.$props.value
      const lngname = this.defaultLanguage.name
      const code = this.defaultLanguage.code
      const lng_ids = this.defaultLanguage.quizzes.map(e => e.quiz_id)
      if (!v.test_results) return { active: false }
      const index = v.test_results.findIndex(e => lng_ids.includes(e.test_id))
      const result = {
        active: false
      }
      if (index > -1) {
        result.name = lngname /* v.test_results[index] */
        result.code = code/* v.test_results[index] */
        result.score = v.test_results[index].score
        result.active = true
      }
      return result
    },
    img () {
      return '/api/v1/user/resource?resource-type=av&user_id=' + this.$props.value.id
    },
    test_results () {
      if (!this.value || !this.value.test_results) return []
      return this.value.test_results.filter(el => el.quiz_meta.main && Array.isArray(el.quiz.pass_rate))
    }
  },
  methods: {
    level (val) {
      let select = 'A1'
      for (let [key, value] of Object.entries(LANGUAGE_LEVELS)) {
        if (val >= value.min) {
          select = value.code
        }
      }
      return select
    },
    getTooltip (test_result) {
      return (this.getLanguageById(test_result.quiz_meta.language_id).name || '') + ` - ${test_result.quiz.title}`
    },
    getLanguageById (id) {
      if (!id) return null
      return this.getLanguages.find(el => el.id === id) || ''
    },
    getSession (sessions) {
      const val = this.sessions.find(e => e.session_id === sessions[0].session_id)
      return val !== undefined ? val.session_location : ''
    },
  }
}
</script>

<style lang="scss">
.draggable-user.connected .draggable-user-item{
  box-shadow: 0 0 10px 2px rgba(255, 0, 0, 0.6);
}
  .room-number{
    position: relative;
    font-weight: bold;
    font-size: 1.25rem;
    color: #248d14;
    &.not-last{
      color: #1a202c;
      &::after{
        content: "|";
        padding-left: .75rem;

        position: relative;
      }
    }
    &.red{
      color: #8a4a4a;
    }

  }
</style>
