import axios from 'axios'
import vuex from '../store/vuex-store'
import constants from '../helpers/constants'

axios.defaults.headers.common.Accept = 'application/json'

axios.interceptors.request.use(
  (config) => {
    // eslint-disable-next-line no-param-reassign
    config.baseURL = constants.BaseURL
    // eslint-disable-next-line no-param-reassign
    config.timeout = 100000
    if (vuex.getters.getAccountXAuth) { // eslint-disable-next-line no-param-reassign
      config.headers = {
        'x-auth': vuex.getters.getAccountXAuth || null
      }
    }
    return config
  },
  error => Promise.reject(error)
)

axios.interceptors.response.use(
  response => response.data,
  (error) => {
    if (error.response === undefined) {
      return Promise.reject(error)
    }
    if (error.response.status === 404) {
      return Promise.resolve({
        status: 404,
        error: error.response
      })
    }
    if (error.response.status === 403 || error.response.status === 401) {
      window.location.reload()
    }
    return Promise.reject(error)
  }
)
export const DEFAULT_ERROR_REPS_TEXT = 'Something went wrong'
export const DEFAULT_ERROR_UPDATE_REPS_TEXT = 'Updated failed'

const validateUrl = (attrs) => {
  if (Array.isArray(attrs) && typeof attrs[0] === 'string' && !attrs[0].startsWith('/')) {
    attrs[0] = `/${attrs[0]}`
  }
  return attrs
}

export default {
  post (...attrs) {
    return axios.create({
      baseURL: constants.BaseURL,
      timeout: 100000,
      method: 'post',
      headers: {
        'x-auth': vuex.getters.getAccountXAuth || '',
        'Access-Control-Allow-Origin': '*'
      }
    })
      .post(...validateUrl(attrs))
  },
  get (...attrs) {
    return axios.create({
      baseURL: constants.BaseURL,
      timeout: 100000,
      method: 'post',
      headers: {
        'x-auth': vuex.getters.getAccountXAuth || null
      }
    })
      .get(...validateUrl(attrs))
  },
}
