<template>
  <div>
    <h5 class="pb-4">
      {{ show_code ? 'Enter your email and invite code' : 'Enter your email' }}
    </h5>
    <default-form
      @submit="submitForm"
      :error_text="errormsg"
      :success_text="infomsg"
      class="mx-4">
      <default-input
        name="email"
        type="email"
        placeholder="Email"
        required
        v-model="email_.val"
        :valid.sync="email_.valid"
        @input="errormsg = ''"
        :validate_f="validateEmail"
      />
      <default-input
        v-if="show_code"
        name="text"
        type="text"
        placeholder="Invite code"
        v-model="code.val"
        :valid.sync="code.valid"
        @input="errormsg = ''"
        :validate_f="validateLogin"
      />

      <template v-slot:buttons_block>
        <div class="card-actions">
          <div class="button-wrapper">
            <default-button
              :disabled="!email_.val"
              type="submit"
              class="is-info ml-auto"
            >Continue
            </default-button>
          </div>
        </div>
      </template>
    </default-form>
  </div>
</template>

<script>
import {mapActions} from 'vuex'
import DefaultButton from '../small-components/DefaultButton'
import DefaultInput from '../small-components/DefaultInput'
import input_validations from '../../helpers/input_validations'
import axiosService, {DEFAULT_ERROR_REPS_TEXT} from '../../plugins/axiosService'
import DefaultForm from '../small-components/DefaultForm'

const PATH_NAME_REGISTER = '/register/'
export default {
  name: 'StepEnterCode',
  mixins: [input_validations],
  props: {
    email: {
      default: '',
      required: false,
    }
  },
  mounted () {
    this.$set(this.email_, 'val', this.email)
    if (!location.pathname.startsWith(PATH_NAME_REGISTER)) return
    let code = location.pathname.replace(PATH_NAME_REGISTER, '')
    if (!code) return
    this.location_has_code = code
  },
  data () {
    return {
      errormsg: '',
      infomsg: '',
      show_code: false,
      location_has_code: false,
      email_: {
        val: '',
        valid: true
      },
      code: {
        val: '',
        valid: true
      },
    }
  },
  components: {
    DefaultForm,
    DefaultInput,
    DefaultButton
  },
  watch: {
    email (val) {
      this.email_.val = val
    }
  },
  methods: {
    ...mapActions([
      'loginAction'
    ]),
    submitForm () {
      let sendData = {
        email: this.email_.val
      }
      if (this.code.val) {
        sendData['code'] = this.code.val
      }
      this.infomsg = ''
      this.errormsg = ''
      axiosService.post('/account/check', sendData)
        .then(result => {
          let status = result.data?.status || ''
          switch (status) {
            case 'success':
              this.$router.push({
                name: 'registerPassword',
                params: {
                  email_: this.email_.val,
                  code: this.code.val,
                }
              })
              break
            case 'not found':
              this.errormsg = 'Can\'t found user with this email'
              break
            case 'send':
              this.show_code = true
              this.infomsg = 'On your email was send email with code confirmation'
              break
            case 'code send':
              this.errormsg = 'On your email already was send confirmation code. Please check your email'
              break
            case 'code not found':
              this.errormsg = 'Code doesn\'t exist'
              break
            case 'from_ref':
              this.show_code = true
              if (this.location_has_code) {
                this.code.val = this.location_has_code
                this.location_has_code = ''
                this.submitForm()
              }
              break
          }
        })
        .catch(e => {
          if (e.response !== undefined) {
            if (e.response.status === 403) {
              this.errormsg = 'Account already exists. If you forgot password, use password reset.'
            }
            this.errormsg = e.response.data?.errors?.email[0] || DEFAULT_ERROR_REPS_TEXT
          }
        })
    }
  }
}
</script>
