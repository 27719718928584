<template>
  <popup-block
    is_wide
    :closeModal="closeModal"
    :loader="!obj.id"
    :head_status_text="text_success || text_error"
    :header_status_error="!!text_error"
    :shown="opened">
    <template v-slot:popup_title>Edit user id: {{ obj.id }}</template>
    <template v-if="local_obj">
      <tabs
        @tab_change="tabSelected"
      >
        <tab name="Info">
          <div class="row">
            <div class="col-3 mb-10">Name:</div>
            <div class="col-9 mb-10 bold">{{ local_obj.firstName || '' }}</div>
            <div class="col-3 mb-10">Last name:</div>
            <div class="col-9 mb-10 bold">{{ local_obj.lastName || '' }}</div>
            <div class="col-3 mb-10">Phone:</div>
            <div class="col-9 mb-10 bold">{{ local_obj.phone || '' }}</div>
            <div class="col-3 mb-10">Email:</div>
            <div class="col-9 mb-10 bold">{{ local_obj.email || '' }}</div>
            <div class="col-3 mb-10">Active:</div>
            <div class="col-9 mb-10">
              <toggled
                :value="local_obj.active"
                @input="changeActive"
              />
              <span class="ml-10" v-if="!local_obj.active">User banned</span>
            </div>
            <div class="col-3 mb-10">Role:</div>
            <div class="col-9 mb-10 bold">
              <select-component
                :items="roles"
                @input="selectedRole"
                :value="local_obj.role || ''"
              />
            </div>
            <div class="col-3 mb-10">Cv:</div>
            <div class="col-9 mb-10 bold">
              <a v-if="local_obj.__cv"
                 :href="`/api/v1/user/resource?user_id=${local_obj.id}`"
                 download
                 class="button is-link is-outlined">
                <download-icon/>
              </a>
              <template v-else>-</template>
            </div>
            <div class="col-3 mb-10">Avatar:</div>
            <div class="col-9 mb-10 bold">
              <user-avatar
                large
                :src="`/api/v1/user/resource?resource-type=_av&user_id=${local_obj.id}`"
              />
            </div>
            <div class="col-3 mb-10">Reject:</div>
            <div class="col-9 mb-10 bold"><default-button class="is-warning" @click="rejInterview(local_obj.id)">Reject Interview</default-button></div>
          </div>
        </tab>
        <tab
          v-if="getAccountPermissionAdmin"
          name="Edit user info">
          <edit-user-form
            v-if="local_obj"
            :user="local_obj"
            :users_roles="roles"
          />
        </tab>
        <tab
          :hidden="!sessions || !sessions.length"
          name="Active session">
          <template v-if="sessions || sessions.length">
            <div class="row">
              <div class="col-12 mb-10 bold">Push candidate to new session</div>
              <div class="col-3 mb-10">Choose session:</div>
              <div class="col-9 mb-10 bold">
                <select-component
                  clearable
                  :items="sessions_locations"
                  @selected="newSession"
                  :value="selected_sessions_locations_text"
                />
              </div>
            </div>
          </template>
          <template v-if="user_active_session.id">
            <div class="row">
              <div class="col-3 mb-10">Session name:</div>
              <div class="col-9 mb-10 bold">{{ user_active_session.session_location || '' }}</div>
              <div class="col-3 mb-10">Session Recruitment:</div>
              <div class="col-9 mb-10 bold">{{ user_active_session.session_jrStartDate || '' }} - {{user_active_session.session_jrEndDate}}</div>
              <div class="col-3 mb-10">Session Training:</div>
              <div class="col-9 mb-10 bold">{{ user_active_session.session_trStartDate || '' }} - {{user_active_session.session_trEndDate}}</div>
              <div class="col-3 mb-10">User status in current sessions:</div>
              <div class="col-9 mb-10">
                <sessions-buttons-status
                  show_status
                  :approved="user_active_session.approved"
                  @click="(st) => user_active_session.approved = st"
                />
              </div>
              <div class="col-3 mb-10">Session description:</div>
              <div class="col-9 mb-10 session_description" v-html="user_active_session.session_jobDesc"></div>
            </div>
          </template>
        </tab>
        <tab
          v-if="local_obj && local_obj.sessions.length"
          name="Sessions requests">
          <default-table
            :fields_name="session_table_fields"
            :items="local_obj.sessions">
            <template slot="session_location" slot-scope="props">
              {{ props.session_location }}
            </template>
            <template slot="activity" slot-scope="props">
              <toggled
                @input="changeActiveSession(props.id)"
                :value="props.activity"
              />
            </template>
            <template slot="approved" slot-scope="el">
              <sessions-buttons-status
                :approved="el.approved"
                @click="(st) => local_obj.sessions[el.__col_index].approved = st"
              />
            </template>
            <template slot="created_at" slot-scope="props">
              {{ getDateFormatStrToShow(props.created_at) || '-' }}
            </template>
          </default-table>
        </tab>
      </tabs>
    </template>
    <template
      v-if="tab_selected !== 'Edit user info'"
      v-slot:popup_footer>
      <button class="button is-link is-outlined" @click="submitModal">Save</button>
      <button class="button is-success ml-10 is-outlined" @click="saveAndClose">Save & Close</button>
    </template>
  </popup-block>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import PopupBlock from '__module_list/popup/PopupBlock'
import ModalMixin from '../../mixins/ModalMixin'
import Toggled from '../../../module_list/toggled/Toggled'
import Tabs from '../../../module_list/tabs/Tabs'
import Tab from '../../../module_list/tabs/Tab'
import SelectComponent from '../../../module_list/dropdown/SelectComponent'
import axiosService, {DEFAULT_ERROR_REPS_TEXT, DEFAULT_ERROR_UPDATE_REPS_TEXT} from '../../plugins/axiosService'
import DownloadIcon from '../small-components/icons/DownloadIcon'
import DefaultTable from '__module_list/default_table/DefaultTable'
import dateMixin from '../../mixins/dateMixin'
import CustomCheckbox from '__module_list/custom-checkbox/CustomCheckbox'
import SessionsButtonsStatus from '../blocks/SessionsButtonsStatus'
import {transformUserSessions} from '../../mixins/UserMixin'
import UserAvatar from '../blocks/UserAvatar'
import DefaultButton from "../small-components/DefaultButton";
import bus from "../../bus";
import {Notification, STATUS_SUCCESS, STATUS_ERROR} from "../../helpers/httpCatcher";
import EditUserForm from '../Users/EditUserForm'

export default {
  name: 'UserModal',
  mixins: [ModalMixin, dateMixin],
  components: {
    EditUserForm,
    DefaultButton,
    UserAvatar,
    SessionsButtonsStatus,
    CustomCheckbox,
    DefaultTable,
    DownloadIcon,
    SelectComponent,
    Toggled,
    PopupBlock,
    Tabs,
    Tab,
  },
  props: {
    obj: {
      type: Object,
      default: () => ({})
    },
    roles: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      new_session_index: -1, // index of array
      tab_selected: '',
      text_success: '',
      text_error: '',
      local_obj: null,
      save_close: false,
    }
  },
  computed: {
    ...mapGetters([
      'sessions',
      'getAccountPermissionAdmin',
    ]),
    session_table_fields () {
      return {
        session_id: 'id',
        session_location: 'location',
        created_at: 'created',
        activity: 'in session',
        approved: 'request status'
      }
    },
    user_active_session() {
      if (!this.local_obj) return
      return this.local_obj.sessions.find(session => session.activity) || {}
    },
    sessions_locations() {
      return this.sessions.map(session => {
        return `${session.session_location}; Recruitment: ${session.session_jrStartDate} - ${session.session_jrEndDate};`+
            ` Travel: ${session.session_trStartDate} - ${session.session_trEndDate}`
      })
    },
    selected_sessions_locations_text() {
      if (!this.selected_sessions_locations) return
      return `${this.selected_sessions_locations.session_location}; Recruitment: ${this.selected_sessions_locations.session_jrStartDate} - ${this.selected_sessions_locations.session_jrEndDate};`+
        ` Travel: ${this.selected_sessions_locations.session_trStartDate} - ${this.selected_sessions_locations.session_trEndDate}`

    },
    selected_sessions_locations() {
      return this.sessions[this.new_session_index]
    }
  },
  methods: {
    ...mapActions([
      'fetchSessions'
    ]),
    rejInterview(id){
      axiosService.post('api/v1/admin/users/reject', {id}).then(e => {
        bus.$emit('notification', new Notification('Successfully rejected', STATUS_SUCCESS))
      })
    },
    openModal () {
      this.opened = true
    },
    closeModal () {
      this.new_session_index = -1
      this.text_success = ''
      this.text_error = ''
      this.local_obj = null
      this.opened = false
      this.save_close = false
      this.$emit('closed', true)
    },
    newSession({ index }) {
      this.new_session_index = index
    },
    changeActive (val) {
      this.local_obj.active = val ? 1 : false
    },
    tabSelected (val) {
      this.tab_selected = val
    },
    selectedRole (val) {
      this.local_obj.role = val
    },
    submitModal () {
      this.text_success = ''
      this.text_error = ''
      let resp;
      switch (this.tab_selected) {
        case 'Active session':
          if (this.new_session_index >= 0) {
            resp = this.addUserToSessions()
              .then(resp => {
                if (resp.data.user) {
                  this.local_obj = transformUserSessions(resp.data.user)
                }
                this.$emit('updated', this.local_obj)
                return new Promise(resolve => resolve(resp))
              })
          }
          break;
        case 'Sessions requests':
          if (this.local_obj.sessions.length) {
            resp = this.changeUserSessions()
              .then(r => {
                this.$emit('updated_sessions', this.local_obj)
                return new Promise(resolve => resolve(r))
              })
          }
          break;
        default: {
          resp = this.changeUser()
            .then(r => {
              this.$emit('updated', this.local_obj)
              return new Promise(resolve => resolve(r))
            })
        }
      }
      if (!resp) return
      resp
        .then(() => {
          this.text_success = 'Updated'
          if (this.save_close) {
            setTimeout(this.closeModal, 2000)
          }
        })
        .catch(e => {
          this.text_error = e.response?.data?.error || `${DEFAULT_ERROR_UPDATE_REPS_TEXT} ${DEFAULT_ERROR_REPS_TEXT}`
          console.log([e])
        })
    },
    saveAndClose () {
      this.save_close = true
      this.submitModal()
    },
    changeUser () {
      return axiosService.post('/api/v1/user/update-role', {
        id: this.local_obj.id,
        active: this.local_obj.active || 0,
        role: this.local_obj.role || 0
      })
    },
    changeActiveSession (id) {
      let selected = this.local_obj.sessions.find(session => session.id === id)
      if (!selected) return
      selected.activity = selected.activity ? 0 : 1
      // deactivate old active
      let old_selected = this.local_obj.sessions.find(session => session.id !== id && session.activity)
      if (old_selected) {
        old_selected.activity = 0
      }
    },
    addUserToSessions() {
      if (!this.selected_sessions_locations) return
      return axiosService.post(
        '/api/v1/sessions/admin/create',
        {
          user_id: this.local_obj.id,
          session_id: this.selected_sessions_locations.session_id
        }
      )
    },
    changeUserSessions () {
      return axiosService.post(
        '/api/v1/sessions/admin/update',
        {
          id: this.local_obj.id,
          sessions: this.local_obj.sessions.map(session => {
            return {
              id: session.id,
              approved: session.approved,
              activity: session.activity,
            }
          })
        }
      )
    },
  },
  watch: {
    'obj.id' (val) {
      if (!val) return
      this.openModal()
      // unlink obj
      this.local_obj = JSON.parse(JSON.stringify(transformUserSessions(this.obj)))
      if (!this.sessions) {
        this.fetchSessions({ date: new Date() })
      }
    },
  }
}
</script>
