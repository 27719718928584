<script>
import ModalMixin from "../../../../mixins/ModalMixin";
export default {
  name: "PropsMixin",
  mixins: [ModalMixin],
  props: {
    property: {
      type: Object
    },
  },
  data: () => ({
    editing: {},
  }),
  watch: {
    property(val){
      if(val.open) {
        this.editing = val.data
        this.opened = true
        if(typeof this.load === 'function' )this.load()
      } else {
        this.opened = false
      }

    }
  }
}
</script>
