import axiosService from '../../plugins/axiosService'
import authHelper from '../../plugins/authHelper'
import { statuserror } from '../../helpers/httpCatcher'
import bus from '../../bus'
import router from '../../router/routes'

export default {
  actions: {
    loginAction (context, account) {
      context.commit('FETCH_ACCOUNT', account)
      if (account.quiz != null) context.commit('QRESULTS', account.quiz)

      context.dispatch('clearSessions')
      context.dispatch('fetchSessions')
      context.dispatch('fetchUsers')
    },
    fetchUserQuizList (context) {
      return axiosService.get('/api/v1/user/quiz-list')
        .then(({ data }) => {
          context.commit('mutationAccountAvailableTests', data.available_tests || [])
          context.commit('mutationAccountPassedTests', data.passed_tests || [])
        })
        .catch((e) => {
          console.log([e])
        })
    },
    actionPermission (context) {
      return axiosService.post('/api/v1/permission')
        .then((e) => {
          context.commit('MUTATION_PERMISSION', e.data.permission)
          return e.data.permission
        })
        .catch((e) => {
          bus.$emit('notification', statuserror(e.response.status))
        })
    },
    logoutAction(context) {
      axiosService.post('/logout')
      .then(() => {
        context.dispatch('logout')
        router.push({name: 'home'})
      })
    },
    async checkAuth (context) {
      const time = (new Date()).valueOf()
      const account = context.getters.getAccount
      if (account == null || account === false || account.x_auth === undefined || time > account.time) {
        return axiosService.post('/account/get')
          .then((result) => {
            context.commit('FETCH_ACCOUNT', { ...result.data.user, ...{ x_auth: result.headers['x-auth'] } })
            context.commit('FETCH_QUIZ', { ...result.data.quizes })

            context.commit('QRESULTS', result.data.user.quiz)
            authHelper.setHead('x-auth', window.localStorage.getItem('x-auth'))
            authHelper.setCookie('x-auth', window.localStorage.getItem('x-auth'))
            return context.getters.getAccount
          })
          .catch(result => {
            window.localStorage.removeItem('x-auth')
            context.commit('CLEAR_ACCOUNT')
            // (new HttpCatcher()).process(result)
            //   .then(result => {
            //     if (result.status !== null) {
            //       window.localStorage.removeItem('x-auth')
            //       context.commit('CLEAR_ACCOUNT')
            //     }
            //   })
          })
          .finally(() => {
            context.dispatch('actionAccountLoaded', true)
          })
      } else {
        return new Promise(resolve => {
          resolve(context.getters.getAccount)
        })
      }
    },
    logout: (context) => {
      context.commit('CLEAR_ACCOUNT')
      context.commit('CLEAR_DATA')
    },
    actionAccountLoaded:(context, bool = false) => context.commit('mutationAccountLoaded', bool)
  },
  mutations: {
    FETCH_ACCOUNT (state, user) {
      if (user !== undefined && user !== null) {
        if (user.x_auth !== undefined) {
          state.x_auth = user.x_auth
          window.localStorage.setItem('x-auth', user.x_auth)

          authHelper.setHead('x-auth', user.x_auth)
          authHelper.setCookie('x-auth', user.x_auth)
        }
        state.account = {
          id: user.id,
          /* 5 minute active data in local storage*/
          time: (new Date()).valueOf() + 1000 * 60 * 5,
          /* local cookie with that checked */
          x_auth: user.x_auth,

          permission: null,

          active: user.active,
          quiz: user.quiz,
          email: user.email,
          login: user.login,
          first: user.firstName,
          last: user.lastName,
          phone: user.phone,
          languages: user.languages || [],
        }
      }
    },

    CLEAR_DATA (state) {
      state.x_auth = localStorage.getItem('x-auth')
      state.permission = ''
      state.quizes = []
      state.qresults = []
      state.sessions = 0
      state.activeSession = 0
      state.users = []
    },
    CLEAR_ACCOUNT (state) {
      state.account = null
      window.localStorage.removeItem('x-auth')
      authHelper.clearHead('x-auth')
      authHelper.clearCookie('x-auth')
    },
    MUTATION_PERMISSION (state, payload) {
      state.permission = payload
      try {
        state.account['permission'] = payload

      } catch (e) {
        console.error('Cannot found stored account in vuex')
      }
    },
    QRESULTS: (state, payload) => state.qresults = payload,
    FETCH_QUIZ: (state, payload) => {
      state.quizes = payload
    },
    mutationAccountLoaded: (state, bool) => state.account_loaded = bool,
    mutationAccountAvailableTests: (state, list) => state.available_tests = list,
    mutationAccountPassedTests: (state, list) => state.passed_tests = list,
  },
  state: {
    account: null,
    account_loaded: false,
    x_auth: localStorage.getItem('x-auth'),
    permission: '',
    quizes: [],
    qresults: [],

    available_tests: [],
    passed_tests: []
  },
  getters: {
    getAccount: store => store.account,
    getAccountPermissionAdmin: store => store.account?.permission.name === 'admin',
    getAccountLanguages: store => store.account.languages || [],
    getAccountLoaded: store => store.account_loaded,
    getAccountAvailableTests: store => store.available_tests,
    getAccountPassedTests: store => store.passed_tests,
    getAccountXAuth: store => store.x_auth,
    getQuizResults: store => store.qresults,
    getAccountActive: store => store.account ? store.account.active === 1 || false : false,
    getPermission: store => store.permission,
    //getPermissionManager: store => store.permission?.permissions.,
    getQuizList: store => store.quizes,

    getUserQuizIds: (store, getters) => getters.getUserQuiz.map(e => e.id),
    getUserQuiz: store => {
      let ids = []
      for (let [id, name] of Object.entries(store.quizes)) {
        ids.push({
          id: parseInt(id),
          name
        })
      }
      return ids
    },
    passedQuiz: store => store.account?.quiz || [],
    passedQuizIds: (store, getters) => getters.passedQuiz.map(e => e.id)
  }
}
