export const LANGUAGE_LEVELS = {
  beginner: {
    min: 0,
    code: 'A1',
    hex_color: '#991B1B'
  },
  elementary: {
    min: 25,
    code: 'A2',
    hex_color: '#991B1B'
  },
  intermediate:  {
    min: 50,
    code: 'B1',
    hex_color: '#946c00'
  },
  upper: {
    min: 75,
    code: 'B2',
    hex_color: '#946c00'
  },
  advanced: {
    min: 87,
    code: 'C1',
    hex_color: '#0072CE'
  },
  proficient: {
    min: 100,
    code: 'C2',
    hex_color: '#0072CE'
  },
}

export const LANGUAGE_LEVELS_LIST = Object.entries(LANGUAGE_LEVELS).map(([key, val]) => {
  return {...{lvl: key}, ...val }
})
