import axiosService from '../../../application/plugins/axiosService'

export const QUERY_PAGE_KEY = 'page'
const PER_PAGE = 'size'
const ORDER_BY_KEY = 'order_by'
const SEARCH_BY_KEY = 'search_by'
const SEARCH_KEY = 'search'
const URI_KEYS = [PER_PAGE, ORDER_BY_KEY, SEARCH_BY_KEY, SEARCH_KEY, QUERY_PAGE_KEY]
export const SHOW_RANGE = [5, 10, 20, 50]
export const DEFAULT_RANGE = SHOW_RANGE[1]

export const queryJoiner = (query = '') => {
  return query.indexOf('?') < 0 ? '?' : '&'
}

export const queryBuilder = (obj = null) => {
  if (!obj) return ''
  let query = ''
  for (let [key, value] of Object.entries(obj)) {
    if (key === QUERY_PAGE_KEY) {
      value = value = value < 0 ? 1 : value
      if (parseInt(value) === 1) continue
    } else if (key === PER_PAGE) {
      value = parseInt(value)
      if (value === DEFAULT_RANGE || !SHOW_RANGE.includes(value)) continue
    }
    query += `${queryJoiner(query)}${key}=${value}`
  }
  return query
}

export default {
  props: {
    fetch_url: {
      type: String,
      default: ''
    },
    request_is_get: {
      type: Boolean,
      default: false
    },
    response_key_list: {
      type: String,
      default: ''
    },
    change_location: {
      type: Boolean,
      default: false
    },
    active_table: {
      type: Boolean,
      default: false
    },
    update_index: { // used by index updated in watch
      type: Number,
      default: 0
    },
    update_outer: {
      type: [Object, Array],
      default: null
    },
    filter_query_outer: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      loading: false,
      local_items: [],
      sortable_fields: [],

      sort_by: {
        field: '',
        asc: null,
        desc: null
      },
      filters: null,
      selected_filters: {},

      search_val: '',
      search_by_list: [],
      search_by_index: -1,

      pagination_data: null,
    }
  },
  computed: {
    has_pagination () {
      return !!this.pagination_data
    },
    has_selected_filters() {
      return Object.keys(this.selected_filters).length
        && Object.entries(this.selected_filters).some(([key, val]) => val !== '')
    },
    fetch_method() {
      return this.request_is_get ? 'get' : 'post'
    },
    pagination_obj () {
      if (!this.pagination_data) return null
      let data = {}
      data[QUERY_PAGE_KEY] = this.pagination_data.current_page
      if (this.pagination_data.per_page !== DEFAULT_RANGE) {
        data[PER_PAGE] = this.pagination_data.per_page
      }
      if (!this.empty_sort) {
        data[ORDER_BY_KEY] = `${this.sort_by.field},${this.sort_dir}`
      }
      if (this.search_val && this.search_by_index >=0) {
        data[SEARCH_BY_KEY] = this.search_by_list[this.search_by_index]
        data[SEARCH_KEY] = `${this.search_val}`
      }
      if (this.has_selected_filters) {
        for (let [key, value] of Object.entries(this.selected_filters)) {
          if (value !== ''){
            data[key] = value
          }
        }
      }
      return data
    },
    empty_sort () {
      return !this.sort_by.field || !this.sort_dir
    },
    sort_dir () {
      return this.sort_by.asc
        ? 'asc'
        : this.sort_by.desc ? 'desc' : ''
    },
    has_selected_filers_or_search () {
      return this.search_by_index >= 0
        || this.selected_order_by
        || this.search_val !== ''
        || Object.entries(this.selected_filters)
          .some(([key, val]) => val !== '')
    }
  },
  methods: {
    filterFetch(){
      this.pagination_data.current_page = 1
      this.fetchData(queryBuilder(this.pagination_obj))
    },
    setSortData (val) {
      if (!this.sortable_fields.includes(val.field)) return
      this.sort_by = val
      this.fetchData(queryBuilder(this.pagination_obj))
    },
    changePerPage(val) {
      if (val === this.pagination_data.per_page) return
      this.pagination_data.per_page = val
      this.pagination_data.current_page = 1
      this.fetchData(queryBuilder(this.pagination_obj))
    },
    paginateData(page) {
      if (page === this.pagination_data.current_page) return
      this.pagination_data.current_page = page
      this.fetchData(queryBuilder(this.pagination_obj))
    },
    changeLocationUrl(query) {
      if (!this.change_location) return
      history.pushState('', '', `${location.origin}${location.pathname}${query}${location.hash}`)
    },
    fillFilters(list) {
      if (!list || !Object.keys(list).length) {
        this.filters = null
        return
      }
      for (let [key, value] of Object.entries(list)) {
        list[key] = (Object.entries(value || [])).map(([id, value]) =>  {
          return {
            id: /^-?\d+$/.test(id) ? Number(id) : id,
            value: value,
          }
        })
        // add filters if didn't exist & make reactivity
        if (this.selected_filters[key] === undefined) {
          this.updateSelectedFilters(key)
        }
      }
      this.filters = list
    },
    fillSearch(list) {
      this.search_by_list = list
      if (list.length && this.search_by_index === -1 && location.search.includes(SEARCH_BY_KEY)) {
        let location_obj = Object.fromEntries(new URLSearchParams(location.search))
        if (location_obj[SEARCH_BY_KEY]) {
          this.search_by_index = this.search_by_list.indexOf(location_obj[SEARCH_BY_KEY])
        }
      }
    },
    fetchData(query = '', changeLocation = true){
      if (changeLocation) {
        this.changeLocationUrl(query)
      }
      this.loading = true
      axiosService[this.fetch_method](this.fetch_url + query, this.filter_query_outer || {})
        .then(resp => {
          // init sorting
          this.sortable_fields = resp.data.order_by || []
          // init filters
          this.fillFilters(resp.data.filters || null)
          // init searching
          this.fillSearch(resp.data.search_fields || [])
          if (!resp.data[this.response_key_list]) return
          this.local_items = resp.data[this.response_key_list].data || resp.data[this.response_key_list]
          if (resp.data[this.response_key_list].per_page) {
            resp.data[this.response_key_list].per_page = parseInt(resp.data[this.response_key_list].per_page)
            if (resp.data[this.response_key_list].data) {
              delete resp.data[this.response_key_list].data
            }
            this.pagination_data = resp.data[this.response_key_list]
          }
        })
        .catch(e => {
          console.log([e])
        })
        .finally(() => {
          this.loading = false
        })
    },
    setOrderByFromStr(str) {
      if (!str) return
      let [key, val]  = str.split(',')
      this.sort_by.field = key
      if (val === 'asc') {
        this.sort_by.asc = true
      } else {
        this.sort_by.desc = true
      }
    },
    setFiltersByFromStr(obj) {
      // set filters from url
      for (let [key, value] of Object.entries(obj)) {
        if (URI_KEYS.includes(key)) continue
        if (/^-?\d+$/.test(value)) { // check numeric value
          value = Number(value)
        }
        this.updateSelectedFilters(key, value);
      }
    },
    updateSelectedFilters(key, value = '') {
      //update reactivity
      this.$set(this.selected_filters, key, value)
    },
    setSearchFromStr(obj) {
      if (obj[SEARCH_KEY]) {
        this.search_val = obj[SEARCH_KEY]
      }
    },
    firstFetch() {
      if (!this.fetch_url || !this.active_table) return
      // fetch data from url
      if (!this.pagination_data && !this.local_items.length) {
        let obj = Object.fromEntries(new URLSearchParams(location.search))
        this.setOrderByFromStr(obj[ORDER_BY_KEY])
        this.setFiltersByFromStr(obj)
        this.setSearchFromStr(obj)
        let query = queryBuilder(obj)
        this.fetchData(query)
      } else {
        // update only url when tab changed
        this.changeLocationUrl(queryBuilder(this.pagination_obj))
      }
    },
    updateSearchVal(val) {
      this.search_val = val
    },
    selectedSearchBy(val) {
      this.search_by_index = val
    },
    clearFilters() {
      if (!this.empty_sort) {
        this.sort_by.asc = null
        this.sort_by.desc = null
        this.sort_by.field = ''
      }
      if (this.search_by_index >= 0) {
        this.search_by_index = -1
      }
      this.search_val = ''
      if (this.selected_filters) {
        Object.keys(this.selected_filters).forEach(key => {
          this.selected_filters[key] = ''
        })
      }
      if (this.search_by_index >= 0) {
        this.search_by_index = -1
      }
      this.filterFetch()
    },
    updateLocalItem(obj) {
      if (!obj || !obj.id) return
      let local_item = this.local_items.find(el => el.id === obj.id)
      if (!local_item) return
      for (const [key] of Object.entries(local_item)) {
        local_item[key] = obj[key] !== undefined ? obj[key] : local_item[key]
      }
    },
    popstateEvent(e) {
      console.log(e)
    }
  },
  mounted() {
    this.firstFetch()
  },
  // beforeDestroy() {
  //   window.removeEventListener('popstate', this.popstateEvent);
  // },
  watch: {
    'active_table'(val) {
      if (!this.fetch_url) return
      if (val) {
        this.$nextTick(() => {
          this.firstFetch()
        })
        // window.addEventListener('popstate', this.popstateEvent);
      } else {
        // clear query on disabling table
        history.replaceState('', '', `${location.origin}${location.pathname}${location.hash}`)
        // window.removeEventListener('popstate', this.popstateEvent);
      }
    },
    'update_index'(val) {
      if (!this.fetch_url || !this.active_table) return
      this.fetchData(location.search, false)
    },
    'update_outer': {
      deep: true,
      handler (val) {
        if (!val) return
        if (Array.isArray(val)) {
          val.forEach(el => {
            this.updateLocalItem(el)
          })
        } else {
          this.updateLocalItem(val)
        }
      }
    },
    'filter_query_outer': {
      deep: true,
      handler (val) {
        if (!val) return
        this.filterFetch()
      }
    }
  }
}
