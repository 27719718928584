<template>
  <div class="badge"
       :class="{badge_error: error}"
       :style="badge_styles">
      <div v-if="!is_main_test && !is_arr">
        {{error ? 'test fail' : 'Success'}}
      </div>
      <div v-else>
        <template v-if="!is_arr">Your result will be show soon</template>
        <template v-else>
          {{get_lvl.code}}
        </template>
      </div>
  </div>
</template>

<script>
import { LANGUAGE_LEVELS } from '../../mixins/TestMixin'

export default {
  name: 'Badge',
  props: {
    pass_rate: {
      type: [Number, Array],
      default: 0
    },
    is_main_test: {
      type: Boolean,
      default: false
    },
    result: {
      type: Number,
      default: 0
    }
  },
  computed: {
    valid_test_list() {
      return this.is_main_test && this.is_arr
    },
    is_arr() {
      return Array.isArray(this.pass_rate)
    },
    error() {
      return !this.is_main_test && !this.is_arr && this.result < this.pass_rate
    },
    badge_styles() {
      if (!this.valid_test_list) return {}
      let color = this.get_lvl ? this.get_lvl.hex_color : LANGUAGE_LEVELS.beginner.hex_color
      return {
        color: color,
        borderColor: color
      }
    },
    get_lvl() {
      if (!this.valid_test_list) return null
      return this.pass_rate.reduce((x, y) => y.min <= this.result ? y : x)
    },
  }
}
</script>
