<template>
  <div class="screen centered-content">
    <div class="card-500 card">
      <h5 class="pb-4 mt-20">
        Enter new password
      </h5>
      <default-form
        @submit="submitLogin"
        class="mx-4"
        :success_text="success_msg"
        :error_text="errormsg">
        <div>
          <default-input
            name="name"
            required
            type="password"
            placeholder="New password"
            v-model="password.val"
            :valid.sync="password.valid"
            :validate_f="validatePassword"
          />
          <default-input
            name="name"
            required
            type="password"
            placeholder="Confirm Password"
            :compare="password.val"
            v-model="password_confirm.val"
            :valid.sync="password_confirm.valid"
            :validate_f="confirmPassword"
          />
             </div>
        <template v-slot:buttons_block>
          <div class="card-actions">
            <div class="button-wrapper">
              <default-button
                type="submit"
                class="is-link ml-auto"
              >Next
              </default-button>
            </div>
          </div>
        </template>
      </default-form>
    </div>
  </div>
</template>

<script>
import DefaultInput from '../small-components/DefaultInput'
import DefaultButton from '../small-components/DefaultButton'
import input_validations from '../../helpers/input_validations'
import axiosService from '../../plugins/axiosService'

import IosAlertIcon from 'vue-ionicons/dist/ios-information-circle.vue'
import DefaultForm from '../small-components/DefaultForm'

export default {
  name: 'ResetNewPassword',
  mixins: [input_validations],
  data() {
    return {
      token: '',
      email: '',
      password: {
        val: '',
        valid: true,
      },
      agree: false,
      password_confirm: {
        val: '',
        valid: true,
      },
      errormsg: '',
      success_msg: ''
    }
  },
  components: {
    DefaultForm,
    DefaultButton,
    DefaultInput,
    IosAlertIcon
  },
  created() {
    let urlParams = new URLSearchParams(window.location.search);
    this.email = urlParams.get('email')
    this.token = urlParams.get('token')
  },
  methods: {
    submitLogin () {
      this.errormsg = ''
      this.success_msg = ''
      axiosService.post(
        '/reset-password',
        {
          email: this.email,
          token: this.token,
          password: this.password.val,
          password_confirmation: this.password_confirm.val,
        })
        .then(r => {
          this.success_msg = r.data.message
          setTimeout(() => {
            this.$router.push({ name: 'dashboard.home' })
          }, 3000)
        })
        .catch(e => {
          this.errormsg = e.response.data.errors.email[0] || 'The given data was invalid.'
        })
    }
  }
}
</script>

