<template>
  <div class="card py-2 px-4 is-dark mx-auto">
    <template v-if="activeSession !== 0 &&
    active === 0 && activeSession !== null && activeSession !== undefined && activeSession !== false"
    >
      <h4>{{ decodeURI(activeSession.session_location) }}</h4>
      <train-buttons v-if="isSessionAvailable">
        <button class="button is-disabled is-small">{{ activeSession.session_jrStartDate }}</button>
        <button class="button is-disabled is-small" style="pointer-events: none">-</button>
        <button class="button is-disabled is-small">{{ activeSession.session_jrEndDate }}</button>
      </train-buttons>
      <train-buttons v-if="isSessionAvailable">
        <button class="button is-disabled is-small">{{ activeSession.session_trStartDate }}</button>
        <button class="button is-disabled is-small" style="pointer-events: none">-</button>
        <button class="button is-disabled is-small">{{ activeSession.session_trEndDate }}</button>
      </train-buttons>

      <template v-if="isSessionAvailable && isSessionActiveNow(activeSession)">
        <timetable v-model="activeSession.room" v-if="activeSession !== 0 && activeSession.room !== undefined && activeSession.room.length > 0" />


        <div v-if="isSessionAvailable" v-html="activeSession.session_jobDesc"></div>
      <div class="actions pb-4" v-if="isSessionAvailable">

        <div class="dropdown pt-10" :class="{'is-active': dropdown.active}">
          <div class="dropdown-trigger">
          </div>
          <div class="dropdown-menu" id="dropdown-menu" role="menu">
            <div class="dropdown-content">
              <button class="button dropdown-item" @click.prevent="">
                Approve candidate
              </button>
            </div>
          </div>
        </div>
      </div>
      </template>
      <template v-else>
        Now session isn't active
      </template>
    </template>
    <template v-else-if="active === 0">
      No sessions
    </template>
    <template v-if="active === 1">
      <h2>Sessions
        <button class="button is-info is-small ml-8" @click="create" v-if="isManager">create new</button>
      </h2>
      <div class="tile is-parent" style="column-gap: 1rem;">
        <div class="left tile is-child">
          <p>You can select session by Location</p>
          <train-buttons>
            <input type="text" class="input" v-model="locationSearch"
                   placeholder="Enter preferred location">
            <button class="button bi-align-middle my-auto"
                    @click="fetchSessions({date: locationDate})">Search</button>
          </train-buttons>
        </div>

        <div class="right tile is-child">
          <p>You can select session by Date</p>
            <train-buttons>
              <input type="date" class="input" v-model="locationDate"
                     placeholder="Enter preferred location">
              <button class="button bi-align-middle my-auto"
                      @click="fetchSessions({date: locationDate})">Search</button>
            </train-buttons>
        </div>
      </div>

      <div class="tests pb-12" v-if="locationsFound.length > 0">
        <table class="test-entity">
          <thead>
          <tr>
            <th class="header">Location</th>
            <th class="header">Learn</th>
            <th class="header">Travel</th>
            <th class="header">Actions</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(location, key) in locationsFound">
            <td>
              {{ location.location }}
            </td>
            <td>
              <train-buttons>
                <button class="button is-disabled is-small">{{ location.dates.tr.start }}</button>
                <button class="button is-disabled is-small" style="pointer-events: none">-</button>
                <button class="button is-disabled is-small">{{ location.dates.tr.end }}</button>
              </train-buttons>
            </td>
            <td>
              <train-buttons>
                <button class="button is-disabled is-small">{{ location.dates.sr.start }}</button>
                <button class="button is-disabled is-small" style="pointer-events: none">-</button>
                <button class="button is-disabled is-small">{{ location.dates.sr.end }}</button>
              </train-buttons>
            </td>
            <td @click.prevent="locationSelected = key; join()">
              <button class="button bi-align-middle my-auto is-small" :class="!isSessionAvailable ? 'is-static':''">
                Join
              </button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </template>
    <template v-else-if="active === 1">
      No sessions
    </template>
    <div class="modal" :class="{'is-active': editing.id !== undefined }">
      <div class="modal-background is-light" @click="locationSelected = -1"></div>
      <div class="modal-content">
        <div class="card pt-4 px-4">
          <div class="media column">
            <span>
              <h3 class="pb-4">Create session</h3>
            </span>
            <div>
              <default-input type='name' label="Location" required name="Location"
                             v-model="editing.location"></default-input>
              <default-input type='name' label="Job name" required name="Job position"
                             v-model="editing.job"></default-input>
              <default-input type='name' label="Description" required name="Session description"
                             v-model="editing.desc"></default-input>
              <div class="clearfix tile is-0">
                <div class="column is-6">
                  <default-input label="Recruitment session start"
                                 v-model="editing.start1"
                                 type="date"
                                 ref='calendarTrigger'
                                 class="input"
                                 locale="en_EN"/>
                  <default-input label="Training session start" v-model="editing.start2" type="date" ref='calendarTrigger' class="input"
                                 locale="en_EN"/>
                </div>
                <div class="column is-6">
                  <default-input label="Recruitment session end" v-model="editing.end1" type="date" ref='calendarTrigger' class="input"
                                 locale="en_EN"/>
                  <default-input label="Training session end" v-model="editing.end2" type="date" ref='calendarTrigger' class="input"
                                 locale="en_EN"/>
                </div>
              </div>
            </div>
           <div class="actions pt-4">
              <button class="button is-success" @click="save">save</button>
              <button class="button is-danger" @click="editing = {id: undefined}">cancel</button>
            </div>
          </div>
        </div>
      </div>
      <button class="modal-close is-large" aria-label="close" @click="editing = {id: undefined}">close
      </button>
    </div>
  </div>
</template>


<script>
import AlertIcon from 'vue-ionicons/dist/ios-alert.vue'
import {mapActions, mapGetters} from "vuex";
import axiosService from "../../plugins/axiosService";
import DefaultInput from "../small-components/DefaultInput";
import TrainButtons from "../../../module_list/TrainButtons";
import Timetable from "../Session/Timetable";

export default {
  name: "SessionComponents",
  components: {Timetable, TrainButtons, DefaultInput, AlertIcon},
  props: {
    active: {}
  },
  data: () => ({
    dropdown: {
      active: false,
      available: []
    },
    editing: {
      id: undefined
    },
    locationSelected: -1,
    locationSearch: '',
    locationDate: (new Date()).toISOString().substring(0, 10),
    currentSession: null,
    usersFound: [{
      name: 'User Name',
      cv: 'url...',
      email: 'email@test.test',
      session: {
        location: 'Krusowice',
        dates: '',
        id: ''
      }
    }]
  }),
  computed: {
    isSessionAvailable(){
      console.log(this.activeSession)
      return this.activeSession === 0 || this.activeSession == null || (this.activeSession.status && this.activeSession.status === 1) || this.activeSession.status === undefined;
    },
    locationsFound(data) {
      if (data === undefined) return [];
      return !this.sessions?[]:this.sessions.map(e => Object.assign({
        id: e.session_id,
        location: decodeURI(e.session_location),
        dates: {
          tr: {
            start: e.session_trStartDate,
            end: e.session_trEndDate,
          },
          sr: {
            start: e.session_jrStartDate,
            end: e.session_jrEndDate,
          }
        }
      }))
    },
    dn() {
      return this.getAccount.first + ' ' + this.getAccount.last
    },

    isManager() {
      console.log(this.getPermission.permission)
      return this.getPermission != null && this.getPermission.permission != null
        && (this.getPermission.permission.findIndex(i => i === 'rooms') !== -1)
    },
    ...mapGetters([
      'getAccountActive',
      'getAccount',
      'getPermission',
      'activeSession',
      'sessions']),
  },

  methods: {
    ...mapActions([
      'fetchSessions',
      'joinSession',
    ]),
    isSessionActiveNow(session){
      const a = (new Date(session.session_jrStartDate)).toISOString().substring(0,10)
      const b = (new Date(session.session_jrEndDate)).toISOString().substring(0,10)
      const c = (new Date(session.session_trStartDate)).toISOString().substring(0,10)
      const d = (new Date(session.session_trEndDate)).toISOString().substring(0,10)
      const now = (new Date()).toISOString().substring(0,10)

      return  (a <= now && now <= b) || (c <= now && now <= d);
    },
    join() {
      if(!this.isSessionAvailable){
        return;
      }
      let id = this.locationSelected;
      id = this.locationsFound[id].id;
      this.joinSession(id);
    },

    save() {
      axiosService.post('/api/v1/sessions/new', this.editing).then(() => {
        alert('Session stored as ' + this.editing.location)
      });
    },

    create() {
      this.$set(this, 'editing', {
        id: -1,
        location: "Krucovice",
        start1: "2021-11-19",
        end1: "2021-11-21",
        start2: "2021-12-01",
        end2: "2021-12-12",
        job: "Test",
        desc: "Test Description"
      })
    }
  },
}
</script>

<style scoped>
.modal-background.is-light {
  background-color: rgba(10, 10, 10, 0.46);
}

@media screen and (max-width: 600px) {
  .text-chat {
    min-width: auto;
  }
}

@media screen and (min-width: 600px) {
  .text-chat {
    min-width: 350px;
  }
}
.chatBox{
  max-width: 600px;

}
.message.left{
  margin-left: auto;
}
.message{
  max-width: 50%;
  padding: 4px 16px;
  border-radius: 8px;
}

.message-box {
  min-height: 120px;
  margin-top: .5rem;
  border: 4px #2b74b1 groove;
  padding: .25rem .5rem;
  max-width: 600px;
  overflow-y: scroll;
  max-height: 400px;
  background-color: #efefff;
}
</style>
