<template>
    <div class="d">
        <p>
            Publish an event to channel <code>my-channel</code>
            with event name <code>my-event</code>; it will appear below:
        </p>
        <ul>
            <li v-for="message in messages">
                {{message}}
            </li>
        </ul>
        <button @click="send">123123</button>
    </div>
</template>
<script>
// eslint-disable-next-line import/no-absolute-path,no-unused-vars

const vue = {
  name: 'PusherTestComponent',
  data: () => ({
    messages: []
  }),
  mounted () {
    this.$pusher.subscribe('my-channel', (channel) => {
      channel.bind('my-event', (data) => {
        console.log(`User ${data}`)
      })
    })
    this.$get('/pusher')
  },
  methods: {
    send () {
      this.$get('/pusher')
    }
  }
}

export default vue
</script>
