import {mapGetters} from 'vuex'

export default {
  computed: {
    ...mapGetters([
      'getUserActiveSession',
      'getUserBlockedSessionIds',
    ]),
    available_sessions_fields_name() {
      return {
        session_location: 'Location',
        session_jrStartDate: 'Recruitment start',
        session_jrEndDate: 'Recruitment end',
        session_trStartDate: 'Training start',
        session_trEndDate: 'Training end'
      }
    },
  },
  methods: {
    buttonText(session) {
      if (this.getUserBlockedSessionIds.includes(session.session_id)) {
        return 'Blocked'
      }
      if (this.getUserActiveSession && session.session_id === this.getUserActiveSession.session_id) {
        return this.getUserActiveSession.approved === -1 ? 'Awaiting to confirm' : 'Joined'
      }
      return  'Join'
    },
  }
}
