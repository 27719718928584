<template>
  <div class="check-item"
      :class="class_list"
       @click="$emit('click', $event)">
    <svg
      v-if="handler"
      class="check-item_handle"
      xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
      <path fill-rule="evenodd" d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5zm-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5z"/>
    </svg>
    <div class="check-item_text">
      <slot/>
    </div>
    <close
      v-if="removable"
      class="check-item_remove"
      @click.stop="$emit('remove')"
    />
  </div>
</template>

<script>
import Close from '../small-components/icons/Close'
export default {
  name: 'CheckItem',
  components: { Close },
  props: {
    selected: {
      type: Boolean,
      default: false
    },
    removable: {
      type: Boolean,
      default: false
    },
    default_styles: {
      type: Boolean,
      default: true
    },
    handler: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    class_list() {
      return {
        'check-item_colored': this.default_styles,
        selected: this.selected
      }
    }
  }
}
</script>
