<template>
  <div>
    <tabs>
      <tab
          :hidden="!show_session_tab"
          style_fix
          name="Your session"
          tab_img_href="/images/add.svg">
        <div class="session-tab is-parent mt-4 px-0 pl-1 ">
          <div v-if="getUserActiveSession && getUserActiveSession.approved === -1" class="px-4">
            Your request is awaiting confirmation from the manager
          </div>
          <template v-else-if="activeSession" class="px-4">
            <h4 class="pb-10 mx-6">{{ activeSession.session_location }}</h4>
            <train-buttons class="mx-6">
              <button class="button is-disabled is-small">{{ activeSession.session_jrStartDate }}</button>
              <button class="button is-disabled is-small" style="pointer-events: none">-</button>
              <button class="button is-disabled is-small">{{ activeSession.session_jrEndDate }}</button>
            </train-buttons>
            <train-buttons v-if="false" :class="/*We are hiding this information*/{}">
              <button class="button is-disabled is-small">{{ activeSession.session_trStartDate }}</button>
              <button class="button is-disabled is-small" style="pointer-events: none">-</button>
              <button class="button is-disabled is-small">{{ activeSession.session_trEndDate }}</button>
            </train-buttons>
            <tabs class="px-4">
              <tab style_fix
                   name="Description">
                <h4 class="is-primary select pl-4" @click="resetShown">Description </h4>
                <div class="white-description" v-if="description_shown">
                  <div v-html="activeSession.session_jobDesc">
                  </div>
                </div>

                <h4 class="is-primary select pl-4 mt-8"  v-if="todayMeeting " @click="resetShownMeeting">Meeting </h4>
                <div class="white-description is-primary mt-8" v-else>
                  <strong>Session has no active meeting today.</strong>
                </div>
                <div class="white-description is-primary mt-8" v-if="todayMeeting && meeting_shown">
                  <div class="row">
                    <div class="col-4 mb-10 bold">Meeting name:</div>
                    <div class="col-8 mb-10">{{todayMeeting.topic}}</div>
                    <div class="col-4 mb-10 bold">Start at:</div>
                    <div class="col-8 mb-10">
<!--                      {{ todayMeeting.start_time }}-->
                      {{ get_user_meeting_start_time }}
                    </div>
                    <div class="col-4 mb-10 bold">Duration:</div>
                    <div class="col-8 mb-10">{{ todayMeetingDuration }}</div>
                    <div class="col-4 mb-10 bold">Url:</div>
                    <div class="col-8 mb-10">
                      <button
                        v-if="is_active_room"
                        class="is-primary button is-small"
                        @click="open_meeting_id = todayMeeting.id;/*joinSessionUrl( todayMeeting.url )*/">
                        Join
                      </button>
                      <template v-else>Today meeting is over</template>
                    </div>
                  </div>
                </div>

                <div class="past-meetings pt-10 pb-10" v-if="isManager">
                    <h4 class="is-primary select pl-4 mt-8" slot="1" @click="swapPast">
                        Past meetings
                    </h4>
                  <div class="white-description is-primary mt-8" v-if="pastOpen">
                      <meeting-list class="ml-4" v-model="pastMeetings" v-if="pastOpen"/>
                  </div>
                </div>


              </tab>
              <tab :hidden="!isManager || !historyLoaded || lockedProgrammly" name="History">
                <div class="history-body" v-if="historyData">
                  <div class="history-item" v-for="data in historyData">
                    <span><strong>{{data.actor}}</strong></span> <span> {{ data.message }} </span> <span>{{data.session_name}}</span>
                  </div>

                </div>


              </tab>


              <tab :hidden="!isSessionActiveNow || !isManager" name="Meetings">
                <default-button v-if="isManager"
                                :router_obj="{name: 'dashboard.listSessions', hash: '#plan-participants', params: {id: activeSession.session_id}}">
                  Open Allocating Users
                </default-button>
              </tab>
              <tab name="User list" :hidden="!isSessionActiveNow || !isManager">
                <user-table :key="key" v-if="activeSession && activeSession.room !== undefined || activeSession.users"
                            @reload="load"
                            :users="usersFiltered"/>

              </tab>
            </tabs>
          </template>

        </div>
        <!--        <div class="card mt-4" v-if="isSessionActiveNow && chatEnabled">-->
        <!--          <chat-component/>-->
        <!--        </div>-->
      </tab>
      <div class="modal" :class="{'is-active': open_meeting_id !== undefined }">
        <div class="modal-background is-light" @click="open_meeting_id = undefined"></div>
        <div class="modal-content" v-if="open_meeting_id !== undefined">
          <div class="card pt-4 px-4">
            <h2 class="pb-4">{{ findMeetingToActivate(open_meeting_id).topic }}</h2>
            <p class="is-primary block">
              You are about to join the rally. Please, when connecting to the room, indicate the email with which you
              registered on this site for unambiguous identification. You will be redirected to the Zoom site to connect
              to the current room.
            </p>
            <p>
              The selected rally will be launched at
              {{ findMeetingToActivate(open_meeting_id).start_time.substring(11, 16) }} If the rally has not started
              yet, wait until the start or come back later.

            </p>
            <div class="d-flex justify-content-center mt-4 ml-auto">
              <default-button class="button is-success" @click="openMeeting">Open meeting</default-button>
            </div>

          </div>
        </div>
      </div>

      <tab
          name="Available sessions"
          tab_img_href="/images/list.svg">
        <default-table
            :fields_name="available_sessions_fields_name || {}"
            :items="sessions || []">
          <template slot="session_location" slot-scope="props">
            {{ props.session_location }}
          </template>
          <template slot="session_jrStartDate" slot-scope="props">
            {{ props.session_jrStartDate }}
          </template>
          <template slot="session_jrEndDate" slot-scope="props">
            {{ props.session_jrEndDate }}
          </template>
          <template slot="session_trStartDate" slot-scope="props">
            {{ props.session_trStartDate }}
          </template>
          <template slot="session_trEndDate" slot-scope="props">
            {{ props.session_trEndDate }}
          </template>
          <template slot="row_after" slot-scope="props">
            <button
                :disabled="blocked_join_session_btn || getUserBlockedSessionIds.includes(props.session_id)"
                @click="joinSessionById(props.session_id)"
                class="button is-link is-outlined">
              {{ buttonText(props) }}
            </button>
          </template>
        </default-table>
      </tab>`
    </tabs>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import Tabs from '__module_list/tabs/Tabs.vue'
import Tab from '__module_list/tabs/Tab.vue'
import ChatComponent from "./ChatComponent";
import DefaultTable from '__module_list/default_table/DefaultTable'
import TrainButtons from '__module_list/TrainButtons'
import Timetable from '../Session/Timetable'
import axiosService from '../../plugins/axiosService'
import {Browser} from '@capacitor/browser'
import moment from "moment";
import SessionsMixin from '../../mixins/SessionsMixin'

import constants from '../../helpers/constants';
import DefaultButton from "../small-components/DefaultButton";
import MeetingList from "./parts/MeetingList";
import UserTable from "../Session/UserTable";
import {
  addMinutesToDate,
  getDateFormatStr,
  getDateFormatStrToShow,
  getDateObj,
  getDateWithSomeTimeZone,
  getToday
} from '../../mixins/dateMixin'

export default {
  name: 'UserSession',
  mixins: [SessionsMixin],

  components: {
    UserTable,
    MeetingList,
    DefaultButton,
    ChatComponent,
    Timetable,
    TrainButtons,
    DefaultTable,
    Tabs,
    Tab,
  },
  data() {
    return {
      open_meeting_id: undefined,
      assignedUsers: [],
      usersFiltered: [],
      description_shown: true,
      meeting_shown: true,
      key: 1,
      lockedProgrammly: true,
      active_session: [],
      pastOpen: false,
      historyLoaded: false,
      historyData: [],
      chatEntity: {
        msgBox: '',
        messages: [],
        chat_id: ''
      },
      dropdown: 0
    }
  },
  computed: {
    ...mapGetters([
      'sessions',
      'activeSession',
      'getPermission',
      'getUserActiveSession',
      'getUserBlockedSessionIds',
    ]),
    pastMeetings() {
      return this.active_session.filter(e => this.isPast(e.start_time))
    },
    todayMeeting() {
      return this.active_session.find(entity => {
        const today = moment().format("YYYY-MM-DD")
        const date = moment(entity.start_time).format("YYYY-MM-DD")
        return date === today;
      })
    },
    todayMeetingDuration() {
      return moment.duration(this.todayMeeting.duration * 60 * 1000).humanize()
    },
    chatEnabled() {
      return constants.chatEnabled ?? false
    },
    isManager() {
      return (this.getPermission && this.getPermission.permission && this.getPermission.permission.includes('promote'))
    },
    blocked_join_session_btn() {
      if (this.getPermission
          && this.getPermission.permission
          && (this.getPermission.permission.includes('list') || this.getPermission.permission.includes('promote'))
      ) {
        return false
      }
      return !!this.getUserActiveSession
    },

    top() {
      return this.chatEntity.messages.filter((a, b, r) => b > r.length - 10)
    },
    show_session_tab() {
      return this.getUserActiveSession?.approved === -1 || !!this.activeSession
    },
    isSessionActiveNow() {
      if (!this.activeSession ||
          !this.activeSession.session_jrStartDate
          || !this.activeSession.session_trStartDate) return false

      const a = moment(this.activeSession.session_jrStartDate).toISOString(true).substring(0, 10)
      const b = moment(this.activeSession.session_jrEndDate).toISOString(true).substring(0, 10)
      const c = moment(this.activeSession.session_trStartDate).toISOString(true).substring(0, 10)
      const d = moment(this.activeSession.session_trEndDate).toISOString(true).substring(0, 10)
      const now = moment().toISOString(true).substring(0, 10)
      return (a <= now && now <= b) || (c <= now && now <= d);
    },
    get_today_room() {
      let today = getToday()
      let room = this.activeSession.room?.filter(room => {
        if (room.meta_chat?.start_time) {
          let date = getDateFormatStr(room.meta_chat.start_time)
          if (date === today) {
            return true
          }
        }
      }).find(room => Number(room.id) === Number(this.todayMeeting?.entity_id))
      if (!room) return null;
      room['start_time_timezone'] = getDateWithSomeTimeZone(room.meta_chat.start_time, room.meta_chat.timezone)
      room['start_end_timezone'] = addMinutesToDate(room.start_time_timezone, room.meta_chat.duration)
      return room
    },
    is_active_room() {
      if (!this.get_today_room) return false
      let now_date = getDateObj(new Date())
      let room_end = getDateObj(this.get_today_room.start_end_timezone)
      return `${room_end.hours}${room_end.minutes}` > `${now_date.hours}${now_date.minutes}`
    },
    get_user_meeting_start_time() {
      if (!this.get_today_room) return ''
      return getDateFormatStrToShow(this.get_today_room.start_time_timezone, '-', true)
    },
  },
  created() {
    const isMeet = window.localStorage.getItem("isMeetingShown");
    this.meeting_shown = isMeet !== 'false';
  },
  mounted() {
    this.fetchHistory();
    this.load();
  },
  methods: {
    ...mapActions([
      'joinSession',
      'fetchSessions',
    ]),
    fetchHistory(){
      axiosService.post('api/v1/meetings/history').then(data => {
        if(data.data.list){
          this.historyData = data.data.list.map(e => ({
            message: ` moved user to room ${e.room.room_name}`,
            session_name: e.session?.session_location,
            actor: e.user.firstName + ' ' + e.user.lastName,
          }));
        }
        this.historyLoaded = true;
      }).catch(data => {
        console.error(data);
        this.historyData = data.status;
        this.historyLoaded = true;
      })
    },
    joinSessionUrl($url){
      Browser.open({url: $url})
    },
    resetShownMeeting(){
      this.meeting_shown = !this.meeting_shown;
      window.localStorage.setItem('isMeetingShown', this.meeting_shown ? 'true' : 'false')
    },
    resetShown() {
      this.description_shown = !this.description_shown;
      window.localStorage.setItem('isDescriptionShown', this.description_shown ? 'true' : 'false')
    },
    openMeeting() {
      var strWindowFeatures = "location=yes,height=600,width=720,scrollbars=yes,status=yes";
      if (this.active_session[0] && this.active_session[0].url) {
        window.open(this.active_session[0].url, "_blank", strWindowFeatures)
      }
    },
    findMeetingToActivate(id) {
      return this.active_session.find(entity => entity.id === id)
    },
    reload(session_id) {
      axiosService.post('/api/v1/sessions/part/rooms', {session_id}).then(e => {
        this.usersFiltered = this.activeSession.users?.map(user => {
              const meeting_entity = e.data.users.find(e => e.user_id === user.id)
              const entity = Object.assign({}, user, {
                status: meeting_entity == null ? 'new' : 'connected',
              })
              const roomId = e.data.meetings.find(item => -1 !== item.users.findIndex(u => u.id === user.id))

              if (!roomId?.room_key) entity.room = null;
              else {
                entity.secondaryName = meeting_entity ? meeting_entity.user_name : null

                entity.room = this.active_session.find(item => item.id === roomId.room_key).topic

              }
              return entity;
            }
        )
        this.key++;
      })
    },
    swapPast() {
      this.pastOpen = !this.pastOpen
    },
    isPast(date) {
      return moment().diff(date) > 0
    },

    load(){
      this.fetchSessions({date: new Date()}).then(
          () => this.getActiveSession()
      )
    },
    getActiveSession() {
      return axiosService.post('api/v1/sessions/part/activeSession', {session: this.activeSession.session_id}).then(e => {
        this.active_session = e.data.list
        this.reload(this.activeSession.session_id)
      })
    },
    joinSessionById(id) {
      this.joinSession(id)
          .then(() => {
            this.fetchSessions({date: new Date()})
          })
    },
    trySend() {
      if (this.$wschannels !== null && this.$wschannels.chat !== null) {
        this.$wschannels.chat.send('send_' + this.chatEntity.msgBox)
        this.chatEntity.messages.push({user: this.dn, message: this.chatEntity.msgBox, left: true})
      }
    },
    openTest() {
      axiosService.post('/api/v1/user/session/connect').then(e => {
        Browser.open({url: e.data.url})
      });
    },
  }
}
</script>

<style scoped lang="scss">
.title-meetings {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding-right: 2rem;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border: #00d1b2 .5px solid;
  padding-left: 1rem;
}

.session-tab .tab-component.tab-component_details {
  background-color: white;
  margin-right: .25rem;

}

.select.is-primary {
  padding-bottom: 0px;
  border: 1px solid #dbdbdb;
  margin-left: 1.5rem;
  padding-top: .75rem;

  width: 100%;
  display: block;
  position: relative;
  max-width: 600px;

  &::after {
    right: 1.125em;
    z-index: 4;
    top: calc(50%);
  }
}

.history-item{
  margin-bottom: .75rem;
  color: #3d3d3dc2;
}

.tab_component_wrap .session-tab .tab-component.tab-component_list {
  margin-left: 0 !important;
}

.session-tab {

  background-color: white;
  padding-top: 20px;
  padding-bottom: 20px;
}

.white-description {
  margin-left: 1.5rem;
  background: white;
  border: 1px solid #ed8b00;
  padding: 1rem 1.5rem;
}

.tab-component_details
.block-active-meeting {
  display: block;
  margin-left: 1.5rem;
  cursor: pointer;
  background: white;
  border: 1px solid #ed8b00;
  padding: 1rem 1.5rem;
  &:hover {
    background-color: #fafafa;
  }
}

</style>
