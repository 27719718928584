<template>
  <div id="wrapper">
    <img id="logo" src="/src/renderer/assets/logo.bmp" alt="electron-vue">
    <main>
      <div class="left-side">
        <span class="title">
          Laravel zoom!
        </span>
      </div>

      <div class="right-side">
        <div class="doc">
          <div class="title">Getting Started</div>
          <p>
            Laravel zoom application
          </p>
          <button @click="open('https://simulatedgreg.gitbooks.io/electron-vue/content/')">Read the Docs</button><br><br>
        </div>
        <div class="doc">
          <div class="title alt">Other Documentation</div>
          <button class="alt" @click="open('https://electron.atom.io/docs/')">Electron</button>
          <button class="alt" @click="open('https://vuejs.org/v2/guide/')">Vue.js</button>
        </div>
      </div>
    </main>
  </div>
</template>

<script>

  import { Browser } from '@capacitor/browser';
export default {
    name: 'landing-page',
    methods: {
      async open (link) {
        await Browser.open({ url: link })
      }
    }
  }
</script>
