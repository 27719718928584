<template>
  <button :class="{'is-small': small}" class="button  is-outlined is-dark is-primary p-0" style="font-size: 1.45rem; height: inherit;"
          @click="$emit('input', !value )">
    <circle-icon-ok v-if="value"/>
    <circle-icon-not-ok v-else/>
  </button>
</template>

<script>
import CircleIconOk from 'vue-ionicons/dist/ios-checkmark-circle'
import CircleIconNotOk from 'vue-ionicons/dist/ios-close-circle'

export default {
  name: "ToggleButton",
  components: {
    CircleIconOk,
    CircleIconNotOk
  },
  props: {
    value: {
      type: Boolean
    },
    small: {
      type: Boolean,
      default: true
    }
  }
}
</script>
