<template>
  <div>
    <tabs ref="tabs">
      <tab
        name="List"
        tab_img_href="/images/quiz-list.svg">
        <default-table
          change_location
          response_key_list="tests"
          active_table
          fetch_url="api/v1/quiz/list"
          :update_outer="update_outer"
          :filter_fields_names="filter_fields_names"
          :hide_outer_after_column="test"
          :fields_name="fields_name">
          <template slot="id" slot-scope="props">
            {{ props.id }}
          </template>
          <template slot="title" slot-scope="props">
            {{ props.title }}
          </template>
          <template slot="description" slot-scope="props">
            {{ props.description }}
          </template>
          <template slot="questions" slot-scope="props">
            {{ props.questions || 0 }}
          </template>
          <template slot="pass_rate" slot-scope="props">
            <quiz-pass-rate
              :is_main_test="!!(props.meta_value && props.meta_value.main)"
              :pass_rate="props.pass_rate"
            />
          </template>
          <template slot="language_id" slot-scope="props">
            <template v-if="props.meta_value">
              {{ getLanguageById(props.meta_value.language_id) }}
            </template>
          </template>
          <template slot="public" slot-scope="props">
            {{ props.public ? '✔' : '' }}
          </template>
          <template slot="openFrom" slot-scope="props">
            {{ dateFormat(props.openFrom) }}
          </template>
          <div
            slot="row_after" class="field is-grouped" slot-scope="props">
            <tooltip
              tooltip_bg="#48c78e"
              text="Edit quiz info"
              is_inline>
              <button class="button is-success" @click="editQuiz(props)">
                <edit-icon/>
              </button>
            </tooltip>
            <tooltip
              is_inline
              tooltip_bg="#485fc7"
              text="Edit questions">
              <default-button
                class="is-link button ml-10"
                :router_obj="{name: 'editQuiz', params: {mid: props.id}}">
                <configure/>
              </default-button>
            </tooltip>
            <tooltip
              text="Show statistics"
              tooltip_bg="#ffe08a"
              tooltip_color="#946c00"
              is_inline>
              <button class="button is-warning ml-10"
                      @click="statisticQuiz(props)">
                <statistic-icon color="#946c00"/>
              </button>
            </tooltip>
          </div>
        </default-table>
      </tab>
      <tab
        style_fix
        name="Create new"
        tab_img_href="/images/add-new.svg">
        <edit-quiz
          :text_success="text_success"
          :text_error="text_error"
          @submitted="storeTestInfo"
          :new_quiz="new_quiz"
          show_btn
        />
      </tab>
    </tabs>
    <edit-quiz-modal
      :quiz="edit_quiz"
      @updated="editList"
      @closed="editQuiz()"
    />
    <quiz-statistic-modal
      :quiz="statistic_quiz"
      @closed="statisticQuiz()"
    />
  </div>
</template>

<script>
import axiosService, { DEFAULT_ERROR_REPS_TEXT } from '../../plugins/axiosService'
import Tabs from '__module_list/tabs/Tabs.vue'
import Tab from '__module_list/tabs/Tab.vue'
import DefaultInput from '../small-components/DefaultInput'
import DefaultForm from '../small-components/DefaultForm'
import input_validations from '../../helpers/input_validations'
import DefaultTable from '../../../module_list/default_table/DefaultTable'
import DefaultButton from '../small-components/DefaultButton'
import EditQuizModal from '../modals/EditQuizModal'
import EditQuiz from './EditQuiz'
import Configure from '../small-components/icons/Configure'
import EditIcon from '../small-components/icons/EditIcon'
import StatisticIcon from '../small-components/icons/StatisticIcon'
import QuizStatisticModal from '../modals/QuizStatisticModal'
import Tooltip from '../../../module_list/tooltip/Tooltip'
import { mapGetters } from 'vuex'
import QuizPassRate from '../blocks/QuizPassRate'
import TrainButtons from '__module_list/TrainButtons'

export default {
  name: 'List',
  components: {
    TrainButtons,
    QuizPassRate,
    Tooltip,
    QuizStatisticModal,
    StatisticIcon,
    EditIcon,
    Configure,
    EditQuiz,
    EditQuizModal,
    DefaultButton,
    DefaultTable,
    DefaultForm,
    DefaultInput,
    Tabs,
    Tab
  },
  mixins: [input_validations],
  data () {
    return {
      test: false,
      dev: [],
      new_quiz: {
        id: -1,
        title: 'New test',
        questions: 0,
        description: '',
        public: 0,
        available: 0,
        pass_rate: 0,
        openFrom: '',
        language_id: 0,
        main: 0
      },
      tab_selected: '',

      update_outer: null,

      edit_quiz: null,
      statistic_quiz: null,
      text_success: '',
      text_error: '',
    }
  },
  computed: {
    ...mapGetters([
      'getLanguageById',
    ]),
    fields_name () {
      return {
        title: 'Title',
        description: 'Description',
        questions: 'Questions',
        pass_rate: 'Pass rate',
        openFrom: 'Available from',
        language_id: 'Language',
      }
    },
    filter_fields_names () {
      return {
        id: 'Language',
        main: 'Quiz type',
        public: 'Quiz visibility',
      }
    }
  },
  methods: {
    dateFormat (date_format) {
      if (!date_format) return ''
      let date = date_format.split(' ')[0]
      return date || ''
    },
    edit (mid) {
      this.$router.push({
        name: 'editQuiz',
        params: { mid }
      })
    },
    editList (obj) {
      this.update_outer = obj
    },
    editQuiz (props = null) {
      this.edit_quiz = JSON.parse(JSON.stringify(props))
    },
    statisticQuiz (obg = null) {
      this.statistic_quiz = obg
    },
    storeTestInfo (new_quiz) {
      delete new_quiz['id']
      this.text_success = ''
      this.text_error = ''
      axiosService.post('/api/v1/quiz/edit', new_quiz)
        .then(result => {
          this.dev.push(result.data)
          this.resetQuiz()
          this.$refs.tabs.changeTabByName('List')
        })
        .catch(() => {
          this.text_error = DEFAULT_ERROR_REPS_TEXT
        })
    },
    resetQuiz () {
      this.new_quiz.title = 'New test'
      this.new_quiz.questions = 0
      this.new_quiz.description = ''
      this.new_quiz.public = 0
      this.new_quiz.available = 0
      this.new_quiz.openFrom = ''
      this.new_quiz.pass_rate = 0
    },
  }
}
</script>
