<template>
  <div class="field default_input"
       :class="{'validate_inputs': required}">
    <label class="default_input-label">
      <textarea
        v-if="input_textarea"
        ref="input"
        class="input"
        :class="input_classes"
        :name="name"
        v-html="value"
        :disabled="disabled"
        :readonly="read_only"
        @change="$emit('change', $event)"
        @keydown="$emit('keydown', $event)"
        @keypress="$emit('keypress', $event)"
        :rows="input_textarea_rows"/>
      <input
        v-else
        ref="input"
        @focusout="$emit('focusout', $event)"
        class="input"
        :class="input_classes"
        :name="name"
        :value="value"
        :disabled="disabled"
        :readonly="read_only"
        :min="min"
        :max="max"
        :step="step"
        @change="$emit('change', $event)"
        @keydown="$emit('keydown', $event)"
        @keypress="$emit('keypress', $event)"
        :type="local_type"/>
      <span v-if="label_text" class="default_input-placeholder">{{ label_text }}</span>
      <span v-if="type_password"
            class="default_input-icon"
            @touch="changeInputType"
            @click="changeInputType">
        <ios-eye-icon v-if="local_type_password"/>
        <ios-eye-off-icon v-else/>
      </span>
      <span
        v-if="error_text"
        class="default_input-error_text ">
        {{ error_text }}
      </span>
      <span
        v-if="input_information"
        class="default_input-error_text info_text">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
             viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
            <path
              d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
          </svg>
        {{ input_information }}
      </span>
    </label>
  </div>
</template>


<script>

import IosEyeIcon from 'vue-ionicons/dist/ios-eye.vue'
import IosEyeOffIcon from 'vue-ionicons/dist/ios-eye-off.vue'

export default {
  name: 'DefaultInput',
  components: {
    IosEyeIcon,
    IosEyeOffIcon
  },
  props: {
    name: {
      type: String,
      default: 'name'
    },
    placeholder: {
      type: String,
      default: 'Name'
    },
    type: {
      type: String,
      default: 'text'
    },
    value: {
      type: [String, Number],
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    valid: {
      type: Boolean,
      default: true
    },
    compare: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    read_only: {
      type: Boolean,
      default: false
    },
    input_information: {
      type: String,
      default: ''
    },
    input_textarea: {
      type: Boolean,
      default: false
    },
    input_textarea_rows: {
      type: String,
      default: '1'
    },
    min: {
      default: false
    },
    max: {
      default: false
    },
    step: {
      type: [String, Number],
      default: 1
    },
    validate_f: {
      type: Function,
      default: (val) => {
        let valid = false
        if (!isNaN(parseInt(val)) || (!!(val && val.length > 0))) {
          valid = true
        }
        return {
          valid: valid,
          error_text: !valid ? 'This field can\'t be empty' : ''
        }
      }
    },
  },
  data () {
    return {
      local_type: this.type,
      error_text: ''
    }
  },
  computed: {
    type_password () {
      return this.type === 'password'
    },
    is_valid () {
      return this.valid && !this.error_text
    },
    local_type_password () {
      return this.local_type === 'password'
    },
    input_classes () {
      return {
        'is-danger': !this.is_valid,
        not_empty: !!this.value || this.type === 'date' || this.value === 0
      }
    },
    label_text () {
      return this.label || this.placeholder
    }
  },
  mounted () {
    this.$refs.input.addEventListener('input', this.input)
  },
  beforeDestroy () {
    this.$refs.input.removeEventListener('input', this.input)
  },
  methods: {
    input (e) {
      this.$emit('input', e.target.value)
      this.$emit('input_event', e)
      this.error_text = ''
    },
    updateValid (value) {
      if (!this.required) return
      const validation = this.validate_f(value, this.label_text, this.compare)
      this.$emit('update:valid', validation.valid)
      this.error_text = validation.error_text || ''
    },
    changeInputType () {
      this.local_type = this.local_type_password ? 'text' : 'password'
    }
  },

}
</script>
