<template>
  <draggable :key="value.id" class="draggable-user-item">
  <div class="user">
    <div class="icon">
      <svg class="circle-icon" :class="value.status && value.status === 'new' ? 'gray' : 'green'"
           viewBox="0 0 24 24" version="1.1"
           xmlns="http://www.w3.org/2000/svg">
        <circle cx="12" cy="12" r="10"/>
      </svg>
    </div>
    <div class="name">
      {{ value.firstName + " " + value.lastName }}
    </div>
    <div class="last-meeting">
      <template v-if="value.sessions&& value.sessions.find(e => e.activity === 1)">
        {{ value.last_connection != undefined &&  value.last_connection.length > 0  ? value.last_connection.find(e => true).meeting.room_name : "new user" }}
      </template>
    </div>
    <div class="languages">

    </div>
  </div>
  </draggable>
</template>

<script>
import {Draggable} from "vue-smooth-dnd";
export default {
  name: "UserListEntity",
  components: {Draggable},
  props: {
    value: {}
  }
}
</script>

<style scoped>
  .circle-icon.gray{
    stroke: #1a202c;
  }
  .circle-icon.green{
    stroke: #42b983;
  }
</style>
