<template>
  <div class="search_select">
    <slim-select
      class="search_select-select"
      clearable
      :class="{has_error: field_required}"
      placeholder="Search by"
      :selected_index="selected_index"
      :values="search_by_list"
      @selected="selectedSearchBy"
    />
    <input type="text"
           class="search_select-input"
           @keyup.enter="initSearch"
           :value="search_value"
           @input="searchVal">
    <div
      @click="initSearch"
      class="search_select-btn">
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
      </svg>
    </div>
  </div>
</template>

<script>

import SlimSelect from '../slim_select/SlimSelect'
export default {
  name: 'SearchSelect',
  components: { SlimSelect },
  props: {
    search_by_list: {
      type: Array,
      default: () => []
    },
    selected_index: {
      type: Number,
      default: -1
    },
    search_val: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      field_required: false
    }
  },
  computed: {
    selected_by() {
      return this.search_by_list[this.selected_index]
    },
    search_value(){
      return decodeURIComponent(this.search_val)
    }
  },
  methods: {
    searchVal(event) {
      // prevent users special characters stored in URL
      this.$emit('searchVal', encodeURIComponent(event.target.value))
    },
    selectedSearchBy(val) {
      this.$emit('selectedSearchBy', this.search_by_list.indexOf(val))
    },
    initSearchByError() {
      this.field_required = true
    },
    initSearch() {
      this.field_required = false
      if (!this.selected_by) {
        this.initSearchByError()
      } else {
        this.$emit('search', false)
      }
    }
  }
}
</script>
