<template>
  <div class="schedule">
    <div class="list-wrapper">
      <div class="list-item" v-for="item in sorted">
        <div class="room-name">
          <span class="pr-10 mr-10">
            {{ item.topic }}
          </span>
          <span v-if="isCurrentDate(item)" class="ml-8">
            <button class="button is-small" @click="openUrl(item)">Open room</button>
          </span>
        </div>
        <div class="room-scheduled">
          <span class="room-date">{{getDate(item)}}</span>
          <span class="room-start">starts at {{getStartTime(item)}}</span>
          <span class="duration"> for {{ getDuration(item) }}</span>
        </div>
      </div>
      <div class="users">
        <div class="cards_item">
          <div class="cards_item-header">
            <span>Users connected</span>
            <default-button @click="sendUpdateRequest()"
              class="is-warning cards_item-header_link">
              <svg color="rgba(0,0,0,.7)" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M13.5 2c-5.288 0-9.649 3.914-10.377 9h-3.123l4 5.917 4-5.917h-2.847c.711-3.972 4.174-7 8.347-7 4.687 0 8.5 3.813 8.5 8.5s-3.813 8.5-8.5 8.5c-3.015 0-5.662-1.583-7.171-3.957l-1.2 1.775c1.916 2.536 4.948 4.182 8.371 4.182 5.797 0 10.5-4.702 10.5-10.5s-4.703-10.5-10.5-10.5z"/></svg>            </default-button>
          </div>
          <default-table
            :fields_name="{created_at: 'created', test: 'test title' }"
            :items="users">
            <template slot="id" slot-scope="props">
              {{ props.id }}
            </template>
            <template slot="Name" slot-scope="props">
              {{ props.user_name }}
            </template>
            <template slot="Role" slot-scope="props">
              <a class="button is-link is-small">{{props.role}}</a>
<!--              <badge-->
<!--                v-if="props.asks"-->
<!--                :show_rate="!!(props.quiz_meta && props.quiz_meta.main)"-->
<!--                :rate_percent="props.score"-->
<!--                :error="props.asks.pass_rate > props.score"-->
<!--                :text="props.asks.pass_rate > props.score  ? 'test fail' : 'Success'"/>-->
            </template>
            <template slot="Status" slot-scope="props">
              Connected
            </template>
            <template slot="Room">
              Second room
            </template>
          </default-table>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import moment from 'moment'
import DefaultButton from "../small-components/DefaultButton";
import ArrowRight from "../small-components/icons/ArrowRight";
import EditIcon from "../small-components/icons/EditIcon";
import DefaultTable from "../../../module_list/default_table/DefaultTable";
import axiosService from "../../plugins/axiosService";
export default {
name: "Timetable",
  components: {DefaultTable, ArrowRight, DefaultButton, EditIcon},
  data: () => ({
    user_sessions:[
      {
        id:	5,
        account_id:	"16780288",
        user_zoom_id:	0,
        user_name:	"Konstantin Dzhanov",
        user_email:	"kdzhanov@gmail.com",
        meeting_uid:	"m3Xr0fM9Rr+hz0yaSXojQw==",
        meeting_id:	2147483647,
        user_id:	51,
        additional:	"",
        created_at:	"2021-12-10T15:08:40.000000Z",
        updated_at:	"2021-12-10T15:08:40.000000Z",
      }
    ]
  }
  ),
  props: {
    value: {
      default: []
    },
    users: {
      default: []
    }
  },
  created() {
    console.log(this.value)
  },
  computed: {
    sorted(){
      console.log(this.value)
      return this.value.filter(item => item.meta_chat).map(i => {
        return Object.assign({}, i, {topic: i.meta_chat.topic, status: i.meta_chat.status, start_time: i.meta_chat.start_time, start_url: i.meta_chat.start_url,join_url: i.meta_chat.join_url, id: i.meta_chat.id, duration: i.meta_chat.duration } )
      })
    }
  },
  methods: {
    sendUpdateRequest() {
      axiosService.post('/api/v1/admin/zoom/get').then(e => {
        this.debug = e.data
      })
    },
    isCurrentDate(item){
      const time = new Date(item.meta_chat.start_time).toISOString(true);
      const now = new Date()
      return time.substring(0,10) === now.toISOString().substring(0,10)

    },
    openUrl(item){
      var strWindowFeatures = "location=yes,height=600,width=720,scrollbars=yes,status=yes";
      window.open(item.join_url, "_blank", strWindowFeatures)
    },
    getDate(item){
      const time = moment(item.start_time).toISOString(true);
      return time.substring(0,10)
    },
    getStartTime(item){
      const time = moment(item.meta_chat.start_time).toISOString(true);
      return time.substring(11,16)
    },
    getDuration(item){
      return moment.duration(item.duration * 60 * 1000).humanize()
    }
  },
  watch: {
    users(nval){
      console.log(nval);
    }
  }
}
</script>

<style scoped>
  .users{
    border-bottom: 1px solid black;
    border-top: 1px solid black;
    margin: 2rem 0;
    padding: 1rem 0;
  }
  .room-name{
    padding: 0.3rem 1rem 1rem;
    font-size: 1.35rem;
    font-weight: 600;
  }
  .list-item{
    border-top: solid #e0f2fd 1px;
    border-bottom: solid white 1px;
    margin-top:3px;
    margin-bottom: 3px;
    background-color: #f3f4f6;
    padding: .5rem .25rem;
  }
  .duration{
    color: #6c6c6c;
    font-size: 1rem;
    padding: 0rem .5rem;
  }
  .room-date{
    color: #3c3c3c;
    font-size: 1rem;
    padding: 0rem .5rem;
  }
</style>
