<template>
  <div class="default_table">
    <table-filters
      :filters="filters"
      :filter_fields_names="filter_fields_names"
      :selected_filters="selected_filters"
      @filter_update="filterFetch"
      :search_fields="search_by_list"
      :search_val="search_val"
      :search_by_index="search_by_index"
      :selected_order_by="!empty_sort"
      :filter_query_outer="filter_query_outer"
      :has_selected_filers_or_search="has_selected_filers_or_search"
      @search="filterFetch"
      @searchVal="updateSearchVal"
      @selectedSearchBy="selectedSearchBy"
      @clear="clearFilters"
    />
    <empty-search
      v-if="!sorted_items.length"
      :class="{loading: loading}"
      :empty_text="empty_list_text"
      :show_sub_title="has_selected_filers_or_search"/>
    <div
      v-else
      :class="{paginated: has_pagination}"
      class="default_table-list">
      <table>
        <table-head
          :sortable_field="sortable_fields"
          :sort_by="sort_by"
          :fields_name="fields_name"
          @sort="setSortData"
          :custom_fields="custom_fields"
          @input:custom_fields="e => $emit('input:custom_fields', e)"
          @custom-action="e => $emit('custom-action', e)"
          :t_head_values="slot_columns_list"
          :column_before="has_slot_before"
          :column_after="has_slot_after"/>
        <tbody>
        <template>
          <template v-for="(item_line, index) in sorted_items">
            <tr class="default_table-list_item"
              ref="table_item"
              :data-index="index"
              :key="`table_item_${index}`">
              <td v-show="has_slot_before">
                <slot :name="slot_before_column_name" v-bind="item_line"></slot>
              </td>
              <td
                class="default_table-list_item-column"
                v-for="(column, column_i) in slot_columns_list"
                :key="`table_item_${index}_column_${column_i}`"
              >
                <slot
                  :name="column"
                  :__col_index="index"
                  v-bind="item_line">
                </slot>
              </td>
              <td v-show="has_slot_after">
                <slot :name="slot_after_column_name" v-bind="item_line"></slot>
              </td>
            </tr>
            <tr v-if="has_collapse_line">
              <td colspan="100" class="collapse_line">
                <slide-up-down
                  :active="collapse_line === index">
                  <slot :name="collapse_line_name" v-bind="item_line"></slot>
                </slide-up-down>
              </td>
            </tr>
          </template>
        </template>
        </tbody>
      </table>
    </div>
    <laravel-pagination
      v-if="pagination_data"
      :pagination_data="pagination_data"
      :pagination_obj="pagination_obj"
      :selected_per_page="pagination_data.per_page"
      @change_page="paginateData"
      @per_page="changePerPage"
      class="default_table_pagination"
    />
    <loader class="default_table-loader" v-if="loading"/>
  </div>
</template>

<script>
import TableHead from './TableHead'
import TableBody from './TableBody'
import FetchMixin from './mixins/FetchMixin'
import TableFilters from '../table_filters/TableFilters'
import SlideUpDown from '__module_list/slide-up-down/SlideUpDown'
import LaravelPagination from '__module_list/laravel_pagination/LaravelPagination'
import Loader from '__module_list/loader/Loader'
import EmptySearch from './EmptySearch'

export default {
  name: 'DefaultTable',
  mixins: [FetchMixin],
  components: {
    EmptySearch,
    TableFilters,
    TableBody,
    TableHead,
    Loader,
    LaravelPagination,
    SlideUpDown,
  },
  props: {
    custom_fields: {
      type: Object,
      default: () => ({})
    },
    uniq_table_name: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: () => []
    },
    collapse_line: {
      type: Number,
      default: -1
    },
    fields_name: {
      type: Object,
      default: () => ({})
    },
    filter_fields_names: {
      type: Object,
      default: () => ({})
    },
    hide_outer_pre_column: {
      type: Boolean,
      default: false
    },
    hide_outer_after_column: {
      type: Boolean,
      default: false
    },
    empty_list_text: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      opened_collapse: -1,
    }
  },
  computed: {
    sorted_items () {
      if (this.local_items.length) {
        return this.local_items
      }
      return this.filtered_items
    },
    filtered_items () {
      return this.items
    },
    slot_columns_list () {
      return this.columns.filter(el => !this.technical_slots.includes(el))
    },
    has_slot_after () {
      return this.columns.some(el => el === this.slot_after_column_name)
    },
    has_slot_before () {
      return this.columns.some(el => el === this.slot_before_column_name)
    },
    has_collapse_line () {
      return this.columns.some(el => el === this.collapse_line_name)
    },
    technical_slots() {
      return [
        this.slot_after_column_name,
        this.slot_before_column_name,
        this.collapse_line_name
      ]
    },
    slot_before_column_name () {
      return 'row_before'
    },
    slot_after_column_name () {
      return 'row_after'
    },
    collapse_line_name () {
      return 'collapse_line'
    },
    columns () {
      return Object.keys(this.$scopedSlots) || []
    }
  },
  methods: {
    slotColumns_listEl (el) {
      let result = {}
      let parts = el.split('.')
      let target = result
      while (parts.length > 0) {
        let part = parts.shift()
        target = target[part] = target[part] || {}
      }
      return result
    },
  },
}
</script>
