<template>
  <default-form
    @submit="updateCreateUser"
    :success_text="text_success"
    :error_text="text_error"
    class="tab-form edit-quiz">
    <div class="row">
      <div class="col-12 col-sm-6">
        <default-input
          placeholder="Name"
          required
          v-model="local_user.name"
          name="name"
        />
      </div>
      <div class="col-12 col-sm-6">
        <default-input
          placeholder="Last name"
          required
          v-model="local_user.last_name"
          name="last_name"
        />
      </div>
      <div class="col-12 col-sm-6">
        <default-input
          placeholder="Email"
          required
          type="email"
          v-model="local_user.email"
          name="email"
        />
      </div>
      <div class="col-12 col-sm-6">
        <default-input
          placeholder="Phone"
          required
          type="phone"
          v-model="local_user.phone"
          name="phone"
        />
      </div>
      <div class="col-12 col-sm-6">
        <default-input
          placeholder="New password"
          :required="!local_user.id"
          type="password"
          v-model="local_user.password"
          name="password"
          :valid.sync="password_valid"
          :validate_f="validatePassword"
        />
      </div>
      <div class="col-12 col-sm-6">
        <slim-select
          :error_text="show_error && !local_user.role ? 'Choose user role' : ''"
          :values="users_roles"
          :selected_index="role_id"
          obj_value_name="role"
          empty_option_text="User roles"
          @selected="changeUserRole"
        />
      </div>
    </div>
    <div class="mb-10">
      <button class="button is-link is-outlined" @click="show_error = true">Save</button>
    </div>
  </default-form>
</template>

<script>
import DefaultForm from '../small-components/DefaultForm'
import SlimSelect from '__module_list/slim_select/SlimSelect'
import DefaultInput from '../small-components/DefaultInput'
import CustomCheckbox from '__module_list/custom-checkbox/CustomCheckbox'
import input_validations from '../../helpers/input_validations'
import axiosService from '../../plugins/axiosService'
export default {
  name: 'EditUserForm',
  mixins: [input_validations],
  components: { CustomCheckbox, DefaultInput, SlimSelect, DefaultForm },
  computed: {
    role_id() {
      return this.users_roles.findIndex(role => role === this.local_user.role)
    }
  },
  props: {
    user: {
      type: Object,
      default: null
    },
    users_roles: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      text_success: '',
      text_error: '',
      password_valid: true,
      show_error: false,
      local_user: {
        id: 0,
        name: '',
        last_name: '',
        phone: '',
        email: '',
        password: '',
        role: '',
        role_id: '',
      }
    }
  },
  mounted() {
    if (!this.user) return
    this.local_user.id = this.user.id
    this.local_user.name = this.user.firstName
    this.local_user.last_name = this.user.lastName
    this.local_user.email = this.user.email
    this.local_user.phone = this.user.phone
    this.local_user.role = this.user.role
  },
  methods: {
    updateCreateUser() {
      this.show_error = true
      this.text_success = ''
      this.text_error = ''
      if (!this.local_user.role) return
      axiosService.post('/api/v1/user/update-by-id', this.local_user)
        .then(r => {
          this.text_success = r.data.status
        })
        .catch(e => {
          this.text_error = e.response?.data?.error || 'Something went wrong'
        });
    },
    changeUserRole(role) {
      this.show_error = !Boolean(role)
      this.local_user.role = role
    }
  }
}
</script>
