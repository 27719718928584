<template>
  <label
    class="custom-checkbox"
    :class="classes"
    >
    <input
      @change="$emit('change', $event)"
      :name="name"
      :type="type"
      :checked="checked">
    <span class="custom-checkbox_checkmark"></span>
    <span
      class="custom-checkbox_label">
      <slot></slot>
    </span>
  </label>
</template>

<script>
export default {
  name: 'CustomCheckbox',
  props: {
    name: {
      type: String,
      required: true
    },
    text: {
      type: String,
      default: ''
    },
    checked: {
      type: Boolean,
      default: false
    },
    is_radio: {
      type: Boolean,
      default: false
    },
    bordered: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    type() {
      return this.is_radio ? 'radio' : 'checkbox'
    },
    classes() {
      return {
        'custom-checkbox': this.is_radio,
        'bordered': this.bordered
      }
    }
  },
}
</script>
