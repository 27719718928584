<template>
  <div class="position-relative">
    <div class="icon-block" @click="onClick">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
           :fill="{'none': !active, '#000': active}" stroke="#000" stroke-width="2" stroke-linecap="round"
           stroke-linejoin="round">
        <polygon points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3"/>
      </svg>
    </div>
    <div class="select-window" :class="{'active': active}">
      <div class="option" v-for="o in optionsData">
        <div class="wrapper">
          <label>
            <input type="checkbox" :checked="o.value" @click.prevent="onSelect(o.label)"/>
          </label>
          <span class="wrap-text-select">{{ o.label }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DefaultFilter",
  data: () => ({active: false}),
  props: {
    filterOptions: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    optionsData() {
      return this.$props.filterOptions.available.map(av => {
        return {label: av, value: this.$props.filterOptions.filter.findIndex(fi => fi === av) !== -1}
      })
    }
  },
  methods: {
    onClick() {
      this.active = !this.active
    },
    onSelect(option) {
      const a = this.$props.filterOptions.filter.findIndex(f => f === option)
      const filter = this.$props.filterOptions.filter;
      if (a !== -1) {
        filter.splice(a, 1);
      } else {
        filter.push(option);
      }
      const ret = Object.assign({}, this.$props.filterOptions, {filter: filter})
      this.$emit('input:filterOptions', ret)

    }

  }
}
</script>

<style scoped>
.select-window {
  display: none;
}

.wrap-text-select {
  padding-left: .5rem;
  font-weight: 600;
}

.select-window.active {
  display: block;
  width: 250px;
  position: fixed;
  background: white;
  border: 1px #0008 solid;
  padding: .5rem 1rem;
  border-radius: 4px;
  box-shadow: #0008 7px 9px 19px 1px;
}
</style>
