<template>
  <div>
    <default-table
      :items="sessions">
      <template slot="id" slot-scope="props">
        {{props.session_id}}
      </template>
      <template slot="session_location" slot-scope="props">
        {{props.session ? props.session.session_location : '-'}}
      </template>
      <template slot="user" slot-scope="props">
        <template v-if="props.user">
          {{props.user.lastName}} {{props.user.firstName}}
        </template>
      </template>
      <template slot="email" slot-scope="props">
        {{props.user ? props.user.email : ''}}
      </template>
      <template slot="status" slot-scope="props">
        <sessions-buttons-status
          @click="(st) => updateStatus(props, st)"
          :approved="props.approved"
          :buttons_range="[0, 1]"
        />
      </template>
      <template slot="created_at" slot-scope="props">
        {{getDateFormatStrToShow(props.created_at)}}
      </template>
    </default-table>
  </div>
</template>

<script>
import axiosService from "../../../../../plugins/axiosService";
import DefaultTable from '__module_list/default_table/DefaultTable'
import dateMixin from '../../../../../mixins/dateMixin'
import SessionsButtonsStatus from '../../../../blocks/SessionsButtonsStatus'
import { mapActions } from 'vuex'
import { STATUS_ERROR } from '../../../../../helpers/httpCatcher'
import UserMixin from '../../../../../mixins/UserMixin'

export default {
  name: "ApproveUserToSessions",
  components: { SessionsButtonsStatus, DefaultTable },
  mixins: [dateMixin, UserMixin],
  data() {
    return {
      sessions: []
    }
  },
  created() {
    this.getSessions()
  },
  methods: {
    ...mapActions([
      'actionPushNotification'
    ]),
    getSessions() {
      axiosService.post('/api/v1/sessions/admin/pending')
        .then(r => {
          this.sessions = r.data.sessions ?? []
        })
    },
    updateStatus(sessions, status) {
      axiosService.post(
        '/api/v1/sessions/admin/update',
        {
          id: sessions.user_id,
          sessions: [
            {
              id: sessions.id,
              approved: status,
              activity: sessions.activity,
            }
          ]
        })
        .then(() => {
          let el = this.sessions.find(item => item.id === sessions.id)
          if (el) {
            el.approved = status
            this.actionPushNotification({
              message: `User status in sessions is changed to: ${this.sessionStatusText(status)}`
            })
          }
        })
        .catch(e => {
          if(e.response && e.response.status && e.response.status === 422){
            this.getSessions()
          }
          this.actionPushNotification({
            message: `Error cant update user status`,
            status: STATUS_ERROR
          })
          console.log([e])
        })
    }
  }
}
</script>
