<template>
  <div class="card user-list-card">
    <h2>User list</h2>
    <div class="users pb-12">
      <table class="user-entity">
        <thead>
          <tr>
            <th class="header">Username</th>
            <th class="header">Name</th>
            <th class="header">Active session</th>
            <th class="header">Cv</th>
            <th class="header">Role </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="user in getUsers">
            <td>
              <span class="centred-cell">
              {{user.email}}
                </span>
            </td>
            <td>
              <span class="centred-cell">
                {{camelize(user.firstName) + ' ' + camelize(user.lastName)}}</span>
            </td>
            <td>
              <span class="centred-cell" v-if="user.session === -1">
                not connected
              </span>
              <template v-else>
                <div class="field has-addons">
                  <div class="control">
                    <button class="button is-static is-small">{{decodeURI(user.session.location)}}</button>
                  </div>
                  <div class="control">
                    <button class="bi-align-middle button is-small my-auto" :class="
                    {
                       'is-success': user.session.status === 1,
                       'is-warning': user.session.status === 0,
                       'is-danger': user.session.status > 1,
                       'is-disabled': user.session.status > 1
                    }" @click="approveUser(user.session.id, user.id)">
                      {{ user.session.status === 1 ? 'approve' : 'decline' }}
                    </button>
                  </div>
                </div>
              </template>

            </td>
            <td>
                <a target="_blank" :href="'/api/v1/user/resource?user_id='+user.id">
                  <button class="button is-info is-small">check cv</button>
                </a>
            </td>
            <td >
              <span class="role-name centred-cell">{{ user.role }}</span>
              <div class="dropdown" :class="{'is-active': dropdown === user.id}">
                <div class="dropdown-trigger">
                  <button class="button is-info is-small"
                        aria-haspopup="true" aria-controls="dropdown-menu" @click="dropdown = dropdown === user.id ? 0 : user.id ">grant</button>
                  </div>
                <div class="dropdown-menu" id="dropdown-menu" role="menu">
                  <div class="dropdown-content">
                    <template v-for="a in approveRoles">
                      <button class="button dropdown-item" @click.prevent="recrute(user.id, a)" >
                        grant a {{a}} role
                      </button>
                    </template>

                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

    </div>
  </div>
</template>

<script>
import IosBrush from 'vue-ionicons/dist/ios-brush'
import {mapActions, mapGetters} from "vuex";
import axiosService from "../../plugins/axiosService";

export default {
  name: "UserListComponent",
  components: {
    IosBrush
  },
  data: () => ({
    userList: [],
    dropdown: 0
  }),
  computed: {
    ...mapGetters(['getUsers',
      'getPermission']),
    approveRoles(){
      if(this.getPermission != null && this.getPermission.permission != null){
        if(this.getPermission.permission.findIndex( i => i === 'admin') !== -1){
          return ['user', 'candidate', 'recruiter', 'manager', 'admin']
        } else if(this.getPermission.permission.findIndex( i => i === 'promote') !== -1){
          return ['user', 'candidate', 'recruiter']
        } else if(this.getPermission.permission.findIndex( i => i === 'list') !== -1){
          return ['user', 'candidate']
        }
      }
      return []
    }
  },
  methods:{
    ...mapActions(['fetchUsers']),
    approveUser(id, user_id){
      axiosService.post('/api/v1/sessions/approve', {user_id: user_id, 'session': id, action: 'confirm'})
    },
    camelize(str) {
      return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
        return index !== 0 ? word.toLowerCase() : word.toUpperCase();
      }).replace(/\s+/g, '');
    },
    recrute(user, role){
      axiosService.post('/api/v1/user/approve', {user, role})
    },
  }
}
</script>

<style scoped>
  .user-list-card{
    padding: 20px 1rem 10px;
  }
  .user-entity{
    table-layout:fixed;
    width:100%;
    margin-bottom: 20px;
    padding-bottom: 20px;
  }
  .user-entity th.header{
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .user-entity td{
    padding: 1px;
  }
  .user-entity td .centred-cell{
    vertical-align: sub;
  }
  .user-entity td .role-name{
    padding: 1px 8px 2px 0px;
  }
</style>
