<template>
  <div
    class="table_body-item">
    <slot
      :name="column"
      :[column]="item[column]">
    </slot>
  </div>
</template>

<script>
export default {
  name: 'TableBody',
  props: {
    column: {
      type: String,
      required: true,
    },
    item: {
      type: Object,
      required: () => {},
    }
  },
  created() {
    if (this.column === 'obj.id') {
      console.log(this.column)
      console.log(this.item)
    }
  }
}
</script>
