<template>
  <popup-block
    is_wide
    :closeModal="closeModal"
    :shown="opened">
    <template v-slot:popup_title>Statistic "{{ local_quiz.title || '' }}"</template>
    <template v-slot:popup_sub_title>Passed {{statistics.length}} time(s)</template>
    <template>
      <loader v-if="loading"/>
      <div>
        <p v-if="!statistics.length">
          No one pass this test currently
        </p>
        <div v-else>
          <default-table
            :items="statistics">
            <template slot="id" slot-scope="props">
              {{ props.id }}
            </template>
            <template slot="user" v-if="props.user" slot-scope="props">
              <div>{{ props.user.firstName || '' }} {{ props.user.lastName || '' }}</div>
              <div>{{ props.user.email || '' }}</div>
            </template>
            <template slot="score" slot-scope="props">
                {{ props.score || 0 }}%
            </template>
            <template slot="-" slot-scope="props">
              <badge
                v-if="props.quiz && props.quiz.meta_value"
                :is_main_test="!!props.quiz.meta_value.main"
                :result="props.score"
                :pass_rate="props.quiz.pass_rate"/>
            </template>
          </default-table>
        </div>
      </div>
    </template>
  </popup-block>
</template>

<script>
import PopupBlock from '__module_list/popup/PopupBlock'
import ModalMixin from '../../mixins/ModalMixin'
import EditQuiz from '../Test/EditQuiz'
import axiosService, { DEFAULT_ERROR_REPS_TEXT } from '../../plugins/axiosService'
import Loader from '../../../module_list/loader/Loader'
import DefaultTable from '../../../module_list/default_table/DefaultTable'
import Badge from '../small-components/Badge'

export default {
  name: 'QuizStatisticModal',
  mixins: [ModalMixin],
  components: {
    Badge,
    DefaultTable,
    Loader,
    EditQuiz,
    PopupBlock
  },
  props: {
    quiz: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      loading: false,
      text_success: '',
      text_error: '',
      local_quiz: null,
      statistics: [],
      and_close: false
    }
  },
  methods: {
    openModal(){
      this.opened = true
      this.loading = true
      axiosService.post(`/api/v1/quiz/statistic`, {id: this.quiz.id})
        .then(r => {
          this.statistics = r.data
        })
        .finally(() => {
          this.loading = false
        })
    },
    closeModal() {
      this.text_success = ''
      this.text_error = ''
      this.statistics = []
      this.opened = false
      this.loading = false
      this.$emit('closed', true)
    },
    submitForm() {
      this.$refs.edit_quiz?.$children[0]?.submit()
    },
    submitFormClose() {
      this.and_close = true
      this.submitForm()
    },
    submitted(val) {
      this.text_success = ''
      this.text_error = ''
      axiosService.post('/api/v1/quiz/edit', val)
        .then(result => {
          this.text_success = 'Updated'
          for (let [key, value] of Object.entries(result.data)) {
            if (this.local_quiz[key]) {
              this.local_quiz[key] = value
            }
          }
          if (this.and_close) {
            setTimeout(() => {
              this.closeModal()
            }, 2000)
          }
          this.$emit('updated', this.local_quiz)
        })
        .catch(() => {
          this.text_error = DEFAULT_ERROR_REPS_TEXT
        })
        .finally(() => {
          this.and_close = false
        })
    }
  },
  watch: {
    'quiz.id'(val) {
      if (!val) return
      this.openModal()
      this.local_quiz = this.quiz
    },
  }
}
</script>
