import {mapGetters} from 'vuex'

export const transformUserSessions = (user) => {
  if (!user || !Object.keys(user).length) return user
  user.active_session = null
  user.sessions = user.sessions.map(el => {
    el.session_location = decodeURI(el.session_location)
    if (el.activity) {
      // unlink data
      user.active_session = JSON.parse(JSON.stringify(el))
    }
    return el
  })
  user.__cv = false;
  if (user.user_meta) {
    user.user_meta = user.user_meta.filter(user_meta => {
      if (user_meta.meta_value && !user_meta.meta_value.mime) {
        user_meta.meta_value = JSON.parse(user_meta.meta_value)
      }
      return user_meta.meta_value?.path
    })
    user.__cv = user.user_meta.length
  }
  return user
}

export default {
  computed: {
    ...mapGetters([
      'getLanguages'
    ]),
    default_user_avatar() {
      return '/api/v1/user/resource?resource-type=avatar'
    },
    empty_user_avatar() {
      return '/images/empty_user.svg'
    },
    default_user_cv() {
      return '/api/v1/user/resource?resource-type=cv'
    },
    empty_user_cv() {
      return '/images/empty_cv.svg'
    }
  },
  methods: {
    replaceSrcByDefault(e) {
      e.target.src = this.empty_user_avatar
    },
    calculateQuizScore(e) {
      return 0
    },
    sessionStatusText(status) {
      return status === -1
        ? 'Awaiting to confirm'
        : status
          ? 'Joined'
          : 'Blocked'
    },
    checkCv(list) {
      if (!list || !list.length) return ''
      list.filter(el => {
        if (el.meta_value && !el.meta_value.name) {
          el.meta_value = JSON.parse(el.meta_value)
        }
        return el
      })
      return list.length ? "&#10004;" : ''
    },
    activeSession(list) {
      let return_obj = {}
      if (!list || !list.length) return return_obj
      return_obj = list.find(el => el.activity) ?? {}
      if (return_obj && return_obj.session_location) {
        return_obj.session_location = decodeURI(return_obj.session_location)
      }
      return return_obj
    },
    langIds(list) {
      return list.map(el => el.id)
    },
    userLanguages(list, short = true) {
      if (!list || !list.length) return ''
      return this.getLanguages
        .filter(lang => list.includes(lang.id))
        .map(el => {
          if (short) {
            return el.code
          }
          return el.name
        })
        .join(', ')
    }
  }
}
