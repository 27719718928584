<template>
  <label
    :class="{checked: checked}"
    class="toggled">
    <input
      :type="get_type"
      :name="name"
      ref="input"
      :disabled="disabled"
      @change="$emit('input', $event.target.checked)"
      :checked="checked">
    <span class="toggled_circle"></span>
  </label>
</template>

<script>
export default {
  name: 'Toggled',
  props: {
    value: {
      type: [Boolean, Number],
      default: false
    },
    type_radio: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: 'name'
    }
  },
  computed: {
    checked() {
      return !!this.value
    },
    get_type() {
      return this.type_radio ? 'radio' : 'checkbox'
    }
  },
}
</script>
