<template>
  <div
    class="user_avatar"
    :class="{large: large}">
    <img
      ref="user_avatar"
      :src="src"
      @error="replaceSrcByDefault"
      alt="user_avatar">
  </div>
</template>

<script>
import userMixin from '../../mixins/UserMixin'

export default {
  name: 'UserAvatar',
  mixins: [userMixin],
  props: {
    src: {
      type: String,
      required: true
    },
    large: {
      type: Boolean,
      default: false
    }
  }
}
</script>
