<template>
    <div class="screen centered-content">
        <div class="card-500 card">
            <tabs>
                <tab name="Sign in">
                    <step-email/>
                </tab>
                <tab name="Register">
                    <step-enter-code/>
                </tab>
                <tab name="Forgot password">
                    <reset-password/>
                </tab>
            </tabs>
        </div>
    </div>
</template>

<script>
import Tabs from '__module_list/tabs/Tabs.vue'
import Tab from '__module_list/tabs/Tab.vue'
import StepEmail from './StepEmail'
import StepEnterCode from './StepEnterCode'
import ResetPassword from './ResetPassword'

export default {
    name: 'LoginPage',
    components: {
      ResetPassword,
        StepEnterCode,
        StepEmail,
        Tabs,
        Tab
    }
}
</script>
