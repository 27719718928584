export default {
  methods: {
    getComponentDomById (id) {
      return document.getElementById(id)
    },
    getComponentVueById (id) {
      const dom = this.getComponentDomById(id)
      if (!dom || !dom.__vue__) return
      return dom.__vue__
    },
    getAllComponentsByClass (className, el = false) {
      if (!el) {
        el = document
      }
      return el.querySelectorAll(className)
    },
    getAllVueComponentsByClass (className, el) {
      return Array.from(this.getAllComponentsByClass(className, el))
        .map(domEl => {
          if (!domEl || !domEl.__vue__) return
          return domEl.__vue__
        }).filter(Boolean)
    }
  }
}
