<template>
  <div
    @mouseover="initTooltip"
    @touchstart="initTooltip"
    @mouseleave="destroyListen"
    @touchend="destroyListen"
    :class="parent_classes"
    class="tooltip">
    <slot></slot>
    <div
      ref="tooltip"
      :class="tooltip_classes"
      :style="tooltip_style"
      class="tooltip_dropdown">{{text}}</div>
  </div>
</template>

<script>
const CARET_HEIGHT = 8;
export default {
  name: 'Tooltip',
  props: {
    text: {
      type: String,
      default: ''
    },
    tooltip_color: {
      type: String,
      default: '#fff'
    },
    tooltip_bg: {
      type: String,
      default: '#ffd85b'
    },
    is_inline: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      show: false,
      top: 0,
      left: 0,
      tooltip_bottom: true
    }
  },
  computed: {
    tooltip_classes( ){
      return {
        from_top: !this.tooltip_bottom
      }
    },
    parent_classes() {
      return {
        is_inline: this.is_inline ? 'is_inline' : '',
        show: this.show
      }
    },
    tooltip_style() {
      return {
        color: this.tooltip_color,
        borderColor: this.tooltip_bg,
        backgroundColor: this.tooltip_bg,
        left: `${this.left}px`,
        top: `${this.top}px`
      }
    }
  },
  methods: {
    initTooltip() {
      if (!this.text) return
      document.addEventListener('scroll', this.calculatePosition)
      this.calculatePosition()
      this.$nextTick(() => {
        this.show = true
      })
    },
    calculatePosition() {
      let parent = this.$el.getBoundingClientRect()
      let tooltip = this.$refs.tooltip.getBoundingClientRect()
      let tooltip_height = Math.round(tooltip.height) + CARET_HEIGHT

      this.tooltip_bottom = parent.bottom + tooltip_height < window.innerHeight

      this.left = parent.right - ( tooltip.width / 2) - (parent.width / 2)
      this.top =  this.tooltip_bottom ? parent.bottom + CARET_HEIGHT :parent.top - tooltip_height
    },
    destroyListen() {
      this.show = false
      document.removeEventListener('scroll', this.calculatePosition)
    }
  },
  beforeDestroy() {
    this.destroyListen()
  }
}
</script>
