<template>
  <div class="empty-search">
    <svg
      class="empty-search_img"
      xmlns="http://www.w3.org/2000/svg" width="50" height="40" viewBox="0 0 50 40" fill="none">
      <path
        d="M6.33333 38.6666C3.38781 38.6666 1 36.2788 1 33.3333V6.66659C1 3.72107 3.38781 1.33325 6.33333 1.33325H17L22.3333 6.66659H33C35.9455 6.66659 38.3333 9.0544 38.3333 11.9999V14.6666M6.33333 38.6666H43.6667C46.6122 38.6666 49 36.2788 49 33.3333V19.9999C49 17.0544 46.6122 14.6666 43.6667 14.6666H17C14.0545 14.6666 11.6667 17.0544 11.6667 19.9999V33.3333C11.6667 36.2788 9.27885 38.6666 6.33333 38.6666Z"
        stroke="#9CA3AF" stroke-width="1.6" stroke-linecap="square"/>
    </svg>
    <div class="empty-search_title">{{ text }}</div>
    <div v-if="show_sub_title"
         class="empty-search_sub_title">
      Try adjusting your search or filter to find what you’re looking for.
    </div>
  </div>
</template>

<script>
export default {
  name: 'EmptySearch',
  props: {
    empty_text: {
      type: String,
      default: 'No Result Found'
    },
    show_sub_title: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    text() {
      return this.empty_text ? this.empty_text : 'No Result Found'
    }
  }

}
</script>
