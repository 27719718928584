<template>
  <div class="dashboard_content">
    <div class="mt-4">
      <div  class="cards">
        <div class="cards_item profile_page-security_form text-left">
          <div class="d-flex">
            <div class="mr-10 bold">
              Emails:
            </div>
            <div class="min_w-0">
              <a class="overflow-el"
                 title="careers@dare-tradeacademy.com"
                 href="mailto:dare.recruiters@veoworldwide.com">careers@dare-tradeacademy.com</a>
              <a class="overflow-el"
                 title="dare.recruiters@veoworldwide.com"
                 href="mailto:dare.recruiters@veoworldwide.com">dare.recruiters@veoworldwide.com</a>
            </div>
          </div>
        </div>
        <div class="cards_item profile_page-security_form text-left">
          <div class="d-flex">
            <div class="mr-10 bold">
              Phone:
            </div>
            <div>
              <a href="tel:4000000000">+4000000000</a>
            </div>
          </div>
        </div>
        <div class="cards_item profile_page-security_form text-left">
          <div class="d-flex">
            <div class="mr-10 bold">
              Address:
            </div>
            <div>
              <a href="https://www.google.com/search?channel=nrow5&client=firefox-b-d&q=Sos+Pacurari+116B%2C+lasi%2C+Romania#">+Sos Pacurari 116B, lasi, Romania</a>
            </div>
          </div>
        </div>
        <div class="cards_item profile_page-security_form text-left">
          <div class="d-flex">
            <div class="mr-10 bold">
              Academy:
            </div>
            <div>
              <a :href="$constants.DARE_TRADE_URL">dare-tradeacademy.com</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Contacts"
}
</script>

<style>
  .overflow-el {
    white-space: nowrap;
    display: block;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
  }
</style>
