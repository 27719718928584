<template>
  <div>
    <popup-block
      :closeModal="closeModal"
      :shown="opened"
      is_wider
      @close="$emit('close')">
      <template v-slot:popup_title>
        <tooltip
          tooltip_bg="#9dc6e788"
          tooltip_color="#015498"
          :text="`#${editing.id}`">
          Edit room {{ editing.title }}
        </tooltip>
      </template>
      <template>
        <tabs>
          <tab
            name="Main Info"
            tab_img_href="/images/quiz-list.svg">
            <div>
              <default-input
                type='name'
                label="Room name"
                required
                name="Location"
                v-model="editing.title"/>

              <div class="clearfix tile is-0">
                <div class="column is-6">
                  <default-input
                    label="Meeting start date"
                    v-model="editing.start1"
                    type="date"
                    ref='calendarTrigger'
                    :min="editing.session_jrStartDate"
                    :max="editing.end1 != null && editing.end1 !== '' ? editing.end1 :editing.session_trEndDate"
                    locale="en_EN"/>

                  <default-input
                    label="Meeting start time"
                    v-model="editing.start2"
                    type="time"
                    step="60"
                    ref='calendarTrigger'
                    locale="en_EN"/>
                </div>
                <div class="column is-6">
                  <default-input
                    label="Meeting end date"
                    v-model="editing.end1"
                    type="date"
                    ref='calendarTrigger'
                    :min="editing.start1 != null && editing.start1 !== '' ? editing.start1 : editing.session_jrStartDate"
                    :max="editing.session_trEndDate"
                    locale="en_EN"/>

                  <default-input
                    label="Meeting end time"
                    v-model="editing.end2"
                    type="time"
                    step="60"
                    ref='calendarTrigger'
                    locale="en_EN"/>
                </div>
              </div>
            </div>
          </tab>
          <tab
            name="Participants"
            tab_img_href="/images/quiz-list.svg">
            <template>
              <user-allocate-components
                v-model="items"
                :items="fetchedUsers"
                :session_id="editing.id"
                @splice="(v) => { GPUSH(v) }" @push="(v) => { GPUSH(v) }"
                @pushV="(v) => { items[v.row].splice(v.index, 0, v.value) }"
                @fetch="fetch"
                @provide="() => save()"
              />
            </template>
          </tab>
        </tabs>


      </template>

      <template v-slot:popup_footer>
        <button class="button is-link is-outlined" @click="submitFormClose">Save</button>
      </template>
    </popup-block>
  </div>
</template>

<script>
import PopupBlock from '__module_list/popup/PopupBlock'
import EditQuiz from '../../../Test/EditQuiz'
import DefaultInput from '../../../small-components/DefaultInput'
import Mixin from '../ModalMixins/ModalIndexMixin'
import ButtonActions from '../Parts/ButtonActions'
import SelectSessionUserComponent from './blocks/SelectSessionUserComponent'
import axiosService from '../../../../plugins/axiosService'
import { Container, Draggable } from 'vue-smooth-dnd'
import DraggableUser from '../Groups/DraggableUser'
import SelectComponent from '../../../../../module_list/dropdown/SelectComponent'
import ToggleButton from '../Parts/ToggleButton'
import TrainButtons from '../../../../../module_list/TrainButtons'
import UserAllocateComponents from '../Parts/UserAllocateComponents'
import Tab from '../../../../../module_list/tabs/Tab'
import Tabs from '../../../../../module_list/tabs/Tabs'
import { mapActions, mapGetters } from 'vuex'
import Tooltip from '__module_list/tooltip/Tooltip'

export default {
  name: 'EditRoomComponent',
  components: {
    Tooltip,
    Tabs,
    Tab,
    UserAllocateComponents,
    TrainButtons,
    ToggleButton,
    SelectComponent,
    DraggableUser,
    SelectSessionUserComponent,
    ButtonActions,
    DefaultInput,
    EditQuiz,
    Container,
    Draggable,
    PopupBlock
  },
  mixins: [Mixin],
  computed: {
    ...mapGetters(['activeSession'])
  },
  data () {
    return {
      text_success: '',
      text_error: '',
      room_id: -1,
      usersFiltered: [],
      and_close: false,
      fetchedUsers: [],
      session_users: [],
      items: [[]],
      watch_params: { showJr: true }
    }
  },
  mounted () {
    const sid = this.$props.session_id
    this.fetchSessions({ date: new Date() })
      .then(
        () => this.getUserList(sid)
      )
    // console.log(this.$props.editing);
  },
  methods: {
    ...mapActions(['updRoom', 'fetchSessions']),
    GPUSH (v) {
      if (v.value) {
        this.fetchedUsers.splice(v.index, v.length, v.value)
      } else {
        this.fetchedUsers.splice(v.index, v.length)
      }
    },
    load () {
      this.fetchedUsers = []
      axiosService.post('/api/v1/admin/users/groups/get', { session_id: this.editing.id })
        .then(e => {
          this.editing.groups = e.data.map(a => a.group_id)

          const users = e.data.map(entity => ({
            firstName: entity.user.firstName,
            lastName: entity.user.lastName,
            role_active: entity.user.role_active,
            languages: entity.user.languages,
            sessions: entity.user.sessions,
            is_connected: entity.connected,
            id: entity.user.id,
            room_transfers: entity.user.room_transfers.map(e => e.room_group_id) ?? [],
            test_results: entity.user.test_results,
            group: entity.group_id,
          }))

          const sliced = []
          users.forEach(user => {
            if (sliced[user.group] === undefined) {
              sliced[user.group] = [user]
            } else {
              sliced[user.group].push(user)
            }
          })

          const slice = []
          sliced.forEach(item => {
            slice.push(item)
          })
          this.items = slice
        })
    },
    closed () {
    },
    fetch () {
      /* todo point 1 */
      axiosService.post('/api/v1/sessions/part/room/participants', { room_id: this.editing.id })
        .then(r => {
          this.fetchedUsers = r.data.users.map(e => {
            const obj = e.user

            if (e.connected) {
              obj.nickname = e.user_nickname
              obj.is_connected = true
            } else {
              obj.is_connected = false
            }
            obj.test_results = e.user.test_results
            obj.sessions = e.user.sessions
            obj.role_active = e.user.role_active
            obj.id = e.user.id
            obj.room_transfers = e.user.room_transfers ? e.user.room_transfers.map(e => e.room_group_id) : []
            return obj
          })
            .filter(e => (e !== undefined))
        })
    },
    closeModal () {
      this.text_success = ''
      this.text_error = ''
      this.opened = false
    },
    saveUsers () {
      axiosService.post('/api/v1/admin/users/groups/push', {
        groups: this.items.map(e => e.map(i => i.id)),
        global: this.isGlobal,
        public: this.isPublic,
        session_id: this.editing.sid
      })
    },
    getUserList (session_id) {
      axiosService.post('/api/v1/sessions/part/rooms', { session_id })
        .then(e => {
          this.usersFiltered = this.activeSession.users?.map(user => {
              const meeting_entity = e.data.users.find(e => e.user_id === user.id)
              const entity = Object.assign({}, user, {
                status: meeting_entity == null ? 'new' : 'connected',
              })
              const roomId = e.data.meetings.find(item => -1 !== item.users.findIndex(u => u.id === user.id))

              if (!roomId?.room_key) {
                entity.room = null
              } else {
                entity.secondaryName = meeting_entity ? meeting_entity.user_name : null

                entity.room = this.active_session.find(item => item.id === roomId.room_key).topic
              }
              return entity
            }
          )
        })
    },
    save () {
      const hash = this.$route.hash
      switch (hash) {
        case '#participants':
          axiosService.post('/api/v1/admin/users/groups/set', {
            room_id: this.editing.id,
            session_id: this.editing.id,
            user_ids: this.items.map(s => s.map(e => (e.id)))
          })
          return
        case '#plan-participants':
          this.saveUsers()
        default:
          this.updRoom(this.editing)
      }
    }
  }
}
</script>
