<template>
  <div class="dropdown_button">
    <dropdown attached_right>
      <template v-slot:dropdown_content>
        <div class="dropdown_button-btn">
          <slot name="dropdown_button_text">{{btn_text}}</slot>
          <div class="dropdown_button-btn_arrow">
            <svg v-if="has_dropdown" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down" viewBox="0 0 16 16">
              <path d="M3.204 5h9.592L8 10.481 3.204 5zm-.753.659 4.796 5.48a1 1 0 0 0 1.506 0l4.796-5.48c.566-.647.106-1.659-.753-1.659H3.204a1 1 0 0 0-.753 1.659z"/>
            </svg>
          </div>
        </div>
      </template>
      <template v-slot:dropdown_body>
        <slot></slot>
      </template>
    </dropdown>
  </div>
</template>

<script>
import Dropdown from '../dropdown/Dropdown'
export default {
  name: 'DropdownButton',
  components: { Dropdown },
  props: {
    btn_text: {
      type: String,
      default: ''
    },
  },
  computed: {
    has_dropdown () {
      return this.$slots.default
    },
    get_body_class_list () {
      return {
        has_dropdown_body: !!this.$slots.dropdown_body,
      }
    }
  }
}
</script>
