<template>
    <div id="app">
        <div class="app-wrapper pt-0">
            <router-view></router-view>
        </div>
        <notifier/>
    </div>
</template>

<script>
import WSController from './wsplug/WSController'
import Notifier from '__module_list/notifier/NotifierBlock.vue'
import bus from "./bus";

export default {
    name: 'laravel_zoom',
    components: {
        Notifier,
    },
    data () {
        return {
            active: false,
            errorStack: []
        }
    },
    mounted() {
      bus.$on('notification', e => {
        console.log('Notification handheld');
        this.$store.dispatch("actionPushNotification", e)
      })
    },
    mixins: [WSController],
    methods: {
        ready () {
            const channel = this.$pusher.subscribe('my-channel')
            channel.bind('pusher:subscription_error', (status) => {
                console.log(status)
            })
            channel.bind('my-event', (log) => {
                console.log('Message from server ', log)
            })
        }
    },
}
</script>

<style>
@import "~bulma/css/bulma.css";
@import '~vue-ionicons/ionicons.scss';

</style>
