export default {
  data() {
    return {
      tab_selected: '',
    }
  },
  methods: {
    selectedTab(val) {
      this.tab_selected = val
    }
  }
}
