<template>
  <table class="user-entity" width="100%" style="vertical-align: center">
    <thead>
    <tr>
      <th class="header">Username</th>
      <th class="header">Name</th>
      <th class="header">Active session</th>
      <th class="header">Cv</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="user in getUsers">
      <td>
        <span class="centred-cell">{{ user.email }}</span>
      </td>
      <td>
        <span class="centred-cell">{{ camelize(user.firstName) + ' ' + camelize(user.lastName) }}</span>
      </td>
      <td>
        <div class="field has-addons">
          <div class="control">
            <button class="button is-static is-small" v-if="user.session !== -1">
              {{ decodeURI(user.session.location) }}
            </button>

            <div class="dropdown" :class="{'is-active': dropdown === user.id}" v-else>
              <div class="dropdown-trigger">
                <button class="button is-info is-small"
                        aria-haspopup="true" aria-controls="dropdown-menu"
                        @click="dropdown = dropdown === user.id ? 0 : user.id ">Select location
                </button>
              </div>

              <div class="dropdown-menu" id="dropdown-menu-2" role="menu">
                <div class="dropdown-content">
                  <template v-for="a in sessions">
                    <button class="button dropdown-item" @click="select(a.session_id)">
                      {{ a.session_location }}
                    </button>
                  </template>
                </div>
              </div>
            </div>

          </div>

          <div class="control">
            <div class="dropdown" :class="{'is-active': dropdown === user.id}" v-if="user.session !== -1">
              <div class="dropdown-trigger">
                <button class="bi-align-middle button is-small my-auto" aria-haspopup="true"
                        aria-controls="dropdown-menu"
                        :class="{
                       'is-disabled': user.session.activity,
                       'is-success': user.session.status === 1,
                       'is-warning': user.session.status === 0,
                       'is-danger': user.session.status > 1,
                    }" @click="dropdown = dropdown === user.id ? 0 : user.id">
                  action
                </button>
              </div>
              <div class="dropdown-menu" id="dropdown-menu-1" role="menu">
                <div class="dropdown-content">
                  <template v-for="a in actions">
                    <button class="button dropdown-item" :class="a.color" @click="action(a.text, user.session.id)">
                      {{ a.text }}
                    </button>
                  </template>
                </div>
              </div>
            </div>
            <button v-else class="bi-align-middle button is-small my-auto is-static">
              action
            </button>
          </div>
        </div>
      </td>
      <td>
        <a target="_blank" :href="'/api/v1/user/resource?user_id='+user.id">
          <button class="button is-info is-small">check cv</button>
        </a>
      </td>
    </tr>
    </tbody>
  </table>
</template>

<script>
import axiosService from "../../../../../plugins/axiosService";
import {mapGetters} from "vuex";

export default {
  name: "ApproveUserComponent",
  data: () => ({    dropdown: 0,
    actions: [
      {text: 'close', color:''},
      {text: 'approve', color:'is-success'},
      {text: 'decline', color:'is-warning'},
      {text: 'ban', color:'is-danger'},
    ],}),
  computed: {

    ...mapGetters(['getUsers',
      'getPermission', 'sessions']),
  },
  methods: {
    camelize(str) {
      if(!str || typeof str.replace !== 'function')return str

      return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
        return index !== 0 ? word.toLowerCase() : word.toUpperCase();
      }).replace(/\s+/g, '');
    },
    approveUser(id, user_id) {
      axiosService.post('/api/v1/sessions/approve', {user_id: user_id, 'session': id, action: 'confirm'})
    },
    action(text, id){
      switch (text){
        case 'close': this.dropdown = 0; return;
        case 'approve':
          axiosService.post('/api/v1/sessions/approve', {user_id: this.dropdown, 'session': id, action: 'confirm'}).then(() => {
            this.$store.dispatch('userEdit', {id: this.dropdown, session: {status: 1}})
            this.dropdown = 0;
          })
          return;
        case 'decline':
          axiosService.post('/api/v1/sessions/approve', {user_id: this.dropdown, 'session': id, action: 'decline'}).then(() => {
            this.$store.dispatch('userEdit', {id: this.dropdown, session: {status: 2}})
            this.dropdown = 0;
          })
          return;
        case 'ban':
          axiosService.post('/api/v1/sessions/approve', {user_id: this.dropdown, 'session': id, action: 'delete'})
          this.$store.dispatch('userEdit', {id: this.dropdown, session: -1})
          this.dropdown = 0;
          return;
      }
    },
    select(id){
      axiosService.post('/api/v1/sessions/admin/join', {'session': id, user_id: this.dropdown}).then(() => {
        this.$store.dispatch('userEdit', {id: this.dropdown, session: {id, status: 1, location: this.sessions.find(e => e.session_id === id).session_location}})
        this.dropdown = 0;
      })
    },
  }
}
</script>
