<template>
  <div v-show="isActive" :class="{tab_component_wrap :style_fix}">
    <loader v-if="data_loading"/>
    <template v-else>
      <slot></slot>
    </template>
  </div>
</template>

<script>
import axiosService from '../../application/plugins/axiosService'
import Loader from '__module_list/loader/Loader'

export default {
  name: 'Tab',
  components: { Loader },
  props: {
    name: {
      required: true
    },
    tab_img_href: {
      type: String,
      default: ''
    },
    selected: {
      type: Boolean,
      default: false
    },
    notification: {
      type: [Boolean, String, Number],
      default: false
    },
    fetch_url: {
      type: String,
      default: ''
    },
    fetch_method_get: {
      type: Boolean,
      default: false
    },
    vuex_action: {
      type: String,
      default: ''
    },
    hidden: {
      type: Boolean,
      default: false
    },
    vuex_action_data: {
      type: Object,
      default: null
    },
    style_fix: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isActive: false,
      data_loaded: false,
      data_loading: false,
    }
  },
  computed: {
    href () {
      return `#${this.name.toLowerCase()
        .replace(/ /g, '-')}`
    }
  },
  mounted () {
    this.isActive = this.selected
  },
  methods: {
    vuexMethodAction(){
      if (!this.vuex_action || (this.data_loaded || this.data_loading)) return
      this.data_loading = true
      this.$store.dispatch(this.vuex_action, this.vuex_action_data)
        .finally(() => {
          this.data_loading = false
          this.data_loaded = true
        })
    },
    async fetchData() {
      if (!this.fetch_url || (this.data_loaded || this.data_loading)) return
      let resp
      this.data_loading = true
      await axiosService[this.fetch_method_get ? 'get' : 'post'](this.fetch_url, [])
        .then(r => {
          this.$emit('fetch_data', r.data)
          resp = r.data
        })
        .catch(r => {
          this.$emit('fetch_failure', {status: r.response.status,data: r.response.data})
          console.log("retas", {status: r.response.status,data: r.response.data})
          console.log(r)
        })
        .finally(() => {
          this.data_loading = false
          this.data_loaded = true
        })
    }
  },
  watch: {
    isActive(val) {
      if (val) {
        this.fetchData()
        this.vuexMethodAction()
      }
    }
  }
}
</script>
