<template>
  <div
    class="notifier"
    :class="{notifier_spacer: sidebar_contains}">
    <transition-group v-if="!showAll"
      class="notifier-group"
      name="notifier_fade"
      tag="div" :duration="animation_dur">
      <div v-for="message in getNotifications"
           class="notifier_item"
           v-show="message.visible"
           :key="`message_${message.id}`">
        <div
          :class="'notifier_'+message.type">
          <div class="">{{ message.message }}</div>
        </div>
        <div
          @click="actionRemoveNotification(message.id)"
          class="notifier_item-close">
          <svg
            height="20px" width="20px" style="enable-background:new 0 0 512 512;" version="1.1"
            viewBox="0 0 512 512" xml:space="preserve" xmlns="http://www.w3.org/2000/svg">
              <path d="M443.6,387.1L312.4,255.4l131.5-130c5.4-5.4,5.4-14.2,0-19.6l-37.4-37.6c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4  L256,197.8L124.9,68.3c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4L68,105.9c-5.4,5.4-5.4,14.2,0,19.6l131.5,130L68.4,387.1  c-2.6,2.6-4.1,6.1-4.1,9.8c0,3.7,1.4,7.2,4.1,9.8l37.4,37.6c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1L256,313.1l130.7,131.1  c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1l37.4-37.6c2.6-2.6,4.1-6.1,4.1-9.8C447.7,393.2,446.2,389.7,443.6,387.1z"/>
          </svg>
        </div>
      </div>
    </transition-group>
<!--    asad-->
<!--    <transition-group-->
<!--      v-else-->
<!--      class="notifier-group"-->
<!--      name="notifier_fade"-->
<!--      tag="div" :duration="animation_dur">-->
<!--    </transition-group>-->
  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'
import { NOTIFY_ANIMATION_DURATION } from './notifier'
import ComponentHelper from '../../application/mixins/ComponentHelper'
import moment from "moment";

export default {
  name: 'NotifierBlock',
  mixins: [ComponentHelper],
  data: () => {
    return {
      errorStack: [],
      id: 0,
      sidebar_contains: true,
      showAll: false,
      fadeTime: 5000,
      modifiedAt: []
    }
  },
  computed: {
    ...mapGetters([
      'getNotifications'
    ]),
    animation_dur () {
      return NOTIFY_ANIMATION_DURATION
    }
  },
  methods: {
    ...mapActions([
      'actionPushNotification',
      'actionRemoveNotification'
    ]),
  },
  watch: {
    getNotifications(val) {
      this.sidebar_contains = true;
      //this.sidebar_contains = !!this.getComponentDomById('aside_dashboard')
      const newLength = val.length
      const t = this.modifiedAt.length
      const timelock = moment().millisecond();
      for(let i = t; i < newLength; i++){
        this.modifiedAt.push(timelock)
        setTimeout( () => this.actionRemoveNotification(val[i].id), this.fadeTime)
      }
    }
  }
}
</script>
