<template>
  <loader v-if="loading"/>
  <div v-else
       class="cards">
    <template v-if="is_candidate">
      <div class="cards_item" v-if="acs">
        <div class="cards_item-header">
          <span>Your session</span>
          <default-button
            :router_obj="{name: 'dashboard.userSession'}"
            class="is-warning cards_item-header_link">
            <arrow-right color="#000000b3"/>
          </default-button>
        </div>
        <div class="cards_item-body">
          <p class="bold mb-10">{{ acs.session_location }}</p>
          <div class="row">
            <div class="col-4 mb-10 bold">Recruitment start:</div>
            <div class="col-8 mb-10">{{ acs.session_jrStartDate }}</div>
            <div class="col-4 mb-10 bold">Recruitment finish:</div>
            <div class="col-8 mb-10">{{ acs.session_jrEndDate }}</div>
            <div class="col-4 mb-10 bold">Join meeting:</div>
            <div class="col-8 mb-10">
<!--              <pre>{{acs}}</pre>-->
              <template v-if="!acs.url">
                Wait for invite to meeting
              </template>
              <template v-else>
                <button
                  v-if="acs_url" class="is-primary button is-small"
                  @click="open_meeting_id = 1;">Join
                </button>
                <template v-else>Today meeting is over</template>
              </template>
            </div>

          </div>
        </div>
      </div>

      <div class="cards_item">
        <div class="cards_item-header">
          <span>Available sessions</span>
          <default-button
            :router_obj="{name: 'dashboard.userSession', hash: '#available-sessions'}"
            class="is-warning cards_item-header_link">
            <arrow-right color="#000000b3"/>
          </default-button>
        </div>
        <default-table
          empty_list_text="No sessions found for upcoming period"
          :fields_name="available_sessions_fields_name"
          :items="sessions || []">
          <template slot="session_location" slot-scope="props">
            {{ props.session_location }}
          </template>
          <template slot="session_jrStartDate" slot-scope="props">
            {{ props.session_jrStartDate }}
          </template>
          <template slot="session_jrEndDate" slot-scope="props">
            {{ props.session_jrEndDate }}
          </template>
          <!--          <template slot="session_trStartDate" slot-scope="props">-->
          <!--            {{ props.session_trStartDate }}-->
          <!--          </template>-->
          <!--          <template slot="session_trEndDate" slot-scope="props">-->
          <!--            {{ props.session_trEndDate }}-->
          <!--          </template>-->
          <template slot="status" slot-scope="props">
            <button
              :disabled="blocked_join_session_btn || getUserBlockedSessionIds.includes(props.session_id)"
              @click="joinSessionById(props.session_id)"
              class="button is-link is-outlined">
              {{ buttonText(props) }}
            </button>
          </template>
        </default-table>
      </div>
    </template>


    <div class="cards_item">
      <div class="cards_item-header">
        <span>Last added test</span>
        <default-button
          :router_obj="{name: 'tests'}"
          class="is-warning cards_item-header_link">
          <arrow-right color="#000000b3"/>
        </default-button>
      </div>
      <div v-if="!getAccountLanguages.length">
        <div class="card">
          <user-languages
            form_text="To continue, choose languages your know"/>
        </div>
      </div>
      <default-table
        v-else
        empty_list_text="No tests are available"
        :items="available_tests">
        <template slot="id" slot-scope="props">
          {{ props.id }}
        </template>
        <template slot="title" slot-scope="props">
          {{ props.title }}
        </template>
        <template slot="description" slot-scope="props">
          {{ props.description }}
        </template>
        <template slot="row_after" slot-scope="props">
          <default-button :router_obj="{name: 'testId', params: {id: props.id}}">Run test</default-button>
        </template>
      </default-table>
    </div>

    <div class="cards_item">
      <div class="cards_item-header">
        <span>Last finished test</span>
        <default-button
          :router_obj="{name: 'tests', hash: '#finished-tests'}"
          class="is-warning cards_item-header_link">
          <arrow-right color="#000000b3"/>
        </default-button>
      </div>
      <default-table
        empty_list_text="You are didn't finish any test right now"
        :items="passed_tests">
        <template slot="test_id" slot-scope="props">
          {{ props.test_id }}
        </template>
        <template slot="quiz_title" slot-scope="props">
          {{ props.quiz_title }}
        </template>
        <template slot="quiz_description" slot-scope="props">
          {{ props.quiz_description }}
        </template>
        <template slot="score" slot-scope="props">
          {{ props.score || 0 }}%
        </template>
        <template slot="status" slot-scope="props">
          <badge
            :is_main_test="!!(props.quiz_meta && props.quiz_meta.main)"
            :result="props.score"
            :pass_rate="props.quiz.pass_rate"/>
          <!--          <badge-->
          <!--            :show_rate="!!(props.quiz_meta && props.quiz_meta.main)"-->
          <!--            :rate_percent="props.score"-->
          <!--            :error="props.pass_rate > props.score"-->
          <!--            :text="props.pass_rate > props.score  ? 'test fail' : 'Success'"/>-->
        </template>
        <template slot="row_after" slot-scope="props">
          <default-button
            :router_obj="{name: 'tests', hash: '#finished-tests', params: {id: props.id}}"
            class="button is-warning">
            <statistic-icon color="#946c00"/>
          </default-button>
        </template>
      </default-table>
    </div>
    <div class="modal" :class="{'is-active': open_meeting_id !== undefined }">
      <div class="modal-background is-light" @click="open_meeting_id = undefined"></div>
      <div class="modal-content" v-if="open_meeting_id !== undefined">
        <div class="card pt-4 px-4">
          <h2 class="pb-4">{{ acs.topic }}</h2>
          <p class="is-primary block">
            You are about to join the rally. Please, when connecting to the room, indicate the email with which you
            registered on this site for unambiguous identification. You will be redirected to the Zoom site to connect
            to the current room.
          </p>
          <p>
            The selected rally will be launched at
            {{ acs.start_time.substring(11, 16) }} If the rally has not started
            yet, wait until the start or come back later.

          </p>
          <div class="d-flex justify-content-center mt-4 ml-auto">
            <default-button class="button is-success" @click="openMeeting">Open meeting</default-button>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axiosService from '../../plugins/axiosService'
import Loader from '__module_list/loader/Loader'
import DefaultButton from '../small-components/DefaultButton'
import ArrowRight from '../small-components/icons/ArrowRight'
import UserMixin, { transformUserSessions } from '../../mixins/UserMixin'
import DefaultTable from '__module_list/default_table/DefaultTable'
import dateMixin, {
  addMinutesToDate,
  getDateFormatStr,
  getDateObj,
  getDateWithSomeTimeZone, getToday
} from '../../mixins/dateMixin'
import Badge from '../small-components/Badge'
import { mapGetters, mapActions } from 'vuex'
import StatisticIcon from '../small-components/icons/StatisticIcon'
import SessionsMixin from '../../mixins/SessionsMixin'
import UserLanguages from './UserLanguages'

import { Browser } from '@capacitor/browser'

export default {
  name: 'DashboardCandidate',
  mixins: [dateMixin, UserMixin, SessionsMixin],
  components: {
    UserLanguages,
    StatisticIcon,
    Badge,
    DefaultTable,
    ArrowRight,
    DefaultButton,
    Loader
  },
  data () {
    return {
      open_meeting_id: undefined,
      loading: false,
      users: [],
      available_tests: [],
      passed_tests: []
    }
  },
  created () {
    this.getDashboardData()
  },
  computed: {
    ...mapGetters([
      'sessions',
      'getAccountLanguages',
      'getPermission',
      'getUserBlockedSessionIds',
    ]),
    blocked_join_session_btn () {
      if (this.getPermission
        && this.getPermission.permission
        && (this.getPermission.permission.includes('list') || this.getPermission.permission.includes('promote'))
      ) {
        return false
      }
      return !!this.getUserActiveSession
    },
    acs () {
      return this.$store.getters.activeSession
    },
    is_candidate () {
      return this.getPermission?.name === 'candidate'
    },
    acs_url() {
      let today = getToday()
      let room = this.acs.room?.find(room => {
        if (room.meta_chat?.start_time) {
          let date = getDateFormatStr(room.meta_chat.start_time)
          if (date === today) {
            return true
          }
        }
      })
      if (!room) return false;
      room['start_time_timezone'] = getDateWithSomeTimeZone(room.meta_chat.start_time, room.meta_chat.timezone)
      room['start_end_timezone'] = addMinutesToDate(room.start_time_timezone, room.meta_chat.duration)

      let now_date = getDateObj(new Date())
      let room_end = getDateObj(room.start_end_timezone)
      return `${room_end.hours}${room_end.minutes}` > `${now_date.hours}${now_date.minutes}`
    }
  },
  methods: {
    ...mapActions([
      'joinSession',]),
    findMeetingToActivate (id) {
      return this.active_session.find(entity => entity.id === id)
    },
    openMeeting () {
      this.openAction(this.acs.url)
    },
    joinSessionById (id) {
      //console.log(this.activeSession)
      // if (this.activeSession?.session_id) return
      this.joinSession(id)
        .then(() => {
          this.fetchSessions({ date: new Date() })
        })
    },
    openAction ($url) {
      Browser.open({ url: $url })
    },
    /* joinSession($url){
       Browser.open({url: $url})
     },*/
    getDashboardData () {
      this.loading = true
      axiosService.post('/api/v1/dashboard-data')
        .then(r => {
          this.available_tests = r.data.available_tests ?? []
          this.passed_tests = r.data.passed_tests ?? []
        })
        .catch(e => {
          console.log([e])
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
