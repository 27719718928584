const DATE_SEPARATOR = '-'
const DATE_WITH_TIME = false
/**
 * @param date
 * @param shortDate
 * @returns {{hours: number, month: number, year: number, minutes: number, day: number}|boolean}
 */
export const getDateObj = (date, shortDate = false) => {
  if (!date) {
    console.error('Date format empty')
    return false
  } else if (typeof date === 'string') {
    try {
      date = new Date(date)
    } catch (e) {
      console.error('Can\'t init date format')
      return false
    }
  }
  let day = date.getDate()
  let month = date.getMonth() + 1
  let hours = date.getHours()
  let minutes = date.getMinutes()
  const year = date.getFullYear()
  if (!shortDate) {
    if (day < 10) {
      day = `0${day}`
    }
    if (month < 10) {
      month = `0${month}`
    }
    if (hours < 10) {
      hours = `0${hours}`
    }
    if (minutes < 10) {
      minutes = `0${minutes}`
    }
  }

  return {
    day: day,
    month: month,
    year: year,
    hours: hours,
    minutes: minutes
  }
}

export const getDateFormatStr = (date, separator = DATE_SEPARATOR, withTime = DATE_WITH_TIME) => {
  if (!date) return
  if (!(date instanceof Date)) {
    date = new Date(date)
  }
  const dateObj = getDateObj(date)
  let dateStr = `${dateObj.year}${separator}${dateObj.month}${separator}${dateObj.day}`
  if (withTime) {
    dateStr += `T${dateObj.hours}:${dateObj.minutes}`
  }
  return dateStr
}

export const getDateFormatStrToShow = (date, separator = DATE_SEPARATOR, withTime = DATE_WITH_TIME) => {
  if (!date) return
  if (!(date instanceof Date)) {
    date = new Date(date)
  }
  const dateObj = getDateObj(date)
  let dateStr = `${dateObj.day}${separator}${dateObj.month}${separator}${dateObj.year}`
  if (withTime) {
    dateStr += ` ${dateObj.hours}:${dateObj.minutes}`
  }
  return dateStr
}

export const getDateWithSomeTimeZone = (date, time_zone) => {
  if (!date || !time_zone) return
  return new Date((new Date(date)).toLocaleString('en-US', {
    timeZone: time_zone,
  }))
}

export const addMinutesToDate = (date, minutes = 0) => {
  if (!date) return date
  let new_date = new Date(date)
  new_date.setMinutes(date.getMinutes() + minutes)
  return new_date
}

/**
 * @returns {string}
 */
export const getToday = () => {
  return getDateFormatStr(new Date())
}

export default {
  methods: {
    getToday() {
      return getToday()
    },
    getDateFormatStrToShow(date, separator = DATE_SEPARATOR, withTime = DATE_WITH_TIME) {
      return getDateFormatStrToShow(date, separator, withTime)
    },
    getDateFormatStr(date, separator = DATE_SEPARATOR, withTime = DATE_WITH_TIME) {
      return getDateFormatStr(date, separator, withTime)
    },
    getDateObj(date, shortDate = false) {
      return getDateObj(date, shortDate)
    },
  }
}
