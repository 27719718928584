import axiosService from '../../plugins/axiosService'
import moment from "moment";

export default {
  actions: {
    HISTORY_READ (context, data) {
      axiosService.post('/api/v1/messages/chats', data).then(e => {
        const arr = Object.values(e.data)
        context.commit('ApplyChats', arr)
      })
    },
    PUSH_MESSAGE (context, data){
      return axiosService.post('/api/v1/messages/send', {target: data.target.id, message: data.message}).then(e => {
        context.commit('MessageSent', data)
        return {sender: context.getters.getAccount.id, text: data.message, time: moment().toISOString(true), read: 0}
      }).catch(e => {
        return {'error': 'cant send', error_description: e};
      })
    },
    FETCH_AVAILABLE (context, data) {
      axiosService.post('/api/v1/messages/users', data).then(e => {
        context.commit('ApplyUsers', e.data)
      })
    }
  },
  mutations: {
    ApplyUsers (context, payload) {
      if(payload != null && payload.length === undefined)return null;
      context.availableUsers = payload
    },
    MessageSent(context, payload){
      const index = context.chats.findIndex(e => e.user.id  === payload.target.id)
      if(index !== -1){
        // console.log(context.chats, index)
        context.chats[index].messages.push({user_id: this.getters.getAccount.id, text: payload.message, read: 0, time: moment().toISOString(true)})
      } else {
        context.chats.push({user: payload.target, messages: [{user_id: this.getters.getAccount.id, text: payload.message, read: 0, time: moment().toISOString(true)}]})
      }
    },
    ApplyChats (context, payload) {
      if(payload != null && payload.length === undefined)return null;
      const findUid = (id) => {
        const data = context.chats.find(e => e.user.id === id)
        if(data) return data
        return null
      }
      payload.forEach(item => {
        if(item.user === undefined) return ;
        const updatable = findUid(item.user.id)
        if(!updatable){
          context.chats.push(item)
        } else {
          updatable.messages = item.messages
        }
      })

    },
  },
  state: {
    chats: [],
    availableUsers: [],
  },
  getters: {
    getChats(store){
      return store.chats
    },
    getUnread(store){

    },
    getChat(store, id){
      return store.chats.find(id)
    },
    getAvailableChats(store){
      return store.availableUsers;
    }
  }
}
