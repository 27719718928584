<template>
  <div>
    <tabs>
      <tab
        name="All upcoming sessions"
        tab_img_href="/images/quiz-list.svg">
        <div class="card">
          <table-session-component
            :upcoming="true"
            :dropdown_update="dropdown_table_update"
            class="mt-4"
            @modal="modalOpen"/>
        </div>
        <button class="button" @click="modalOpen({target: 'newS'})">
          Create
        </button>
      </tab>
      <tab
        name="Active sessions"
        tab_img_href="/images/add-new.svg">
        <div class="card">
          <table-session-component :upcoming="false" class="mt-4" @modal="modalOpen"/>
        </div>
        <button class="button" @click="modalOpen({target: 'newS'})">create</button>
      </tab>
      <tab
        name="Approve users for session"
        tab_img_href="/images/add-new.svg">
        <approve-user-to-sessions/>
      </tab>
    </tabs>
    <!--     New Session component-->
    <create-session-component
      :property="modal_create_session"
      @input="closed('newS')"
    />
    <!--     Edit Room of Session component-->
    <edit-room-component
      :property="modal_edit"
      @close="closed('edit')"/>
    <!--     Create Room of Session component-->
    <create-room-component
      @created="dropdown_table_update++"
      :property="modal_create"
      @close="closed('new')"/>
    <edit-session-component :property="modal_init" @close="closed('init')"/>
    <edit-create-user-groups-modal :property="modal_user_groups" @close="closed('users')"/>
  </div>
</template>

<script>

import axiosService from '../../plugins/axiosService'
import Tabs from '__module_list/tabs/Tabs.vue'
import Tab from '__module_list/tabs/Tab.vue'
import { mapActions, mapGetters } from 'vuex'
import ShowUsersModal from '../modals/ShowUsersModal'
import ApproveUserComponent from './Modal/components/blocks/ApproveUserComponent'
import TableSessionComponent from './TableSessionComponent'
import CreateSessionComponent from './Modal/components/CreateSessionComponent'
import EditSessionComponent from './Modal/components/EditSessionComponent'

import CreateRoomComponent from './Modal/components/CreateRoomComponent'
import EditRoomComponent from './Modal/components/EditRoomComponent'
import ApproveUserToSessions from './Modal/components/blocks/ApproveUserToSessions'
import EditCreateUserGroupsModal from './Modal/Groups/EditCreateUserGroupsModal'
import { addMinutesToDate, getDateObj, getDateWithSomeTimeZone } from '../../mixins/dateMixin'

export default {
  name: 'List',
  components: {
    EditCreateUserGroupsModal,
    ApproveUserToSessions,
    CreateRoomComponent,
    EditSessionComponent,
    EditRoomComponent,
    CreateSessionComponent,
    TableSessionComponent,
    ApproveUserComponent,
    ShowUsersModal,
    Tabs,
    Tab
  },
  props: {
    id: { default: null }
  },
  computed: {
    ...mapGetters(['getUsers',
      'getPermission', 'sessions']),

    approveRoles () {
      if (this.getPermission != null && this.getPermission.permission != null) {
        if (this.getPermission.permission.findIndex(i => i === 'admin') !== -1) {
          return ['user', 'candidate', 'recruiter', 'manager', 'admin']
        } else if (this.getPermission.permission.findIndex(i => i === 'promote') !== -1) {
          return ['user', 'candidate', 'recruiter']
        } else if (this.getPermission.permission.findIndex(i => i === 'list') !== -1) {
          return ['user', 'candidate']
        }
      }
      return []
    }
  },
  methods: {
    ...mapActions([
      'fetchDefaultLanguage',
      'fetchUsers',
      'fetchSessions'
    ]),
    modalOpen (val) {
      this.fetchDefaultLanguage()
      if (!val) return
      switch (val.target) {
        case 'edit':
          if (val.room.meta !== null) {
            // let start_date = new Date(val.room.meta.start_time)
            let start_date = getDateWithSomeTimeZone(val.room.meta.start_time, val.room.meta.timezone)
            let end_date = addMinutesToDate(start_date, val.room.meta.duration)

            let start_date_obj = getDateObj(start_date)
            let end_date_obj = getDateObj(end_date)

            const data = {
              id: val.room.id,
              sid: val.room.sid,
              start1: `${start_date_obj.year}-${start_date_obj.month}-${start_date_obj.day}`,
              start2: `${start_date_obj.hours}:${start_date_obj.minutes}`,
              end1: `${end_date_obj.year}-${end_date_obj.month}-${end_date_obj.day}`,
              end2: `${end_date_obj.hours}:${end_date_obj.minutes}`,
              title: val.room.meta.topic,
              meeting: val.room.meta.uuid
            }
            this.$set(this, 'modal_edit', {
              open: true,
              data
            })
          }
          break
        case 'new':
          this.$set(this, 'modal_create', {
            open: true,
            data: val.props
          })
          break
        case 'newS':
          this.$set(this, 'modal_create_session', {
            open: true,
            data: { title: 'session' }
          })
          break
        case 'users':
          this.$set(this, 'modal_user_groups', {
            open: true,
            data: val.props
          })
          break
        case 'init':
          const obj = {
            id: val.props.session_id,
            start1: val.props.session_jrStartDate,
            start2: val.props.session_trStartDate,
            end1: val.props.session_jrEndDate,
            end2: val.props.session_trEndDate,
            location: val.props.session_location,
            desc: val.props.session_jobDesc,
            job: val.props.session_jobName,
            meetings_jr: val.props.room ? val.props.room.filter(e => e.meta_chat != null)
              .map(room => {
                let start_date = getDateWithSomeTimeZone(room.meta_chat.start_time, room.meta_chat.timezone)
                let end_date = addMinutesToDate(start_date, room.meta_chat.duration)

                let start_date_obj = getDateObj(start_date)
                let end_date_obj = getDateObj(end_date)

                return {
                  id: room.meta_chat.id,
                  title: room.meta_chat.topic,
                  sid: val.props.session_id,
                  duration: room.meta_chat.duration,
                  date: `${start_date_obj.year}-${start_date_obj.month}-${start_date_obj.day}`,
                  start: `${start_date_obj.hours}:${start_date_obj.minutes}`,
                  end: `${end_date_obj.hours}:${end_date_obj.minutes}`,
                  enabled: true
                }
              }) : []

          }
          this.$set(this, 'modal_init', {
            open: true,
            data: obj
          })
          break
      }

    },
    closed (val) {
      switch (val) {
        case 'edit':
          this.$set(this, 'modal_edit', {
            open: false,
            data: this.modal_edit.data
          })
          break
        case 'new':
          this.$set(this, 'modal_create', {
            open: false,
            data: this.modal_create.data
          })
          break
        case 'newS':
          this.$set(this, 'modal_create_session', {
            open: false,
            data: this.modal_create_session.data
          })
          break
        case 'init':
          this.$set(this, 'modal_init', {
            open: false,
            data: this.modal_init.data
          })
          break
        case 'users':
          this.$set(this, 'modal_user_groups', {
            open: false,
            data: this.modal_user_groups.data
          })
          break
      }
    },
    approveUser (id, user_id) {
      axiosService.post('/api/v1/sessions/approve', {
        user_id: user_id,
        'session': id,
        action: 'confirm'
      })
    },
    openZoom (val) {
      console.log(val)
    },
    updModal (val) {
      this.modal_data = val.users
    },
    openCreateUsers () {
      this.create_session_open = true
    },

    recrute (user, role) {
      axiosService.post('/api/v1/user/approve', {
        user,
        role
      })
        .then(e => {
        })
    },
  },
  data: () => ({
    arrays: {},
    create_session_open: false,
    userList: [],
    editing: {},
    modal_data: [],
    table1: [],
    meetings: [],
    modal_user_groups: {
      open: false,
      data: null
    },
    modal_create_session: {
      open: false,
      data: null
    },
    modal_edit_session: {
      open: false,
      data: null
    },
    modal_create: {
      open: false,
      data: null
    },
    modal_edit: {
      open: false,
      data: null
    },
    modal_init: {
      open: false,
      data: null
    },
    dropdown_table_update: 0,
  }),
  mounted () {
    // axiosService.post('/api/v1/zoom/meets/me').then(e => {this.$set(this, 'meetings', e.data.meetings.meetings);})
    this.fetchUsers()
    if (!this.sessions || this.sessions === 0) {
      this.fetchSessions({ date: new Date() })
    }

    if (this.$props.id && this.$route.hash) {
      switch (this.$route.hash) {
        case '#main-info':
        case '#plan-meetings':
        case '#plan-participants':
        case '#participants':
          this.modalOpen({
            target: 'init',
            props: this.sessions.find(e => e.session_id === this.$props.id)
          })
      }
    }
  }
}
</script>
