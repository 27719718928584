<template>
  <tabs class="profile_page">
    <tab
        name="Personal information"
        tab_img_href="/images/info.svg">
      <div class="row">
        <div class="col-12 col-sm-6 col-md-6">
          <div class="profile_page-security_form">
            <div class="row">
              <div class="col-6">
                <file-input
                    :file_src="default_user_avatar"
                    @file_uploaded="download_avatar"
                />
              </div>
              <div class="col-6">
                <file-input
                    :file_src="default_user_cv"
                    cv_input
                    @file_uploaded="download_cv"
                />
              </div>
            </div>
          </div>
          <div class="mt-10 tab-form" v-if="isManager">
            <default-form
                :error_text="email.error_text"
                :success_text="email.success_text"
                @submit="sendReferral"
            >
              <p class="mb-10">You can invite new employee by his(her) email. And we send him invitation code</p>
              <default-input
                  type="email"
                  name="email"
                  label="E-mail"
                  required
                  :validate_f="validateEmail"
                  :valid.sync="email.valid"
                  v-model="email.val"/>
              <template v-slot:buttons_block>
                <div class="button-wrapper mt-20">
                  <button
                      :class="{'is-loading': email.loading}"
                      :disabled="!email.val"
                      class="button is-info ml-auto">Invite
                  </button>
                </div>
              </template>
            </default-form>
          </div>
        </div>
        <div class="col-12 col-sm-6 col-md-6">
          <default-form
              class="profile_page-security_form"
              @submit="save">
            <default-input
                name="first_name"
                required
                type="text"
                placeholder="First Name"
                v-model="user.first"
                :valid.sync="user_valid.first"
                :validate_f="validateLogin"
            />
            <default-input
                name="last_name"
                required
                type="text"
                placeholder="Last name"
                v-model="user.last"
                :valid.sync="user_valid.last"
                :validate_f="validateLogin"
            />
            <default-input
                name="phone"
                required
                type="phone"
                placeholder="Phone"
                v-model="user.phone"
                :valid.sync="user_valid.phone"
                :validate_f="validateLogin"
            />
            <template v-slot:buttons_block>
              <div class="button-wrapper mt-20">
                <default-button
                    :disabled="!user_has_changes"
                    type="submit"
                    class="is-info ml-auto"
                >Save changes
                </default-button>
              </div>
            </template>
          </default-form>
        </div>
      </div>
    </tab>

    <tab
        name="Security"
        tab_img_href="/images/security-img.svg">
      <div class="row">
        <div class="col-12 col-sm-6 col-md-6">
          <default-form
              class="profile_page-security_form"
              :error_text="change_password_error"
              :success_text="change_password_success"
              @submit="submitChangePassword">
            <default-input
                name="old_password"
                required
                type="password"
                placeholder="Old password"
                v-model="user_passwords.old_password.val"
                :valid.sync="user_passwords.old_password.valid"
                :validate_f="validatePassword"
            />
            <default-input
                name="new_password"
                required
                type="password"
                placeholder="New password"
                :compare="user_passwords.old_password.val"
                v-model="user_passwords.password.val"
                :valid.sync="user_passwords.password.valid"
                :validate_f="validatePasswordOld"
            />
            <default-input
                name="password_confirm"
                required
                type="password"
                placeholder="New password confirmation"
                :compare="user_passwords.password.val"
                v-model="user_passwords.password_confirm.val"
                :valid.sync="user_passwords.password_confirm.valid"
                :validate_f="confirmPassword"
            />
            <template v-slot:buttons_block>
              <div class="button-wrapper mt-20">
                <default-button
                    type="submit"
                    class="is-info ml-auto"
                >Change password
                </default-button>
              </div>
            </template>
          </default-form>
        </div>
      </div>
    </tab>
    <tab
        name="My languages"
        tab_img_href="/images/languages.svg">
      <div class="tab-form">
        <user-languages/>
      </div>
    </tab>
  </tabs>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import Tabs from '__module_list/tabs/Tabs.vue'
import Tab from '__module_list/tabs/Tab.vue'
import FileInput from '__module_list/file_input/FileInput.vue'

import axiosService, {DEFAULT_ERROR_REPS_TEXT} from '../plugins/axiosService'
import DefaultForm from './small-components/DefaultForm'
import DefaultInput from './small-components/DefaultInput'
import input_validations from '../helpers/input_validations'
import DefaultButton from './small-components/DefaultButton'
import UserMixin from '../mixins/UserMixin'
import constants from '../helpers/constants'
import {STATUS_WARNING, Notification} from '../helpers/httpCatcher'
import DownSvg from 'vue-ionicons/dist/ios-arrow-down'
import TrainButtons from '../../module_list/TrainButtons'
import UserLanguages from './blocks/UserLanguages'

export default {
  name: 'Profile',
  components: {
    UserLanguages,
    TrainButtons,
    FileInput,
    DefaultButton,
    DefaultInput,
    DefaultForm,
    DownSvg,
    Tabs,
    Tab
  },
  computed: {
    ...mapGetters([
      'getAccount',
      'getAccountXAuth',
      'getPermission',
      'getQuizResults',
    ]),
    user_has_changes() {
      if (!this.user.id) return false
      return this.user.first !== this.getAccount.first
          || this.user.last !== this.getAccount.last
          || this.user.phone !== this.getAccount.phone
    },
    isManager() {
      return this.perms.includes('promote') || this.perms.includes('list')
    },
  },
  mixins: [input_validations, UserMixin],

  data: () => ({
    // eslint-disable-next-line no-undef
    user: {firstName: 'fname'},

    email: {
      success_text: '',
      error_text: '',
      val: '',
      valid: true,
      loading: false
    },
    change_password_error: '',
    change_password_success: '',
    user_passwords: {
      old_password: {
        val: '',
        valid: true
      },
      password: {
        val: '',
        valid: true
      },
      password_confirm: {
        val: '',
        valid: true
      },
    },
    user_valid: {
      first: true,
      second: true,
      phone: true,
    },
    fetchAcc: '',
    user_cv: '/api/v1/user/resource?resource-type=av',
    user_image: '/api/v1/user/resource?resource-type=cv',
    user_cv_file: '',
    user_avatar_file: '',
    role: '',
    tab: 0,
    perms: [],
    table1: [],
    zoom_user: null
  }),
  mounted() {
    this.user = JSON.parse(JSON.stringify(this.getAccount))
    if(this.getPermission){
      this.perms = this.getPermission.permission
    }
  },
  watch: {
    async permission(val) {
      this.role = val !== undefined ? val.name : val
      this.perms = val !== undefined ? val.permission : []
    }
  },
  methods: {
    sendTestRequest() {
      axiosService.post('/api/v1/test-api')
    },
    ...mapActions([
      'actionPushNotification',
    ]),
    save() {
      if (!this.user_has_changes) return
      axiosService
          .post('/api/v1/user/update', {
            firstName: this.user.first,
            lastName: this.user.last,
            phone: this.user.phone
          })
          .then(({data}) => {
            if (data.result.firstName) {
              this.getAccount.first = data.result.firstName
            }
            if (data.result.lastName) {
              this.getAccount.last = data.result.lastName
            }
            if (data.result.phone) {
              this.getAccount.phone = data.result.phone
            }
            this.actionPushNotification({message: 'Your profile was updated'})
          })
    },
    download_cv(file) {
      if (typeof file !== 'object') {
        return
      }
      try {
        const formData = new FormData()
        formData.append('file', file)
        axiosService.post('/api/v1/user/resource', formData)
            .then((e) => {
              this.actionPushNotification({message: 'Your cv was uploaded'})
            })
            .catch((s) => {
              console.log(s.error)
            })
      } catch (e) {
        console.log(e)
      }
    },
    download_avatar(file) {
      if (typeof file !== 'object') {
        return
      }
      try {
        const formData = new FormData()
        formData.append('file', file)
        axiosService.post('/api/v1/user/resource?resource-type=avatar',
            formData,
            {
              headers: {
                'content-type': 'text/plain-text',
                'resource-type': 'avatar'
              }
            })
            .then((e) => {
              this.actionPushNotification({message: 'Your avatar was uploaded'})
            })
            .catch((s) => {
              console.log(s.error)
            })
      } catch (e) {
        console.log(e)
      }
    },
    goTest(val = 1) {
      switch (val) {
        case 1:
          this.$router.push({name: 'english-test'})
          break
        default:
      }
    },
    sendReferral() {
      this.email.success_text = ''
      this.email.error_text = ''
      this.email.loading = true
      axiosService.post('/api/v1/invite', {email: this.email.val})
          .then(r => {
            this.email.val = ''
            this.email.success_text = 'Your invitation has been sent'
          })
          .catch(e => {
            this.email.error_text = e.response.status === 429
                ? 'To many attempts. Try send invitation later'
                : e.response.data?.errors?.email[0] || DEFAULT_ERROR_REPS_TEXT
            console.log([e])
          })
          .finally(() => {
            this.email.loading = false
          })
    },
    updateUserAvatar(val) {
      this.user_avatar_file = val
    },
    setZoomUser(val) {
      if (val.status !== undefined && val.data !== undefined && val.data.error !== undefined) {
        const appClient = 'z44zPOkBQ7CsfeiOfMubJg'
        const authUrl = constants.BaseURL + '/api/v1/zoom/oauth/provide'
        this.$set(this, 'fetchAcc', `https://zoom.us/oauth/authorize?response_type=code&client_id=${appClient}&redirect_uri=${authUrl}`)
        return
      }
      if (val.user != null) {
        this.$set(this, 'zoom_user', {
          name: val?.user.first_name,
          email: val?.user.email,
          img: val?.user.pic_url,
          role: val?.user.role_name,
          family: val?.user.last_name,
          token: val?.token.token,
          renew: val?.token.renew,
        })
      } else {
        const appClient = 'z44zPOkBQ7CsfeiOfMubJg'
        const authUrl = constants.BaseURL + '/api/v1/zoom/oauth/provide'
        this.$set(this, 'fetchAcc', `https://zoom.us/oauth/authorize?response_type=code&client_id=${appClient}&redirect_uri=${authUrl}`)
      }
    },
    requestAuthToApp() {
      axiosService.post('/api/v1/zoom/users/invite')
          .then(e => {
            console.log(e)
          })
    },
    submitChangePassword() {
      this.change_password_error = ''
      this.change_password_success = ''
      axiosService.post(
          '/api/v1/user/change-password',
          {
            old_password: this.user_passwords.old_password.val,
            password: this.user_passwords.password.val,
            password_confirmation: this.user_passwords.password_confirm.val
          }
      )
          .then(r => {
            this.change_password_success = 'Password successfully updated'
            console.log(r)
          })
          .catch(e => {
            let error_text = ''
            for (let [key, value] of Object.entries(e.response?.data?.errors)) {
              error_text += `${(value[0] || '')} </br>`
            }
            this.change_password_error = error_text
          })
    }
  }
}
</script>

<style>
.invite-block {
  background: white;
  justify-content: center;
  padding-top: 1rem;
  display: flex;
  padding-bottom: 1rem;
}
</style>
