<template>
    <div>
        <h2 style="text-align: center;">English test</h2>
        <div class="quiz-wrapper">
            <div class="progress-panel">
                <div class="prev" @click="progress = Math.max(progress -1, 0)"><b-icon-chevron-left /></div>
                <div class="progress">
                    <progress-bar :width="200" v-model="progress" :max="40"></progress-bar>
                </div>
                <div class="next" @click="progress = Math.min(100, progress +1)"><b-icon-chevron-right /></div>
            </div>
            <slither-slider :options="{fullscreen:true, dots: true,overflowHiddenPadding: { top: 16, left: 16, right: 16, bottom: 4 },}">
                <div class="question" v-for="(item, key) of test" >
                    <div class="theme">
                        {{test[key]}}
                    </div>
                    <div class="ask">

                    </div>
                </div>
            </slither-slider>

            <div class="next-bottom">

            </div>
        </div>
        <div class="quiz">
            <template v-for="(item, key) of test" >
                <question v-model="test[key]" @selected="index => answers[key] = index"></question>
            </template>
        </div>
        <div style="display: block;width: max-content;margin-left: auto;margin-right: auto;"><button>Send result</button></div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Question from './Test/Question.vue'
import SlitherSlider from './Slider/SlitherApp.vue'

export default {
  name: 'EnglishTest',
  components: { SlitherSlider, Question },
  data: () => ({
    test: [], answers: [], progress: 0, max: 40
  }),
  computed: {
    ...mapGetters(['account'])
  },
  mounted () {
    this.user = this.account !== undefined ? this.account : { firstName: 'fname' }
    const chain = this.$post('/api/v1/quiz/start')
    chain.then((e) => {
      this.test = e.data.q
    })
  }
}
</script>

<style scoped>
    .quiz{
        display: grid;
        grid-template-columns: repeat(auto-fill, 400px);
        margin-left: auto;
        margin-right: auto;
        max-width: 1200px;
    }
    .progress-panel{
        height: 48px;
        font-size: 24px;
        background-color: #42b983;
        color: black;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-around;
        align-content: center;
    }
    .quiz-wrapper{
        display: block;
        max-width: 600px;
        margin: 2rem auto;
    }
</style>
